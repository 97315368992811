import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Typography, Button } from '@mui/material';
import { Grid } from '@mui/material';
import "./Invities.css";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReactCardFlip from 'react-card-flip';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from "react-router-dom";
import 'animate.css';

const itemData = [
    {
        id: 1,
        title: 'INVITACION 2 SEPTIEMBRE',
        lugar: 'EL GOLAZO',
        invitado: 'JUAN SALINAS PEREZ',
        color1: "card_reservation--add",
        color2: "card_reservation--change",
        textButton: "ACEPTAR INVITACION",
        textButton2: "ESTOY OCUPADO",
        stateFront:"cardF-state",
        stateBack:"cardB-state",
        invitaciones: [
            { nombre: "Andy", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
            { nombre: "Jordan", apellido: "Cornejo Chinga", rut: "2-33333", correo: "andy@gmal.com" },
            { nombre: "Kiara", apellido: "Medina Valencia", rut: "2-22222", correo: "andy@gmal.com" },
            { nombre: "Josselyn", apellido: "Lozano Medina", rut: "2-33333", correo: "andy@gmal.com" },
            { nombre: "Jhobany", apellido: "Joaquin Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
        ]
    },
    {
        id: 2,
        title: 'INVITACION 3 SEPTIEMBRE',
        lugar: 'EL GOLAZO',
        invitado: 'JUAN SALINAS PEREZ',
        color1: "card_reservation--add",
        color2: "card_reservation--change",
        textButton: "ACEPTAR INVITACION",
        textButton2: "ESTOY OCUPADO",
        stateFront:"cardF-state",
        stateBack:"cardB-state",
        invitaciones: [
            { nombre: "Andy", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
            { nombre: "Jordan", apellido: "Cornejo Chinga", rut: "2-33333", correo: "andy@gmal.com" },
            { nombre: "Kiara", apellido: "Medina Valencia", rut: "2-22222", correo: "andy@gmal.com" },
            { nombre: "Josselyn", apellido: "Lozano Medina", rut: "2-33333", correo: "andy@gmal.com" },
            { nombre: "Jhobany", apellido: "Joaquin Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
        ]
    },
    {
        id: 3,
        title: 'INVITACION 4 SEPTIEMBRE',
        lugar: 'EL GOLAZO',
        invitado: 'JUAN SALINAS PEREZ',
        color1: "card_reservation--add",
        color2: "card_reservation--change",
        textButton: "ACEPTAR INVITACION",
        textButton2: "ESTOY OCUPADO",
        stateFront:"cardF-state",
        stateBack:"cardB-state",
        invitaciones: [
            { nombre: "Andy", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
            { nombre: "Jordan", apellido: "Cornejo Chinga", rut: "2-33333", correo: "andy@gmal.com" },
            { nombre: "Kiara", apellido: "Medina Valencia", rut: "2-22222", correo: "andy@gmal.com" },
            { nombre: "Josselyn", apellido: "Lozano Medina", rut: "2-33333", correo: "andy@gmal.com" },
            { nombre: "Jhobany", apellido: "Joaquin Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
        ]
    },
    {
        id: 4,
        title: 'INVITACION 5 SEPTIEMBRE',
        lugar: 'EL GOLAZO',
        invitado: 'JUAN SALINAS PEREZ',
        color1: "card_reservation--add",
        color2: "card_reservation--change",
        textButton: "ACEPTAR INVITACION",
        textButton2: "ESTOY OCUPADO",
        stateFront:"cardF-state",
        stateBack:"cardB-state",
        invitaciones: [
            { nombre: "Andy", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
            { nombre: "Jordan", apellido: "Cornejo Chinga", rut: "2-33333", correo: "andy@gmal.com" },
            { nombre: "Kiara", apellido: "Medina Valencia", rut: "2-22222", correo: "andy@gmal.com" },
            { nombre: "Josselyn", apellido: "Lozano Medina", rut: "2-33333", correo: "andy@gmal.com" },
            { nombre: "Jhobany", apellido: "Joaquin Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
        ]
    },
    {
        id: 5,
        title: 'INVITACION 6 SEPTIEMBRE',
        lugar: 'EL GOLAZO',
        invitado: 'JUAN SALINAS PEREZ',
        color1: "card_reservation--add",
        color2: "card_reservation--change",
        textButton: "ACEPTAR INVITACION",
        textButton2: "ESTOY OCUPADO",
        stateFront:"cardF-state",
        stateBack:"cardB-state",
        invitaciones: [
            { nombre: "Andy", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
            { nombre: "Jordan", apellido: "Cornejo Chinga", rut: "2-33333", correo: "andy@gmal.com" },
            { nombre: "Kiara", apellido: "Medina Valencia", rut: "2-22222", correo: "andy@gmal.com" },
            { nombre: "Josselyn", apellido: "Lozano Medina", rut: "2-33333", correo: "andy@gmal.com" },
            { nombre: "Jhobany", apellido: "Joaquin Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
        ]

    },

];

export const Invities = () => {
    const [isFlipped, setIsFlipped] = React.useState()
    const [arrayInvites, setArrayInvites] = React.useState(itemData)
    const [render, setRender] = React.useState(false);

    const handleClickBack = (id) => {
        const newArray = arrayInvites;
        arrayInvites.map((item, index) => {
            if (item.id === id) {
                var value = { ...item, stateFront:"card-state-enableF", stateBack:"card-state-enableB"}
                newArray[index] = value
            }
            setArrayInvites(newArray);
            setIsFlipped(!isFlipped);
        })}
        const handleClickFront = (id) => {
            const newArray = arrayInvites;
            arrayInvites.map((item, index) => {
                if (item.id === id) {
                    var value = { ...item, stateFront:"cardF-state", stateBack:"cardB-state"}
                    newArray[index] = value
                }
                setArrayInvites(newArray);
                setIsFlipped(!isFlipped);
            })}
    
        
    const handleButtonInvities = (id) => {
        const newArray = arrayInvites;

        arrayInvites.map((item, index) => {
            if (item.id === id) {
                var value = { ...item, textButton: "INVITACION ACEPTADA", textButton2: "CANCELAR INVITACION", color1: "btn-acepted-invitate", color2: "card_reservation--add" }
                newArray[index] = value
            }
        })

        setArrayInvites(newArray);
        setRender(!render)
    }

    const handleButtonCanceled = (id) => {
        const newArray = arrayInvites;
        arrayInvites.map((item, index) => {
            if (item.id === id) {

                if (item.textButton2 == "ESTOY OCUPADO") {
                    var value1 = { ...item, textButton: "INVITACION RECHAZADA", textButton2: "", color1: "card_reservation--add", color2: "" }
                    newArray[index] = value1
                }
                if (item.textButton2 == "CANCELAR INVITACION") {
                    var value = { ...item, textButton: "ACEPTAR INVITACION", textButton2: "ESTOY OCUPADO", color1: "card_reservation--add", color2: "card_reservation--change" }
                    newArray[index] = value

                }

            }
        })
        setArrayInvites(newArray);
        setRender(!render)
    }


    const render1 = <Grid container mt={2}>
        {arrayInvites.map((item) => (
            <Grid 
            item xs={12} md={4} p={1}>
                <Card 
                key={item.id}
                sx={{ width: "100%", background: "#fff", borderRadius: "10px" }}>
                    {/* <p>{item.id}</p> */}
                    <CardActionArea 
                    className='contenedor'>
                        <CardContent
                        >
                            { <Grid key={item.id}>                        
                                <div className={item.stateFront}>
                                    <Grid item xs={12}>
                                        <Grid mb={-2}>
                                            <Typography textAlign={"right"}>
                                                <ContactMailIcon sx={{ color: "#b3b3b3" }} />
                                            </Typography>
                                        </Grid>

                                        <Typography style={{ fontWeight: "bolder", color: "#469489" }} gutterBottom variant="h6" component="div" ml={2} mt={1} >
                                            {item.title}
                                        </Typography>
                                        <Typography gutterBottom variant="subtitle1" component="div" ml={2} mt={0}>
                                            <span style={{ fontWeight: "bolder", color: "#191D3D" }}>LUGAR: </span>     {item.lugar}
                                        </Typography>
                                        <Typography gutterBottom variant="subtitle1" component="div" ml={2} mt={0}>
                                            <span style={{ fontWeight: "bolder", color: "#191D3D" }}>INVITA: </span>{item.invitado}
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="subtitle2"
                                            component="div"
                                            ml={2}
                                        >
                                            Invitados (9) <b onClick={() => {handleClickBack(item.id)}}>ver detalles</b>
                                        </Typography>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Button fullWidth variant='outlined' onClick={() => { handleButtonInvities(item.id) }} className={item.color1} >{item.textButton}</Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button fullWidth variant='outlined' onClick={() => { handleButtonCanceled(item.id) }} className={item.color2}>{item.textButton2}</Button>
                                        </Grid>

                                    </Grid>
                                </div>

                                <div className={item.stateBack}>
                                   {<Grid key={item.id}>
                                        <Grid item mt={2} mb={2}>
                                            <Typography className='card_reservation--club' gutterBottom variant="h5" component="div">
                                                <EventAvailableIcon className='card_reservation--club--icon' />Lista de invitados
                                            </Typography>
                                            <Grid ml={2}>
                                             { <List sx={{
                                                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                                                    maxHeight: 130,

                                                    overflow: 'auto',
                                                }}>
                                                    {item.invitaciones.map((value, index) => (
                                                        <ListItem
                                                            disableGutters
                                                        >
                                                            <Typography
                                                                className='card_invite--list'
                                                            >{`${value.nombre}, ${value.apellido}  `}</Typography>
                                                        </ListItem>
                                                    ))}
                                                </List>}
                                            </Grid>


                                        </Grid>
                                        <Grid mt={3} item container display='flex' alignContent='center' justifyContent='space-between'>

                                            <p onClick={() => handleClickFront(item.id)}><ArrowBackIosIcon sx={{ marginBottom: '-5px' }} fontSize="small" className='icon_back' /><b>Regresar</b></p>


                                        </Grid>
                                    </Grid>
                                    }
                                </div>
                            </Grid>
                            }
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        ))}
    </Grid>

    React.useEffect(() => {

    }, []);

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} mt={2}>
                    <Grid container justifyContent={"center"} mt={1} pl={1}>
                        <Grid item xs={12} md={12}>
                            <Grid xs={12}>
                                <Link to={'/modulos'} className='link__css'>
                                    <Typography variant='h6' gutterBottom className='link__css-back'>
                                        <ArrowBackIosIcon className='icon_back' /> Volver
                                    </Typography>
                                </Link>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant={"h5"} className="title__main">MIS INVITACIONES</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {render ? render1 : render1

                    }
                </Grid>
            </Grid>
        </>
    )
}