import { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, Stack, TextField } from '@mui/material';
import { Grid } from '@mui/material';
import "./ReservationInformation.css";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Invitados from '../../Components/Modal/Invitados';
import ReactCardFlip from 'react-card-flip';
import axios from "axios";
import moment from 'moment'
import 'moment/locale/es';
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import { ConnectingAirportsOutlined, Rocket } from "@mui/icons-material";
import LinearProgress from '@mui/material/LinearProgress';
import pidecancha from '../../assets/images/logo_circle.png';
import login_muestra from '../../assets/images/imagen_login_muestra.png';
import ListItemButton from '@mui/material/ListItemButton';
import * as qs from 'qs'

moment.locale('es');

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 400,
    },
}));


export const ReservationAssistance = (props) => {
    //BACKDROP DE CARGA
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [dataInfo, setDataInfo]=useState({
        date:'',
        end:'',
        field:'',
        name:'',
        start:'',
        surname:''
    });
    const {date, end, field, name,start,surname} =dataInfo;
    const [mensaje, setMensaje] = useState('');
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    const handleToggleBackdrop = () => {
        setOpenBackdrop(!openBackdrop);
    };

    const [ rut, setRut ] = useState('')
    const [ initial, setInitial ] = useState(true)
    const [ successResponse, setSuccessResponse ] = useState(false)
    const [ successResponse2, setSuccessResponse2 ] = useState(false)
    const [ errorResponse, setErrorResponse ] = useState(false)
    const [ message, setMessage ] = useState('')
    const [ reservation, setReservation ] = useState('')
    const [ loading, setLoading ] = useState(false)

    const ruta = props.location.search + '.';
//   const { r, pw } = props.location && qs.parse(ruta.slice(1, -1));

    // const ruta = 'http://localhost:3000/invitation?r=NTU1NTU1NTUtNQ==&ir=MzQ=.';
    const { id } = props.location && qs.parse(ruta.slice(1, -1));

    // const getDataInfo =  () => {
    //     if (r && ir) {
    //         var id;
    //         var rut;
    //         try {
    //             id = atob(ir);
    //             rut = atob(r);
    //             axios
    //                 .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/validateQR/${id}?rut=${rut}`
    //                 )
    //                 .then((response) => {
    //                     setDataInfo(response.data.data.data);
    //                     setMensaje(response.data.data.message);
    //                 })
    //                 .catch((error) => {
    //                     console.log("error: ", error)
    //                 });
    //         } catch (error) {
    //             console.log('error')
    //         }
    //     }
    // }

    // useEffect(() => {
    //     getDataInfo();
    // }, [])

    const getReservation = async () => {
        try {
            setLoading(true)
            await axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/getReservationQR?rut=${rut}&idfield=${id}`)
            .then((response) => {
                setInitial(false)
                if (response.data.data.bool) {
                    setReservation(response.data.data.data)
                    setSuccessResponse(true)
                } else {
                    setErrorResponse(true)
                }
                setMessage(response.data.data.message)
            })
            .catch((error) => {
                console.log(error)
            })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log('error')
        }
    }

    const onConfirm = async () => {
        try {
            setLoading(true)
            await axios
            .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/markQR/${id}`)
            .then((response) => {
                setInitial(false)
                if (response.data.data.bool) {
                    setSuccessResponse(false)
                    setSuccessResponse2(true)
                } else {
                    setErrorResponse(true)
                }
                setMessage(response.data.data.message)
            })
            .catch((error) => {
                console.log(error)
            })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log('error')
        }
    }

    return (
        <Grid container justifyContent="center" height={'100vh'} sx={{ background: 'rgb(241, 241, 241)' }} alignContent="start">
            <Grid container item xs={12} md={5} p={1} mb={1} mt={5} justifyContent="center" sx={{ background: "#fff", borderRadius: "15px" }}>
                <Grid item xs={12} mb={2} textAlign='center'>
                    <img style={{ width: 100 }} src={pidecancha}></img>
                    <Typography variant={"h5"} textAlign='center' className="title__main" gutterBottom>MARCAR ASISTENCIA DE LA RESERVA</Typography>
                </Grid>
                {
                    loading
                    ?
                    <Stack p={8} width="100%" direction="row" justifyContent="center">
                        <CircularProgress color="inherit"/>
                    </Stack>
                    :
                    <Grid container xs={12} item spacing={2}>
                    <>
                        {
                        initial
                        ?
                        <>
                        <Grid item xs={12} mb={2} textAlign='center'>
                            <Typography variant='body1' textAlign='center' gutterBottom>Ingrese su RUT de la persona que reservó para marcar la asistencia de sus reserva.</Typography>
                        </Grid>
                        <Grid item xs={12} md={12} p={1}>
                            <Grid item container justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography textAlign={'center'} fontWeight={"bolder"} gutterBottom variant="h6" color="#002545">
                                        Reserva en la Cancha {id}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                <TextField
                                required
                                id="outlined-required"
                                label="RUT"
                                size="small"
                                value={rut}
                                onChange={(e) => setRut(e.target.value)}
                                InputProps={{
                                    inputProps: {
                                        maxLength: 10
                                    }
                                }}
                                />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} mt={1} container justifyContent={"center"}>
                            <Grid item>
                                <Button fullWidth className='login_reservation' onClick={getReservation} sx={{ width: 150 }}>BUSCAR</Button>
                            </Grid>
                        </Grid>
                        </>
                        :
                        null
                        }
                        {
                            successResponse
                            ?
                            <>
                            <Grid item xs={12} md={12} p={1}>
                                <Grid item container justifyContent="center">
                                    {/* <Grid item xs={12}>
                                        <Typography textAlign={'center'} fontWeight={"bolder"} gutterBottom variant="h6" color="#002545">
                                            {message}
                                        </Typography>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <Typography textAlign={'center'} fontWeight={"bolder"} gutterBottom variant="h6" color="#002545">
                                            Confirme su reserva
                                        </Typography>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ margin: '0px auto', textAlign: 'center'}}>
                                            <spam style={{ fontWeight: "bold" }}>Reservador:</spam>
                                            <Typography variant='body1'>
                                                {reservation?.name + ' ' + reservation?.surname}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '0px auto', textAlign: 'center'}}>
                                            <spam style={{ fontWeight: "bold" }}>Fecha:</spam>
                                            <Typography variant='body1'>
                                                {reservation?.date}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '0px auto', textAlign: 'center'}}>
                                            <spam style={{ fontWeight: "bold" }}>Hora Inicio:</spam>
                                            <Typography variant='body1'>
                                                {reservation?.start}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} mt={1} container justifyContent={"center"}>
                                <Grid item>
                                    <Button fullWidth className='login_reservation' onClick={onConfirm} sx={{ width: 150 }}>CONFIRMAR</Button>
                                </Grid>
                            </Grid>
                            </>
                            :
                            null
                        }
                        {
                            errorResponse
                            ?
                            <Grid item xs={12} md={12} p={1}>
                                <Grid item container justifyContent="center">
                                    <Grid item xs={12}>
                                        <Typography textAlign={'center'} fontWeight={"bolder"} gutterBottom variant="h6" color="#FF0000">
                                            {message}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                        {
                            successResponse2
                            ?
                            <Grid item xs={12} md={12} p={1}>
                                <Grid item container justifyContent="center">
                                    <Grid item xs={12}>
                                        <Typography textAlign={'center'} fontWeight={"bolder"} gutterBottom variant="h6" color="#002545">
                                            {message}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                    </>
                </Grid>
                }
            </Grid>
            <Backdrop
                invisible
                sx={{ bgcolor: 'primary', color: '#f00', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
                onClick={handleCloseBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}