import { Box, Modal, Fade, Grid, Typography, CircularProgress, Stack, Card, CardContent } from "@mui/material"
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import { useEffect, useState } from "react"
import axios from "../../utils/axios"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    p: 4
}

export const ParticipantsModal = ({ rowSelected, open, onClose }) => {
    
    var Token = localStorage.getItem('token_user')
    
    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(false)

    useEffect(()=>{
        if (open === true) {
            getParticipants()
        }
    },[open])

    const getParticipants = async () => {
        setLoading(true)
        await axios
        .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/invites/${rowSelected.id}`, {
            headers: {
                Authorization: `Bearer ${Token}`,
            }
        })
        .then((response) => {
            setData(response.data.data.data)
        })
        .catch((error) => {
            console.error(error);
        })
        setLoading(false)
    }

    return(
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
        <Fade in={open}>
            <Box sx={style}>
                <Grid item mt={2} mb={2}>
                    <Typography className='card_reservation--club' gutterBottom variant="h5" component="div">
                        <EventAvailableIcon className='card_reservation--club--icon' />Lista de invitados
                    </Typography>
                    {
                        loading
                        ?
                        <Stack p={8} width="100%" direction="row" justifyContent="center">
                            <CircularProgress color="inherit"/>
                        </Stack>
                        :
                        data != null && (data.map(participant => {
                            return (
                                <Card sx={{ margin: '10px 0px'}}>
                                    <CardContent>
                                        <Typography variant="subtitle1" className='card_reservation--precio'>
                                            <b>NOMBRE: </b>
                                            { participant.name || '' } { participant.surname || '' }
                                        </Typography>
                                        <Typography variant="subtitle1" className='card_reservation--precio'>
                                            <b>RUT: </b>{ participant.rut || 'No registrado' }
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )
                        }))
                    }
                </Grid>
            </Box>
        </Fade>
    </Modal>
    )
}