import { IconButton, Grid, Typography, Divider } from "@mui/material"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import "./Schedule.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import CommitIcon from '@mui/icons-material/Commit';
import { useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useParams } from "react-router-dom";
import { session } from "../../session/constants";
import axios from "axios";
import { useHistory } from "react-router-dom";

export const HorarioCanchas = (props) => {
    const [isDay, setIsDay] = useState('');
    const [isHorario, setIsHorario] = useState(false);
    var token = sessionStorage.getItem(session.SESSION_TOKEN);
    const [listHorarios, setListHorarios]=useState([]);
    const date='2022-09-30';
    let history = useHistory();
    const idField=props.location.state.idField;

    function handleDay(day) {
        return fetch(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/list?idField=${idField}&idday=${day}`, {
            method: 'GET',
            // data: {
            //     "idField": props.location.state.idField,
            //     "idday": 
            // },
            headers: {
                // 'Access-Control-Allow-Origin': '*',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(pros => pros.json())
            .then(
                async pros => {
                    const data = (pros.data || []).map((raw) => ({
                        id: raw?.id,
                        start: raw?.start,
                        end: raw?.end,
                        duration: raw?.duration,                        
                    }))
                    setListHorarios(data);
                 }
            
            )
    }
    const handleReservacion = async (idHorary) => {
        try {
            const response = await axios({
                method: "post",
                url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data:{
                    "date" : "2022-09-30",
                    "idtype_reservation" : props.location.state.idtype_reservation,
                    "idhorary" : idHorary,
                    "a_iduser": props.location.state.a_iduser,
                    "a_idinvite": []
                }
            });
            history.push('/registro-exitoso');
        } catch (error) {
            console.log(error);
        }
    }

    var año = 2022;
    var mes = 8;

    var diasMes = new Date(año, mes, 0).getDate();
    var diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

    for (var dia = 1; dia <= diasMes; dia++) {
        // Ojo, hay que restarle 1 para obtener el mes correcto
        var indice = new Date(año, mes - 1, dia).getDay();
       
    }
   
    return (
        <>

            <Grid container justifyContent={"center"} mt={3} xs={12}>
                <Grid xs={10} mb={2}>
                    <Link to={'/lista-canchas'} className='link__css'>
                        <Typography variant='h6' gutterBottom className='link__css-back'>
                            <ArrowBackIosIcon className='icon_back' /> Volver
                        </Typography>
                    </Link>
                    <Typography variant={"h5"} className="title__main" gutterBottom> HORARIOS DISPONIBLES</Typography>
                </Grid>
                <Grid container item xs={7} mb={1} justifyContent={"center"}>
                    <Grid item justifyContent='center'>
                        <Typography ml={3} mb={1} variant="h6" color="#191D3D">{`Setiembre - 2022`}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent={"center"} item xs={12} md={10}>
                    {/* <Grid container  item xs={12} justifyContent={'center'}> */}
                    {/* <Grid item xs={1} mt={4}>
                            <IconButton className="ico--fondo">
                                <ChevronLeftIcon className="ico-day" />
                            </IconButton>
                        </Grid> */}
                    {/* <Grid container item xs={12}> */}
                    {/* <Grid container item spacing={1} justifyContent='center'> */}
                    <Grid item xs={1} mt={4}>
                        <IconButton className="ico--fondo">
                            <ChevronLeftIcon className="ico-day" />
                        </IconButton>
                    </Grid>

                    <Grid container item xs={3} md={1} justifyContent={"center"} alignItems="center">
                        <Grid item onClick={() => handleDay(1)} className={isDay == 1 ? 'day-active' : 'day'}>
                            <Typography textAlign={"center"} gutterBottom variant="h6">01</Typography>
                            <Typography textAlign={"center"} gutterBottom variant="subtitle1">JUEV.</Typography>
                        </Grid>
                    </Grid>

                    <Grid container item xs={3} md={1} justifyContent={"center"} alignItems="center">
                        <Grid item onClick={() => handleDay(2)} className={isDay == 2 ? 'day-active' : 'day'}>
                            <Typography textAlign={"center"} gutterBottom variant="h6">02</Typography>
                            <Typography textAlign={"center"} gutterBottom variant="subtitle1">VIER.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={3} md={1} justifyContent={"center"} alignItems="center">
                        <Grid item onClick={() => handleDay(3)} className={isDay == 3 ? 'day-active' : 'day'}>
                            <Typography textAlign={"center"} gutterBottom variant="h6">03</Typography>
                            <Typography textAlign={"center"} gutterBottom variant="subtitle1">SÁBA.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={3} md={1} justifyContent={"center"} alignItems="center">
                        <Grid item onClick={() => handleDay(4)} className={isDay == 4 ? 'day-active' : 'day'}>
                            <Typography textAlign={"center"} gutterBottom variant="h6">04</Typography>
                            <Typography textAlign={"center"} gutterBottom variant="subtitle1">DOMI.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={3} md={1} justifyContent={"center"} alignItems="center">
                        <Grid item onClick={() => handleDay(5)} className={isDay == 5 ? 'day-active' : 'day'}>
                            <Typography textAlign={"center"} gutterBottom variant="h6">05</Typography>
                            <Typography textAlign={"center"} gutterBottom variant="subtitle1">LUNE.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={3} md={1} justifyContent={"center"} alignItems="center">
                        <Grid item onClick={() => handleDay(6)} className={isDay == 6 ? 'day-active' : 'day'}>
                            <Typography textAlign={"center"} gutterBottom variant="h6">06</Typography>
                            <Typography textAlign={"center"} gutterBottom variant="subtitle1">MART.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={3} md={1} justifyContent={"center"} alignItems="center">
                        <Grid item onClick={() => handleDay(7)} className={isDay == 7 ? 'day-active' : 'day'}>
                            <Typography textAlign={"center"} gutterBottom variant="h6">07</Typography>
                            <Typography textAlign={"center"} gutterBottom variant="subtitle1">MIÉR.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} mt={4}>
                        <IconButton className="ico--fondo">
                            <ChevronRightIcon className="ico-day" />
                        </IconButton>
                    </Grid>
                    {/* <Grid container justifyContent={"right"}>
                                    <IconButton className="ico--fondo">
                                        <ChevronRightIcon className="ico-day" />
                                    </IconButton>
                                </Grid> */}
                    {/* </Grid> */}
                    {/* <Grid item xs={1} mt={4}>
                                <Grid container justifyContent={"right"}>
                                    <IconButton className="ico--fondo">
                                        <ChevronRightIcon className="ico-day" />
                                    </IconButton>
                                </Grid>
                            </Grid> */}
                    {/* </Grid> */}

                    {/* </Grid> */}

                </Grid>
            </Grid>

            <Grid container justifyContent={"center"} mt={5}>

            <Grid item xs={12} md={8} className="border-container">
                    <Grid item xs={12} className="fondo-hours">
                        <Typography gutterBottom color="#fff" mt={1}>Seleccione un horario para el día 0{isDay} de Setiembre</Typography>
                    </Grid>
                    <Grid container>
                            {listHorarios.map((row,i)=>{

                                return(
                                    <Grid className='horaiosPair' sx={{cursor:'pointer'}} item xs={12} md={4} onClick={() => handleReservacion(row.id)}>
                                        <Grid container  >                            

                                            <Grid xs={12} display="flex" justifyContent={"space-between"} >
                                                {/* <Grid display="flex"> */}
                                                <Grid>
                                                    <Typography textAlign={"center"} color="#9b9b9b" gutterBottom variant="subtitle2">INICIO</Typography>
                                                    <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">{row.start}</Typography>
                                                </Grid>
                                                <Grid mt={2}>
                                                    <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">-</Typography>
                                                </Grid>
                                                <Grid>
                                                    <Typography textAlign={"center"} color="#9b9b9b" gutterBottom variant="subtitle2">FIN</Typography>
                                                    <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">{row.end}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}

                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}
