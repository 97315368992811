import { AuthAPI, ProfileAPI} from '../apis'
import { onLogin, onOpenError, onOpenSuccess } from '../redux/slices/authStore'
import { useDispatch, useSelector } from '../redux/store'
import { session } from '../session/constants'
import { useHistory } from "react-router-dom";

export const useAuthStore = () => {
  const dispatch = useDispatch()
  const history = useHistory();
  const { status, user, errorMessage, openSuccess, openError} = useSelector(state => state.auth)

  const setProfileData = async (access_token) => {
    try {
      const perfil = await ProfileAPI.get(access_token)
      console.log(perfil)
      // saveLocalStorage(LocalStorageKey.USER_DATA, detail)
      setOpenSuccess(true);
      sessionStorage.setItem(session.SESSION_ID_USER, perfil?.id_user);
      sessionStorage.setItem(session.SESSION_TYPE_USER, perfil?.type_user);
      // handleCloseBackdrop();
      loadLogin(sessionStorage.getItem(session.SESSION_TYPE_USER));
      localStorage.setItem('token_user', `Bearer ${access_token}`);
      localStorage.setItem('data_user', JSON.stringify(perfil));
      dispatch(onLogin(perfil))
      return perfil
    } catch (error) {
      console.error(error)
    }
  }

  const login = async ({ password, rut }) => {
    try {
      const login = await AuthAPI.login( password, rut)
        console.log('login', login)
      if (!login?.access_token) {
        setOpenError(true)
        throw new Error()
      }
      const { access_token } = login
        sessionStorage.setItem(session.SESSION_STATE, session.SESSION_ON);
        sessionStorage.setItem(session.SESSION_TOKEN, access_token);
      // saveLocalStorage(LocalStorageKey.TOKEN_KYTE, access_token)
      const detail = await setProfileData(`Bearer ${access_token}`)
      console.log('detail',detail)
      // toast.success('Bienvenido')
      return {
        status: true,
        detail
      }

    } catch (error) {
      // dispatch(onLogout('Credenciales Incorrectas'))
      // toast.error('Credenciales Incorrectas')
      // setTimeout(() => {
      //   dispatch(clearErrorMessage())
      // }, 500)
      return {
        status: false,
        detail: {}
      }
    }
  }

  const autologin = async ({ password, rut }) => {
    try {
      const login = await AuthAPI.autologin( password, rut)
        console.log('login', login)
      if (!login?.access_token) {
        setOpenError(true)
        throw new Error()
      }
      const { access_token } = login
        sessionStorage.setItem(session.SESSION_STATE, session.SESSION_ON);
        sessionStorage.setItem(session.SESSION_TOKEN, access_token);
      // saveLocalStorage(LocalStorageKey.TOKEN_KYTE, access_token)
      const detail = await setProfileData(`Bearer ${access_token}`)
      console.log('detail',detail)
      // toast.success('Bienvenido')
      return {
        status: true,
        detail
      }

    } catch (error) {
      // dispatch(onLogout('Credenciales Incorrectas'))
      // toast.error('Credenciales Incorrectas')
      // setTimeout(() => {
      //   dispatch(clearErrorMessage())
      // }, 500)
      return {
        status: false,
        detail: {}
      }
    }
  }

   const setOpenSuccess = () => {
    dispatch(onOpenSuccess(true))
  }
  const setCloseSuccess = () => {
    dispatch(onOpenSuccess(false))
  }

  const setOpenError = () => {
    dispatch(onOpenError(true))
  }
  const setCloseError = () => {
    dispatch(onOpenError(false))
  }

  const  loadLogin = (perfilSesion) => {
    return new Promise(resolve => {
      setTimeout(() => {
        if (perfilSesion === 'ADMINISTRADOR') {
          history.push('/modulos-administrador');
          window.location.reload();
        } else {
          history.push('/modulos');
          window.location.reload();
        }
      }, 3000);
    });
  }
    const verifySession = async () => {
    var token = localStorage.getItem('token_user');
    try {
        const detail = await setProfileData(token)
    } catch (error) {

    }
  }


  // const verifySession = async () => {
  //   dispatch(onVerifying())
  //   const token = readLocalStorage(LocalStorageKey.TOKEN_KYTE)
  //   if (!token) return logout()
  //   try {
  //     const urlRegex = /^\/\w+\?id=\w+&&idbranch=\w+$/;
  //     const urlProfessional = location.pathname + location.search
  //     const isTokenValid = await AuthAPI.verifyToken(token)
  //     const isUrlValid = urlRegex.test(urlProfessional)
  //     if (isTokenValid) {
  //       const detail = await setProfileData(token)
  //       dispatch(onChangeAuthStatus(AuthStatus.AUTHENTICATED))
  //       if ([4,8,9].includes(detail?.idmembership)) {
  //        setTimeout(() => {
  //           navigate({ pathname: detail?.url_redirect })
  //         }, 1000)
  //        //  navigate({ pathname: detail?.url_redirect })
  //         return
  //       }
  //       if (location.pathname.includes('/create-reservation-client/')) {
  //         navigate({ pathname: location.pathname, search: location.search })
  //         return
  //       }
  //       if (isUrlValid){
  //         navigate({ pathname: location.pathname, search: location.search })
  //         return
  //       }
  //       /* if (location.pathname.includes('/profesional-detalle/')) {
  //         navigate({ pathname: location.pathname, search: location.search })
  //         return
  //       } */
  //       if (location.pathname === '/reserva-exitosa') {
  //         navigate({ pathname: location.pathname, search: location.search })
  //         return
  //       }
  //       if (location.pathname === '/create-reservation-reprogramacion') {
  //         navigate({ pathname: location.pathname, search: location.search })
  //         return
  //       }
  //       navigate({ pathname: location.pathname })
  //       return
  //     } else {
  //       await refreshToken(token)
  //     }
  //     localStorage.clear()
  //     dispatch(onLogout())
  //     toast.error('Su sesión expiró')
  //   } catch (error) {
  //     toast.error('Su sesión expiró')
  //     localStorage.clear()
  //     dispatch(onLogout())
  //   }
  // }

  // const refreshToken = async (token: string) => {
  //   try {
  //     const session = await AuthAPI.refresh(token)
  //     if (!session?.authorisation?.token) {
  //       localStorage.clear()
  //       dispatch(onLogout())
  //       toast.error('Su sesión expiró')
  //     } else {
  //       const { token } = session.authorisation
  //       await setProfileData(token)
  //       saveLocalStorage(LocalStorageKey.TOKEN_KYTE, token)
  //       dispatch(onRefreshToken(session.user))

  //       //?test=hola?test2=hola2
  //       if (location.pathname === '/reserva-exitosa') {
  //         navigate({ pathname: location.pathname, search: location.search })
  //         return
  //       }
  //       navigate({ pathname: location.pathname })
  //     }
  //   } catch (error) {
  //     console.error('[Error whilte refreshing token]', error)
  //   }
  // }

  // const RecoveryPassword = async (perfil: number, rut: string, email?:string) => {
  //   try {
  //     if (perfil == 1) {
  //       openMailRecover(true)
  //       setRut(rut)
  //       return
  //     }
  //     const pass = await AuthAPI.recoveryPass(perfil, rut, email)
  //     if (!pass?.status)
  //     {
  //       toast.error('rut invalido')
  //       return
  //     }
  //     toast.success('Se le envio un correo con las credenciales')
  //   } catch (error) {
  //     console.error('Error', error)
  //   }
  // }

  // const logout = async () => {
  //   try {
  //     await AuthAPI.logout()
  //     localStorage.clear()
  //     dispatch(onLogout())
  //   } catch (error) {
  //     console.error(error)
  //   }

  // }

  // const credentials_params = async (rut: string, iduser_type: number) => {
  //   try {
  //     const res =  await AuthAPI.credentials_params(iduser_type, rut)
  //     return res?.data?.detail
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  return {
    //states
    status,
    user,
    errorMessage,
    openSuccess,
    openError,
    //actions
    login,
    autologin,
    setProfileData,
    setOpenSuccess,
    setCloseSuccess,
    setOpenError,
    setCloseError,
    verifySession
  }
}
