import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./Login.css"
import { useHistory } from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/Lock';
import fondo from '../../assets/images/fondo2_old.jpg';
import PersonIcon from '@mui/icons-material/Person';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import logo from '../../assets/images/logo2_old.png';
import { ModuleSeleccion } from '../ModuleMain/ModuleSeleccion';
import * as qs from 'qs'
import pidecancha from '../../assets/images/logo1_old.png'
//import pidecancha from '../../assets/images/logopidecancha.png';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { actionSetListNotification } from '../../redux/actions';
import { session } from '../../session/constants';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuthStore } from '../../hooks/useAuthStore';

const theme = createTheme();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Login= (props) => {
  //BACKDROP DE CARGA
  const [openBackdrop, setOpenBackdrop] = React.useState(true);
  const [values, setValues] = React.useState({
    rut: "",
    password: ""
  });
  const { rut, password, } = values;

  const [error, setError] = React.useState(null);
  const { login, autologin, setProfileData, user, setCloseSuccess, openSuccess, openError, setOpenError, setCloseError } = useAuthStore()

  const [snackBarConfig, setSnackBarConfig] = React.useState({
    open: false,
    severity: 'error',
    message: 'Error',
    autoHideDuration: 3000,
  })
  const ruta = props.location.search + '.';
  const { r, pw } = props.location && qs.parse(ruta.slice(1, -1));

  React.useEffect(() => {
    if (r && pw) {
      var rut;
      var password;
      try {
        rut = atob(r);
        password = pw;
        // const respuesta = Autologin(rut, password)
        localStorage.setItem('passwordEncrypt', password);
        autologin({ password: password, rut: rut })

      } catch (error) {

      }
    }
  }, []);

  const RecoveryPassword = async () => {

    if (!values.rut) {

      setSnackBarConfig(prev => ({
        ...prev,
        open: true,
        severity: 'warning',
        message: "Debe rellenar el campo rut",
      }));
      return 'hola'
    }
    try {


      const response = await axios
        .post(
          `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/auth/recover-password/${values.rut}`
        )
        .catch((err) => {
          console.log("Err: ", err);
        });

      console.log(response)
      setSnackBarConfig(prev => ({
        ...prev,
        open: true,
        severity: 'success',
        message: "Se le envio un correo con las credenciales ",
      }));
    } catch (error) {
    }

  }

  const handleToggleBackdrop = () => {
    setOpenBackdrop(!openBackdrop);
  };


  const hanldeRegister = () => {
    history.push("/registro")
    window.location.reload();
  }

  let history = useHistory();

  function handleLogin(e) {
    setError('');
    if (e.target.name == 'rut') {
      var foo = e.target.value
      if (foo.length > 0 && foo.length < 11) {
        setValues(prev => ({ ...prev, rut: foo, textError: '' }))
      } else if (foo.length == 0) {
        setValues(prev => ({ ...prev, rut: "", textError: '' }))
      }

    } else {

      const changedFormValues = {
        ...values,
        [e.target.name]: e.target.value,
      };
      changedFormValues.rut.trim() !== "" && setError("");
      changedFormValues.password.trim() !== "" && setError("");
      setValues(changedFormValues);
    }
  }

  const validaterut = async (rut) => {
    // store the states in the form data
    // e.preventDefault();
    try {
      // make axios post request
      const response = await axios
        .get(`https://api.libreapi.cl/rut/validate?rut=${rut}`)
        .catch((err) => {
          console.log("Err: ", err);
        });
      if (response.data.data.valid == true) {
        return true;
      }

    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    if (!rut.trim()) {
      return setError("rut");
    }
    if (!(/^(\d{1,2}(?:[\.]?\d{3}){2}-[\dkK])$/).test(rut)) {
      return setError("rut_invalid");
    }
    const respuesta_rut = await validaterut(rut);
    console.log('esperando rut: ', respuesta_rut);
    if (!respuesta_rut) {
      return setError("rut_invalid");
    }
    if (!password.trim()) {
      return setError("password");
    }
    try {
      handleToggleBackdrop();
      localStorage.setItem('type_login', 'normal');
      login({ password: values?.password, rut: values?.rut })
    } catch (error) {
      console.log('Error fuera del catch', error);;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }} justifyContent="center" >
        <CssBaseline />
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          sx={{
            backgroundImage: `url(${fondo})`,
            backgroundRepeat: 'no-repeat',
            zIndex: 0,
            // backgroundColor: (t) =>
            //   t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Grid item container xs={12} sm={8} md={10} square>
            <Grid
              container
              item
              alignItems={'center'}
            >

              <Grid p={1} justifyContent={"center"} container>

                <Grid item xs={12} md={7} sx={{ display: { md: 'block', xs: 'none' }, textAlign: 'center' }} mb={2}>
                 
                </Grid>

                <Grid item container xs={12} md={5} sx={{ background: "rgb(255, 255, 255)", borderRadius: "20px" }} p={2}>
                  <Grid sx={{ background: "#BA3B41", height: "5px", borderRadius: "10px 10px 0px 0px ", width: "100%" }}></Grid>
                  <Typography variant="h2" align='center'>
                      <img src={pidecancha} width={'100%'}></img>
                    </Typography>
                  <form >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="rut"
                      placeholder="RUT"
                      autoComplete={'off'}
                      name="rut"
                      error={error === "rut" || error == "rut_invalid" ? true : false}
                      helperText={error === "rut" ? "El RUT es un campo requerido" : error == "rut_invalid" ? "Ingrese un rut válido" : ""}
                      value={values.rut}
                      onChange={handleLogin}
                      className='input-login'
                      variant='outlined'
                      sx={{ input: { color: '#000', fontSize: '18px', marginBottom: '1px' } }}
                    // error= {renderErrorMessage("rut")}

                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      name="password"
                      placeholder="Clave"
                      className='input-login'
                      type="password"
                      error={error === "password" ? true : false}
                      helperText={error === "password" ? "El password es un campo requerido" : ""}
                      value={values.password}
                      onChange={handleLogin}
                      variant='outlined'
                      id="password"
                      sx={{ input: { color: '#000', fontSize: '18px', marginBottom: '1px' } }}
                      autoComplete="current-password"
                    // error={renderErrorMessage("clave")}
                    />



                    <Grid container mt={1} justifyContent='center'>
                      <Link onClick={() => { RecoveryPassword() }} className='forgot-password'>
                        ¿Olvidaste tu contraseña?
                      </Link>
                    </Grid>
                    <Grid container spacing={1} mt={2}>
                      <Grid item xs={12} md={6}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          className='btn-login'
                          onClick={handleSubmitLogin}
                        >
                          Iniciar sesión
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {/* <Link to={'/registro'} style={{ textDecoration: 'none' }}> */}
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          className='btn-create'
                          sx={{ mb: 2 }}
                          // component={Link} to={'/registro'}
                          onClick={hanldeRegister}
                        >
                          Crear cuenta externo
                        </Button>
                        {/* </Link> */}
                        {/* <hr/> */}
                      </Grid>

                    </Grid>

                  </form>
                </Grid>


              </Grid>

            </Grid>
          </Grid>

        </Grid>

      </Grid>
      {/* <Backdrop
        invisible
        sx={{ bgcolor: 'primary', color: '#f00', zIndex: 1000 }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Snackbar
        open={snackBarConfig.open}
        autoHideDuration={snackBarConfig.autoHideDuration}
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
          severity={snackBarConfig.severity}
          variant="filled"
        >
          {snackBarConfig.message}
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={3000} onClose={setCloseError}>
        <Alert onClose={setCloseError} severity="error" sx={{ width: '100%' }}>
          RUT y/o clave incorrecto(s)
        </Alert>
      </Snackbar>
      <Snackbar open={openSuccess} autoHideDuration={3000} onClose={setCloseSuccess}>
        <Alert onClose={setCloseSuccess} severity="success" sx={{ width: '100%' }}>
          ¡Bienvenido a Oldmackayans
        </Alert>
      </Snackbar>
      {/* <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          Para recuperar su cuenta, primero ingrese su RUT
        </Alert>
      </Snackbar> */}
    </ThemeProvider>
  )
}

// const mapStateToProps = ({ NotificationReducer }) => ({
//   $store: {
//     NotificationReducer,
//   },
// });
// const mapDispatchToProps = (dispatch) => ({
//   $action: bindActionCreators(
//     {
//       actionSetListNotification: actionSetListNotification
//     },
//     dispatch
//   ),
// });

// export const Login = compose(
//   withRouter,
//   connect(mapStateToProps, mapDispatchToProps)
// )(LoginView);