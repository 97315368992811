import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import "./Main.css";

import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Link } from "react-router-dom";

export const Main = () => {

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} mt={2}>
                    <Grid container justifyContent={"center"} mt={1} pl={1}>
                        <Grid item xs={12} md={12}>
                            <Grid xs={12}>
                                <Link to={'/modulos'} className='link__css'>
                                    <Typography variant='h6' gutterBottom className='link__css-back'>
                                        <ArrowBackIosIcon className='icon_back' /> Volver
                                    </Typography>
                                </Link>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant={"h5"} className="title__main">MIS CANCHAS FAVORITAS</Typography>
                                    </Grid>
                                    {/* <Grid item xs={4} md={2}>
                                <Button fullWidth className="btn-agregar" onClick={() => abrirModalInsertar()} startIcon={<AddCircleOutlineIcon />}>AGREGAR</Button>
                            </Grid> */}
                                </Grid>
                                <Typography variant='body1' gutterBottom>Seleccione su cancha a reservar</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container mt={2}>
                        {itemData.map((item) => (
                            <Grid item xs={12} md={6} p={1}>
                                <Link to={`/registro-exitoso`}>
                                    <Card sx={{ width: "100%", background: "#fff", borderRadius: "10px" }}>
                                        <CardActionArea className='contenedor'>
                                            <CardMedia
                                                component="img"
                                                height="180"
                                                image={item.img}
                                            />
                                            <Grid container className='texto-encima'>
                                                <Grid item xs={10}>
                                                    <Typography gutterBottom variant="h6" component="div" ml={2} mt={1} className="texto-card">
                                                        {item.title}
                                                    </Typography>
                                                    <Typography gutterBottom variant="caption" component="div" ml={2} mt={0} className="texto-card2">
                                                        {item.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton>
                                                        <FavoriteIcon sx={{ color: "red", marginLeft: "50px" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
const itemData = [
    {
        img: 'https://cdn.pixabay.com/photo/2015/10/19/16/47/ash-996321_960_720.jpg',
        title: 'Cancha 1',
    },
    {
        img: 'https://cdn.pixabay.com/photo/2015/10/19/16/47/ash-996321_960_720.jpg',
        title: 'Cancha 2',
    },


];

