import React, { useEffect, useState } from "react"
import { Button, Grid, TextField, TableHead, Typography, TextFieldaphy } from "@mui/material"
import "./Field.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import excel from '../../../assets/icons/excel-icon.png';
import agregar from '../../../assets/icons/agregar.png';
import KeyIcon from '@mui/icons-material/Key';
import axios from "../../../utils/axios";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 380,
    },
}));


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const dataInvitados = [
    { id: 1, nombre: "Jordan", apellidos: 'Toledo', rut: "1-222222", correo: "jordan@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 1" },
    { id: 2, nombre: "Jaime", apellidos: 'Lopez', rut: "3-222222", correo: "jaime@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 2" },
    { id: 3, nombre: "Jhon", apellidos: 'Perez', rut: "4-222222", correo: "jhon@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 3" },
    { id: 4, nombre: "Carlos", apellidos: 'Quizpe', rut: "5-222222", correo: "carlos@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 4" },
];

export const Field = () => {
    const [idUsuario, setIdUsuario] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [modalEnviar, setModalEnviar] = useState(false);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [data, setData] = useState(dataInvitados);
    const [search, setSearch] = useState("");
    const [archivo, setArchivo] = useState("");
    const [token, setToken] = useState(
        sessionStorage.getItem("Token")
    );
    const [invitadoSeleccionado, setInvitadoSeleccionado] = useState({
        id: '',
        name: '',
        description: '',
        price: '',
        capacity: '',
        idType_field: 1,
        idCompany: 1,
        image_1: '',
        image_2: ''
    });
    const [fields, setFiels] = useState({
        id: '',
        name: '',
        description: '',
        price: '',
        capacity: '',
        idType_field: 1,
        idCompany: 1,
        image_1: '',
        image_2: ''
    });

    const getField = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/list", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setFiels(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleChange = () => {

    }

    const handleSubmit = async (e) => {
        // store the states in the form data
        const dataFile = FormData();
        dataFile.append("name", fields.name);
        dataFile.append("description", fields.description);
        dataFile.append("price", fields.price);
        dataFile.append("image_1", fields.image_1);
        dataFile.append("image_2", fields.image_2);
        // e.preventDefault();
        try {
            const response = await axios({
                method: "post",
                url: "https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/Register",
                data: dataFile
            });
            console.log(response);

            // make axios post request
        } catch (error) {
            console.log(error)
        }
    };

    const limpiaDatos = () => {
        setFiels((userValues) => ({
            ...userValues,
            id: '',
            name: '',
            description: '',
            price: '',
            capacity: '',
            idType_field: 1,
            idCompany: 1,
            image_1: '',
            image_2: ''
        }));
    };


    const seleccionarInvitado = (elemento, caso) => {
        setInvitadoSeleccionado(elemento);
        switch (caso) {
            case 'Editar':
                setModalEditar(true)
                break;
            case 'Eliminar':
                setModalEliminar(true)
                break;
            default:
                setModalEnviar(true);
                break;
        }
        // (caso === 'Editar') ? setModalEditar(true) : setModalEliminar(true)
    }

    const handleChangeField = e => {
        setFiels((userValues) => ({
            ...userValues,
            [e.target.name]: e.target.value,
        }))
    }

    const editar = () => {
        var dataNueva = data;
        dataNueva.map(invitado => {
            if (invitado.id === invitadoSeleccionado.id) {
                invitado.apellidos = invitadoSeleccionado.apellidos;
                invitado.nombre = invitadoSeleccionado.nombre;
                invitado.rut = invitadoSeleccionado.rut;
                invitado.correo = invitadoSeleccionado.correo;
                invitado.direccion = invitadoSeleccionado.direccion;
                invitado.comuna = invitadoSeleccionado.comuna
            }
        });
        setData(dataNueva);
        setModalEditar(false);
    }

    const enviar = () => {
        setModalEnviar(false);
    }

    const eliminar = () => {
        setData(data.filter(invitado => invitado.id !== invitadoSeleccionado.id));
        setModalEliminar(false);
    }

    const abrirModalInsertar = () => {
        setInvitadoSeleccionado(null);
        setModalInsertar(true);
    }

    const insertar = () => {
        var valorInsertar = invitadoSeleccionado;
        valorInsertar.id = data[data.length - 1].id + 1;
        var dataNueva = data;
        dataNueva.push(valorInsertar);
        setData(dataNueva);
        setModalInsertar(false);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const classes = useStyles();

    const subirArchivo = (e) => {
        const { name, value } = e.target;
        setArchivo((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }
    useEffect(() => {
        setData([...data])
        getField();
    }, [])
    useEffect(() => {
        console.log("El archivo excel subido es: ", archivo);
    }, [archivo])
    return (
        <>
            <Grid container className="containerInvite">
                <Grid container justifyContent={"center"} mt={2}>
                    <Grid item xs={12} md={10}>
                        <Grid xs={12} mb={2}>
                            <Link to={'/modulos-administrador'} className='link__css'>
                                <Typography variant='h6' gutterBottom className='link__css-back'>
                                    <ArrowBackIosIcon className='icon_back' /> Volver
                                </Typography>
                            </Link>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant={"h5"} className="title__main">CANCHAS</Typography>
                                </Grid>
                                <Grid item xs={8} md={6} mt={1}>
                                    <Paper
                                        component="form"
                                        sx={{ p: '0px 2px', display: 'flex', alignItems: 'center' }}
                                    >

                                        <InputBase
                                            // value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            on
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Buscador"
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>


                                    </Paper>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    {/* <Button fullWidth className="btn-agregar" ></Button> */}
                                    <Grid display="flex" justifyContent={"flex-end"}>
                                        <IconButton sx={{ marginLeft: '5px' }} >
                                            <img className="btn-agregar-excel" src={excel}></img>
                                            <input
                                                name="file_excel"
                                                className='contenedor_file--input'
                                                multiple type="file"
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                onChange={(e) => subirArchivo(e)} />
                                        </IconButton>
                                        <IconButton sx={{ marginLeft: '5px' }}
                                            onClick={() => abrirModalInsertar()} >
                                            <img className="btn-agregar-excel" src={agregar}></img>

                                        </IconButton>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Typography variant='body1' gutterBottom>Tienes {data.filter(item => item.nombre.toLowerCase().includes(search.toLowerCase())).length} canchas registradas.</Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} mt={3}>
                        <TableContainer component={Paper} >
                            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                <TableHead >
                                    <TableRow style={{ background: "#191D3D" }}>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Nombre</TableCell>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Descripcion</TableCell>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Precio</TableCell>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Capacidad</TableCell>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Imagen 1</TableCell>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Imagen 1</TableCell>
                                        <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? fields.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : fields
                                    ).filter(item => item.nombre.toLowerCase().includes(search.toLowerCase()) || item.rut.toLowerCase().includes(search.toLowerCase()))
                                        .map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" className="cell1">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell className="cell">
                                                    {row.description}
                                                </TableCell>
                                                <TableCell className="cell">
                                                    {row.price}
                                                </TableCell>
                                                <TableCell className="cell">
                                                    {row.capacity}
                                                </TableCell>
                                                <TableCell className="cell">
                                                    {row.image_1}
                                                </TableCell>
                                                <TableCell className="cell">
                                                    {row.image_1}
                                                </TableCell>
                                                <TableCell>
                                                    <Grid container spacing={1}>
                                                        {/* <Grid item xs={4}>
                                                            <IconButton onClick={() => seleccionarInvitado(row, 'EnviarLlave')}>
                                                                <KeyIcon style={{ color: "#e4980d " }} />
                                                            </IconButton>
                                                        </Grid> */}
                                                        <Grid item xs={4}>
                                                            <IconButton onClick={() => seleccionarInvitado(row, 'Editar')}>
                                                                <EditIcon style={{ color: "#469489 " }} />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <IconButton onClick={() => seleccionarInvitado(row, 'Eliminar')}>
                                                                <DeleteIcon style={{ color: "#b10202" }} />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={3}
                                            count={fields.filter(item => item.nombre.toLowerCase().includes(search.toLowerCase())).length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                TextFieldProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

            </Grid>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEditar}
                onClose={() => setModalEditar(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEditar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                EDITAR SOCIOS
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        readOnly
                                        type="text"
                                        name="id"
                                        fullWidth
                                        value={invitadoSeleccionado && invitadoSeleccionado.id}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        name="nombre"
                                        fullWidth
                                        value={invitadoSeleccionado && invitadoSeleccionado.nombre}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        fullWidth
                                        name="apellidos"
                                        value={invitadoSeleccionado && invitadoSeleccionado.apellidos}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        fullWidth
                                        name="rut"
                                        value={invitadoSeleccionado && invitadoSeleccionado.rut}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        fullWidth
                                        name="correo"
                                        value={invitadoSeleccionado && invitadoSeleccionado.correo}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        fullWidth
                                        name="direccion"
                                        value={invitadoSeleccionado && invitadoSeleccionado.direccion}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        fullWidth
                                        name="comuna"
                                        value={invitadoSeleccionado && invitadoSeleccionado.comuna}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEditar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => editar()}>Actualizar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEliminar}
                onClose={() => setModalEliminar(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEliminar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                Estás Seguro que deseas eliminar el socio {invitadoSeleccionado && invitadoSeleccionado.nombre}
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEliminar(false)}>NO</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => eliminar()}>Si</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEnviar}
                onClose={() => setModalEnviar(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEnviar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                ¿Enviar llave privada al socio {invitadoSeleccionado && invitadoSeleccionado.nombre}?
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEnviar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => enviar()}>Enviar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalInsertar}
                onClose={() => modalInsertar(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalInsertar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                AGREGAR CANCHA
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="text"
                                        name="name"
                                        fullWidth
                                        placeholder="Nombre"
                                        onChange={handleChangeField}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        size="small"
                                        name="description"
                                        placeholder="Descripcion"
                                        fullWidth
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.nombre : ''}
                                        onChange={handleChangeField}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField

                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="price"
                                        placeholder="Precio*"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.apellidos : ''}
                                        onChange={handleChangeField}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField

                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="capacity"
                                        placeholder="Capacidad*"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.rut : ''}
                                        onChange={handleChangeField}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="file"
                                        fullWidth
                                        name="image_1"
                                        placeholder="Imagen 1*"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.correo : ''}
                                        onChange={handleChangeField}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="file"
                                        fullWidth
                                        name="image_2"
                                        placeholder="Image 2"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.direccion : ''}
                                        onChange={handleChangeField}
                                    />
                                </Grid>

                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalInsertar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => insertar()}>Agregar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}