import React, { useEffect, useState } from "react"
import { Button, Grid, TextField, TableHead, Typography, TextFieldaphy, Tooltip } from "@mui/material"
import "./ListTeacher.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import excel from '../../../assets/icons/excel-icon.png';
import agregar from '../../../assets/icons/agregar.png';
import KeyIcon from '@mui/icons-material/Key';
import axios from "../../../utils/axios";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import BlockIcon from '@mui/icons-material/Block';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 350,
    },
}));


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const dataInvitados = [
    { id: 1, nombre: "Jordan", apellidos: 'Toledo', rut: "1-222222", correo: "jordan@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 1" },
    { id: 2, nombre: "Jaime", apellidos: 'Lopez', rut: "3-222222", correo: "jaime@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 2" },
    { id: 3, nombre: "Jhon", apellidos: 'Perez', rut: "4-222222", correo: "jhon@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 3" },
    { id: 4, nombre: "Carlos", apellidos: 'Quizpe', rut: "5-222222", correo: "carlos@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 4" },
];


export const ListProfesores = () => {
    const [value, setValue] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [modalRestore, setModalRestore] = useState(false);
    var user = JSON.parse(localStorage.getItem('data_user'))
    const [modalEnviar, setModalEnviar] = useState(false);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [data, setData] = useState(dataInvitados);
    const [search, setSearch] = useState("");
    const [archivo, setArchivo] = useState("");
    const [teachersActive, setTeachersActive] = useState([])
    const [teachersInactive, setTeachersInactive] = useState([])
    const [teacherSelected, setTeacherSelected] = useState({
        id: '',
        name: '',
        surname: '',
        rut: '',
        birthday: '',
        email: '',
        idTypeUser: 5,
        amount: 0
        // idCompany: 1,
        // rutcompany: '2222222-2'
    })
    const [token, setToken] = useState(
        sessionStorage.getItem("Token")
    );
    const [snackBarConfig, setSnackBarConfig] = React.useState({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })
    // const [invitadoSeleccionado, setInvitadoSeleccionado] = useState({
    //     id: '',
    //     nombre: '',
    //     apellidos: '',
    //     rut: '',
    //     correo: '',
    //     direccion: '',
    //     comuna: ''
    // });
    const [openError, setOpenError] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [messageSuccess, setMessageSuccess] = React.useState("");
    const [modalEliminar2, setModalEliminar2] = useState(false)

    const handleChange = (obj, value) => {
        setValue(value)
    }
    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };
    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };

    const getTeactersActive = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/teachers?status=1", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setTeachersActive(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const getTeactersInactive = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/teachers?status=2", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setTeachersInactive(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const seleccionarInvitado = (elemento, caso) => {
        setTeacherSelected(elemento);
        switch (caso) {
            case 'Editar':
                setModalEditar(true)
                break;
            case 'Eliminar':
                setModalEliminar(true)
                break;
            case 'Restore':
                setModalRestore(true)
                break;
            default:
                setModalEnviar(true);
                break;
        }
        // (caso === 'Editar') ? setModalEditar(true) : setModalEliminar(true)
    }

    const handleChangeTeacher = e => {
        setError('');
        if(e.target.name == 'rut'){
         // setVerify(false)
        
        //   var Fn = {
        //      // Valida el rut con su cadena completa "XXXXXXXX-X"
        //      validaRut: function (rutCompleto) {
        //         if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
        //            return false;
        //         var tmp = rutCompleto.split('-');
        //         var digv = tmp[1];
        //         var rut = tmp[0];
        //         if (digv == 'K') digv = 'k';
        //         return (Fn.dv(rut) == digv);
        //      },
        //      dv: function (T) {
        //         var M = 0, S = 1;
        //         for (; T; T = Math.floor(T / 10))
        //            S = (S + T % 10 * (9 - M++ % 6)) % 11;
        //         return S ? S - 1 : 'k';
        //      }
        //   }
          var foo = e.target.value
          if (foo.length > 0 && foo.length < 11) {
            //  foo = foo.match(new RegExp('.{1,8}', 'g')).join("-");
             setTeacherSelected(prev => ({ ...prev, rut: foo, textError: '' }))
          } else if (foo.length == 0) {
             setTeacherSelected(prev => ({ ...prev, rut: "", textError: '' }))
          }
    
        }else{
       
        const changedFormValues = {
            ...teacherSelected,
            [e.target.name]: e.target.value,
        }; 

        changedFormValues.name.trim() !== "" && setError("");
        changedFormValues.surname.trim() !== "" && setError("");
        changedFormValues.rut.trim() !== "" && setError("");
        changedFormValues.birthday.trim() != "" && setError("");
        changedFormValues.email.trim() !== "" && setError("");
        changedFormValues.amount.amount >= 0 && setError("");
        setTeacherSelected(changedFormValues);
    }
    }

    const editar = async (id) => {
        if (!teacherSelected.name.trim()) {
            return setError("name");
        }
        if (!teacherSelected.surname.trim()) {
            return setError("surname");
        }
        if (!teacherSelected.rut.trim()) {
            return setError("rut");
        }
        if (!teacherSelected.birthday.trim()) {
            return setError("birthday");
        }
        if(!ValidateEmail(teacherSelected.email)){
            return setError("email");
        }
        if (teacherSelected.amount < 0) {
            return setError("amount");
        }

        const formFile = new FormData();
        formFile.append('name', teacherSelected.name)
        formFile.append('surname', teacherSelected.surname)
        formFile.append('rut', teacherSelected.rut)
        formFile.append('birthday', teacherSelected.birthday)
        formFile.append('email', teacherSelected.email)
        formFile.append('amount', teacherSelected.amount)
        formFile.append('idcompany', user?.id_company)
        formFile.append('idTypeUSer', 5)

        try {
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/update/${id}`,
                    formFile,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                )
                .then((response) => {
                    setTeachersActive(...response.data)
                    setTeachersInactive(...response.data)
                    })
                .catch((error) => {
                    console.log(error)
                })
                limpiaDatos();
                getTeactersActive();
                getTeactersInactive();
                setMessageSuccess("Registro editado satisfactoriamente!")
                setOpenSuccess(true);
                setModalEditar(false);
        } catch (error) {
            console.log('error list teacher: ', error)
        }

        getTeactersActive();
        getTeactersInactive();
    }

    const enviar = async () => {
        try {
            const response = await axios
                .post(
                    `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/auth/recover-password/${teacherSelected.rut}`
                )
                .catch((err) => {
                    console.log("Err: ", err);
                });

            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'success',
                message: "Se le envio un correo con las credenciales ",
            }));
        } catch (error) {
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'error',
                message: "Ocurrio un problema, intentelo mas tarde ",
            }));
        }

        setModalEnviar(false);
    }
    const restore = async (id) => {
        await axios
            .patch(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/restore/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                },
            ).then((response) => {
                // setData(...response.data)
                console.log("respuesta", response);
            })
        setMessageSuccess("Registro restaurado correctmante!")
        setOpenSuccess(true)
        setModalRestore(false);
        getTeactersActive();
        getTeactersInactive();
    }

    const eliminar = async (id) => {
        await axios
            .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
        setMessageSuccess("Registro eliminado satisfactoriamente!")
        setOpenSuccess(true)
        setModalEliminar(false);
        getTeactersActive();
        getTeactersInactive();
    };

    const abrirModalInsertar = () => {
        // setInvitadoSeleccionado(null);
        setModalInsertar(true);
    }

    function ValidateEmail(mail) 
    {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
        return true
    }
        // alert("You have entered an invalid email address!")
        return false
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!teacherSelected.name.trim()) {
            return setError("name");
        }
        if (!teacherSelected.surname.trim()) {
            return setError("surname");
        }
        if (!teacherSelected.rut.trim()) {
            return setError("rut");
        }
        if (!teacherSelected.birthday.trim()) {
            return setError("birthday");
        }
        if(ValidateEmail(teacherSelected.email) === false){
            return setError("email");
        }

        if (parseInt(teacherSelected.amount) < 0) {
            return setError("amount");
        }
      
        const formFile = new FormData();
        formFile.append('name', teacherSelected.name)
        formFile.append('surname', teacherSelected.surname)
        formFile.append('rut', teacherSelected.rut)
        formFile.append('birthday', teacherSelected.birthday)
        formFile.append('email', teacherSelected.email)
        formFile.append('amount', teacherSelected.amount)
        formFile.append('idcompany', user?.id_company)
        formFile.append('idTypeUSer', 5)

        try {
            let errorMessage = null
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/register`,
                    formFile,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                )
                .then((response) => {
                    if (response.data.data.message) {
                        errorMessage = response.data.data.message
                    }
                    setTeachersActive(...response.data)
                    setTeachersInactive(...response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
                limpiaDatos();
                setMessageSuccess(errorMessage != null ? errorMessage : "Correo enviado para el autologin!")
                setOpenSuccess(true);
                setModalInsertar(false);
        } catch (error) {
            console.log('error')
        }
        getTeactersActive();
        getTeactersInactive();
    };

    const limpiaDatos = () => {
        setTeacherSelected((userValues) => ({
            ...userValues,
            name: '',
            surname: '',
            rut: '',
            birthday: '',
            email: '',
            amount:0,
            idTypeUser: 1,
            idCompany: 1,
            rutcompany: ''
        }));
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const classes = useStyles();

    const subirArchivo = (e) => {
        const { name, value } = e.target;
        setArchivo((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }
    useEffect(() => {
        setData([...data])
        getTeactersActive([...teachersActive]);
        getTeactersInactive([...teachersInactive]);
    }, [archivo])

    const handleOpenModalDelete = (rowSelected) => {
        setTeacherSelected(rowSelected)
        setModalEliminar2(true)
    }

    const handleEliminar = async () => {
        const id = teacherSelected.id
        await axios
        .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/deleteUser/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        setMessageSuccess("Registro eliminado correctamente!")
        setOpenSuccess(true)
        setModalEliminar2(false);
        getTeactersActive();
        getTeactersInactive();
    }

    const handleDeleteBD = async () => {
        const id = teacherSelected.id
        await axios
        .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/deleteBD/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        setMessageSuccess("Registro eliminado correctamente!")
        setOpenSuccess(true)
        setModalEliminar2(false);
        getTeactersActive();
        getTeactersInactive();
    }

    return (
        <Grid sx={{bgcolor:'#f4f4f4'}}>
            <Grid container className="containerInvite">
                <Grid container justifyContent={"center"} mt={2}>
                    <Grid item xs={12} md={10}>
                        <Grid xs={12} mb={2}>
                            <Link to={'/modulos-administrador'} className='link__css'>
                                <Typography variant='h6' gutterBottom className='link__css-back'>
                                    <ArrowBackIosIcon className='icon_back' /> Volver
                                </Typography>
                            </Link>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant={"h5"} className="title__main">PROFESORES</Typography>
                                </Grid>
                                <Grid item xs={8} md={6} mt={1}>
                                    <Paper
                                        component="form"
                                        sx={{ p: '0px 2px', display: 'flex', alignItems: 'center' }}
                                    >

                                        <InputBase
                                            // value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            on
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Buscador"
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>


                                    </Paper>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    {/* <Button fullWidth className="btn-agregar" ></Button> */}
                                    <Grid display="flex" justifyContent={"flex-end"}>
                                        <IconButton sx={{ marginLeft: '5px' }} >
                                            <img className="btn-agregar-excel" src={excel}></img>
                                            <input
                                                name="file_excel"
                                                className='contenedor_file--input'
                                                multiple type="file"
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                onChange={(e) => subirArchivo(e)} />
                                        </IconButton>
                                        <IconButton sx={{ marginLeft: '5px' }}
                                            onClick={() => abrirModalInsertar()} >
                                            <img className="btn-agregar-excel" src={agregar}></img>

                                        </IconButton>
                                    </Grid>
                                </Grid>

                            </Grid>
                            {value == 0 ? <Typography variant='body1' gutterBottom>Tienes {teachersActive.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).length} profesores activos.</Typography>
                                : <Typography variant='body1' gutterBottom>Tienes {teachersInactive.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).length} profesores inactivos.</Typography>
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} mt={3}>
                        <Box sx={{ width: '100%', bgcolor: '#fff' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs centered value={value} onChange={handleChange} indicatorColor="primary"
                                    textColor="primary" aria-label="basic tabs example">
                                    <Tab label="PROFESORES ACTIVOS " textColor="#f00" {...a11yProps(0)} />
                                    <Tab label="PROFESORES INACTIVOS " textColor="#f00" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} >
                                    <TabPanel value={value} index={0}>
                                        <Grid item xs={12} mt={3}>
                                            <TableContainer component={Paper} >
                                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                                    <TableHead >
                                                        <TableRow style={{ background: "#191D3D" }}>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Nombres y Apellidos</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Rut</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Fecha de nacimiento</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Correo</TableCell>
                                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(rowsPerPage > 0
                                                            ? teachersActive.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : teachersActive
                                                        ).filter(item => item.name.toLowerCase().includes(search.toLowerCase()) || item.rut.toLowerCase().includes(search.toLowerCase()))
                                                            .map((row) => (
                                                                <TableRow key={row.name}>

                                                                    <TableCell className="cell">
                                                                        {row.name + " " + row.surname}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.rut}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.birthday}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.email}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid container spacing={1}>
                                                                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                                <Tooltip title="Enviar llave secreta">
                                                                                    <IconButton onClick={() => seleccionarInvitado(row, 'EnviarLlave')}>
                                                                                        <KeyIcon style={{ color: "#e4980d " }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Editar">
                                                                                    <IconButton onClick={() => seleccionarInvitado(row, 'Editar')}>
                                                                                        <EditIcon style={{ color: "#469489 " }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Deshabilitar">
                                                                                    <IconButton onClick={() => seleccionarInvitado(row, 'Eliminar')}>
                                                                                        <BlockIcon style={{ color: "#b10202" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Eliminar">
                                                                                    <IconButton onClick={() => handleOpenModalDelete(row)}>
                                                                                        <DeleteIcon style={{ color: "#b10202" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}

                                                        {/* {emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={5} />
                                                            </TableRow>
                                                        )} */}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                                colSpan={5}
                                                                count={teachersActive.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                SelectProps={{
                                                                    TextFieldProps: {
                                                                        'aria-label': 'rows per page',
                                                                    },
                                                                    native: true,
                                                                }}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                                ActionsComponent={TablePaginationActions}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Grid item xs={12} mt={3}>
                                            <TableContainer component={Paper} >
                                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                                    <TableHead >
                                                        <TableRow style={{ background: "#191D3D" }}>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Nombres y Apellidos</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Rut</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Fecha de nacimiento</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Correo</TableCell>
                                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(rowsPerPage > 0
                                                            ? teachersInactive.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : teachersInactive
                                                        ).filter(item => item.name.toLowerCase().includes(search.toLowerCase()) || item.rut.toLowerCase().includes(search.toLowerCase()))
                                                            .map((row) => (
                                                                <TableRow key={row.name}>

                                                                    <TableCell className="cell">
                                                                        {row.name + " " + row.surname}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.rut}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.birthday}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.email}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid container spacing={1}>
                                                                            {/* <Grid item xs={4}>
                                                            <IconButton onClick={() => seleccionarInvitado(row, 'EnviarLlave')}>
                                                                <KeyIcon style={{ color: "#e4980d " }} />
                                                            </IconButton>
                                                        </Grid> */}
                                                                            <Grid item xs={12} display="flex" justifyContent="center">
                                                                                <Tooltip title="Editar">
                                                                                    <IconButton onClick={() => seleccionarInvitado(row, 'Editar')}>
                                                                                        <EditIcon style={{ color: "#469489 " }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Habilitar">
                                                                                    <IconButton onClick={() => seleccionarInvitado(row, 'Restore')}>
                                                                                        <RestartAltIcon style={{ color: "#056600" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Eliminar">
                                                                                    <IconButton onClick={ () => handleOpenModalDelete(row) }>
                                                                                        <DeleteIcon style={{ color: "#b10202" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}

                                                        {/* {emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={6} />
                                                            </TableRow>
                                                        )} */}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                                colSpan={5}
                                                                count={teachersInactive.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                SelectProps={{
                                                                    TextFieldProps: {
                                                                        'aria-label': 'rows per page',
                                                                    },
                                                                    native: true,
                                                                }}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                                ActionsComponent={TablePaginationActions}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </TabPanel>

                                </Grid>
                            </Grid>


                        </Box>

                    </Grid>
                </Grid>

            </Grid>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEditar}
                onClose={() => {setModalEditar(false);  limpiaDatos();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEditar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                EDITAR PROFESOR
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">

                                <Grid item xs={12}>
                                    <TextField                                    
                                        id="name"
                                        type="text"
                                        size="small"
                                        name="name"
                                        error={error === "name" ? true : false}
                                        helperText={error === "name" ? "Los nombres son un campo requerido" : ""}
                                        value={teacherSelected.name}
                                        placeholder="Nombres"
                                        fullWidth
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="surname"
                                        error={error === "surname" ? true : false}
                                        helperText={error === "surname" ? "Los apellidos es un campo requerido" : ""}
                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="surname"
                                        placeholder="Apellidos*"
                                        value={teacherSelected.surname}
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        id="rut"
                                        error={error === "rut" ? true : false}
                                        helperText={error === "rut" ? "RUT es un campo requerido" : ""}
                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="rut"
                                        placeholder="RUT*"
                                        value={teacherSelected.rut}
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="birthday"
                                        error={error === "birthday" ? true : false}
                                        helperText={error === "birthday" ? "La fecha de nacimiento un campo requerido" : ""}
                                        size="small"
                                        type="date"
                                        fullWidth
                                        name="birthday"
                                        placeholder="Fecha de nacimiento"
                                        value={teacherSelected.birthday}
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="email"
                                        error={error === "email" ? true : false}
                                        helperText={error === "email" ? "Ingrese un correo valido" : ""}
                                        size="small"
                                        type="text"
                                        fullWidth
                                        name="email"
                                        placeholder="Correo*"
                                        value={teacherSelected.email}
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="amount"
                                        error={error === "amount" ? true : false}
                                        helperText={error === "amount" ? "Ingrese un monto valido" : ""}
                                        size="small"
                                        type="number"
                                        fullWidth
                                        name="amount"
                                        placeholder="Monto*"      
                                        defaultValue={0}                                  
                                        value={teacherSelected.amount}
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>

                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEditar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => editar(teacherSelected.id)}>Actualizar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEliminar2}
                onClose={() => {setModalEliminar2(false); limpiaDatos()}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalEliminar2}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >   Estás Seguro que deseas eliminar a {teacherSelected && (teacherSelected.name + " " + teacherSelected.surname)}
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEliminar2(false)}>NO</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => teacherSelected.status == '0' ? handleDeleteBD() : handleEliminar()}>Si</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEliminar}
                onClose={() =>{ setModalEliminar(false);  limpiaDatos();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEliminar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                Estás Seguro que deseas desactivar a {teacherSelected && (teacherSelected.name + " " + teacherSelected.surname)}
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEliminar(false)}>NO</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => eliminar(teacherSelected.id)}>Si</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEnviar}
                onClose={() => {setModalEnviar(false);  limpiaDatos();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEnviar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                ¿Enviar llave privada al profesor {teacherSelected && teacherSelected.name}?
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEnviar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => enviar()}>Enviar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalInsertar}
                onClose={() => {setModalInsertar(false);  limpiaDatos();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalInsertar}>
                    <div className={classes.paper} >
                        <form onSubmit={handleSubmit}>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                AGREGAR PROFESOR
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">

                                <Grid item xs={12}>
                                    <TextField
                                        id="name"
                                        type="text"
                                        size="small"
                                        name="name"
                                        error={error === "name" ? true : false}
                                        helperText={error === "name" ? "Los nombres son un campo requerido" : ""}
                                        // value={parnerSelected.name}
                                        placeholder="Nombres"
                                        fullWidth
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        error={error === "surname" ? true : false}
                                        helperText={error === "surname" ? "Los apellidos es un campo requerido" : ""}
                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="surname"
                                        placeholder="Apellidos*"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.apellidos : ''}
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField                         
                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="rut"
                                        placeholder="RUT*"
                                        error={error === "rut" ? true : false}
                                        helperText={error === "rut" ? "RUT es un campo requerido" : ""}
                                        value={teacherSelected.rut}
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={error === "birthday" ? true : false}
                                        helperText={error === "birthday" ? "La fecha de nacimiento es un campo requerido" : ""}
                                        size="small"
                                        type="date"
                                        fullWidth
                                        name="birthday"
                                        placeholder="Fecha de nacimiento"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.direccion : ''}
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={error === "email" ? true : false}
                                        helperText={error === "email" ? "Ingrese un correo valido" : ""}
                                        size="small"
                                        type="text"
                                        fullWidth
                                        name="email"
                                        placeholder="Correo*"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.correo : ''}
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={error === "amount" ? true : false}
                                        helperText={error === "amount" ? "Ingrese un monto valido" : ""}
                                        size="small"
                                        type="number"
                                        fullWidth
                                        name="amount"
                                        placeholder="Monto*"
                                        defaultValue={0}
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.correo : ''}
                                        onChange={handleChangeTeacher}
                                    />
                                </Grid>

                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalInsertar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" type="submit">Guardar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalRestore}
                onClose={() => {setModalRestore(false);  limpiaDatos();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalRestore}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                ¿Restaurar al profesor {teacherSelected.name}  {teacherSelected.surname}?
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalRestore(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => restore(teacherSelected.id)}>Restaurar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            {/* <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                        Error al registrar profesor
                    </Alert>
                </Snackbar>
            </Stack> */}
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
                    <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
                        {messageSuccess}
                    </Alert>
                </Snackbar>
            </Stack>
        </Grid>
    )
}