import "./SelectGuests.css"
import React, { useEffect, useCallback, useMemo } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Box, Card, Grid, TextField, Typography } from "@mui/material";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
// import { session } from "../../session/constants";
import { session } from "../../session/constants";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AgregarInvitados from "../../Components/Modal/AgregarInvitados";
import { useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
//import PartnerService from "../../services/api/PartnerService"
// import AgregarInvitados from "./AgregarInvitados";

import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { actionSetListInvite } from '../../redux/actions';
import axios from "../../utils/axios";
import AgregarStudent from "../../Components/Modal/AgregarStudent";
import { CardContent } from "@material-ui/core";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SelectGuestsHorarioView = (props) => {
  const history = useHistory();
  // ALERTS
  const [openAlertTypeGame, setOpenAlertTypeGame] = React.useState(false);
  const [openAlertNuleInvited, setOpenAlertNuleInvited] = React.useState(false);
  const [openAlertInvitedSingle, setOpenAlertInvitedSingle] = React.useState(false);
  const [openAlertInvitedDouble, setOpenAlertInvitedDouble] = React.useState(false);

  const data_props = props.location.state.from;
  const idTypeField = props.location.state.idTypeField;
  const [openInvitados, setOpenInvitados] = React.useState(false)
  const [openStudent, setOpenStudent] = React.useState(false)
  const [parner, setParner] = React.useState(false)
  const [family, setFamily] = React.useState(false)
  const [invitados, setInvitados] = React.useState(false)
  const [alumnos, setAlumnos] = React.useState(false)
  const [teacher, setTeacher] = React.useState(false)
  const [stateType, setStateType] = React.useState(false);
  const [cardSocio, setCardSocio] = React.useState(false);
  const [cardFamiliar, setCardFamiliar] = React.useState(false);
  const [cardExternos, setCardExternos] = React.useState(false);
  const [cardAlumnos, setCardAlumnos] = React.useState(false);
  const [cardProfesores, setCardProfesores] = React.useState(false);
  const [btnAddStudent, setBtnAddStudent] = React.useState(false);
  const [typeGame, setTypeGame] = React.useState('');
  //datas
  const [partners, setPartners] = React.useState([]);
  const [familyData, setFamilyData] = React.useState([]);
  const [alumnosData, setAlumnosData] = React.useState([]);
  const [teacherData, setTeacherData] = React.useState([]);
  const [externosData, setExternosData] = React.useState([]);
  const [studentData, setStudentData] = React.useState([]);

  //select
  const [partnersSelected, setPartnersSelected] = React.useState([]);
  const [invitedSelected, setInvitedSelected] = React.useState([]);
  const [studentSelected, setStudentSelected] = React.useState([]);
  const [render, setRender] = React.useState(false);
  const [termBusqueda, setTermBusqueda] = React.useState('');

  const [listaInvitados, setListaInvitados] = React.useState([]);
  const [snackBarConfig, setSnackBarConfig] = React.useState({
    open: false,
    severity: 'success',
    message: 'Error',
    autoHideDuration: 5000,
  })
  const token = sessionStorage.getItem(session.SESSION_TOKEN);
  var data_user = JSON.parse(localStorage.getItem("data_user"))
  let idUser = sessionStorage.getItem(session.SESSION_ID_USER)
  let tipoUsuario = data_user.type_user
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    // Función para detectar el tamaño de la pantalla y actualizar el estado
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Ajusta aquí el ancho que consideres como "móvil"
    };

    // Agregar el evento de escucha al tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Ejecutar la función al montar el componente para inicializar el estado
    handleResize();

    // Eliminar el evento de escucha al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changeViewBack = (view) => {
    switch (view) {
      case 'horario':
        history.push('/modulos', { from: 'horario' })
        break;

      default:
        history.push('/modulos', { from: 'cancha' });
        break;
    }
  }
  // ALERTS
  const handleCloseAlertNuleInvited = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertNuleInvited(false);
  };
  const handleCloseAlertTypeGame = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertTypeGame(false);
  };
  const handleCloseAlertInvitedSingle = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertInvitedSingle(false);
  };
  const handleCloseAlertInvitedDouble = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertInvitedDouble(false);
  };

  useEffect(() => {
    getPartners();
    getStudens();
    // getFamily();
    // getStudents();
    // getTeacher();
    getExterns();
    switch (tipoUsuario) {

      case "SOCIO":
        setCardSocio(true);
        // setCardFamiliar(true);
        setCardExternos(true);
        setStateType(true)
        setBtnAddStudent(false)
        break;
      case "EXTERNO":
        setCardSocio(false);
        setCardFamiliar(false);
        setCardExternos(true);
        setStateType(true)
        setBtnAddStudent(false)
        break;
      case "PROFESOR":
        setCardAlumnos(true);
        setBtnAddStudent(true)
        break;
      case "FAMILIAR":
        setCardSocio(true);
        // setCardFamiliar(true);
        setCardExternos(true);
        setStateType(true)
        setBtnAddStudent(false)
        break;
    }
  }, []);

  // function getFamily() {
  //   return fetch('https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/family', {
  //     method: 'GET',
  //     headers: {
  //       'Access-Control-Allow-Origin': '*',
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'Authorization': 'Bearer ' + token,
  //     }
  //   })
  //     .then(pros => pros.json())
  //     .then(async pros => {
  //       const data = (pros.data || []).map((raw) => (
  //         {
  //           birthday: raw?.birthday,
  //           companies: raw?.companies,
  //           email: raw?.email,
  //           id: raw?.id,
  //           name: raw?.name,
  //           rut: raw?.rut,
  //           surname: raw?.surname,
  //           invited: 0,
  //         }
  //       ))
  //       console.log(data);
  //       setFamilyData(data);
  //     })
  // }

  // function getTeacher() {
  //   return fetch('https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/teachers', {
  //     method: 'GET',
  //     headers: {
  //       'Access-Control-Allow-Origin': '*',
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'Authorization': 'Bearer ' + token,
  //     }
  //   })
  //     .then(pros => pros.json())
  //     .then(async pros => {
  //       const data = (pros.data || []).map((raw) => (
  //         {
  //           birthday: raw?.birthday,
  //           companies: raw?.companies,
  //           email: raw?.email,
  //           id: raw?.id,
  //           name: raw?.name,
  //           rut: raw?.rut,
  //           surname: raw?.surname,
  //           invited: 0,
  //         }
  //       ))
  //       console.log(data);
  //       setTeacherData(data);
  //     })
  // }

  function getPartners() {
    return axios
      .get('https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/partnersFamily',
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token,
          }
        },
      ).then(pros => {
        const data = (pros.data.data).map((raw) => (
          {
            birthday: raw?.birthday,
            companies: raw?.companies,
            email: raw?.email,
            id: raw?.id,
            name: raw?.name,
            rut: raw?.rut,
            surname: raw?.surname,
            id_type_user: raw?.id_type_user,
            type_user: raw?.type_user,
            invited: 0,
          }
        ))

        // console.log("esta es la data traída ge getpartners: ", data);
        let arrayFind = [];

        data.map((value) => {
          let itemFind = partnersSelected.find((item) => { return item.id == value.id })
          console.log(itemFind);
          if (itemFind == undefined) {
            arrayFind.push(value);
          }
        })
        // console.log(arrayFind)
        setPartners(arrayFind)
        setRender(!render)
      })
    // return fetch('https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/partnersFamily', {
    //   method: 'GET',
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'Authorization': 'Bearer ' + token,
    //   }
    // })
    //   .then(pros => pros.json())
    //   .then(async pros => {
    //     const data = (pros.data || []).map((raw) => (
    //       {
    //         birthday: raw?.birthday,
    //         companies: raw?.companies,
    //         email: raw?.email,
    //         id: raw?.id,
    //         name: raw?.name,
    //         rut: raw?.rut,
    //         surname: raw?.surname,
    //         id_type_user: raw?.id_type_user,
    //         type_user: raw?.type_user,
    //         invited: 0,
    //       }
    //     ))
    //     console.log("esta es la data traída ge getpartners: ",data);
    //     let arrayFind = [];
    //     console.log()

    //     data.map((value) => {
    //       let itemFind = partnersSelected.find((item) => { return item.id == value.id })
    //       console.log(itemFind);
    //       if (itemFind == undefined) {
    //         arrayFind.push(value);
    //       }
    //     })
    //     console.log(arrayFind)
    //     setPartners(arrayFind)
    //     setRender(!render)

    //     // changePartners(data, 'SOCIO')
    //     //setPartners(data);
    //   })
  }

  function getExterns() {
    return axios
      .get('https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/invite',
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token,
          }
        },
      )
      .then(pros => {
        const data = (pros.data.data).map((raw) => (
          {
            birthday: raw?.birthday,
            // companies: raw?.companies,
            email: raw?.email,
            id: raw?.id,
            name: raw?.name,
            // rut: raw?.rut,
            surname: raw?.surname,
            invited: 0,
            // id_type_user: raw?.id_type_user,
            type_user: 'INVITADO',
          }
        ))
        // console.log("esta es la data traída ge getinvite: ", data);
        let arrayFind = [];
        data.map((value) => {
          let itemFind = invitedSelected.find((item) => { return item.id == value.id })
          console.log(itemFind);
          if (itemFind == undefined) {
            arrayFind.push(value);
          }
        })
        console.log(arrayFind)
        setExternosData(arrayFind)
        setRender(!render)
      })
    // return fetch('https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/invite', {
    //   method: 'GET',
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'Authorization': 'Bearer ' + token,
    //   }
    // })
    //   .then(pros => pros.json())
    //   .then(async pros => {
    //     const data = (pros.data || []).map((raw) => (
    //       {
    //         birthday: raw?.birthday,
    //         // companies: raw?.companies,
    //         email: raw?.email,
    //         id: raw?.id,
    //         name: raw?.name,
    //         // rut: raw?.rut,
    //         surname: raw?.surname,
    //         invited: 0,
    //         // id_type_user: raw?.id_type_user,
    //         type_user: 'INVITADO',
    //       }
    //     ))
    //     console.log(data);
    //     console.log(data);
    //     let arrayFind = [];
    //     console.log()

    //     data.map((value) => {
    //       let itemFind = invitedSelected.find((item) => { return item.id == value.id })
    //       console.log(itemFind);
    //       if (itemFind == undefined) {
    //         arrayFind.push(value);
    //       }
    //     })
    //     console.log(arrayFind)
    //     setExternosData(arrayFind)
    //     setRender(!render)

    // setExternosData(data);
    // changePartners(data, 'INVITADO')
    // })
  }

  function getStudens() {
    return axios
      .get('https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/students',
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token,
          }
        },
      )
      .then(pros => {
        const data = (pros.data.data).map((raw) => (
          {
            birthday: raw?.birthday,
            // companies: raw?.companies,
            email: raw?.email,
            id: raw?.id,
            name: raw?.name,
            rut: raw?.rut,
            surname: raw?.surname,
            invited: 0,
            id_type_user: raw?.id_type_user,
            type_user: 'ALUMNO',
          }
        ))
        // console.log("esta es la data traída ge getStudents: ", data);
        let arrayFind = [];
        data.map((value) => {
          let itemFind = studentSelected.find((item) => { return item.id == value.id })
          console.log(itemFind);
          if (itemFind == undefined) {
            arrayFind.push(value);
          }
        })
        console.log(arrayFind)
        setStudentData(arrayFind)
        setRender(!render)
      })
  }

  function getSearchPartners(value) {
    return axios
      .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/partnersFamily?term=${value}`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token,
          }
        }
      )
      .then(pros => {
        const data = (pros.data.data).map((raw) => (
          {
            birthday: raw?.birthday,
            companies: raw?.companies,
            email: raw?.email,
            id: raw?.id,
            name: raw?.name,
            rut: raw?.rut,
            surname: raw?.surname,
            id_type_user: raw?.id_type_user,
            type_user: raw?.type_user,
            invited: 0,
          }
        ))
        // console.log("esta es la data traída ge getinvite: ", data);
        let arrayFind = [];
        data.map((value) => {
          let itemFind = partnersSelected.find((item) => { return item.id == value.id })
          console.log(itemFind);
          if (itemFind == undefined) {
            arrayFind.push(value);
          }
        })
        console.log(arrayFind)
        setPartners(arrayFind)
        setRender(!render)
      })
    // return fetch(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/partnersFamily?term=${value}`, {
    //   method: 'GET',
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'Authorization': 'Bearer ' + token,
    //   }
    // })
    //   .then(pros => pros.json())
    //   .then(async pros => {
    //     const data = (pros.data || []).map((raw) => (
    //       {
    //         birthday: raw?.birthday,
    //         companies: raw?.companies,
    //         email: raw?.email,
    //         id: raw?.id,
    //         name: raw?.name,
    //         rut: raw?.rut,
    //         surname: raw?.surname,
    //         id_type_user: raw?.id_type_user,
    //         type_user: raw?.type_user,
    //         invited: 0,
    //       }
    //     ))
    //     console.log(data);
    //     console.log(data);
    //     let arrayFind = [];
    //     console.log()

    //     data.map((value) => {
    //       let itemFind = partnersSelected.find((item) => { return item.id == value.id })
    //       console.log(itemFind);
    //       if (itemFind == undefined) {
    //         arrayFind.push(value);
    //       }
    //     })
    //     console.log(arrayFind)
    //     setPartners(arrayFind)
    //     setRender(!render)
    //     // changePartners(data, 'SOCIO')
    //     //setPartners(data);
    //   })
  }

  function getSearchInvited(value) {
    return axios
      .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/invite?term=${value}`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token,
          }
        }
      )
      .then(pros => {
        const data = (pros.data.data).map((raw) => (
          {
            birthday: raw?.birthday,
            companies: raw?.companies,
            email: raw?.email,
            id: raw?.id,
            name: raw?.name,
            rut: raw?.rut,
            surname: raw?.surname,
            id_type_user: raw?.id_type_user,
            type_user: raw?.type_user,
            invited: 0,
          }
        ))
        // console.log(data);
        //setExternosData(data);
        // changePartners(data, 'INVITADO')
        console.log(data);
        console.log(data);
        let arrayFind = [];
        console.log()

        data.map((value) => {
          let itemFind = invitedSelected.find((item) => { return item.id == value.id })
          console.log(itemFind);
          if (itemFind == undefined) {
            arrayFind.push(value);
          }
        })
        console.log(arrayFind)
        setExternosData(arrayFind)
        setRender(!render)
      })
  }

  function getSearchStudent(value) {
    return axios
      .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/students?term=${value}`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token,
          }
        }
      )
      .then(pros => {
        const data = (pros.data.data).map((raw) => (
          {
            birthday: raw?.birthday,
            companies: raw?.companies,
            email: raw?.email,
            id: raw?.id,
            name: raw?.name,
            rut: raw?.rut,
            surname: raw?.surname,
            id_type_user: raw?.id_type_user,
            type_user: raw?.type_user,
            invited: 0,
          }
        ))
        // console.log(data);
        //setExternosData(data);
        // changePartners(data, 'INVITADO')
        console.log(data);
        console.log(data);
        let arrayFind = [];
        console.log()

        data.map((value) => {
          let itemFind = studentSelected.find((item) => { return item.id == value.id })
          console.log(itemFind);
          if (itemFind == undefined) {
            arrayFind.push(value);
          }
        })
        // console.log(arrayFind)
        setStudentData(arrayFind)
        setRender(!render)
      })
  }

  const handleInvited = (value, type_invited) => {
    if (typeGame == '') {
      return setOpenAlertTypeGame(true);
    };
    let invited = 0;
    if (value.invited == 0) {
      const numPartner = partnersSelected.filter(y => y.invited == 1);
      const numInvited = invitedSelected.filter(x => x.invited = 1);
      const numStudent = studentSelected.filter(x => x.invited = 1);
      if (typeGame == 'SIMPLE') {
        if (numPartner.length + numInvited.length <= 0) {
          //  changePartners(numPartner);
          invited = 1;
        } else {
          return setOpenAlertInvitedSingle(true);
          // alert('El máximo de invitaciones para tipo de juego Simple es 1')
        }

      }

      if (typeGame == 'DOBLE') {
        if (numPartner.length + numInvited.length <= 2) {
          //      changePartners(numPartner);
          invited = 1;
        }
        else {
          return setOpenAlertInvitedDouble(true);
          // return alert('El máximo de invitaciones para tipo de juego Double es 3')
        }

      }

      // console.log(invited)
      let valueform = { ...value, invited: invited };
      // let valueform1 = { ...value, invited: invited };

      let arrayPartner = partnersSelected;
      let arrayExtern = invitedSelected;

      if (type_invited == 'SOCIO') {

        arrayPartner.push(valueform);
        console.log(arrayPartner);
        setPartnersSelected(arrayPartner);
        changePartners(arrayPartner, type_invited);

        // setListaInvitados([...arrayPartner]);
      }

      if (type_invited == 'INVITADO') {
        arrayExtern.push({ ...value, invited: 1 });
        console.log(arrayExtern);
        setInvitedSelected(arrayExtern);
        changeInvites(arrayExtern, type_invited);

        // setListaInvitados([...listaInvitados,...arrayExtern]);
      }
      // if (type_invited == 'PROFESOR') {
      //   arrayExtern.push({ ...value, invited: 1 });
      //   // console.log(arrayExtern);
      //   setInvitedSelected(arrayExtern);
      //   changeInvites(arrayExtern, type_invited);

      //   // setListaInvitados([...listaInvitados,...arrayExtern]);
      // }

      // let arrayConcat = arrayPartner.concat(arrayExtern)
      //deleteArrayBusqueda(arrayConcat)
      setRender(!render);

    } else {
      //eliminamos del array de seleccion


      if (type_invited == 'SOCIO') {
        let removedItemPartner = partnersSelected.filter(x => x.id != value.id);
        setPartnersSelected(removedItemPartner)
        let array10 = partners;
        array10.push({ ...value, invited: 0 });
        // console.log(array10);
        setPartners(array10);
      }

      if (type_invited == 'INVITADO') {
        let removedItemExtern = invitedSelected.filter(x => x.id != value.id);
        setInvitedSelected(removedItemExtern)
        let array11 = externosData;
        array11.push({ ...value, invited: 0 });
        // console.log(array11);
        setExternosData(array11);
      }
      //changePartners(removedItem)
    }
  }
  const handleStudent = (value, type_invited) => {

    let invited = 0;
    if (value.invited == 0) {
      const numStudent = studentSelected.filter(x => x.invited == 1);
      // console.log("Estduiantes seleccionados: ",numStudent)
      if (tipoUsuario == 'PROFESOR') {
        setTypeGame('DOBLE');
      }

      // if (typeGame == 'DOBLE') {
      //   if (numStudent.length<=100){
      //     invited = 1;
      //   }
      //   else {
      //     return setOpenAlertInvitedDouble(true);
      //     // return alert('El máximo de invitaciones para tipo de juego Double es 3')
      //   }

      // }

      // console.log(invited)
      let valueform = { ...value, invited: 1 };
      // let valueform1 = { ...value, invited: invited };

      let arrayStudent = studentSelected;

      if (type_invited == 'ALUMNO') {
        arrayStudent.push({ ...value, invited: 1 });
        // console.log("ALUMNOS SELECCIONADOS: ", arrayStudent);//000000000000000000000000000000000000000
        setStudentSelected(arrayStudent);
        changeStudent(arrayStudent, type_invited);

        // setListaInvitados([...listaInvitados,...arrayExtern]);
      }

      // let arrayConcat = arrayPartner.concat(arrayExtern)
      //deleteArrayBusqueda(arrayConcat)
      setRender(!render);

    } else {
      //eliminamos del array de seleccion

      // getExterns();
      // getPartners();
      // setRender(!render)
      console.log(value);
      if (type_invited == 'ALUMNO') {
        let removedItemStudent = studentSelected.filter(x => x.id != value.id);
        setStudentSelected(removedItemStudent)
        let array12 = studentData;
        array12.push({ ...value, invited: 0 });
        console.log(array12);
        setAlumnosData(array12);
      }
      //changePartners(removedItem)
    }
  }
  useEffect(() => {
    // console.log("Está actualizando lista:", listaInvitados);
  }, [listaInvitados]);

  const deleteArrayBusqueda = (value) => {
    console.log(value)
    //partners
  }

  const handleBuscador = (event) => {
    try {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
        case 'search':
          if (value.length > 0 && parner) {
            getSearchPartners(value)
          }
          if (value.length > 0 && invitados) {
            getSearchInvited(value)
          } if (value.length > 0) {
            getSearchStudent(value)
          }

          if (value.length == 0) {
            getPartners();
            getExterns();
            getStudens();

          }
          setTermBusqueda(value);

          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e)
    }
  }

  const changePartners = (paramas_partnersSelected, type_invited) => {
    let arrayFind = [];
    console.log()
    if (type_invited == 'SOCIO') {
      partners.map((value) => {
        let itemFind = paramas_partnersSelected.find((item) => { return item.id == value.id })
        console.log(itemFind);
        if (itemFind == undefined) {
          arrayFind.push(value);
        }
      })
      // console.log(arrayFind)
      setPartners(arrayFind)
      setRender(!render)
    }

  }

  const changeInvites = (paramas_invitesSelected, type_invited) => {
    let arrayFind = [];

    if (type_invited == 'INVITADO') {
      externosData.map((value) => {
        let itemFind = paramas_invitesSelected.find((item) => { return item.id == value.id })
        console.log(itemFind);
        if (itemFind == undefined) {
          arrayFind.push(value);
        }
      })
      // console.log(arrayFind)
      setExternosData(arrayFind)
      setRender(!render)
    }

  }

  const changeStudent = (paramas_studentSelected, type_invited) => {
    let arrayFind = [];

    if (type_invited == 'ALUMNO') {
      studentData.map((value) => {
        let itemFind = paramas_studentSelected.find((item) => { return item.id == value.id })
        console.log(itemFind);
        if (itemFind == undefined) {
          arrayFind.push(value);
        }
      })
      // console.log("ALUMNOS NO SELECCIONADOS: ", arrayFind)
      setStudentData(arrayFind)
      setRender(!render)
    }

  }

  const SaveInvited = () => {
    const numPartner = partnersSelected.filter(y => y.invited == 1);
    const numInvited = invitedSelected.filter(x => x.invited == 1);
    const numStudent = studentSelected.filter(x => x.invited == 1);

    if (tipoUsuario == 'PROFESOR') {
      if (numStudent.length <= 0) {
        return setOpenAlertNuleInvited(true);
        //alert('¡No se agregó invitados!');
      }
    } else {
      if (numPartner.length + numInvited.length <= 0) {
        return setOpenAlertNuleInvited(true);
        //alert('¡No se agregó invitados!');
      }
    }



    if (typeGame == 'DOBLE') {
      if (tipoUsuario !== 'PROFESOR') {
        if (numPartner.length + numInvited.length <= 1) {
          // console.log('hola')
          setSnackBarConfig({ ...snackBarConfig, open: true, severity: 'warning', message: '¡Si el tipo de juego es Doble debe elegir como mínimo 2 invitados!' })
          return null
        }
      }


    }
    let arrayresponsePartner = numPartner.map((value) => {
      return value.id;
      console.log(value.name)
    })

    let arrayresponseInvited = numInvited.map((value) => {
      return value.id;
    })
    let arrayresponseStudent = numStudent.map((value) => {
      return value.id;
    })

    let externosBolean = false;
    // console.log("NO HAY INVITADOS: ",arrayresponseInvited);
    if (arrayresponseInvited.length == 0) {
      externosBolean = false;
    } else {
      externosBolean = true;
    }
    let a_idinvite = tipoUsuario == 'PROFESOR' ? '' : arrayresponseInvited;
    let listaInvitados = tipoUsuario == 'PROFESOR' ? numStudent : numPartner.concat(numInvited);
    let a_iduser = tipoUsuario == 'PROFESOR' ? arrayresponseStudent : arrayresponsePartner;
    // let arrayresponse = arrayresponsePartner.concat(arrayresponseInvited)
    // console.log(data_props)
    if (data_props == 'horario') {
      history.push('/horarios', {
        a_iduser: a_iduser,
        a_idinvite: a_idinvite,
        externosBolean: externosBolean,
        idtype_reservation: typeGame == 'SIMPLE' ? 1 : 2,
        listaInvitados: listaInvitados,
        from: 'horarios',
        idTypeField: idTypeField
      });
    } else {
      history.push('/lista-canchas', {
        a_iduser: a_iduser,
        a_idinvite: a_idinvite,
        externosBolean: externosBolean,
        idtype_reservation: typeGame == 'SIMPLE' ? 1 : 2,
        listaInvitados: listaInvitados,
        from: 'canchas',
        idTypeField: idTypeField
      });
    }


  }

  const handleSocios = () => {
    setParner(true);
    setAlumnos(false)
    setInvitados(false);
    setFamily(false);
    setTeacher(false)
  };
  const handleFamilies = () => {
    setParner(false);
    setAlumnos(false)
    setInvitados(false);
    setFamily(true);
    setTeacher(false)
  };
  const handleInvities = () => {
    setParner(false);
    setAlumnos(false)
    setInvitados(true);
    setFamily(false);
    setTeacher(false)
  };

  const handleTeacher = () => {
    setParner(false);
    setInvitados(false);
    setFamily(false);
    setAlumnos(false)
    setTeacher(true)
  };

  const handleAlumnos = () => {
    setParner(false);
    setInvitados(false);
    setFamily(false);
    setAlumnos(true)
    setTeacher(false)
  };

  const handleShowInvitados = () => {
    setOpenInvitados(true)
  }
  const handleCloseInvitados = () => {
    setOpenInvitados(false)
  }
  const handleShowStudents = () => {
    setOpenStudent(true)
  }
  const handleCloseStudents = () => {
    setOpenStudent(false)
  }

  const listInvitados =
    // <Grid xs={12} mt={2} mb={-3}>

    <List sx={{
      width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
      maxHeight: 200,

      overflow: 'auto',
    }}>
      {externosData.map((value) => (
        <ListItem
          key={value}
          disableGutters
          secondaryAction={
            <Button className='btn-invitar-now' onClick={() => {
              handleInvited(value, 'INVITADO')
            }}>INVITAR AHORA</Button>
          }
        >
          <ListItemText primary={`${value.name}, ${value.surname}  `} />
        </ListItem>
      ))}

    </List>

  // </Grid>

  const renderListaInvitados = render ? listInvitados : listInvitados

  const ListPartner =
    <>
      <List sx={{
        width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
        maxHeight: 200,

        overflow: 'auto',
      }}>
        {partners.map((value) => (
          // validateSelect(value) &&
          <ListItem
            key={value}
            disableGutters
            secondaryAction={
              <Button className={'btn-invitar-now'}
                onClick={() => {
                  handleInvited(value, 'SOCIO')
                }}
              >{'INVITAR AHORA'}</Button>
            }
          >
            <ListItemText primary={`${value.name}, ${value.surname}  `} />
          </ListItem>
        ))}
      </List>
    </>

  const renderListPartner = render ? ListPartner : ListPartner


  const ListStudent =
    <>
      <List sx={{
        width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
        maxHeight: 200,

        overflow: 'auto',
      }}>
        {studentData.map((value) => (
          // validateSelect(value) &&
          <ListItem
            key={value}
            disableGutters
            secondaryAction={
              <Button className={'btn-invitar-now'}
                onClick={() => {
                  handleStudent(value, 'ALUMNO')
                }}
              >{'INVITAR AHORA'}</Button>
            }
          >
            <ListItemText primary={`${value.name}, ${value.surname}  `} />
          </ListItem>
        ))}

      </List>
    </>

  const renderListStudent = render ? ListStudent : ListStudent;

  return (
    <Box sx={{ pt: isMobile ? 1 : 5, pl: isMobile ? 1 : 30, pr: isMobile ? 1 : 30, bgcolor: '#f4f4f4' }}>
      <Grid xs={2} >
        <Typography sx={{ cursor: 'pointer' }} variant='h6' onClick={() => changeViewBack(data_props)} gutterBottom className='link__css-back'>
          <ArrowBackIosIcon className='icon_back' /> Volver
        </Typography>
      </Grid>
      <Card>
        <CardContent>
          <Grid container item xs={10} mb={1} mt={3} pl={1} justifyContent="center">
            <Grid xs={10} mb={2} >

              <Typography variant={"h5"} className="title__main" gutterBottom> AGREGA A TUS {tipoUsuario == 'PROFESOR' ? 'ESTUDIANTES' : 'INVITADOS'}</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item container xs={12}>
              {stateType &&
                <Grid item container sx={{ ml: 2, mr: 2 }}>
                  <Typography
                    textAlign="left"
                    variant="subtitle1"
                    id="transition-modal-title"
                    className="text-modal-title"
                  >
                    Seleccione el tipo de juego
                  </Typography>
                  <Grid item container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Button fullWidth className={typeGame == 'SIMPLE' ? "card-seleccionado" : "card-no-seleccionado"}
                        onClick={() => { typeGame == 'SIMPLE' ? setTypeGame('') : setTypeGame('SIMPLE') }}>
                        <Typography textAlign={"center"} className={"txt-card-opcion"}>SINGLE</Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button fullWidth className={typeGame == 'DOBLE' ? "card-seleccionado" : "card-no-seleccionado"}
                        onClick={() => { typeGame == 'DOBLE' ? setTypeGame('') : setTypeGame('DOBLE') }}>
                        <Typography textAlign={"center"} className={"txt-card-opcion"}>DOBLES</Typography>
                      </Button >
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
            <Grid item container xs={12} sx={{ mt: 2 }}>
              <Grid item container sx={{ ml: 2, mr: 2 }}>
                {stateType && <Typography
                  textAlign="left"
                  variant="subtitle1"
                  id="transition-modal-title"
                  className="text-modal-title"
                >
                  Seleccione el tipo de invitado
                </Typography>}
                <Grid item container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                  {cardSocio && <Grid item xs={12} md={6}>
                    <Button fullWidth className={parner ? "card-seleccionado" : "card-invite2"} onClick={handleSocios} >
                      <Typography textAlign={"center"} className={"txt-card-opcion"} >SOCIOS</Typography>
                    </Button>
                  </Grid>}
                  {cardFamiliar && <Grid item xs={12} md={6}>
                    <Button fullWidth className={family ? "card-seleccionado" : "card-invite2"} onClick={handleFamilies}>
                      <Typography textAlign={"center"} className={"txt-card-opcion"}>FAMILIAR</Typography>
                    </Button >
                  </Grid>}
                  {cardProfesores && <Grid item xs={12} md={6}>
                    <Button fullWidth className={teacher ? "card-seleccionado" : "card-invite2"} onClick={handleTeacher}>
                      <Typography textAlign={"center"} className={"txt-card-opcion"}>ALUMNOS</Typography>
                    </Button >
                  </Grid>}
                  {/* {cardAlumnos && <Grid item xs={4} md={4}>
                    <Button fullWidth className={alumnos ? "card-seleccionado" : "card-invite2"} onClick={handleAlumnos}>
                      <Typography textAlign={"center"} className={"txt-card-opcion"}>ALUMNOS</Typography>
                    </Button >
                  </Grid>} */}
                  {cardExternos && <Grid item xs={12} md={6}>
                    <Button fullWidth className={invitados ? "card-seleccionado" : "card-invite2"} onClick={handleInvities}>
                      <Typography textAlign={"center"} className={"txt-card-opcion"}>EXTERNOS</Typography>
                    </Button>
                  </Grid>}
                </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={12} sx={{ mt: 2 }} >
              <Grid item container justifyContent="center" alignItems={'center'} >
                {tipoUsuario == 'PROFESOR' ?
                  <Grid sx={{ m: 2 }}  >
                    <Button fullWidth variant='outlined' className='card_reservation--action' onClick={handleShowStudents}>AGREGAR ESTUDIANTE</Button>
                  </Grid>
                  :
                  <Grid sx={{ m: 2 }} >
                    <Button fullWidth variant='outlined' className='card_reservation--action' onClick={handleShowInvitados}>AGREGAR INVITADO</Button>
                  </Grid>
                }
                <Grid sx={{ m: 2 }} >
                  <Button fullWidth onClick={() => { SaveInvited() }} variant='outlined' className='card_reservation--submit'>CONTINUAR</Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={12} sx={{ mt: 2, mb: 2 }}>
              <Grid item container sx={{ ml: 2, mr: 2 }}>
                <Grid xs={12} >
                  <TextField
                    name='search'
                    type='search'
                    placeholder="Buscador por Nombre"
                    value={termBusqueda}
                    onChange={handleBuscador}
                    margin="normal"
                    required
                    fullWidth
                    size='small'
                  />

                  <List sx={{
                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                    maxHeight: 200,

                    overflow: 'auto',
                  }}>
                    {invitedSelected.map((value) => (
                      <ListItem
                        key={value}
                        disableGutters
                        secondaryAction={
                          <Button className={'btn-invited'}
                            onClick={() => {
                              handleInvited(value, 'INVITADO')
                            }}
                          >{'SELECCIONADO'}</Button>
                        }
                      >
                        <ListItemText primary={`${value.name}, ${value.surname}  `} />
                      </ListItem>
                    ))}
                  </List>

                  <List sx={{
                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                    maxHeight: 200,

                    overflow: 'auto',
                  }}>
                    {partnersSelected.map((value) => (
                      <ListItem
                        key={value}
                        disableGutters
                        secondaryAction={
                          <Button className={'btn-invited'}
                            onClick={() => {
                              handleInvited(value, 'SOCIO')
                            }}
                          >{'SELECCIONADO'}</Button>
                        }
                      >
                        <ListItemText primary={`${value.name}, ${value.surname}  `} />
                      </ListItem>
                    ))}
                  </List>

                  {parner && renderListPartner}
                  {/* {family && <List sx={{
                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                    maxHeight: 200,

                    overflow: 'auto',
                  }}>
                    {families.map((value) => (
                      <ListItem
                        key={value}
                        disableGutters
                        secondaryAction={
                          <Button className='btn-invitar-now'>INVITAR AHORA</Button>
                        }
                      >
                        <ListItemText primary={`${value.nombre}, ${value.apellido}  `} />
                      </ListItem>
                    ))}
                  </List>} */}

                  {invitados &&
                    renderListaInvitados}
                  <List sx={{
                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                    maxHeight: 200,

                    overflow: 'auto',
                  }}>
                    {studentSelected.map((value) => (
                      <ListItem
                        key={value}
                        disableGutters
                        secondaryAction={
                          <Button className={'btn-invited'}
                            onClick={() => {
                              handleStudent(value, 'ALUMNO')
                            }}
                          >{'SELECCIONADO'}</Button>
                        }
                      >
                        <ListItemText primary={`${value.name}, ${value.surname}  `} />
                      </ListItem>
                    ))}
                  </List>
                  {btnAddStudent && renderListStudent}

                </Grid>
              </Grid>
            </Grid>



          </Grid>
        </CardContent>
      </Card>
      <Snackbar open={openAlertTypeGame} autoHideDuration={3000} onClose={handleCloseAlertTypeGame}>
        <Alert onClose={handleCloseAlertTypeGame} severity="warning" sx={{ width: '100%' }}>
          ¡Elija un tipo de juego!
        </Alert>
      </Snackbar>
      <Snackbar open={openAlertNuleInvited} autoHideDuration={3000} onClose={handleCloseAlertNuleInvited}>
        <Alert onClose={handleCloseAlertNuleInvited} severity="warning" sx={{ width: '100%' }}>
          ¡No se agregó {tipoUsuario == 'PROFESOR' ? 'alumnos' : 'invitados'}!
        </Alert>
      </Snackbar>
      <Snackbar open={openAlertInvitedSingle} autoHideDuration={3000} onClose={handleCloseAlertInvitedSingle}>
        <Alert onClose={handleCloseAlertInvitedSingle} severity="warning" sx={{ width: '100%' }}>
          ¡El máximo de invitaciones para tipo de juego Single es 1!
        </Alert>
      </Snackbar>
      <Snackbar open={openAlertInvitedDouble} autoHideDuration={3000} onClose={handleCloseAlertInvitedDouble}>
        <Alert onClose={handleCloseAlertInvitedDouble} severity="warning" sx={{ width: '100%' }}>
          ¡El máximo de invitaciones para tipo de juego Double es 3!
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackBarConfig.open}
        autoHideDuration={snackBarConfig.autoHideDuration}
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
          severity={snackBarConfig.severity}
          variant="filled"
        >
          {snackBarConfig.message}
        </Alert>
      </Snackbar>

      <AgregarInvitados
        open={openInvitados}
        handleCloseModal={handleCloseInvitados}
        // invities={invities}
        getExterns={getExterns}
      />
      <AgregarStudent
        open={openStudent}
        handleCloseModal={handleCloseStudents}
        students={studentData}
        getStudens={getStudens}
        idUser={idUser}
      />

    </Box>
  )
}

const mapStateToProps = ({ InviteReducer }) => ({
  $store: {
    InviteReducer,
  },
});
const mapDispatchToProps = (dispatch) => ({
  $action: bindActionCreators(
    {
      actionSetListInvite: actionSetListInvite
    },
    dispatch
  ),
});

export const SelectGuestsHorario = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SelectGuestsHorarioView);