import { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, TextField } from '@mui/material';
import { Grid } from '@mui/material';
import "./ReservationInformation.css";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Invitados from '../../Components/Modal/Invitados';
import ReactCardFlip from 'react-card-flip';
import axios from "axios";
import moment from 'moment'
import 'moment/locale/es';
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import { ConnectingAirportsOutlined, Rocket } from "@mui/icons-material";
import LinearProgress from '@mui/material/LinearProgress';
import pidecancha from '../../assets/images/logo_circle.png';
import login_muestra from '../../assets/images/imagen_login_muestra.png';
import ListItemButton from '@mui/material/ListItemButton';
import * as qs from 'qs'

moment.locale('es');

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 400,
    },
}));


export const ReservationInformation = (props) => {
    //BACKDROP DE CARGA
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [dataInfo, setDataInfo]=useState({
        date:'',
        end:'',
        field:'',
        name:'',
        start:'',
        surname:''
    });
    const {date, end, field, name,start,surname} =dataInfo;
    const [mensaje, setMensaje] = useState('');
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    const handleToggleBackdrop = () => {
        setOpenBackdrop(!openBackdrop);
    };

    const ruta = props.location.search + '.';
//   const { r, pw } = props.location && qs.parse(ruta.slice(1, -1));

    // const ruta = 'http://localhost:3000/invitation?r=NTU1NTU1NTUtNQ==&ir=MzQ=.';
    const { r, ir } = props.location && qs.parse(ruta.slice(1, -1));
    const getDataInfo =  () => {

        if (r && ir) {
            var id;
            var rut;
            try {
                id = atob(ir);
                rut = atob(r);
                axios
                    .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/validateQR/${id}?rut=${rut}`
                    )
                    .then((response) => {
                        setDataInfo(response.data.data.data);
                        setMensaje(response.data.data.message);
                    })
                    .catch((error) => {
                        console.log("error: ", error)
                    });
            } catch (error) {
                console.log('error')
            }
        }
    }
    useEffect(() => {
        getDataInfo();
    }, [])

    return (
        <Grid container justifyContent="center" height={'100vh'} sx={{ background: 'rgb(241, 241, 241)' }} alignContent="start">
            <Grid container item xs={12} md={5} p={1} mb={1} mt={5} justifyContent="center" sx={{ background: "#fff", borderRadius: "15px" }}>
                <Grid item xs={12} mb={2} textAlign='center'>
                    <img style={{ width: 100 }} src={pidecancha}></img>
                    <Typography variant={"h5"} textAlign='center' className="title__main" gutterBottom>¡INVITACIÓN RESERVA CANCHA!</Typography>
                    <Typography variant='body1' textAlign='center' gutterBottom>Saludos cordiales, ha sido invitado a una reserva.</Typography>
                    <Typography variant='body1' textAlign='center' gutterBottom>¡Es hora de saltar al campo!</Typography>
                </Grid>
                <Grid container xs={12} item spacing={2}>
                    {/* {mensaje} */}
                    {/* {dataInfo.map((item) => ( */}
                        <>
                            <Grid item xs={12} md={12} p={1}>
                                <Grid item container justifyContent="center">
                                    <div>
                                    <Typography textAlign={'center'} fontWeight={"bolder"} gutterBottom variant="h6" color="#002545">
                                                Reserva en la {field}
                                            </Typography>
                                        <Grid item container mt={2} mb={2} justifyContent={"center"}>
                                            
                                            <Grid container item xs={12} md={6} justifyContent="center">
                                                <Grid container>
                                                    <Grid item xs={6} md={6}>
                                                        <spam style={{ fontWeight: "bold" }}>Reservador</spam>
                                                        <Typography variant='body1'>
                                                            {name+', '+surname}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} md={6} textAlign="right">
                                                        <spam style={{ fontWeight: "bold" }}>Fecha</spam>
                                                        <Typography variant='body1'>
                                                            {date}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} md={6} mt={1}>
                                                        <spam style={{ fontWeight: "bold" }}>Hora Inicio</spam>
                                                        <Typography
                                                            gutterBottom
                                                            variant='body1'
                                                        >
                                                            {start}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} md={6} mt={1} textAlign="right">
                                                        <spam style={{ fontWeight: "bold" }}>Hora Fin</spam>
                                                        <Typography
                                                            gutterBottom
                                                            variant='body1'
                                                        >
                                                            {end}
                                                        </Typography>
                                                    </Grid>
                                                    {/* <Grid item xs={6} md={6} mt={1}>
                                                        <spam style={{ fontWeight: "bold" }}>Invitados</spam>
                                                    </Grid> */}

                                                    {/* <Grid item xs={6} md={6} mt={1} textAlign="right">
                                                        <Typography
                                                            variant='body1'
                                                        >
                                                            {item.listaInvitados.map((i) => (
                                                                <Typography
                                                                    variant='body'
                                                                >
                                                                    {i + " "}
                                                                </Typography>
                                                            ))}
                                                        </Typography>

                                                    </Grid> */}

                                                </Grid>
                                            </Grid>


                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} mt={1} container justifyContent={"center"}>
                                <a href="https://cer.pidecancha.com/" style={{ textDecoration: 'none' }}>
                                    <Button fullWidth className='login_reservation' >INGRESAR AHORA</Button>
                                </a>
                            </Grid>
                        </>
                    {/* ))} */}
                </Grid>
            </Grid>
            <Backdrop
                invisible
                sx={{ bgcolor: 'primary', color: '#f00', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
                onClick={handleCloseBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

const itemReserva = [
    {
        reservador: 'Luis Ramirez M',
        club: 'Club "El Maracaná"',
        inicio: '20:00:00',
        fin: '21:30:00',
        cancha: 'Cancha 1',
        dia: '2022-10-19',
        precio_total: '$1500',
        invitados: '9',
        listaInvitados: ['Luis', 'Perez', 'Juan']
    },

];