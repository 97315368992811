import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import EasyCanchasAppShell from './EasyCanchasAppShell';
import { Provider } from 'react-redux';
import { store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <Provider store={store}>
    <BrowserRouter>
      <EasyCanchasAppShell />
    </BrowserRouter>
    </Provider>
  </>
);
// ReactDOM.render(
//   <Provider store={store}>
//   <BrowserRouter>
//     <EasyCanchasAppShell />
//   </BrowserRouter>
//   </Provider>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();