import { api } from './configs/axiosConfigs'

export const AuthAPI = {
   login: async ( password, rut) => {
      const response = await api.post(
         '/auth/login',
         {
            rut,
            password,
         }
      )
         .then(response => response.data.data)
         .catch((error) => {
            console.error(error)
         })
      return response
   },

   autologin: async ( password, rut) => {
      const response = await api.post(
         '/auth/autologin',
         {
            rut,
            password,
         }
      )
         .then(response => response.data.data)
         .catch((error) => {
            console.error(error)
         })
      return response
   },
   // refresh: async (token: string) => {
   //    api.defaults.headers.common['Authorization'] = `Bearer ${token}`

   //    const response = await api.post(
   //       '/auth/refresh',
   //    ).then(response => response.data)
   //       .catch((error) => {
   //          console.error(error)
   //       })
   //    return response
   // },
   // logout: async () => {
   //    const response = await api.post(
   //       '/auth/logout'
   //    ).then(response => response.data)
   //       .catch((error) => {
   //          return error.response.data
   //       })
   //    return response
   // },
   // verifyToken: async (token) => {
   //    api.defaults.headers.common['Authorization'] = `Bearer ${token}`
   //    const response = await api.get(
   //       '/auth/validateToken',
   //    ).then(response => response.data)
   //       .catch((error) => {
   //          console.error(error)
   //       })
   //    return response
   // },
   // recoveryPass: async (perfil: number, rut: string, email: string) => {
   //    const response = await api.post(
   //       '/auth/resend_credentials', {
   //       iduser_type: perfil,
   //       rut: rut,
   //       email: email
   //    }
   //    ).then(response => response.data)
   //       .catch((error) => {
   //          return error.response.data
   //       })
   //    return response
   // },
   // credentials_params: async (perfil: number, rut: string) => {
   //    const response = await api.get(
   //       '/auth/credentials_params', {
   //       params: {
   //          iduser_type: perfil,
   //          rut: rut,
   //       }
   //    }
   //    ).then(response => response.data)
   //       .catch((error) => {
   //          return error.response.data
   //       })
   //    return response
   // },
}
