import React, { useState, useRef, useCallback } from 'react';
import CForm from './components/form';
import Card from './components/card';

import { Grid, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';

const initialState = {
    cardNumber: '#### #### #### ####',
    cardHolder: 'FULL NAME',
    cardMonth: '',
    cardYear: '',
    cardCvv: '',
    isCardFlipped: false
};

const MainScreen = (props) => {
    const history = useHistory();
    
    const propsParseados= JSON.parse(props.location.state);
    
    const flujo = propsParseados.data_props;
    const idTypeField = propsParseados.idTypeField;
    const idtype_reservation = propsParseados.idtype_reservation;
    const a_idinvite = propsParseados.a_idinvite;
    const a_iduser = propsParseados.a_iduser;
    const listaInvitados=propsParseados.listaInvitados;
    const idField =propsParseados.idField;
    const [state, setState] = useState(initialState);
    const [currentFocusedElm, setCurrentFocusedElm] = useState(null);

    const updateStateValues = useCallback(
        (keyName, value) => {
            setState({
                ...state,
                [keyName]: value || initialState[keyName]
            });
        },
        [state]
    );

    // References for the Form Inputs used to focus corresponding inputs.
    let formFieldsRefObj = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
        cardCvv: useRef()
    };

    let focusFormFieldByKey = useCallback((key) => {
        formFieldsRefObj[key].current.focus();
    });

    // This are the references for the Card DIV elements.
    let cardElementsRef = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef()
    };

    let onCardFormInputFocus = (_event, inputName) => {
        const refByName = cardElementsRef[inputName];
        setCurrentFocusedElm(refByName);
    };

    let onCardInputBlur = useCallback(() => {
        setCurrentFocusedElm(null);
    }, []);
    const changeViewBack = () => {

        switch (flujo) {
            case 'canchas':               
                history.push('/horarios', { from: 'canchas',idField:idField, idTypeField:idTypeField,idtype_reservation:idtype_reservation,a_idinvite:a_idinvite,a_iduser:a_iduser, listaInvitados:listaInvitados})
                break;
            default:
                history.push('/horarios', { from: 'horarios', idTypeField:idTypeField,idtype_reservation:idtype_reservation, a_idinvite:a_idinvite, a_iduser:a_iduser, listaInvitados:listaInvitados})
                break;                
        }
    }

    return (
        <div className="wrapper" style={{ marginTop: '20px' }}>
            <Grid container item xs={12} mb={1} pl={1} justifyContent="center">
                <Grid xs={10}  mb={2}>
                    {/* <Link to={'/horarios'} className='link__css'> */}
                        <Typography sx={{ cursor: 'pointer !important' }} onClick={() => changeViewBack()} variant='h6' gutterBottom className='link__css-back'>
                            <ArrowBackIosIcon className='icon_back' /> Volver
                        </Typography>
                    {/* </Link> */}
                </Grid>
                <CForm
                cardMonth={state.cardMonth}
                cardYear={state.cardYear}
                onUpdateState={updateStateValues}
                cardNumberRef={formFieldsRefObj.cardNumber}
                cardHolderRef={formFieldsRefObj.cardHolder}
                cardDateRef={formFieldsRefObj.cardDate}
                onCardInputFocus={onCardFormInputFocus}
                onCardInputBlur={onCardInputBlur}
                data_props={props.location.state}
            >
                <Card
                    cardNumber={state.cardNumber}
                    cardHolder={state.cardHolder}
                    cardMonth={state.cardMonth}
                    cardYear={state.cardYear}
                    cardCvv={state.cardCvv}
                    isCardFlipped={state.isCardFlipped}
                    currentFocusedElm={currentFocusedElm}
                    onCardElementClick={focusFormFieldByKey}
                    cardNumberRef={cardElementsRef.cardNumber}
                    cardHolderRef={cardElementsRef.cardHolder}
                    cardDateRef={cardElementsRef.cardDate}
                ></Card>
            </CForm>
            </Grid>
            

        </div>
    );
};

export default MainScreen;