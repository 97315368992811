import React, { Component } from 'react';
import Routes from './routes/Routes';
import { withRouter } from 'react-router-dom';

class EasyCanchasAppShell extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }
  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    
  }
  render() {
    const { custom={} } = this.props;
    const { snackbar={}, backdrop={} } = custom;
    return (
        <div className="App" style={{height:"100%"}}>
          <Routes 
              {...this.props}
          />
        </div>
    )
  }
}

export default EasyCanchasAppShell;