import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { CssBaseline, makeStyles } from '@material-ui/core';
import { Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

// import MiniDrawer from 'components/mini_drawer/MiniDrawer';
// import MainHeader from 'components/header/MainHeader';
import HeaderMain from '../Components/headermain/HeaderMain';
import { Login } from '../Pages/Login/Login';
import { useState } from 'react';
import { session } from '../session/constants';
import { getSessionInfo } from '../utils/common'


const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    height: '100hv',
  },
}))

const PrivateRoute = ({ component: Component, ...rest }) => {
  const classes = useStyles();
  // const session = sessionStorage.getItem('sesion');
  var tipoUsuario = sessionStorage.getItem(session.SESSION_TYPE_USER)
  const [rutaAdmin, setRutaAdmin] = useState('/modulos-administrador');

  const sessionToken = getSessionInfo();

  return (
    <div className={classes.root}>
      {
        sessionToken ? (
          (tipoUsuario && tipoUsuario == 'ADMINISTRADOR') ?
            <>
              {/* <CssBaseline />
                  <MiniDrawer {...rest}/> */}
              <HeaderMain rutaAdmin={rutaAdmin} />

              <Route {...rest} render={props => <div style={{ width: "100%" }}> <Component {...props} {...rest} /> </div>} />
            </>
            :
            <>
              <HeaderMain />
              <Route {...rest} render={props => <div style={{ width: "100%" }}> <Component {...props} {...rest} /> </div>} />
            </>
        ) : (
          <Route {...rest} render={props => <div style={{width: "100%"}}> <Redirect to="/" /> </div>}/>
        )
        
      }
    </div>
  );
}

export default PrivateRoute;