import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducer'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
  devTools: true,
})

// export type RootState = ReturnType<typeof store.getState>

// export type AppDispatch = typeof store.dispatch

const useDispatch = () => useAppDispatch()
const useSelector = useAppSelector

export { store, useSelector, useDispatch}