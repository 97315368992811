import React from "react"
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import "./Sports.css"
import tenis from '../../assets/icons/pelota.png';
import baseball from '../../assets/icons/baseball.png';
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const DeportesCancha = (props) => {
    const data_props = props.location.state.from;
    return (
        <div>
            <Grid container justifyContent={"center"} mt={3} pl={1}>
                <Grid item xs={12} md={10}>
                    <Grid xs={12}>
                        <Link to={'/modulos'} className='link__css'>
                            <Typography variant='h6' gutterBottom className='link__css-back'>
                                <ArrowBackIosIcon className='icon_back' /> Volver
                            </Typography>
                        </Link>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Typography variant={"h5"} className="title__main">DEssRTES</Typography>
                            </Grid>
                            {/* <Grid item xs={4} md={2}>
                                <Button fullWidth className="btn-agregar" onClick={() => abrirModalInsertar()} startIcon={<AddCircleOutlineIcon />}>AGREGAR</Button>
                            </Grid> */}
                        </Grid>
                        <Typography variant='body1' gutterBottom>¿A qué deporte quieres jugar?</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
            >

                    <Grid container justifyContent="center" alignItems={"center"} >
                        <Grid item xs={12} md={10}>
                            <Grid container>
                                <Grid sm={6} xs={12} md={3}>
                                    <Link to={'/seleccionar-invitados-cancha'} style={{ textDecoration: 'none' }}>
                                        <Card className="cardDeporte">
                                            <Grid container justifyContent={"center"}>
                                                <CardMedia
                                                    style={{ marginTop: "5px" }}
                                                    component="img"
                                                    height="130"
                                                    image={tenis}
                                                />
                                                <CardContent>
                                                    <Typography variant={"h6"} textAlign={"center"} className="txt_cardsDeporte">
                                                        TENIS
                                                    </Typography>
                                                </CardContent>

                                            </Grid>

                                        </Card>
                                    </Link>
                                </Grid>
                                <Grid sm={6} xs={12} md={3}>
                                    <Link to={'/seleccionar-invitados-cancha'} style={{ textDecoration: 'none' }}>
                                        <Card className="cardDeporte">
                                            <Grid container justifyContent={"center"}>
                                                <CardMedia
                                                    style={{ marginTop: "5px" }}
                                                    component="img"
                                                    height="130"
                                                    image={baseball}
                                                />
                                                <CardContent>
                                                    <Typography variant={"h6"} textAlign={"center"} className="txt_cardsDeporte">
                                                        PADEL
                                                    </Typography>
                                                </CardContent>
                                            </Grid>

                                        </Card>
                                    </Link>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
            </Grid>
        </div>
    )
}