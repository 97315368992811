import React, { useEffect, useState, useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Box, Grid, TextField, Typography } from "@mui/material";
import Button from "@material-ui/core/Button";
// import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { Link, Switch } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "../../Pages/Reservation/Reservation.css"
// import { session } from "../../session/constants";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import ListSubheader from '@mui/material/ListSubheader';
import axios from "../../utils/axios";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    width: 450,
    margin: 5,
  },
}));


const AgregarInvitados = (props) => {

  const { open, handleCloseModal, invities, getExterns } = props;
  const [data, setData] = useState(invities);
  const [invitadoSeleccionado, setInvitadoSeleccionado] = useState({
    nombre: '',
    apellido: '',
    rut: '',
    telefono: '',
    correo: '',
    direccion: '',
    comuna: ''
  });

  const [externSelected, setExternSelected] = useState({
    id: '',
    name: '',
    surname: '',
    rut: '',
    birthday: '',
    email: '',
    phone: ''
    // idCompany: 2,
    // rutcompany: '2222222-2'
  })
  const [openError, setOpenError] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [messageSuccess, setMessageSuccess] = React.useState("");

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };
  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const [token, setToken] = useState(
    sessionStorage.getItem("Token")
  );


  const handleSubmit = async (e) => {
    if (!externSelected.name.trim()) {
      return setError("name");
    }
    if (!externSelected.surname.trim()) {
      return setError("surname");
    }
    // if (!externSelected.rut.trim()) {
    //   return setError("rut");
    // }
    // if (!externSelected.birthday.trim()) {
    //   return setError("birthday");
    // }
    // if (!externSelected.email.trim()) {
    //   return setError("email");
    // }

    e.preventDefault();
    const formFile = new FormData();
    try {
      await axios
        .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/invite?name=${externSelected.name}&surname=${externSelected.surname}&rut=${externSelected.rut}&email=${externSelected.email}&birthday=${externSelected.birthday}`,
          formFile,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          },
        )
        .then((response) => {
          setOpenSuccess(true);
          limpiaDatos();
          getExterns();
          handleCloseModal()
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      console.log('error')
    }
    getExterns();
    // getParners();
  };
  const limpiaDatos = () => {
    setExternSelected((userValues) => ({
      ...userValues,
      name: '',
      surname: '',
      rut: '',
      birthday: '',
      email: '',
      idTypeUser: 7,
      phone: ''
      // idCompany: 2,
      // rutcompany: ''
    }));
  };


  const handleChangeInvited = e => {
    // const changedFormValues = {
    //   ...externSelected,
    //   [e.target.name]: e.target.value,
    // }
    let changedFormValues = {}
    if (e.target.name === 'phone') {
      const regex = /^[0-9\b]+$/;
      if (e.target.value === "" || regex.test(e.target.value)) {
        console.log('value', e.target.value)
        changedFormValues = {
          ...externSelected,
          [e.target.name]: e.target.value,
        }
      }
    } else {
      changedFormValues = {
        ...externSelected,
        [e.target.name]: e.target.value,
      }
    }
    changedFormValues.name.trim() != "" && setError("");
    changedFormValues.surname.trim() != "" && setError("");
    // changedFormValues.rut.trim() != "" && setError("");
    // changedFormValues.birthday.trim() != "" && setError("");
    // changedFormValues.email.trim() != "" && setError("");
    setExternSelected(changedFormValues);
  }

  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}

      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography
              textAlign="center"
              variant="h6"
              id="transition-modal-title"
              sx={{ color: "#000", fontWeight: "bold" }}
            >
              AGREGAR INVITADO EXTERNO
            </Typography>
            <Grid container mt={2} justifyContent="center">

              <Grid container spacing={1} mt={2} justifyContent="center">

                <Grid item xs={12}>
                  <TextField
                    // required
                    error={error === "name" ? true : false}
                    helperText={error === "name" ? "El nombre es un campo requerido" : ""}
                    // value={teacherSelected.name}
                    type="text"
                    size="small"
                    name="name"
                    variant='outlined'
                    placeholder="Nombres*"
                    fullWidth
                    onChange={handleChangeInvited}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={error === "surname" ? true : false}
                    helperText={error === "surname" ? "Los apellidos es un campo requerido" : ""}
                    type="text"
                    size="small"
                    fullWidth
                    name="surname"
                    placeholder="Apellidos*"
                    // value={invitadoSeleccionado ? invitadoSeleccionado.apellidos : ''}
                    onChange={handleChangeInvited}
                  />
                </Grid>


                {/* <Grid item xs={12}>
                  <TextField
                    error={error === "rut" ? true : false}
                    helperText={error === "rut" ? "RUT es un campo requerido" : ""}
                    type="text"
                    size="small"
                    fullWidth
                    name="rut"
                    placeholder="RUT*"
                    // value={invitadoSeleccionado ? invitadoSeleccionado.rut : ''}
                    onChange={handleChangeInvited}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    error={error === "phone" ? true : false}
                    helperText={error === "phone" ? "RUT es un campo requerido" : ""}
                    type="text"
                    size="small"
                    fullWidth
                    name="phone"
                    placeholder="Teléfono*"
                    value={externSelected.phone || ''}
                    // value={invitadoSeleccionado ? invitadoSeleccionado.rut : ''}
                    onChange={handleChangeInvited}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    error={error === "birthday" ? true : false}
                    helperText={error === "birthday" ? "La fecha de nacimiento es un campo requerido" : ""}
                    size="small"
                    type="date"
                    fullWidth
                    name="birthday"
                    placeholder="Fecha de nacimiento"
                    // value={invitadoSeleccionado ? invitadoSeleccionado.direccion : ''}
                    onChange={handleChangeInvited}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    error={error === "emailInvalido" ? true : false}
                    helperText={error === "emailInvalido" ? "Ingrese un correo valido" : ""}
                    size="small"
                    type="text"
                    fullWidth
                    name="email"
                    placeholder="Correo*"
                    // value={invitadoSeleccionado ? invitadoSeleccionado.correo : ''}
                    onChange={handleChangeInvited}
                  />
                </Grid>

                <Grid container spacing={1} mt={2}>
                  <Grid item xs={6}>
                    <Button fullWidth className="btn-cancelar" onClick={handleCloseModal}>Cancelar</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button fullWidth className="btn-aceptar" onClick={handleSubmit}>Agregar</Button>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </div>
        </Fade>
      </Modal>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
          <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
            Invitado registrado satisfactoriamente !
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}
export default AgregarInvitados;