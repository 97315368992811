import React, { useEffect, useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Grid, TextField, Typography } from "@mui/material";
import Button from "@material-ui/core/Button";
// import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { Link, Switch } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "../../Pages/Reservation/Reservation.css"
// import { session } from "../../session/constants";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import ListSubheader from '@mui/material/ListSubheader';
import AgregarInvitados from "./AgregarInvitados";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    width: 450,
    margin: 5,
  },
}));

const recently = [
  { nombre: "Andy Jhovany", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan Jhonas", apellido: "Cornejo Chinga", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy Jhovany", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
]

const parners = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
]

const invities = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
]

const families = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
]

const Invitados = (props) => {
  const { open, handleCloseModal } = props;
  const [openInvitados, setOpenInvitados] = React.useState(false)
  const [parner, setParner] = React.useState(false)
  const [family, setFamily] = React.useState(false)
  const [invitados, setInvitados] = React.useState(false)
  const [resenties, setRecenties] = React.useState(true)
  const [cardSocio, setCardSocio]=React.useState(false);
  const [cardFamiliar, setCardFamiliar]=React.useState(false);
  const [cardExternos, setCardExternos]=React.useState(false);
  const [cardAlumnos, setCardAlumnos]=React.useState(false);

  var tipoUsuario= localStorage.getItem("tipoUsuario")


  useEffect(() => {
    switch(tipoUsuario) {
      case "socio":
        setCardSocio(true);
        setCardFamiliar(true);
        setCardExternos(true);
        break;
      case "familiar":
        setCardSocio(false);
        setCardFamiliar(true);
        setCardExternos(true);
        break;
      case "externo":
        setCardSocio(false);
        setCardFamiliar(false);
        setCardExternos(true);
        break;
      case "profesor":
        setCardSocio(false);
        setCardFamiliar(false);
        setCardAlumnos(true);
        setCardExternos(true);
        break;
  }  }, []);

  const handleSocios = () => {
    setParner(true);
    setRecenties(false);
    setInvitados(false);
    setFamily(false);
  };
  const handleFamilies = () => {
    setParner(false);
    setRecenties(false);
    setInvitados(false);
    setFamily(true);
  };
  const handleInvities = () => {
    setParner(false);
    setRecenties(false);
    setInvitados(true);
    setFamily(false);
  };

  const handleShowInvitados = () => {
    setOpenInvitados(true)
  }
  const handleCloseInvitados = () => {
    setOpenInvitados(false)
  }

  const handleClose=()=>{
    setParner(false);
    setRecenties(true);
    setInvitados(false);
    setFamily(false);
    handleCloseModal();
  }
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}

      //   style={{  }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form>
              <Typography
                textAlign="left"
                variant="subtitle1"
                id="transition-modal-title"
                className="text-modal-title"
              >
                SELECCIONAR INVITADO
              </Typography>
              <Grid container >
              <Grid container spacing={1} >
                {cardSocio&&<Grid item xs={4} md={4}>
                  <Button fullWidth className="card-invite1" onClick={handleSocios} >
                    <Typography textAlign={"center"} className={"txt-card-opcion"} >SOCIOS</Typography>
                  </Button>
                </Grid>}
                {cardFamiliar&&<Grid item xs={4} md={4}>
                  <Button fullWidth className="card-invite2" onClick={handleFamilies}>
                    <Typography textAlign={"center"} className={"txt-card-opcion"}>FAMILIAR</Typography>
                  </Button >
                </Grid>}
                {cardAlumnos&&<Grid item xs={4} md={4}>
                  <Button fullWidth className="card-invite2" onClick={handleFamilies}>
                    <Typography textAlign={"center"} className={"txt-card-opcion"}>ALUMNOS</Typography>
                  </Button >
                </Grid>}
                {cardExternos&&<Grid item xs={4} md={4}>
                  <Button fullWidth className="card-invite3" onClick={handleInvities}>
                    <Typography textAlign={"center"} className={"txt-card-opcion"}>EXTERNOS</Typography>
                  </Button>
                </Grid>}
              </Grid>

                <Grid xs={12} >
                {resenties &&<Grid>
                  <Grid mt={2}>
                    <Typography variant={"caption"}>RECIENTES</Typography>
                  </Grid>

                   <List sx={{
                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                    maxHeight: 200,

                    overflow: 'auto',
                  }}>
                    {recently.map((value) => (
                      <ListItem
                        key={value}
                        disableGutters
                        secondaryAction={
                          <Button className='btn-invitar-now'>INVITAR AHORA</Button>
                        }
                      >
                        <ListItemText primary={`${value.nombre}, ${value.apellido}  `} />
                      </ListItem>
                    ))}
                  </List>
                  </Grid>}
                  {parner && <List sx={{
                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                    maxHeight: 200,

                    overflow: 'auto',
                  }}>
                    {parners.map((value) => (
                      <ListItem
                        key={value}
                        disableGutters
                        secondaryAction={
                          <Button className='btn-invitar-now'>INVITAR AHORA</Button>
                        }
                      >
                        <ListItemText primary={`${value.nombre}, ${value.apellido}  `} />
                      </ListItem>
                    ))}
                  </List>}

                  {family && <List sx={{
                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                    maxHeight: 200,

                    overflow: 'auto',
                  }}>
                    {families.map((value) => (
                      <ListItem
                        key={value}
                        disableGutters
                        secondaryAction={
                          <Button className='btn-invitar-now'>INVITAR AHORA</Button>
                        }
                      >
                        <ListItemText primary={`${value.nombre}, ${value.apellido}  `} />
                      </ListItem>
                    ))}
                  </List>}
                  {invitados && <Grid xs={12} mt={2} mb={-3}>

                  <List sx={{
                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                    maxHeight: 200,

                    overflow: 'auto',
                  }}>
                    {invities.map((value) => (
                      <ListItem
                        key={value}
                        disableGutters
                        secondaryAction={
                          <Button className='btn-invitar-now'>INVITAR AHORA</Button>
                        }
                      >
                        <ListItemText primary={`${value.nombre}, ${value.apellido}  `} />
                      </ListItem>
                    ))}
                   
                  </List>
                  
                   <Grid item xs={12} mt={2} mb={1}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Button variant='outlined' fullWidth className='card_reservation--cancel' onClick={handleShowInvitados}>AGREGAR INVITADO</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>}
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
      <AgregarInvitados
        open={openInvitados}
        handleCloseModal={handleCloseInvitados}
        invities={invities}
      />

    </div>
  );
}
export default Invitados;