import "./SelectGuests.css"
import React, { useEffect, useCallback, useMemo } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Grid, TextField, Typography } from "@mui/material";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
// import { session } from "../../session/constants";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AgregarInvitados from "../../Components/Modal/AgregarInvitados";
import { session } from "../../session/constants";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import AgregarInvitados from "./AgregarInvitados";

const recently = [
  { nombre: "Andy Jhovany", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan Jhonas", apellido: "Cornejo Chinga", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy Jhovany", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
]

const parners = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
]

const invities = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
]

const families = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
]
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SelectGuestsCancha = (props) => {
  const { open, handleCloseModal } = props;
  const [openInvitados, setOpenInvitados] = React.useState(false)
  const [parner, setParner] = React.useState(false)
  const [family, setFamily] = React.useState(false)
  const [invitados, setInvitados] = React.useState(false)
  const [resenties, setRecenties] = React.useState(false)
  const [openAlertTypeGame, setOpenAlertTypeGame] = React.useState(false);

  const [cardSocio, setCardSocio] = React.useState(true);
  const [cardFamiliar, setCardFamiliar] = React.useState(true);
  const [cardExternos, setCardExternos] = React.useState(true);
  const [cardAlumnos, setCardAlumnos] = React.useState(true);
  const [typeGame, setTypeGame] = React.useState('');
  const [partners, setPartners] = React.useState([]);
  const [idSelecteds, setIdSelecteds] = React.useState([]);
  const [render, setRender] = React.useState(true);
  var tipoUsuario = localStorage.getItem("tipoUsuario")
  const token = sessionStorage.getItem(session.SESSION_TOKEN);
  var tipoUsuario = localStorage.getItem("tipoUsuario")


  const handleCloseAlertTypeGame = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertTypeGame(false);
  };
  const [reserva, setReserva] = React.useState({
    date: "2022-09-29",
    idtype_reservation: "",
    idhorary: 40,
    a_iduser: [3, 4],
    a_idinvite: []
  });
  const history = useHistory();
  const data_props = props.location.state.from;

  const changeView = (view) => {
    switch (view) {
      case 'horario':
        history.push('/deportes-horario', { from: 'horario' })
        break;

      default:
        history.push('/deportes-horario', { from: 'cancha' });
        break;
    }
  }

  useEffect(() => {
    getPartners();
  }, []);
  function getPartners() {
    return fetch('https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/partners', {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(pros => pros.json())
      .then(async pros => {
        const data = (pros.data || []).map((raw) => ({
          birthday: raw?.birthday,
          companies: raw?.companies,
          email: raw?.email,
          id: raw?.id,
          name: raw?.name,
          rut: raw?.rut,
          surname: raw?.surname,
          invited: 0,
        }))
        setPartners(data);
      })
  }
  const handleInvited = (value) => {
    if (typeGame == '') {
      setOpenAlertTypeGame(true);
    };
    let invited = 0;
    if (value.invited == 0) {
      const numPartner = partners.filter(y => y.invited == 1);
      if (typeGame == 'SIMPLE') {
        if (numPartner.length <= 0) {
          invited = 1;
          idSelecteds.push(value.id);
        } else {
          return alert('El máximo de invitaciones para tipo de juego Simple es 1')
        }

      }

      if (typeGame == 'DOBLE') {
        if (numPartner.length <= 2) {
          invited = 1;
          idSelecteds.push(value.id);
        } else {
          return alert('El máximo de invitaciones para tipo de juego Simple es 2')
        }

      }
    } else {
      invited = 0;
    }
    let valueform = { ...value, invited: invited };

    let arrayPartner = partners;
    partners.map((item, i) => {
      if (item.id == value.id) {
        arrayPartner[i] = valueform;
      }
    })
    setPartners(arrayPartner);
    setRender(!render);
  }
  // const addPartners=()=>{
  //   console.log("Usuario(s) seleccionados: ", idSelecteds)
  //   history.push('/lista-canchas', {
  //     a_iduser: idSelecteds,
  //     idtype_reservation: typeGame == 'SIMPLE' ? 1 : 2
  //   });
  // }
  const addPartners = () => {
    const numPartner = partners.filter(y => y.invited == 1);
    if (numPartner.length <= 0) {
      return alert('No se agregó invitados');
    }
    let arrayresponse = numPartner.map((value) => {
      return value.id;
    })

    history.push('/lista-canchas', {
      a_iduser: arrayresponse,
      idtype_reservation: typeGame == 'SIMPLE' ? 1 : 2
    });
  }
  const handleSocios = () => {
    setParner(true);
    setRecenties(false);
    setInvitados(false);
    setFamily(false);
  };
  const handleFamilies = () => {
    setParner(false);
    setRecenties(false);
    setInvitados(false);
    setFamily(true);
  };
  const handleInvities = () => {
    setParner(false);
    setRecenties(false);
    setInvitados(true);
    setFamily(false);
  };

  const handleTeacher = () => {
    setParner(false);
    setRecenties(false);
    setInvitados(true);
    setFamily(false);
  };

  const handleShowInvitados = () => {
    setOpenInvitados(true)
  }
  const handleCloseInvitados = () => {
    setOpenInvitados(false)
  }

  const handleClose = () => {
    setParner(false);
    setRecenties(true);
    setInvitados(false);
    setFamily(false);
    handleCloseModal();
  }
  const ListPartner = <List sx={{
    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
    maxHeight: 200,

    overflow: 'auto',
  }}>
    {partners.map((value) => (
      <ListItem
        key={value}
        disableGutters
        secondaryAction={
          <Button className={value.invited == 0 ? 'btn-invitar-now' : 'btn-invited'}
            onClick={() => {
              handleInvited(value)
            }}
          >{value.invited == 0 ? 'INVITAR AHORA' : 'SELECCIONADO'}</Button>
        }
      >
        <ListItemText primary={`${value.name}, ${value.surname}  `} />
      </ListItem>
    ))}
  </List>
  const handleTipo = (tipo) => {
    setReserva((reservaValues) => ({
      ...reservaValues,
      idtype_reservation: tipo,
    }));
  }
  const handleSubmit = async (e) => {
    try {
      const response = await axios({
        method: "post",
        url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          date: reserva.date,
          idtype_reservation: reserva.idtype_reservation,
          idhorary: reserva.idhorary,
          a_iduser: reserva.a_iduser,
          a_idinvite: reserva.a_idinvite,
        }
      });
     } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid>
      <Grid container item xs={10} mb={1} mt={3} pl={1} justifyContent="center">
        <Grid xs={10} mb={2} onClick={() => changeView(data_props)}>
          {/* <Link to={'/deportes-horario'} className='link__css'> */}
          <Typography variant='h6' gutterBottom className='link__css-back'>
            <ArrowBackIosIcon className='icon_back' /> Volver
          </Typography>
          {/* </Link> */}
          <Typography variant={"h5"} className="title__main" gutterBottom>AGREGA A TUS INVITADOS</Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={7}>
          <form>
            <Grid container>
              <Typography
                textAlign="left"
                variant="subtitle1"
                id="transition-modal-title"
                className="text-modal-title"
              >
                Seleccione el tipo de juego
              </Typography>
              <Grid container spacing={1} >
                <Grid item xs={4} md={4}>
                  <Button fullWidth className={typeGame == 'SIMPLE' ? "card-seleccionado" : "card-no-seleccionado"}
                    onClick={() => { typeGame == 'SIMPLE' ? setTypeGame('') : setTypeGame('SIMPLE') }}>
                    <Typography textAlign={"center"} className={"txt-card-opcion"}>SINGLE</Typography>
                  </Button>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Button fullWidth className={typeGame == 'DOBLE' ? "card-seleccionado" : "card-no-seleccionado"}
                    onClick={() => { typeGame == 'DOBLE' ? setTypeGame('') : setTypeGame('DOBLE') }}>
                    <Typography textAlign={"center"} className={"txt-card-opcion"}>DOBLES</Typography>
                  </Button >
                </Grid>

              </Grid>
            </Grid>




            <Grid container mt={1}>
              <Typography
                textAlign="left"
                variant="subtitle1"
                id="transition-modal-title"
                className="text-modal-title"
              >
                Seleccione el tipo de invitado
              </Typography>
              <Grid container spacing={1} >
                {cardSocio && <Grid item xs={4} md={4}>
                  <Button fullWidth className="card-seleccionado" onClick={handleSocios} >
                    <Typography textAlign={"center"} className={"txt-card-opcion"} >SOCIOS</Typography>
                  </Button>
                </Grid>}
                {cardFamiliar && <Grid item xs={4} md={4}>
                  <Button fullWidth className="card-invite2" onClick={handleFamilies}>
                    <Typography textAlign={"center"} className={"txt-card-opcion"}>FAMILIAR</Typography>
                  </Button >
                </Grid>}
                {/* {cardAlumnos && <Grid item xs={4} md={4}>
                  <Button fullWidth className="card-invite2" onClick={handleFamilies}>
                    <Typography textAlign={"center"} className={"txt-card-opcion"}>ALUMNOS</Typography>
                  </Button >
                </Grid>} */}
                {cardExternos && <Grid item xs={4} md={4}>
                  <Button fullWidth className="card-invite3" onClick={handleInvities}>
                    <Typography textAlign={"center"} className={"txt-card-opcion"}>EXTERNOS</Typography>
                  </Button>
                </Grid>}
              </Grid>

              <Grid xs={12} >
                {resenties && <Grid>
                  <Grid mt={2}>
                    <Typography variant={"caption"}>RECIENTES</Typography>
                  </Grid>

                  <List sx={{
                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                    maxHeight: 200,

                    overflow: 'auto',
                  }}>
                    {recently.map((value) => (
                      <ListItem
                        key={value}
                        disableGutters
                        secondaryAction={
                          <Button className='btn-invitar-now'>INVITAR AHORA</Button>
                        }
                      >
                        <ListItemText primary={`${value.nombre}, ${value.apellido}  `} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>}
                {ListPartner}

                {family && <List sx={{
                  width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                  maxHeight: 200,

                  overflow: 'auto',
                }}>
                  {families.map((value) => (
                    <ListItem
                      key={value}
                      disableGutters
                      secondaryAction={
                        <Button className='btn-invitar-now'>INVITAR AHORA</Button>
                      }
                    >
                      <ListItemText primary={`${value.nombre}, ${value.apellido}  `} />
                    </ListItem>
                  ))}
                </List>}
                {invitados && <Grid xs={12} mt={2} mb={-3}>

                  <List sx={{
                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                    maxHeight: 200,

                    overflow: 'auto',
                  }}>
                    {invities.map((value) => (
                      <ListItem
                        key={value}
                        disableGutters
                        secondaryAction={
                          <Button className='btn-invitar-now'>INVITAR AHORA</Button>
                        }
                      >
                        <ListItemText primary={`${value.nombre}, ${value.apellido}  `} />
                      </ListItem>
                    ))}

                  </List>

                  <Grid item xs={12} mt={2} mb={1}>
                    <Grid container spacing={2} justifyContent="right">
                      <Grid item xs={12} md={4}>
                        <Button variant='outlined' fullWidth className='card_reservation--cancel' onClick={handleShowInvitados}>AGREGAR INVITADO</Button>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>}
              </Grid>

              <Grid mt={3} item container spacing={1} justifyContent="center">
                <Grid xs={12} md={3} mx={1}>
                  {/* <Link to={"/lista-canchas"} style={{ textDecoration: 'none' }}> */}
                  <Button onClick={() => { addPartners() }} fullWidth variant='outlined' className='card_reservation--cancel'>GUARDAR INVITADOS</Button>
                  {/* </Link> */}
                </Grid>
                <Grid xs={12} md={3} mx={1}>
                  <Link to={"/lista-canchas"} style={{ textDecoration: 'none' }}>
                    <Button fullWidth variant='outlined' className='card_reservation--add' >OMITIR INVITADOS</Button>
                  </Link>

                </Grid>

              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <Snackbar open={openAlertTypeGame} autoHideDuration={3000} onClose={handleCloseAlertTypeGame}>
        <Alert onClose={handleCloseAlertTypeGame} severity="warning" sx={{ width: '100%' }}>
          ¡Elija un tipo de juego!
        </Alert>
      </Snackbar>
      <AgregarInvitados
        open={openInvitados}
        handleCloseModal={handleCloseInvitados}
        invities={invities}
      />

    </Grid>
  )
}