import {
    INVITE,
 } from '../../toolbox/constants/action-type';
 
 export function actionSetListInvite(data) {
    return {
       type: INVITE,
       payload: data
    }
 }
 