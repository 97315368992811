import * as React from 'react';
import { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, TextField } from '@mui/material';
import { Grid } from '@mui/material';
import "./Reservation.css";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Invitados from '../../Components/Modal/Invitados';
import ReactCardFlip from 'react-card-flip';
import axios from '../../utils/axios'
import moment from 'moment'
import 'moment/locale/es';
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import { ConnectingAirportsOutlined, Rocket } from "@mui/icons-material";
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

moment.locale('es');

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 400,
    },
}));
export const Reservation = (props) => {
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const data_user = localStorage.getItem('data_user');
    const id_user = JSON.parse(data_user).id_user
    let tipoUsuario = JSON.parse(data_user).type_user
    const [openModal, setOpenModal] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false)
    const [dataReservation, setDataReservation] = useState([]);
    const [selectReservation, setSelectDataReservation] = useState([{
        id: ''
    }]);
    const [nameUserChange, setNameUserChange] = useState('');
    const [termBusqueda, setTermBusqueda] = useState('');
    const [invitados, setInvitados] = useState([]);
    const [invitados2, setInvitados2] = useState([]);
    const [showInvites, setShowInvites] = useState(false);
    const [possibleUser, setPossibleUser] = useState([]);
    const [posibleUserSelected, setPosibleUserSelected] = useState('');
    const [showInvitesChange, setShowInvitesChange] = useState(false);
    const [showDeleteReservation, setShowDeleteReservation] = useState(false);
    const [dataDelete, setDataDelete] = useState([]);
    const [snackBarConfig, setSnackBarConfig] = useState({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
     })
     
    var Token = localStorage.getItem('token_user');
    const classes = useStyles();

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    const handleToggleBackdrop = () => {
        setOpenBackdrop(!openBackdrop);
    };

    const handleClick = (elemento) => {
        // e.preventDefault();
        //setIsFlipped(!isFlipped);
        console.log('SOy lo contrario del primero');
    }
    const handleOpenModal = () => {
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    }
    let history = useHistory();

    const showModalCambiar = (elemento) => {
        setShowInvites(true);
        setSelectDataReservation(elemento);
    }
    const showModalDeleteReservation = (item) => {
        setShowDeleteReservation(true);
        setDataDelete(item);
 }


    const getReservations = (data) => {
        const array = [];
        data.map((row) => {
            moment.locale('es');
            var localLocale = moment(row.data.date).format('LL');
            var inviteds = row.invite.filter(invited=>invited.iduser != row.data.iduser)
            const [fechaMinima, fechaMaxima] = encontrarFechas(row.hours);
            const horaMin = fechaMinima.getHours().toString().padStart(2, '0');
            const minutosMin = fechaMinima.getMinutes().toString().padStart(2, '0');
            const segundosMin = fechaMinima.getSeconds().toString().padStart(2, '0');

            const horaMinima = `${horaMin}:${minutosMin}:${segundosMin}`;

            const horaMax = fechaMaxima.getHours().toString().padStart(2, '0');
            const minutosMax = fechaMaxima.getMinutes().toString().padStart(2, '0');
            const segundosMax = fechaMaxima.getSeconds().toString().padStart(2, '0'); // Rellenar con cero a la izquierda si el día es de un solo dígito
            const horaMaxima = `${horaMax}:${minutosMax}:${segundosMax}`;
            array.push(
                {
                    id: row.data.id,
                    reservador: row.data.name_user + ' ' + row.data.surname_user,
                    club: row.data.name_company,
                    deporte: row?.hours[0]?.name,
                    cancha: row?.hours[0]?.name_field,
                    dia: moment(row.data.date).format('LL'),
                    hora: `${horaMinima} - ${horaMaxima}`,
                    precio_total: row.data.total,
                    fecha_pago: '',
                    status: row.data.status,
                    invitados: row.invite.length -1,
                    listaInvitados: inviteds
                }
            )
        })

        setDataReservation(array);

    };

    const myReservations = async (user) => {
        handleToggleBackdrop();
        var today = new Date();
        var day = today.getDate();
        var month = today.getMonth() + 1;
        var year = today.getFullYear();
        const date = `${year}-${month}-${day}`;
        var pending=0;
        // switch (tipoUsuario) {
        //     case 'SOCIO':
        //         pending = null
        //         break;
        //     default:
        //         break;
        // }
        // await axios.get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/report?pending=1&user=${user}&date=${date}&orderBy=DESC&pending=0`, {
            await axios.get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/report?user=${user}&date=${date}&orderBy=DESC&status=[0,1]`, {
                
            headers: {
                    Authorization: `Bearer ${Token}`,
                },
            })
            .then((res) => {
                getReservations(res.data.data);
                console.log("Soy res data: ", res.data.data)
                handleCloseBackdrop();
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const myReservationsSocio = async (user) => {
        handleToggleBackdrop();
        var today = new Date();
        var day = today.getDate();
        var month = today.getMonth() + 1;
        var year = today.getFullYear();
        const date = `${year}-${month}-${day}`;
        var pending=0;
        // switch (tipoUsuario) {
        //     case 'SOCIO':
        //         pending = null
        //         break;
        //     default:
        //         break;
        // }
        // await axios.get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/report?pending=1&user=${user}&date=${date}&orderBy=DESC&pending=0`, {
            await axios.get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/report?user=${user}&date=${date}&orderBy=DESC&status=[0,1]`, {
                
            headers: {
                    Authorization: `Bearer ${Token}`,
                },
            })
            .then((res) => {
                getReservations(res.data.data);
                 handleCloseBackdrop();
            })
            .catch((error) => {
                console.error(error);
            });
    };
   //showInvitesForUser(id_reserva, id_detalle_inivitacion, id_invitado, id_usuario)
    const showInvitesForUser = (idReserva, id_detalle_invitacion, id_invitado, id_usuario) => {

        console.log('idReserva: ', idReserva, 'id_detalle_invitacion: ', id_detalle_invitacion);
        setPosibleUserSelected({id_detalle_invitacion: id_detalle_invitacion, id_invitado:id_invitado, id_usuario:id_usuario});
        getposiblesUsers(idReserva, id_detalle_invitacion , id_invitado, id_usuario)
        setShowInvitesChange(true);
    }

    //getposiblesUsers(idReserva, id_detalle_invitacion , id_invitado, id_usuario)
    const getposiblesUsersBuscador = (id_reserva, id_detalle_invitacion , id_invitado, id_usuario, term) => {
        return fetch(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/possible/${id_detalle_invitacion}?term=${term}`, {
            method: 'GET',
            headers: {
                // 'Access-Control-Allow-Origin': '*',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + Token
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                // var inviteds = pros.data.filter(invited=>{invited.iduser || invited.id != row.data.iduser)}
                possiblesUserExpectUserInvited(id_invitado, pros)
               // setPossibleUser(pros.data)
                // const data = (pros || []).map((raw) => ({
                //     id: raw?.id,
                //     name: raw?.name,
                //     description: raw?.description,
                //     image_1: raw?.image_1,
                //     capacity: raw?.capacity
                // }))
                // console.log(data);
                // setListCanchas(data);
            })
    }

    const possiblesUserExpectUserInvited = (id_invitado, pros) => {
        let tipo_invitado_filtro = ''
                if(id_invitado){
                    tipo_invitado_filtro = 'invitado_externo'
                }else{
                    tipo_invitado_filtro = 'invitado_socio'
                }

                let invitados_for_type = [];
                invitados.map((value)=>{
                    let tipo_invitado = ''
                    if(value.idinvite){
                        tipo_invitado = 'invitado_externo'
                    }else{
                        tipo_invitado = 'invitado_socio'
                    }

                    if(tipo_invitado == tipo_invitado_filtro ){
                        invitados_for_type.push(value)
                    }
                })

                let invited_possibles = []
                if(tipo_invitado_filtro == 'invitado_socio'){

                    pros.data.map((item,i)=>{
                        let validate_possibles = invitados_for_type.find(item2=> item2.iduser == item.id)
                        if(validate_possibles == undefined){
                            invited_possibles.push(item)
                        }
                        //si es que lo encuentra, no pushea
                    })

                }
                

                if(tipo_invitado_filtro == 'invitado_externo'){

                    pros.data.map((item,i)=>{
                        let validate_possibles = invitados_for_type.find(item2=> item2.idinvite == item.id)
                        console.log(validate_possibles)
                        if(validate_possibles == undefined){
                            invited_possibles.push(item)
                        }
                    })
                }
                setPossibleUser(invited_possibles)
                // const data = (pros || []).map((raw) => ({
                //     id: raw?.id,
                //     name: raw?.name,
                //     description: raw?.description,
                //     image_1: raw?.image_1,
                //     capacity: raw?.capacity
                // }))
                // console.log(data);
                // setListCanchas(data);

    }
    const getposiblesUsers = (id_reserva, id_user_change , id_invitado, id_usuario) => {
        return fetch(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/possible/${id_user_change}`, {
            method: 'GET',
            headers: {
                // 'Access-Control-Allow-Origin': '*',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + Token
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                possiblesUserExpectUserInvited(id_invitado, pros)

                 })
    }
    const anularReserva = async (data) => {

        try{
            axios
                .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/${data.id}`, {
                    headers: {
                        Authorization: `Bearer ${Token}`,
                    },
                })
                .then((res) => {
                    if(tipoUsuario === 'SOCIO'){
                        myReservationsSocio(id_user);
                    }else{
                        myReservations(id_user);
                    }
                    setShowDeleteReservation(false);
                })
                .catch((error) => {
                    console.error(error);
                });
        

        
            // .then(pros => pros.json())
            // .then(async pros => {
            //     // console.log("si anula debería hacer esto: ", pros)
            //     myReservations(id_user);
            //     setShowDeleteReservation(false);
            //     // const data = (pros || []).map((raw) => ({
            //     //     id: raw?.id,
            //     //     name: raw?.name,
            //     //     description: raw?.description,
            //     //     image_1: raw?.image_1,
            //     //     capacity: raw?.capacity
            //     // }))
            //     // console.log(data);
            //     // setListCanchas(data);
            // })
        }
        catch(e)
        {
            console.log("Erro al anular reserva: ", e)
        }
    };


    // console.log('datos de la reverva', dataReservation)
    useEffect(() => {
        if(tipoUsuario === 'SOCIO'){
            myReservationsSocio(id_user);
        }else{
            myReservations(id_user);
        }
    }, [])

    const changeUserResarvation = (id_user_ant, id_user_new) => {
        return fetch(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/updateInvite/${id_user_ant}?iduser_new=${id_user_new}`, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + Token,
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                console.log(pros)
                setShowInvitesChange(false)
                setShowInvites(false)
                // alert('cambio exitoso')
                if(tipoUsuario=='SOCIO'){
                    myReservationsSocio(id_user);
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'success',
                        message: "El usuario fue cambiado correctamente",
                     }));
                }else{
                    myReservations(id_user);
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'success',
                        message: "El usuario fue cambiado correctamente",
                     }));
                }
                

                // const data = (pros || []).map((raw) => ({
                //     id: raw?.id,
                //     name: raw?.name,
                //     description: raw?.description,
                //     image_1: raw?.image_1,
                //     capacity: raw?.capacity
                // }))
                // console.log(data);
                // setListCanchas(data);
            })

    }

    const handleBuscador = (event) => {
        try {
            const name = event.target.name;
            const value = event.target.value;
            switch (name) {
                case 'search':
                    if (value.length > 0) {
                        //getposiblesUsers(idReserva, id_detalle_invitacion , id_invitado, id_usuario)
                        getposiblesUsersBuscador(selectReservation.id, posibleUserSelected.id_detalle_invitacion,posibleUserSelected.id_invitado,posibleUserSelected.id_usuario , value)

                    } else {
                        getposiblesUsers(selectReservation.id, posibleUserSelected.id_detalle_invitacion,posibleUserSelected.id_invitado,posibleUserSelected.id_usuario)
                    }

                    setTermBusqueda(value);

                    break;
                default:
                    break;
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Grid container justifyContent="center" className='contenedor__main' alignContent="center" sx={{bgcolor:'#f4f4f4'}}>
            <Grid container item xs={10} mb={1} mt={3} pl={1}>
                <Grid xs={10} mb={2}>
                    <Link to={'/modulos'} className='link__css'>
                        <Typography variant='h6' gutterBottom className='link__css-back'>
                            <ArrowBackIosIcon className='icon_back' /> Volver
                        </Typography>
                    </Link>
                    <Typography variant={"h5"} className="title__main" gutterBottom>RESERVAS</Typography>
                    <Typography variant='body1' gutterBottom>Tienes {dataReservation.length} reserva(s).</Typography>
                </Grid>
                <Grid container xs={12} item spacing={2}>
                    {dataReservation.map((item) => (
                        <Grid item xs={12} md={4}>
                            <Card className='card_reservation' >
                                    <Grid item container justifyContent="center">
                                        <CardContent>
                                            <ReactCardFlip isFlipped={item.listaInvitados == invitados2 ? true : false} flipDirection="vertical">
                                                <div>
                                                    <div>
                                                        <Grid item mt={2} mb={2}>
                                                            <Typography className='card_reservation--club' gutterBottom variant="h5" component="div">
                                                                <EventAvailableIcon className='card_reservation--club--icon' />Para {item.deporte}, en {item.cancha}
                                                            </Typography>
                                                            <Typography className='card_reservation--info' gutterBottom variant="subtitle1" component="div">
                                                                Reservado por: <b>{item.reservador}</b>
                                                            </Typography>
                                                            <Typography variant="subtitle1" className='card_reservation--precio'>
                                                                <b>{item.dia + ', ' + item.hora}</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Divider />

                                                        <Grid mt={1} item display='flex' justifyContent='space-between'>
                                                            {/* <Typography
                                                                gutterBottom
                                                                variant="subtitle1"
                                                            color="text.secondary"
                                                            className='card_reservation--precio'
                                                            >
                                                                Fecha de pago
                                                            </Typography> */}
                                                            <Typography
                                                                gutterBottom
                                                                variant="subtitle1"
                                                                // color="text.secondary"
                                                                className='card_reservation--precio'
                                                            >
                                                                {item.fecha_pago}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid mb={1} item display='flex' justifyContent='space-between'>
                                                            <Typography
                                                                gutterBottom
                                                                variant="subtitle1"
                                                            >
                                                                Precio total
                                                            </Typography>
                                                            <Typography
                                                                gutterBottom
                                                                variant="subtitle1"
                                                                className='card_reservation--precio'
                                                            >
                                                                {item.precio_total}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item display='flex' justifyContent='space-between'>
                                                           { item.status !=2 ? <Typography
                                                                gutterBottom
                                                                variant="subtitle1"
                                                            // color="text.secondary"
                                                            // className='card_reservation--precio'
                                                            >
                                                                Invitados ({item.invitados}) 
                                                                <b onClick={() => { handleClick(item); setInvitados2(item.listaInvitados); }}>
                                                                    Ver invitados</b>
                                                            </Typography>
                                                            :
                                                            <Typography
                                                            gutterBottom
                                                            variant="subtitle1"
                                                            className='card_reservation--precio'
                                                        >
                                                            RESERVA ANULADA
                                                        </Typography>
}
                                                        </Grid>
                                                        <Divider />

                                                        <Divider />
                                                        <Grid mt={1} spacing={1} item container display='flex' justifyContent='center'>
                                                            {item.status !=2 &&<Grid item xs={6}>
                                                                {/* <Button fullWidth variant='outlined' className='card_reservation--cancel'onClick={()=>anularReserva(item)} >Anular</Button> */}
                                                                <Button fullWidth variant='outlined' className='card_reservation--cancel' onClick={() => showModalDeleteReservation(item)} >Anular</Button>
                                                            </Grid>}
                                                            {item.status == 1 && <>
                                                                <Grid item xs={6} >
                                                                    <Button fullWidth variant='outlined' className='card_reservation--change' onClick={() => { console.log(item); showModalCambiar(item); setInvitados(item.listaInvitados); }}>Cambiar</Button>
                                                                </Grid>
                                                            </>
                                                            }
                                                        </Grid>
                                                    </div>
                                                </div>
                                                {
                                                    invitados2 == item.listaInvitados && 
                                                    <div>
                                                    <Grid item mt={2} mb={2}>
                                                        <Typography className='card_reservation--club' gutterBottom variant="h5" component="div">
                                                            <EventAvailableIcon className='card_reservation--club--icon' />Lista de invitados
                                                        </Typography>
                                                        {invitados2.map((value) => (
                                                            <Typography variant="subtitle1" className='card_reservation--precio'>
                                                                <b>{`${value.name?value.name: value.name_invite } ${value.surname? value.surname: value.surname_invite} `}</b>
                                                            </Typography>
                                                        ))}

                                                    </Grid>

                                                    <Grid mt={3} item container display='flex' alignContent='center' justifyContent='space-between'>

                                                        {/* <Button variant='outlined' className='card_reservation--add' onClick={(e) => handleClick(e)} >Regresar</Button> */}
                                                        <Typography variant='body1' onClick={() => setInvitados2([])}><ArrowBackIosIcon sx={{ marginBottom: '-5px' }} fontSize="small" className='icon_back' /><b>Regresar</b></Typography>


                                                    </Grid>
                                                </div>
                                                }
                                               
                                            </ReactCardFlip>




                                        </CardContent>
                                    </Grid>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Backdrop
                invisible
                sx={{ bgcolor: 'primary', color: '#f00', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
                onClick={handleCloseBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            </Grid>
           
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showInvites}
                onClose={() => setShowInvites(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={showInvites}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                Seleccionar usuario a cambiar
                            </Typography>
                            <>
                                <List sx={{
                                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                                    maxHeight: 200,

                                    overflow: 'auto',
                                }}>
                                    {invitados.map((value,i) => (
                                        <ListItem
                                            key={i}
                                            disableGutters
                                            secondaryAction={
                                                <Button className={'btn-invitar-now'}
                                                    onClick={() => {
                                                        console.log("Seleccionar usuario a cambiar: ", selectReservation.id)
                                                        console.log("Seleccionar usuario a cambiar2: ", value)
                                                 
                                                        //showInvitesForUser(id_reserva, id_detalle_inivitacion, id_invitado, id_usuario)
                                                        showInvitesForUser(selectReservation.id, value?.id, value.idinvite, value.iduser);
                                                        setNameUserChange(`${value.name? value.name: value.name_invite} ${value.surname ? value.surname: value.surname_invite} `)
                                                    }}
                                                >{'CAMBIAR'}</Button>
                                            }
                                        >
                                            <ListItemText primary={`${value.name? value.name: value.name_invite} ${value.surname ? value.surname: value.surname_invite} `} />
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                            <Grid mt={1} spacing={1} item container display='flex' justifyContent='center'>
                                <Grid item xs={6}>
                                    <Button onClick={() => setShowInvites(false)} fullWidth variant='outlined' className='card_reservation--cancel'>Cancelar</Button>
                                </Grid>
                            </Grid>

                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showInvitesChange}
                onClose={() => setShowInvitesChange(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={showInvitesChange}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                Usuarios disponibles para cambiar a {nameUserChange}
                            </Typography>
                            <>

                                <TextField
                                    name='search'
                                    type='search'
                                    placeholder="Buscador por Nombre"
                                    value={termBusqueda}
                                    onChange={handleBuscador}
                                    margin="normal"
                                    required
                                    fullWidth
                                    size='small'
                                />

                                <List sx={{
                                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                                    maxHeight: 200,

                                    overflow: 'auto',
                                }}>
                                    {possibleUser.map((value) => (
                                        <ListItem
                                            key={value.id}
                                            disableGutters
                                            secondaryAction={
                                                <Button className={'btn-invitar-now'}
                                                    onClick={() => {
                                                        changeUserResarvation(posibleUserSelected.id_detalle_invitacion, value.id)
                                                    }}
                                                >{'SELECCIONAR'}</Button>
                                            }
                                        >
                                            <ListItemText primary={`${value.name} ${value.surname} `} />
                                        </ListItem>
                                    ))}
                                </List>

                            </>
                            <Grid mt={1} spacing={1} item container display='flex' justifyContent='center'>
                                {/* <Grid item xs={6} >
                                    <Button fullWidth variant='outlined' className='card_reservation--change' onClick={() => setShowInvitesChange(false)}>Cambiar</Button>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <Button onClick={() => setShowInvitesChange(false)} fullWidth variant='outlined' className='card_reservation--cancel'>Cancelar</Button>
                                </Grid>
                            </Grid>

                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showDeleteReservation}
                onClose={() => setShowDeleteReservation(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={showDeleteReservation}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                ¿Está seguro que quiere anular esta reserva?
                            </Typography>
                            {/* <>
                                <List sx={{
                                    width: '100%', bgcolor: 'background.paper', position: 'relative', maxHeight: 250,
                                    maxHeight: 200,

                                    overflow: 'auto',
                                }}>
                                    {invitados.map((value) => (
                                        <ListItem
                                            key={value.id}                                
                                            disableGutters
                                            secondaryAction={
                                                <Button className={'btn-invitar-now'}
                                                    onClick={() => {
                                                        showInvitesForUser(selectReservation.id,value.id);
                                                        setNameUserChange(`${value.name} ${value.surname} `)
                                                    }}
                                                >{'CAMBIAR'}</Button>
                                            }
                                        >
                                            <ListItemText primary={`${value.name} ${value.surname} `} />
                                        </ListItem>
                                    ))}
                                </List>
                            </> */}
                            <Grid mt={1} spacing={1} item container display='flex' justifyContent='center'>
                                <Grid item xs={6} >
                                    {/* <Button fullWidth variant='outlined' className='card_reservation--cancel'onClick={()=>anularReserva(item)} >Anular</Button> */}
                                    <Button fullWidth variant='outlined' className='card_reservation--cancel' onClick={() => anularReserva(dataDelete)}>Anular</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button onClick={() => setShowDeleteReservation(false)} fullWidth variant='outlined' className='card_reservation--change'>Cancelar</Button>
                                </Grid>
                            </Grid>

                        </form>
                    </div>
                </Fade>
            </Modal>
            <Snackbar
                        open={snackBarConfig.open}
                        autoHideDuration={snackBarConfig.autoHideDuration}
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                     >
                        <Alert
                           onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                           severity={snackBarConfig.severity}
                           variant="filled"
                        >
                           {snackBarConfig.message}
                        </Alert>
                     </Snackbar>
        </Grid>
    )
}

const itemReserva = [
    {
        reservador: 'Personaje 1',
        club: 'Club "El Maracaná"',
        deporte: 'Tenis 1',
        cancha: 'Cancha 1',
        dia: 'Jueves, 25 agost',
        hora: '09:00 - 10:00',
        precio_total: '$52',
        fecha_pago: '27/ 07/ 2022',
        invitados: '9',
        listaInvitados: ['Luis', 'Perez', 'Juan', 'Rouse']
    },
    {
        reservador: 'Personaje 2',
        club: 'Club La pichanga es primero',
        deporte: 'Tenis 2',
        cancha: 'Cancha 2',
        dia: 'Viernes, 26 agost',
        hora: '09:00 - 10:00',
        precio_total: '$202',
        fecha_pago: '24/ 07/ 2022',
        invitados: '10'

    },
    {
        reservador: 'Personaje 3',
        club: 'Club La pichanga es segundo',
        deporte: 'Tenis 2',
        cancha: 'Cancha 2',
        dia: 'Viernes, 26 agost',
        hora: '09:00 - 10:00',
        precio_total: '$102',
        fecha_pago: '23/ 07/ 2022',
        invitados: '12'

    },
    {
        reservador: 'Personaje 4',
        club: 'Club La pichanga es tercero',
        deporte: 'Tenis 2',
        cancha: 'Cancha 2',
        dia: 'Viernes, 26 agost',
        hora: '09:00 - 10:00',
        precio_total: '$72',
        fecha_pago: '22/ 07/ 2022',
        invitados: '3'

    }

];



function encontrarFechas(reservas) {
    console.log(reservas)
    if (!reservas || reservas.length === 0) {
        return [null, null];
    }

    let fechaMinima = convertirAFechaMilisegundos(reservas[0].start);
    let fechaMaxima = convertirAFechaMilisegundos(reservas[0].end);

    reservas.forEach((reserva) => {
        if (validarFechas(reserva)) {
            const fechaInicio = convertirAFechaMilisegundos(reserva.start);
            const fechaFin = convertirAFechaMilisegundos(reserva.end);

            if (fechaInicio < fechaMinima) {
                fechaMinima = fechaInicio;
            }

            if (fechaFin > fechaMaxima) {
                fechaMaxima = fechaFin;
            }
        }
    });

    return [new Date(fechaMinima), new Date(fechaMaxima)];
}

function validarFechas(fechas) {
    const fechaInicio = convertirAFechaMilisegundos(fechas.start);
    const fechaFin = convertirAFechaMilisegundos(fechas.end);

    if (isNaN(fechaInicio) || isNaN(fechaFin)) {
        return false; // Si alguna fecha no es válida, la validación falla
    }

    if (fechaInicio >= fechaFin) {
        return false; // Si la fecha de inicio no es anterior a la fecha de fin, la validación falla
    }

    return true; // Si todas las validaciones pasan, las fechas son válidas
}

function convertirAFechaMilisegundos(fechaString) {
    const [hora, minuto, segundo] = fechaString.split(":");
    const fecha = new Date();
    fecha.setHours(parseInt(hora, 10));
    fecha.setMinutes(parseInt(minuto, 10));
    fecha.setSeconds(parseInt(segundo, 10));
    return fecha.getTime();
}