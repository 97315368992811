import { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Alert, Button, CardActionArea, Snackbar } from '@mui/material';
import { Grid } from '@mui/material';
import "./Reservation.css";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Invitados from '../../Components/Modal/Invitados';
import ReactCardFlip from 'react-card-flip';
import axios from "axios";
import MuiAlert from '@mui/material/Alert';
import moment from "moment";

export const PrevReservation = (props) => {
    console.log(props)
    const listaInvitados = props.listaInvitados;
    const [snackBarConfig, setSnackBarConfig] = useState({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })
    const arrayObj = props?.horario.filter((item) => props?.idsHorary.includes(item.id))
    //  let arrayObj = props?.idsHorary.map(id => props?.horario.find(objeto => objeto.id === id)).filter(Boolean);
    const startMoment = moment(arrayObj[0].start, 'HH:mm:ss');
    const endMoment = moment(arrayObj[0].end, 'HH:mm:ss');
    var Token = localStorage.getItem('token_user')
    const diffInMinutes = endMoment.diff(startMoment, 'minutes');
    console.log('arrayObj', arrayObj)
    console.log('props?.idsHorary', props?.idsHorary)
    const [fechaMinima, fechaMaxima] = encontrarFechas(arrayObj);
    const horaMin = fechaMinima?.getHours().toString().padStart(2, '0');
    const minutosMin = fechaMinima?.getMinutes().toString().padStart(2, '0');
    const segundosMin = fechaMinima?.getSeconds().toString().padStart(2, '0');

    const horaMinima = `${horaMin}:${minutosMin}:${segundosMin}`;

    const horaMax = fechaMaxima?.getHours().toString().padStart(2, '0');
    const minutosMax = fechaMaxima?.getMinutes().toString().padStart(2, '0');
    const segundosMax = fechaMaxima?.getSeconds().toString().padStart(2, '0'); // Rellenar con cero a la izquierda si el día es de un solo dígito
    const horaMaxima = `${horaMax}:${minutosMax}:${segundosMax}`;

    const [reserva, setReserva] = useState({
        "field": arrayObj[0].field,
        'start': horaMinima,
        'end': horaMaxima,
        "day": props.day,
        "amount": props.amount
    })

    const ReportReservation = async (data) => {
        const res = await
            axios.post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/config/report`,
                data,
                // method: "post",
                // url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation`,
                {
                    headers: {
                        Authorization: `Bearer ${Token}`,
                    },
                })
        return res;
    }

    const confirmReservation = async (value) => {
        let state = {
            'date': props.day,
            'idtype_reservation': props.data.idtype_reservation,
            "idhorary": props?.idsReserveHorary,
            "a_iduser": props.data.a_iduser,
            "a_idinvite": props.data.a_idinvite.length === 0 ? [] : props.data.a_idinvite,
            "idTypeField": props.data.idTypeField,
            "data_props": props.data.from,
            "idField": props.data.idField
        }
       
        const resp_reservation = await props.handleReservation(state);
        if (resp_reservation?.data?.data == 'Some horarys selected is occupied') {
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'warning',
                message: "El horario seleccionado está ocupado",
            }));
            return
        }
        if (resp_reservation) {
            if(value)
            {
                let stateReport = {
                    'start': reserva?.start,
                    'end': reserva?.end,
                    "idfield": arrayObj[0].idfield,
                    "idday": props?.idDay,
                    "duration_horary": value,
                    "idreservation" : resp_reservation?.data?.data?.id
                }

                ReportReservation(stateReport)
            }
            state.resp_reservation = resp_reservation;
            state.reserva = reserva;
            state.listaInvitados = listaInvitados
            

            props.changeView(props.amount, JSON.stringify(state));
        }

    }

    return (
        <Grid item xs={12} md={4}>
            <Typography></Typography>
            <Card  >
                    <Grid item container justifyContent="center">
                        <CardContent>
                            <ReactCardFlip  flipDirection="vertical">
                                <div>
                                    <div>
                                        <Grid item mt={2} mb={2}>
                                            <Typography className='card_reservation--club' gutterBottom variant="h5" component="div">
                                                <EventAvailableIcon className='card_reservation--club--icon' />Para Tenis, en {arrayObj[0]?.field}
                                            </Typography>
                                            {/* <Typography className='card_reservation--info' gutterBottom variant="subtitle1" component="div">
                                                Reservado por: <b>{reserva.reservador}</b>
                                            </Typography> */}
                                            <Typography variant="subtitle1" className='card_reservation--precio'>
                                                <b>{props?.day + ', ' + horaMinima + ' - ' + horaMaxima}</b>
                                            </Typography>
                                        </Grid>
                                        <Divider />
                                        {
                                            listaInvitados.length > 0 &&
                                            <Grid mt={1} item display='flex' justifyContent='space-between'>
                                                <Typography className='card_reservation--info' gutterBottom variant="subtitle1" component="div">
                                                    <b>Invitados:</b>
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="subtitle1"
                                                    // color="text.secondary"
                                                    className='card_reservation--precio'
                                                >
                                                    {/* {reserva.fecha_pago} */}
                                                </Typography>
                                            </Grid>

                                        }


                                        {listaInvitados.map(item => (
                                            <Grid mb={1} item display='flex' justifyContent='space-between'>

                                                <Typography
                                                    gutterBottom
                                                    variant="subtitle1"
                                                >
                                                    {item.name + " " + item.surname}
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="subtitle1"
                                                    className='card_reservation--precio'
                                                >
                                                    {item.type_user}
                                                </Typography>
                                            </Grid>
                                        ))
                                        }

                                        <Grid item display='flex' justifyContent='space-between'>
                                            <Typography
                                                gutterBottom
                                                variant="subtitle1"
                                            // color="text.secondary"
                                            // className='card_reservation--precio'
                                            >
                                                {/* Invitados (9) <b >Ver invitados</b> */}
                                            </Typography>

                                        </Grid>
                                        <Divider />

                                        <Grid mb={1} mt={2} item display='flex' justifyContent='space-between'>
                                            <Typography
                                                gutterBottom
                                                variant="subtitle1"
                                            >
                                                Monto:
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                variant="subtitle1"
                                                className='card_reservation--precio'
                                            >
                                                $ {props?.amount}
                                            </Typography>
                                        </Grid>
                                        <Divider />
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems={'center'} sx={{ pt: 2 }}>

                                            {diffInMinutes && diffInMinutes == 60 &&
                                                <Grid item xs={12} md={8}>
                                                    <Button fullWidth variant='outlined' className='card_reservation--change'
                                                        onClick={() => { confirmReservation(null) }}
                                                    >60 min</Button>
                                                </Grid>
                                            }

                                            {diffInMinutes && diffInMinutes == 90 &&
                                                <Grid item xs={12} md={4}>
                                                    <Button fullWidth variant='outlined' className='card_reservation--change-add'
                                                        onClick={() => { confirmReservation(60) }}
                                                    > 60 min</Button>
                                                </Grid>
                                            }

                                            {diffInMinutes && diffInMinutes == 90 &&
                                                <Grid item xs={12} md={4}>
                                                    <Button  fullWidth variant='outlined' className='card_reservation--change'
                                                        onClick={() => { confirmReservation(90) }}
                                                    >90 min</Button>
                                                </Grid>
                                            }
                                            <Grid item xs={12} md={4} >
                                                <Button variant='outlined'
                                                    fullWidth
                                                    onClick={() => { props.setOpen(false) }}
                                                    className='card_reservation--cancel'>
                                                    Cancelar
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </div>

                                <div>
                                    <Grid item mt={2} mb={2}>
                                        <Typography className='card_reservation--club' gutterBottom variant="h5" component="div">
                                            <EventAvailableIcon className='card_reservation--club--icon' />Lista de invitados
                                        </Typography>
                                        <Typography variant="subtitle1" className='card_reservation--precio'>
                                            <b>Carlos Jordan Chinga</b>
                                        </Typography>
                                        <Typography variant="subtitle1" className='card_reservation--precio'>
                                            <b>Juan Alonso Chávez Changana</b>
                                        </Typography>
                                        <Typography variant="subtitle1" className='card_reservation--precio'>
                                            <b>Eloy Pérez Rick</b>
                                        </Typography>
                                        <Typography variant="subtitle1" className='card_reservation--precio'>
                                            <b>Navarrete Díaz</b>
                                        </Typography>

                                    </Grid>
                                    <Grid mt={3} item container display='flex' alignContent='center' justifyContent='space-between'>

                                        {/* <Button variant='outlined' className='card_reservation--add' onClick={(e) => handleClick(e)} >Regresar</Button> */}
                                        <Typography variant='body1'><ArrowBackIosIcon sx={{ marginBottom: '-5px' }} fontSize="small" className='icon_back' /><b>Regresar</b></Typography>


                                    </Grid>
                                </div>
                            </ReactCardFlip>




                        </CardContent>
                    </Grid>
            </Card>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
        </Grid>

    )
}



function encontrarFechas(reservas) {
    console.log(reservas)
    if (!reservas || reservas.length === 0) {
        return [null, null];
    }

    let fechaMinima = convertirAFechaMilisegundos(reservas[0].start);
    let fechaMaxima = convertirAFechaMilisegundos(reservas[0].end);

    reservas.forEach((reserva) => {
        if (reserva?.start && validarFechas(reserva)) {
            const fechaInicio = convertirAFechaMilisegundos(reserva.start);
            const fechaFin = convertirAFechaMilisegundos(reserva.end);

            if (fechaInicio < fechaMinima) {
                fechaMinima = fechaInicio;
            }

            if (fechaFin > fechaMaxima) {
                fechaMaxima = fechaFin;
            }
        }
    });

    return [new Date(fechaMinima), new Date(fechaMaxima)];
}

function validarFechas(fechas) {
    const fechaInicio = convertirAFechaMilisegundos(fechas.start);
    const fechaFin = convertirAFechaMilisegundos(fechas.end);

    if (isNaN(fechaInicio) || isNaN(fechaFin)) {
        return false; // Si alguna fecha no es válida, la validación falla
    }

    if (fechaInicio >= fechaFin) {
        return false; // Si la fecha de inicio no es anterior a la fecha de fin, la validación falla
    }

    return true; // Si todas las validaciones pasan, las fechas son válidas
}

function convertirAFechaMilisegundos(fechaString) {
    const [hora, minuto, segundo] = fechaString.split(":");
    const fecha = new Date();
    fecha.setHours(parseInt(hora, 10));
    fecha.setMinutes(parseInt(minuto, 10));
    fecha.setSeconds(parseInt(segundo, 10));
    return fecha.getTime();
}