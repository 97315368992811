import "./RegisterPayment.css"
import React, { useEffect, useCallback, useMemo } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Grid, TextField, Typography } from "@mui/material";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import "../../Pages/Reservation/Reservation.css"
// import { session } from "../../session/constants";
import { Divider } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AgregarInvitados from "../../Components/Modal/AgregarInvitados";
import ReactCardFlip from 'react-card-flip';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { ContentCutOutlined } from "@mui/icons-material";
// import AgregarInvitados from "./AgregarInvitados";
import { useHistory } from "react-router-dom";

const recently = [
  { nombre: "Andy Jhovany", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan Jhonas", apellido: "Cornejo Chinga", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy Jhovany", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
]

const parners = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
]

const invities = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
]

const families = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
]
export const RegisterPayment = (props) => {
  var data_user = JSON.parse(localStorage.getItem("data_user"))
  let data_props = JSON.parse(props.location.state);
  const listaInvitados = data_props.listaInvitados
  const [openModal, setOpenModal] = React.useState(false);
  const [isFlipped, setIsFlipped] = React.useState(false)
  const [openInvitados, setOpenInvitados] = React.useState(false)
  const [parner, setParner] = React.useState(false)
  const [family, setFamily] = React.useState(false)
  const [invitados, setInvitados] = React.useState(false)
  const [resenties, setRecenties] = React.useState(true)
  let history = useHistory();
  const [reserva, setReserva] = React.useState({
    "deporte": 'Tenis',
    "cancha": data_props?.res?.data?.data?.status == "0" ? data_props?.dataReserva?.reserva?.field : data_props?.reserva?.field,
    "reservador": data_props?.res?.data?.data?.status == "0" ? data_props?.res?.data?.data?.name + ' ' + data_props?.res?.data?.data?.surname : 'Luis Fernando Ramirez',
    "fecha_pago": data_props?.res?.data?.data?.status == "0" ? data_props?.res?.data?.timestamp : '',
    "dia": data_props?.res?.data?.data?.status == "0" ? data_props?.dataReserva?.reserva?.day : data_props?.reserva?.day,
    "hora": data_props?.res?.data?.data?.status == "0" ? data_props?.dataReserva?.reserva?.start : data_props?.reserva?.start,
    "horaFin": data_props?.res?.data?.data?.status == "0" ? data_props?.dataReserva?.reserva?.end : data_props?.reserva?.end,
    "total": data_props?.res?.data?.data?.status == "0" ? data_props?.dataReserva?.reserva?.amount : data_props?.reserva?.amount,
    "status": data_props?.res?.data?.data?.status == "0" ? data_props?.res?.data?.data?.status : "1",
    "frase": data_props?.res?.data?.data?.status == "0" ? "PRONTO SE VALIDARÁ SU OPERACIÓN TRANSFER, ESTAMOS EN CONTACTO" : "¡ FELICIDADES, SU RESERVA HA SIDO REGISTRADA CON EXITO !"
  })

  const changeViewBack = (view) => {
    switch (view) {
      case 'horario':
        history.push('/modulos', { from: 'horario' })
        break;

      default:
        history.push('/modulos', { from: 'cancha' });
        break;
    }
  }
  const handleClick = (e) => {
    e.preventDefault();
    setIsFlipped(!isFlipped);
   }

  const handleOpenModal = () => {
    setOpenModal(true);
  }
  const mensaje2 = () => {
    console.log('MENSAJE 2');
  }

  const handleSocios = () => {
    setParner(true);
    setRecenties(false);
    setInvitados(false);
    setFamily(false);
  };
  const handleFamilies = () => {
    setParner(false);
    setRecenties(false);
    setInvitados(false);
    setFamily(true);
  };
  const handleInvities = () => {
    setParner(false);
    setRecenties(false);
    setInvitados(true);
    setFamily(false);
  };

  const handleShowInvitados = () => {
    setOpenInvitados(true)
  }
  const handleCloseInvitados = () => {
    setOpenInvitados(false)
  }

  const handleClose = () => {
    setParner(false);
    setRecenties(true);
    setInvitados(false);
    setFamily(false);
    // handleCloseModal();
  }

  return (
    <Grid sx={{bgcolor:'#f4f4f4'}}>
      <Grid container item xs={10} mb={1} pt={3} pl={1} justifyContent="center">
        <Grid xs={10} mb={2} onClick={() => changeViewBack(data_props)}>
          {/* <Link to={'/modulos'} className='link__css'> */}
          <Typography variant='h6' gutterBottom className='link__css-back'>
            <ArrowBackIosIcon className='icon_back' /> Volver al menú principal
          </Typography>
          {/* </Link> */}
          {/* <Typography variant={"h5"} className="title__main" gutterBottom> AGREGA A TUS INVITADOS</Typography> */}
        </Grid>
      </Grid>

      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={7} mb={3} className="reserca-exito">
          <Grid container>
            <Grid xs={12} mt={1} sx={{p:2}}>
              <Typography
                textAlign="left"
                variant="subtitle1"
                id="transition-modal-title"
                className="text-modal-title"
              >
                {reserva.frase}
              </Typography>
            </Grid>
            {/* <Grid xs={2}>
              <Link to={'/mis-reservas'} className='link__css'>
                <Button variant='outlined' fullWidth className='card-btn-ver-success' style={{textTransform:'none', fontSize:'30px'}}>Ver reserva</Button>
              </Link>
            </Grid> */}
          </Grid>
        </Grid>

      </Grid>
      <Grid container xs={12} spacing={2} justifyContent={"center"} >
        <Grid item xs={12} md={4}>
          <Card className='card_reservation' sx={{m:2}} >
              <Grid item container justifyContent="center">
                <CardContent>
                  <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
                      <div>
                        <Grid item mt={2} mb={2}>
                          <Typography className='card_reservation--club' gutterBottom variant="h5" component="div">
                            <EventAvailableIcon className='card_reservation--club--icon' />Para {reserva.deporte}, en {reserva.cancha}
                          </Typography>
                          <Typography className='card_reservation--info' gutterBottom variant="subtitle1" component="div">
                            Reservado por: <b>{data_user.name} {data_user.surname}</b>
                          </Typography>
                          <Typography variant="subtitle1" className='card_reservation--precio'>
                            <b>{reserva?.dia + ', ' + reserva?.hora + ' - ' + reserva?.horaFin}</b>
                          </Typography>
                        </Grid>
                        <Divider />

                        {/* <Grid mt={1} item display='flex' justifyContent='space-between'>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          // color="text.secondary"
                          // className='card_reservation--precio'
                          >
                            Fecha de pago
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                            // color="text.secondary"
                            className='card_reservation--precio'
                          >
                            {reserva.fecha_pago}
                          </Typography>
                        </Grid> */}
                        <Divider/>
                        

                          {
                            listaInvitados.length > 0 &&
                            <Grid mt={1} item display='flex' justifyContent='space-between'>
                              <Typography className='card_reservation--info' gutterBottom variant="subtitle1" component="div">
                                <b>Invitados:</b>
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="subtitle1"
                                // color="text.secondary"
                                className='card_reservation--precio'
                              >
                                {/* {reserva.fecha_pago} */}
                              </Typography>
                            </Grid>

                          }


                          {listaInvitados.map(item => (
                            <Grid mb={1} item display='flex' justifyContent='space-between'>

                              <Typography
                                gutterBottom
                                variant="subtitle1"
                              >
                                {item.name + " " + item.surname}
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="subtitle1"
                                className='card_reservation--precio'
                              >
                                {item.type_user}
                              </Typography>
                            </Grid>
                          ))
                          }

                          <Divider/>
                        <Grid mb={1} item display='flex' justifyContent='space-between'>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          >
                            Precio total
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                            className='card_reservation--precio'
                          >
                            $ {reserva.total}
                          </Typography>
                        </Grid>
                        <Grid item display='flex' justifyContent='space-between'>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                          // color="text.secondary"
                          // className='card_reservation--precio'
                          >
                            {/* Invitados (9) <b onClick={(e) => handleClick(e)}>Ver invitados</b> */}
                          </Typography>

                        </Grid>
                        <Divider />

                        <Divider />
                        {/* <Grid mt={3} item container display='flex' justifyContent='space-between'>
                                                            <Grid>
                                                                <Button variant='outlined' className='card_reservation--add' onClick={handleOpenModal} >Invitar</Button>
                                                            </Grid>
                                                            <Grid>
                                                                <Button variant='outlined' className='card_reservation--change' onClick={()=>{mensaje2()}}>Cambiar</Button>
                                                            </Grid>
                                                            <Grid>
                                                                <Button variant='outlined' className='card_reservation--cancel'>Cancelar</Button>
                                                            </Grid>
                                                        </Grid> */}
                      </div>
                    <div>
                      <Grid item mt={2} mb={2}>
                        <Typography className='card_reservation--club' gutterBottom variant="h5" component="div">
                          <EventAvailableIcon className='card_reservation--club--icon' />Lista de invitados
                        </Typography>
                        <Typography variant="subtitle1" className='card_reservation--precio'>
                          <b>Carlos Jordan Chinga</b>
                        </Typography>
                        <Typography variant="subtitle1" className='card_reservation--precio'>
                          <b>Juan Alonso Chávez Changana</b>
                        </Typography>
                        <Typography variant="subtitle1" className='card_reservation--precio'>
                          <b>Eloy Pérez Rick</b>
                        </Typography>
                        <Typography variant="subtitle1" className='card_reservation--precio'>
                          <b>Navarrete Díaz</b>
                        </Typography>

                      </Grid>
                      <Grid mt={3} item container display='flex' alignContent='center' justifyContent='space-between'>

                        {/* <Button variant='outlined' className='card_reservation--add' onClick={(e) => handleClick(e)} >Regresar</Button> */}
                        <Typography variant='body1' onClick={(e) => handleClick(e)}><ArrowBackIosIcon sx={{ marginBottom: '-5px' }} fontSize="small" className='icon_back' /><b>Regresar</b></Typography>


                      </Grid>
                    </div>
                  </ReactCardFlip>




                </CardContent>
              </Grid>
          </Card>
        </Grid>
      </Grid>

    </Grid>
  )
}