import React, { useEffect } from "react";
import "./ScheduleConfiguration.css"
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, Typography, IconButton, Box, TableHead, InputLabel, MenuItem, FormControl, Select, TextField, Button } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LastPageIcon from '@mui/icons-material/LastPage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import axios from "../../../utils/axios";
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 350,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}


export const ScheduleConfiguration = () => {
    const [day, setDay] = React.useState('');
    const [turn, setTurn] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [modalEliminar, setModalEliminar] = React.useState(false);
    const [dia, setDia] = React.useState([]);
    const [cancha, setCancha] = React.useState([]);
    const [horarioDia, setHorarioDia] = React.useState([]);
    const [horarioTarde, setHorarioTarde] = React.useState([]);
    const [horarioNoche, setHorarioNoche] = React.useState([]);
    const [seletedHorario, setSelectedHorario] = React.useState({
        start: "",
        end: "",
        duration: '',
        maintenance: '',
        idday: '',
        idfield: '',
        idcompetitor: 1,
        idCompany: 1
    })
    const [hourDaySelect, setHourDaySelect] = React.useState({
        id: '',
        day: '',
        turno: '',
        dateBegin: "",
        dateLast: "",
        cancha: '',
        duracionAlquiler: 0,
        duracionMantenimiento: 0
    })
    const [token, setToken] = React.useState(
        sessionStorage.getItem("Token")
    );
    const [openError, setOpenError] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [comment, setComments] = React.useState({
        text:'',
        saverity: 'success'
    })
    const [error, setError] = React.useState(null);
    const [messageSuccess, setMessageSuccess] = React.useState("");

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };
    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };

    const getListaHorariosDia = async (cancha, dia) => {
        axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/list?idField=${cancha}&idDay=${dia}&idTurn=1`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setHorarioDia(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getListaHorariosTarde = async (cancha, dia) => {
        axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/list?idField=${cancha}&idDay=${dia}&idTurn=2`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setHorarioTarde(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getListaHorariosNoche = async (cancha, dia) => {
        axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/list?idField=${cancha}&idDay=${dia}&idTurn=3`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setHorarioNoche(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getListaDia = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/day", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setDia(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getListaCancha = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/list", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setCancha(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const eliminar = () => {

    }

    function handleChangeHours(e) {
        const changedFormValues = {
            ...seletedHorario,
            [e.target.name]: e.target.value,
        }
        changedFormValues.idday.trim() != "" && setError("");
        changedFormValues.idfield.trim() != "" && setError("");
        changedFormValues.start.trim() != "" && setError("");
        changedFormValues.end.trim() != "" && setError("");
        changedFormValues.duration.trim() != "" && setError("");
        changedFormValues.maintenance.trim() != "" && setError("");
        setSelectedHorario(changedFormValues);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (!seletedHorario.idday.trim()) {
            return setError("idday");
        }
        if (!seletedHorario.idfield.trim()) {
            return setError("idfield");
        }
        if (!seletedHorario.start.trim()) {
            return setError("start");
        }
        if (!seletedHorario.end.trim()) {
            return setError("end");
        }
        if (!seletedHorario.duration.trim()) {
            return setError("duration");
        }
        if (!seletedHorario.maintenance.trim()) {
            return setError("maintenace");
        }
        const id_field =  cancha.filter(item => item.name == seletedHorario?.idfield);
        const id_day =  dia.filter(item => item.name == seletedHorario?.idday);
        console.log('id_field', id_field)
        console.log('id_day', id_day)
        const formFile = new FormData();
        formFile.append('start', seletedHorario.start)
        formFile.append('end', seletedHorario.end)
        formFile.append('duration', seletedHorario.duration)
        formFile.append('maintenance', seletedHorario.maintenance)
        formFile.append('idday', id_day[0]?.id)
        formFile.append('idfield', id_field[0]?.id)
        formFile.append('idcompetitor', seletedHorario.idcompetitor)
        formFile.append('idCompany', seletedHorario.idCompany)
        // formFile.forEach((value, key) => {
        //     console.log(`${key}: ${value}`);
        //   });
        try {
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/register`,
                    formFile,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                )
                .then((response) => {
                    if(response.data?.message)
                    {
                        if(response?.data?.status == 200)
                        {
                        setComments(prev =>({...prev, text: response.data?.message, saverity:'success'}))
                        }else{
                            setComments(prev =>({...prev, text: response.data?.message, saverity:'error'}))
                        }
                    }
                    // setSelectedHorario(...response.data)
                 })
                .catch((error) => {
                    console.log(error)
                })
            limpiaDatos();
            setMessageSuccess("Correo enviado para el autologin!")
            setOpenSuccess(true);

        } catch (error) {
            console.log('error')
        }
        const idday = id_day[0]?.id;
        const idfield = id_field[0]?.id;
        getListaHorariosDia(idfield, idday);
        getListaHorariosTarde(idfield, idday);
        getListaHorariosNoche(idfield, idday);
    };

    const limpiaDatos = () => {
        setSelectedHorario((userValues) => ({
            ...userValues,
            start: "",
            end: "",
            duration: '',
            maintenance: '',
            idday: '',
            idfield: '',
            idcompetitor: 1,
            idCompany: 1
        }));
    };


    useEffect(() => {
        getListaDia([...dia]);
        getListaCancha([...cancha])
    }, [])
    const classes = useStyles();

    return (
        <Grid sx={{bgcolor:'#f4f4f4'}}>
            <Grid container className="containerInvite">
                <Grid container justifyContent={"center"} mt={2}>
                    <Grid item xs={12} md={10}>
                        <Grid xs={12} mb={2}>
                            <Link to={'/modulos-administrador'} className='link__css'>
                                <Typography variant='h6' gutterBottom className='link__css-back'>
                                    <ArrowBackIosIcon className='icon_back' /> Volver
                                </Typography>
                            </Link>
                            <Grid item xs={12} md={12}>
                                <Typography variant={"h5"} className="title__main">CONGIFURACION DE HORARIOS</Typography>
                            </Grid>

                        </Grid>
                        <Grid container spacing={1} p={2} mt={2} style={{ background: "rgb(255,255,255,0.7)", borderRadius: "20px" }} className="containerModulos">

                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <InputLabel size="small" id="demo-simple-select-label">
                                                    Seleccione el dia
                                                </InputLabel>
                                                <Select
                                                    error={error === "idday" ? true : false}
                                                    helperText={error === "idday" ? "El dia es un campo requerido" : ""}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    placeholder="Seleccione el dia"
                                                    label="Seleccione el dia"
                                                    onChange={handleChangeHours}
                                                    value={seletedHorario.idday}
                                                    size="small"
                                                    name="idday"
                                                >
                                                    {dia.map((item) => (
                                                        <MenuItem
                                                            sx={{
                                                                display: "block !important",
                                                                padding: "5px 10px !important",
                                                            }}
                                                            key={item.id}
                                                            value={item.name}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <InputLabel size="small" id="demo-simple-select-label">
                                                    Seleccione una cancha
                                                </InputLabel>
                                                <Select
                                                    error={error === "idfield" ? true : false}
                                                    helperText={error === "idfield" ? "La cancha es un campo requerido" : ""}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    placeholder="Seleccione una cancha"
                                                    label="Seleccione una cancha"
                                                    onChange={handleChangeHours}
                                                    value={seletedHorario.idfield}
                                                    size="small"
                                                    name="idfield"
                                                >
                                                    {cancha.map((item) => (
                                                        <MenuItem
                                                            sx={{
                                                                display: "block !important",
                                                                padding: "5px 10px !important",
                                                            }}
                                                            key={item.id}
                                                            value={item.name}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Button onClick={handleSubmit} fullWidth className="btn-acepted-invitate">Agregar</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid mt={1} container spacing={1}>
                                    <Grid item xs={12} md={3}>
                                        <Typography gutterBottom variant="subtitle1">Hora Inicio</Typography>
                                        <TextField
                                            error={error === "start" ? true : false}
                                            helperText={error === "start" ? "La hora de inicio es un campo requerido" : ""}
                                            fullWidth
                                            type="time"
                                            size="small"
                                            name="start"
                                            value={seletedHorario.start}
                                            onChange={handleChangeHours}
                                            variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography gutterBottom variant="subtitle1">Hora Fin</Typography>
                                        <TextField
                                            error={error === "end" ? true : false}
                                            helperText={error === "end" ? "La hora de fin es un campo requerido" : ""}
                                            fullWidth
                                            type="time"
                                            size="small"
                                            variant="outlined"
                                            name="end"
                                            value={seletedHorario.end}
                                            onChange={handleChangeHours}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography gutterBottom variant="subtitle1">Duración Alquiler</Typography>
                                        <TextField
                                            error={error === "duration" ? true : false}
                                            helperText={error === "duration" ? "La duración alquiler es un campo requerido" : ""}
                                            type={"number"}
                                            fullWidth
                                            size="small"
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">m</InputAdornment>,
                                            }}
                                            name="duration"
                                            value={seletedHorario.duration}
                                            onChange={handleChangeHours}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography gutterBottom variant="subtitle1">Duración Mantenimiento</Typography>
                                        <TextField
                                            error={error === "maintenance" ? true : false}
                                            helperText={error === "maintenance" ? "La duración mantenimiento es un campo requerido" : ""}
                                            type={"number"}
                                            fullWidth
                                            size="small"
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">m</InputAdornment>,
                                            }}
                                            name="maintenance"
                                            value={seletedHorario.maintenance}
                                            onChange={handleChangeHours}
                                        />
                                    </Grid>
                                    {/* <Grid container justifyContent={"right"} mt={1} spacing={2}>
                                            
                                        </Grid> */}
                                </Grid>

                            </Grid>


                            <Grid container mt={2} spacing={1} >
                                <Grid item xs={12} md={12} mt={1}>
                                    <Typography variant={"h6"} textAlign="center" color="#191D3D">{"HORARIO " + day}</Typography>
                                </Grid>

                                <Grid item xs={12} md={4} mt={3}>
                                    <TableContainer component={Paper} >
                                        <Grid container justifyContent={"center"}>
                                            <Typography textAlign={"center"} variant="subtitle2">MAÑANA</Typography>
                                        </Grid>
                                        <Table sx={{ minWidth: 300 }} aria-label="custom pagination table">
                                            <TableHead >
                                                <TableRow style={{ background: "#191D3D" }}>
                                                    <TableCell style={{ textAlign: "center", padding: "5px", color: "#fff" }}>Hora Inicio</TableCell>
                                                    <TableCell style={{ textAlign: "center", padding: "5px", color: "#fff" }}>Hora Fin</TableCell>
                                                </TableRow>

                                            </TableHead>
                                            <TableBody>
                                                {horarioDia?.length>0 && horarioDia.map((row) => (
                                                    <TableRow key={row.id}>

                                                        <TableCell  >
                                                            {row.start}
                                                        </TableCell>

                                                        <TableCell >
                                                            {row.end}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} md={4} mt={3}>
                                    <TableContainer component={Paper} >
                                        <Grid container justifyContent={"center"}>
                                            <Typography textAlign={"center"} variant="subtitle2">TARDE</Typography>
                                        </Grid>
                                        <Table sx={{ minWidth: 300 }} aria-label="custom pagination table">
                                            <TableHead >
                                                <TableRow style={{ background: "#191D3D" }}>
                                                    <TableCell style={{ textAlign: "center", padding: "5px", color: "#fff" }}>Hora Inicio</TableCell>
                                                    <TableCell style={{ textAlign: "center", padding: "5px", color: "#fff" }}>Hora Fin</TableCell>
                                                </TableRow>

                                            </TableHead>
                                            <TableBody>
                                                {horarioTarde?.length>0 && horarioTarde.map((row) => (
                                                    <TableRow key={row.id}>

                                                        <TableCell  >
                                                            {row.start}
                                                        </TableCell>

                                                        <TableCell >
                                                            {row.end}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} md={4} mt={3}>
                                    <TableContainer component={Paper} >
                                        <Grid container justifyContent={"center"}>
                                            <Typography textAlign={"center"} variant="subtitle2">NOCHE</Typography>
                                        </Grid>
                                        <Table sx={{ minWidth: 300 }} aria-label="custom pagination table">
                                            <TableHead >
                                                <TableRow style={{ background: "#191D3D" }}>
                                                    <TableCell style={{ textAlign: "center", padding: "5px", color: "#fff" }}>Hora Inicio</TableCell>
                                                    <TableCell style={{ textAlign: "center", padding: "5px", color: "#fff" }}>Hora Fin</TableCell>
                                                </TableRow>

                                            </TableHead>
                                            <TableBody>
                                                {horarioNoche?.length>0 && horarioNoche.map((row) => (
                                                    <TableRow key={row.id}>

                                                        <TableCell  >
                                                            {row.start}
                                                        </TableCell>

                                                        <TableCell >
                                                            {row.end}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEliminar}
                onClose={() => setModalEliminar(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEliminar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                Estás Seguro que deseas eliminar el el horario {hourDaySelect && hourDaySelect.dateBegin + ' -- ' + hourDaySelect.dateLast}
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEliminar(false)}>NO</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => eliminar()}>Si</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
                    <Alert onClose={handleCloseSuccess} severity={comment.saverity} sx={{ width: '100%' }}>
                       {comment?.text}
                    </Alert>
                </Snackbar>
            </Stack>

        </Grid>
    )

}