import {
    Alert,
    Grid,
    Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography
} from "@mui/material"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../../utils/axios";
import { Link } from "react-router-dom";
import { useHoraryStore } from "../../../hooks/useHoraryStore";

export const ReportRestriccion = () => {

    const [configs, setReportConfig] = useState({});
    const [listReserve, setListReserve] = useState([]);
    const [token, setToken] = useState(sessionStorage.getItem("Token"));
    const [messageSuccess, setMessageSuccess] = useState({
        message: "",
        saverity: "success"
    });

    const [openSuccess, setOpenSuccess] = useState(false);
    const { status, selectReport } = useHoraryStore()


    useEffect(() => {
        if(selectReport?.id)
        {
            getReportConfig();
            getListReportConfig()
        }
    }, [])

    const getReportConfig = async () => {
        axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/config/report90`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },params:{
                   idfield: selectReport?.idfield ,
                   idday_: selectReport?.idday
                }
            })
            .then((response) => {
                setReportConfig(response.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getListReportConfig = async () => {
        axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/config/reserve`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },params:{
                   idfield: selectReport?.idfield ,
                   idday_: selectReport?.idday
                }
            })
            .then((response) => {
                setListReserve(response.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };
    return (
        <Grid sx={{ bgcolor: '#f4f4f4' }}>
            <Grid container className="containerInvite">
                <Grid container justifyContent={"center"} mt={2}>
                    <Grid item xs={12} md={10}>
                        <Grid xs={12} mb={2}>
                            <Link to={'/config-render-horary'} className='link__css'>
                                <Typography variant='h6' gutterBottom className='link__css-back'>
                                    <ArrowBackIosIcon className='icon_back' /> Volver
                                </Typography>
                            </Link>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <Typography variant={"h5"} className="title__main">REPORTE DE RESERVAS {selectReport?.name_field.toUpperCase()} / {selectReport?.name_day.toUpperCase()}</Typography>
                                    {/* <Typography variant={"subtitle2"} className="title__main">{}</Typography> */}
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <TableContainer component={Paper} >
                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                    <TableHead >
                                        <TableRow style={{ background: "#191D3D" }}>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Cantidad total de reservas</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Reservas con 90 minutos tomados</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Reservas con 60 minutos tomados</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {
                                            configs.map((row) => ( */}
                                                <TableRow >
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {configs?.cantidad_total} reserva
                                                    </TableCell>
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {configs?.cantidad_90} reserva
                                                    </TableCell>
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {configs?.cantidad_60} reserva
                                                    </TableCell>
                                                </TableRow>
                                            {/* ))} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent={"center"} mt={2}>
                    <Grid item xs={12} md={10}>
                        <Grid xs={12} mb={2}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <Typography variant={"h5"} className="title__main">LISTA DE RESERVAS</Typography>
                                    {/* <Typography variant={"subtitle2"} className="title__main">{}</Typography> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <TableContainer component={Paper} >
                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                    <TableHead >
                                        <TableRow style={{ background: "#191D3D" }}>
                                        <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Fecha</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Inicio</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Fin</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Minutos tomados</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Reservado por</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            listReserve.map((row) => (
                                                <TableRow key={row?.id}>
                                                     <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {row?.date}
                                                    </TableCell>
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {row?.start}
                                                    </TableCell>
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {row?.end}
                                                    </TableCell>
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {row?.duration_horary} min
                                                    </TableCell>
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {row?.user_name_completed}
                                                       <p> {row?.user_rut}</p>
                                                    </TableCell>
                                                </TableRow>
                                           ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleCloseSuccess}>
                    <Alert onClose={handleCloseSuccess} severity={messageSuccess.saverity} sx={{ width: '100%' }}>
                        {messageSuccess.message}
                    </Alert>
                </Snackbar>
            </Stack>
        </Grid>
    )
}