import { FormControl, Grid, Input, InputLabel, Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import "../card/styles.css"
import imagewebpay from "../../../../assets/images/webpayplus.png"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import FormTransfer from '../transfer'
import axios from "axios";

const currentYear = new Date().getFullYear();
const monthsArr = Array.from({ length: 12 }, (x, i) => {
    const month = i + 1;
    return month <= 9 ? '0' + month : month;
});
const yearsArr = Array.from({ length: 9 }, (_x, i) => currentYear + i);

export default function CForm({
    cardMonth,
    cardYear,
    onUpdateState,
    cardNumberRef,
    cardHolderRef,
    cardDateRef,
    onCardInputFocus,
    onCardInputBlur,
    cardCvv,
    children,
    data_props
}) {
    let dataReserva = JSON.parse(data_props)
    let dataReserva2 = {
        "id": dataReserva.resp_reservation.data.data.id,
        "reserva": dataReserva.reserva,
    }
    let listaInvitados = dataReserva.listaInvitados
    const [cardNumber, setCardNumber] = useState('');
    const [validatePayment, setValidatePayment] = useState(true);
    const [dataTransferencia, setDataTransferencia] = useState({});
    const [bankAccount, setBankAccount] = useState([]);
    const handleFormChange = (event) => {
        const { name, value } = event.target;
        onUpdateState(name, value);
    };
    var Token = localStorage.getItem('token_user')
    const onCardNumberChange = (event) => {
        let { value, name } = event.target;
        let cardNumber = value;
        value = value.replace(/\D/g, '');
        if (/^3[47]\d{0,13}$/.test(value)) {
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
            // diner's club, 14 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^\d{0,16}$/.test(value)) {
            // regular cc number, 16 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
                .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        }

        setCardNumber(cardNumber.trimRight());
        onUpdateState(name, cardNumber);
    };

    const onCvvFocus = (event) => {
        onUpdateState('isCardFlipped', true);
    };

    const onCvvBlur = (event) => {
        onUpdateState('isCardFlipped', false);
    };

    const handleSelectPayment = (e) => {
        setValidatePayment(e.target.value)
    }

    useEffect(() => {
        getBankAccount();
    }, [])

    const getBankAccount = async () => {
        const res = await axios({
            method: "get",
            url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/bank_accounts`,
            headers: {
                Authorization: `Bearer ${Token}`,
            }
        })
        setBankAccount(res?.data.data)
    }

    const GenerateTransfer = async (value) => {
        try {
            const dataTransfer = new FormData();
            value.image.data && (dataTransfer.append('image', value.image.data));
            // dataTransfer.append('image', value.image.data);
            dataTransfer.append('rut', value.rut);
            dataTransfer.append('name', value.name);
            dataTransfer.append('surname', value.surname);
            dataTransfer.append('total', dataReserva2.reserva.amount);
            dataTransfer.append('idreservation', dataReserva2.id);
            const res = await axios({
                method: "post",
                url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/transfer`,
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                data: dataTransfer
            })
            console.log(res);
            return res;
            // if(res)
            // {
            //     const activeReservation = await axios({
            //         method: "patch",
            //         url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/transfer/accept/${res.data.data.id}`,
            //         headers: {
            //             Authorization: `Bearer ${Token}`,
            //         },
            //         data:{}
            //     })
            // }

        } catch (e) {
            console.log(e)
        }
    }

    // NOTE: Currently the cursor on the card number field gets reset if we remove a number, the code bellow was used
    // in class components, need to transform this to work with functional components.
    // getSnapshotBeforeUpdate() {
    //     return this.props.cardNumberRef.current.selectionStart;
    // }

    // const componentDidUpdate = function (prevProps, prevState, cursorIdx) {
    //     const node = cardNumberRef.current;
    //     const { cardNumber: cardNum } = state;
    //     const { cardNumber: prevCardNum } = prevState;
    //     if (
    //         cardNum.length > prevCardNum.length &&
    //         cardNum[cursorIdx - 1] === ' '
    //     ) {
    //         cursorIdx += 1;
    //     } else if (prevCardNum[cursorIdx - 1] === ' ') {
    //         cursorIdx -= 1;
    //     }
    //     node.selectionStart = node.selectionEnd = cursorIdx;
    // };

    return (
        <Grid container justifyContent={"center"} mt={3}>
            {/* <Grid xs={10} mb={2}>
                    <Link to={'/modulos'} className='link__css'>
                        <Typography variant='h6' gutterBottom className='link__css-back'>
                            <ArrowBackIosIcon className='icon_back' /> Volver
                        </Typography>
                    </Link>
                </Grid> */}
            <Grid item xs={12} md={10}  style={{ background: "#fff", borderRadius: "5px", }} mt={-2} >
                <Grid container>
                    <Grid item xs={12} md={6} mt={2}>
                        <Grid container justifyContent={"left"} xs={12} style={{ background: "#fff" }} pl={2} pr={2} >
                            <Grid>
                                {/* <img className="imagen-webpayplus" src={imagewebpay}></img> */}
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Typography variant='h5'>TOTAL A PAGAR $ {dataReserva2.reserva.amount}</Typography>
                                {/* <Typography variant='subtitle'>Trescientos cincuenta y cinco mil quinientos pesos chilenos</Typography> */}
                            </Grid>
                            <Grid item xs={10} mt={2}>
                                <Typography gutterBottom variant='h6'>
                                    MÉTODO DE PAGO
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        size='small'
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={validatePayment}
                                        onChange={(e) => { handleSelectPayment(e) }}
                                    >
                                        {/* <MenuItem value={false}>
                                            <Typography gutterBottom variant='caption'>
                                                Con tarjeta (Débito o Crédito)
                                            </Typography>
                                        </MenuItem> */}
                                        <MenuItem value={true}>
                                            <Typography gutterBottom variant='caption'>
                                                Con transferencia
                                            </Typography>
                                        </MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={10} mt={2}>
                                <Typography gutterBottom variant='h5'>
                                    Datos de transferencia
                                </Typography>
                                {
                                    bankAccount.map((value) => {
                                        return (
                                            <>

                                                <Grid xs={12} mb={1}>
                                                    <Typography gutterBottom variant='caption'>
                                                        A nombre de
                                                    </Typography>
                                                    <TextField
                                                        size='small'
                                                        type="tel"
                                                        name="name"
                                                        className="card-input__input"
                                                        autoComplete="off"
                                                        // value={value.name_holder + '(' + value.rut_holder + ')'}
                                                        value={value.name_holder}
                                                        // onChange={handleValuesTransfer}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid xs={12} mb={1}>
                                                    <Typography gutterBottom variant='caption'>
                                                        RUT
                                                    </Typography>
                                                    <TextField
                                                        size='small'
                                                        type="tel"
                                                        name="rut"
                                                        className="card-input__input"
                                                        autoComplete="off"
                                                        value={value.rut_holder}
                                                        // onChange={handleValuesTransfer}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid xs={12} mb={1}>
                                                    <Typography gutterBottom variant='caption'>
                                                        Número de cuenta
                                                    </Typography>
                                                    <TextField
                                                        size='small'
                                                        type="tel"
                                                        name="rut"
                                                        className="card-input__input"
                                                        autoComplete="off"
                                                        value={value.num_account}
                                                        // onChange={handleValuesTransfer}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid xs={12} mb={1}>
                                                    <Typography gutterBottom variant='caption'>
                                                        Banco
                                                    </Typography>
                                                    <TextField
                                                        size='small'
                                                        type="tel"
                                                        name="rut"
                                                        className="card-input__input"
                                                        autoComplete="off"
                                                        value={value.name_bank}
                                                        // onChange={handleValuesTransfer}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid xs={12} mb={1}>
                                                    <Typography gutterBottom variant='caption'>
                                                        Correo
                                                    </Typography>
                                                    <TextField
                                                        size='small'
                                                        type="tel"
                                                        name="rut"
                                                        className="card-input__input"
                                                        autoComplete="off"
                                                        value={"clubespanolrenaca@gmail.com"}
                                                        // onChange={handleValuesTransfer}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </>

                                        )
                                    })
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {!validatePayment ? (
                            <Grid container style={{ background: "#fff" }} pl={2} pr={2} pb={1}>
                                <Grid container>
                                    <Grid item xs={12} md={12} mt={2}>{children}</Grid>
                                    <Grid xs={12}></Grid>
                                    <Grid item xs={12} md={12}>
                                        <Grid container pt={4}>
                                            <Grid xs={12} mb={1}>
                                                <Typography gutterBottom variant='caption'>
                                                    NUMERO DE TARJETA
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    type="tel"
                                                    name="cardNumber"
                                                    className="card-input__input"
                                                    autoComplete="off"
                                                    onChange={onCardNumberChange}
                                                    maxLength="19"
                                                    ref={cardNumberRef}
                                                    onFocus={(e) => onCardInputFocus(e, 'cardNumber')}
                                                    onBlur={onCardInputBlur}
                                                    value={cardNumber}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid xs={12} mb={1}>
                                                <Typography gutterBottom variant='caption'>
                                                    NOMBRE DE PROPIETARIO
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    type="text"
                                                    className="card-input__input"
                                                    autoComplete="off"
                                                    name="cardHolder"
                                                    onChange={handleFormChange}
                                                    ref={cardHolderRef}
                                                    onFocus={(e) => onCardInputFocus(e, 'cardHolder')}
                                                    onBlur={onCardInputBlur}
                                                />
                                            </Grid>

                                            <Grid xs={12} mb={1}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={8}>

                                                        <Typography gutterBottom variant='caption'>
                                                            FECHA EXPIRACION
                                                        </Typography>
                                                        <br />
                                                        <Grid item container spacing={1}>
                                                            <Grid item xs={6} >
                                                                <FormControl fullWidth>
                                                                    <InputLabel size='small' id="demo-simple-select-autowidth-label">Mes</InputLabel>
                                                                    <Select
                                                                        size='small'
                                                                        labelId="demo-simple-select-autowidth-label"
                                                                        id="demo-simple-select-autowidth"
                                                                        value={cardMonth}
                                                                        name="cardMonth"
                                                                        label="Mes"
                                                                        onChange={handleFormChange}
                                                                        ref={cardDateRef}
                                                                        onFocus={(e) => onCardInputFocus(e, 'cardDate')}
                                                                        onBlur={onCardInputBlur}
                                                                    >
                                                                        {monthsArr.map((val, index) => (
                                                                            <MenuItem key={index} value={val}>
                                                                                {val}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={6}>

                                                                <FormControl fullWidth>
                                                                    <InputLabel size='small' id="demo-simple-select-autowidth-label">Año</InputLabel>
                                                                    <Select
                                                                        size='small'
                                                                        labelId="demo-simple-select-autowidth-label"
                                                                        id="demo-simple-select-autowidth"
                                                                        name="cardYear"
                                                                        label="Año"
                                                                        className="year"
                                                                        value={cardYear}
                                                                        onChange={handleFormChange}
                                                                        onFocus={(e) => onCardInputFocus(e, 'cardDate')}
                                                                        onBlur={onCardInputBlur}
                                                                    >
                                                                        {yearsArr.map((val, index) => (
                                                                            <MenuItem key={index} value={val}>
                                                                                {val}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography gutterBottom variant='caption'>
                                                            CVV
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            size='small'
                                                            type="tel"
                                                            className="card-input__input"
                                                            maxLength="4"
                                                            autoComplete="off"
                                                            name="cardCvv"
                                                            onChange={handleFormChange}
                                                            onFocus={onCvvFocus}
                                                            onBlur={onCvvBlur}
                                                            ref={cardCvv}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Typography gutterBottom variant='caption'>
                                                            OPCIONES DE PAGO
                                                        </Typography>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                size='small'
                                                                labelId="demo-simple-select-autowidth-label"
                                                                id="demo-simple-select-autowidth"
                                                                value={cardMonth}
                                                                name="cardMonth"
                                                                onChange={handleFormChange}
                                                                ref={cardDateRef}
                                                                onFocus={(e) => onCardInputFocus(e, 'cardDate')}
                                                                onBlur={onCardInputBlur}
                                                            >

                                                                <MenuItem>
                                                                    Sin cuotas
                                                                </MenuItem>
                                                                <MenuItem>
                                                                    Con cuotas
                                                                </MenuItem>

                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid container spacing={1} p={1}>
                                                        <Grid item xs={12} md={6}>
                                                            <Button variant='outlined' fullWidth className='card_reservation--cancel'>ANULAR</Button>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Link to={`/registro-exitoso`} style={{ textDecoration: 'none' }}>

                                                                <Button variant='outlined' fullWidth className='card_reservation--change'>PAGAR</Button>
                                                            </Link>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <FormTransfer GenerateTransfer={GenerateTransfer} dataReserva={dataReserva2} listaInvitados={listaInvitados} />
                        )}


                    </Grid>
                </Grid>
            </Grid>



        </Grid>
    );
}