import { onSelectField, onSelectReport, selectConfigHoraryState } from '../redux/slices/configHorary'
import { useSelector, useDispatch } from '../redux/store'

export const useHoraryStore = () => {
  const dispatch = useDispatch()
  const { status, selectField, selectReport} = useSelector(selectConfigHoraryState)

  const setSelectedField = async (data) => {
    try {
      dispatch(onSelectField(data))
    } catch (error) {
      console.error(error)
    }
  }

  const setSelectedReport = async (data) => {
    try {
      dispatch(onSelectReport(data))
    } catch (error) {
      console.error(error)
    }
  }


  
  return {
    //States
    status, 
    selectField,
    selectReport,
    setSelectedField,
    setSelectedReport
  }
}
