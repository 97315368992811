import { FormControl, Grid, Input, InputLabel, Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import "../card/styles.css"
import { useHistory } from 'react-router-dom';
import imagewebpay from "../../../../assets/images/webpayplus.png"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import TimerDes from "../../../../Components/Timer/timer"
import './index.css'


export default function FormTransfer(props) {
    const history = useHistory()
    const dataReserva= props.dataReserva
    const listaInvitados = props.listaInvitados
    const [cardNumber, setCardNumber] = useState('');
    const [error, setError] = React.useState(null);
    const [saveImage, setSaveImage] = useState({
        name: '',
        path: '',
        preview: null,
        data: null
    });
    const [data, setData] = useState({
        name: "",
        surname: '',
        rut: '',
    });

    const changePath = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        let src, preview, type = file.type;
        if (type == "image/bmp") {
            alert('Solo se acepta como tipo de imgen/png-jpg-jpeg')
            return;
        }
        // Coincide con la cadena que comienza con image /
        if (/^image\/\S+$/.test(type)) {
            src = URL.createObjectURL(file)
            preview = <img src={src} alt='' width='100%' />
        }
        setSaveImage(prev => ({ ...prev, data: file, path: src, preview: preview }))
    }

    const handleValuesTransfer = (e) => {
        // const name = event.target.name;
        // const value = event.target.value;


        // switch (name) {
        //     case 'rut':
        //         setData(prev => ({ ...prev, rut: value }));
        //         break;
        //     case 'name':
        //         setData(prev => ({ ...prev, name: value }));
        //         break;
        //     case 'surname':
        //         setData(prev => ({ ...prev, surname: value }));
        //         break;
        // }

        const changedFormValues={
            ...data,
            [e.target.name]: e.target.value,
        };
        
        changedFormValues.rut.trim() !== "" && setError("");
        changedFormValues.name.trim() !== "" && setError("");
        changedFormValues.surname.trim() !== "" && setError("");        
        setData(changedFormValues);
     }
  
    const PaymentReservation = async() => {
        if (!data.rut.trim()) {
            return setError("rut");
        }
        if (!data.name.trim()) {
            return setError("name");
        }
        if (!data.surname.trim()) {
            return setError("surname");
        }
       const body ={           
            name: data.name,
            surname: data.surname,
            rut: data.rut,
            image: saveImage
       }
        const res = await props.GenerateTransfer(body);
        console.log(res);
        history.push('/registro-exitoso', JSON.stringify({dataReserva: dataReserva, res: res, listaInvitados: listaInvitados}))
     };

    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={11} style={{ background: "#fff", borderRadius: "5px" }} >
                    <Grid item container justifyContent={"center"} xs={12} md={12} sx={{p:2}}>
                        <Grid container pt={4}>
                            <Typography gutterBottom variant='h5' align='center'>
                                DATOS DEL TITULAR DE LA CUENTA
                            </Typography>
                            <Grid xs={12} mb={1}>
                                <Typography gutterBottom variant='caption'>
                                    CARGAR IMAGEN TRANSFERENCIA
                                </Typography>
                                <br />
                                <div >
                                    <div className="container">
                                        <input className="upload-box" type="file" accept="image/png,image/jpeg" onChange={changePath} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid xs={12} mb={1}>
                                <Typography gutterBottom variant='caption'>
                                    RUT
                                </Typography>
                                <TextField
                                    size='small'
                                    type="tel"
                                    name="rut"
                                    className="card-input__input"
                                    autoComplete="off"
                                    value={data.rut}
                                    onChange={handleValuesTransfer}
                                    error={error === "rut" ? true : false}
                                    helperText={error === "rut" ? "El RUT es un campo requerido" : ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={12} mb={1}>
                                <Typography gutterBottom variant='caption'>
                                    NOMBRES
                                </Typography>
                                <TextField
                                    size='small'
                                    fullWidth
                                    type="text"
                                    className="card-input__input"
                                    autoComplete="off"
                                    value={data.name}
                                    onChange={handleValuesTransfer}
                                    error={error === "name" ? true : false}
                                    helperText={error === "name" ? "Los nombres son campos requerido" : ""}
                                    name="name"
                                />
                            </Grid>

                            <Grid xs={12} mb={1}>
                                <Typography gutterBottom variant='caption'>
                                    APELLIDOS
                                </Typography>
                                <TextField
                                    size='small'
                                    fullWidth
                                    type="text"
                                    className="card-input__input"
                                    autoComplete="off"
                                    value={data.surname}
                                    onChange={handleValuesTransfer}
                                    error={error === "surname" ? true : false}
                                    helperText={error === "surname" ? "Los apellidos son campos requerido" : ""}
                                    name="surname"
                                />
                            </Grid>
                            <Grid xs={12} mb={1}>
                                <Grid container spacing={1}>
                                    <Grid container spacing={1} p={1}>
                                        {/* <Grid item xs={12} md={6}>
                                            <Button variant='outlined' fullWidth className='card_reservation--cancel'>ANULAR</Button>
                                        </Grid> */}
                                        <Grid item xs={12} md={6}>
                                            {/* <Link to={`/registro-exitoso`} style={{ textDecoration: 'none' }}> */}
                                                <Button variant='outlined' fullWidth className='card_reservation--change' onClick={()=>PaymentReservation()}>PAGAR</Button>
                                            {/* </Link> */}

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} mt={3}>
                                <Typography variant='subtitle1'>Si tiene dificultades para realizar la transferencia. Podrá enviar posteriormente la transferencia a <span style={{textDecoration:'underline', color:'#1b929f'}}>pagos@clubespanolderenaca.cl</span> o presentar el comprobante de transferencia al momento de llegar a la cancha.*</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
            </Grid>

        </Grid>
    );
}