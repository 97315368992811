import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CardContent, Accordion, AccordionSummary, AccordionDetails, ListItemButton, Divider } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ModalInfo = (props) => {

    const { open, closeModal, rowSelected, clearState } = props;

    console.log('rowSelected',rowSelected)
    return (
        <div>
            <Dialog
                open={open}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"DETALLE DE LA RESERVA"}
                </DialogTitle>

                <DialogContent>
                    <Grid display='flex' mb={1}>
                        <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Campo:</Typography>
                        <Typography sx={{fontSize:'14px'}}>{rowSelected?.hours[0]?.name_field || '------'}</Typography>
                    </Grid>
                    <Grid display='flex' mb={1}>
                        <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Solicitante:</Typography>
                        <Typography  sx={{fontSize:'14px'}}>{rowSelected.name_user || '-----'}</Typography>
                    </Grid>
                    <Grid display='flex' mb={1}>
                        <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Tipo de reservación:</Typography>
                        <Typography  sx={{fontSize:'14px'}}>{rowSelected.type_reservation || '-----'}</Typography>
                    </Grid>
                    <Grid display='flex' mb={1}>
                        <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Fecha de reserva:</Typography>
                        <Typography sx={{fontSize:'14px'}}>{rowSelected.date_booking || '------'}</Typography>
                    </Grid>

                    <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%'}} pb={3}>
                            <Grid sx={{borderRight: '.5px solid #808080', border:'solid 1px #808080'}} display='flex' justifyContent='start'>
                                <CardContent>
                                    <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                                    Inicio:
                                    </Typography>
                                    <Grid sx={{padding:'0px 30px 0px 30px', textAlign:'center'}}>
                                        <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>
                                            {rowSelected.start_hour || '-----'}
                                        </Typography>
                                    </Grid>
                                </CardContent>
                            </Grid>
                            <Grid sx={{borderLeft: '.5px solid #808080', border:'solid 1px #808080'}} display='flex' justifyContent='end'>
                                <CardContent>
                                    <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                                    Salida :
                                    </Typography>
                                    <Grid sx={{padding:'0px 30px 0px 30px', textAlign:'center'}}>
                                        <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>
                                            {rowSelected.end_hour || '-----'}
                                        </Typography>
                                    </Grid>
                                </CardContent>
                            </Grid>
                    </Grid>

                    <Grid display='flex' alignItems='center' sx={{ width:'100%'}}>
                        <Accordion sx={{width:'100%'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Invitados</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    rowSelected?.invite && (rowSelected?.invite).length > 0 ? (rowSelected?.invite || []).filter(item => item.id !== rowSelected?.iduser).map((item)=> (
                                        <>
                                        <ListItemButton>
                                            <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombres</Typography>
                                            {item?.name? <Typography sx={{ color: 'text.secondary' }}>{item.name || '-----'}</Typography>: <Typography sx={{ color: 'text.secondary' }}>{item.name_invite || '-----'}</Typography>}
                                           
                                        </ListItemButton>
                                        <ListItemButton>
                                            <Typography sx={{ width: '40%', flexShrink: 0 }}>Apellidos</Typography>
                                            {item?.surname? <Typography sx={{ color: 'text.secondary' }}>{item.surname || '-----'}</Typography>:<Typography sx={{ color: 'text.secondary' }}>{item.surname_invite || '-----'}</Typography> }
                                            
                                        </ListItemButton>
                                        <Divider />
                                        </>
                                    ))
                                    :
                                    <Typography sx={{ color: 'text.secondary' }}>No tiene invitados</Typography>
                                }
                                

                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={closeModal}>Cancelar</Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}