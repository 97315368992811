import React from "react"
import { Button, Card, CardContent, CardMedia, Divider, Fade, Grid, IconButton, Modal, TextField, Typography } from "@mui/material"
import "./Qr.css"
import deporte from '../../assets/images/deporte.png';
import calendario from '../../assets/icons/calendario.png';
import favorito from '../../assets/images/favorito.png';
import reservas from '../../assets/images/reservas.png';
import listareserva from '../../assets/icons/listareserva.png';
import redtenis from '../../assets/icons/red.png';
import invitados from '../../assets/images/invitados.png';
import listainvitado from '../../assets/icons/listainvitado1.png';
import canchas from '../../assets/images/cancha.png';
import cuentas from '../../assets/images/cuenta.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from "react-router-dom";
import pelota from '../../assets/icons/pelotados.png';
import 'animate.css';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";


const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 450,
        margin: 5,
    },
}));


export const Qr = (props) => {

    const classes = useStyles();
    const history = useHistory();
    // const tipoUsuario= localStorage.getItem("tipoUsuario");
    const [openModal, setOpenModal] = React.useState(false);
    const [externSelected, setExternSelected] = React.useState({
        password: ''
    })
    const [snackBarConfig, setSnackBarConfig] = React.useState({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })
    var Token = localStorage.getItem('token_user')
    var DataUser = JSON.parse(localStorage.getItem('data_user'))
    var passwordEncrypt = localStorage.getItem('passwordEncrypt');
    var type_login = localStorage.getItem('type_login');
     const changeView = (view) => {
        switch (view) {
            case 'horario':
                history.push('/deportes-horario', { from: 'horario' })
                break;

            default:
                history.push('/deportes-horario', { from: 'cancha' });
                break;
        }
    }


    const handleChange = e => {
        const { name, value } = e.target;
        setExternSelected((prevState) => ({
            ...prevState,
            [name]: value
        }));
 }


    const handleSubmit = async (e) => {

        e.preventDefault();
        try {
            const response = await axios({
                method: "post",
                url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/update-password/${DataUser.id_user} `,
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                data: {
                    passwordEncrypted: passwordEncrypt,
                    newPassword: externSelected.password
                }
            });

           
            if (response.data.data.original.message) {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'success',
                    message: "Cambio de contraseña exitoso",
                }));
            } else {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'error',
                    message: "No se pudo cambiar su contraseña, credenciales inválidas",
                }));
            }
            setOpenModal(false)
            localStorage.setItem('type_login', '')

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (type_login == 'auto') {
            setOpenModal(true)
        }

    }, [])

    return (
        <>
            {/* {(tipoUsuario=="socio" || tipoUsuario=="familiar"||tipoUsuario=="alumno"||tipoUsuario=="profesor" ||tipoUsuario=="externo")&&   */}
            <Grid container justifyContent={"center"} className="backModules"
            >
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openModal}
                    // onClose={() => { setOpenModal(false) }}
                    closeAfterTransition
                // BackdropComponent={Backdrop}
                // BackdropProps={{
                //   timeout: 500,
                // }}

                >
                    <Fade in={openModal}>
                        <div className={classes.paper}>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                Agregar Contraseña
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">

                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        size="small"
                                        name="password"
                                        placeholder="Contraseña*"
                                        fullWidth
                                        value={externSelected.password}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        {/* <Button fullWidth className="btn-cancelar" onClick={() => setOpenModal(false)}>Cancelar</Button> */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button onClick={handleSubmit} fullWidth className="btn-aceptar" type="submit">Guardar Contraseña</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Fade>
                </Modal>
                <Grid item xs={12} md={12} mt={0}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} md={4} mt={4}>
                            <Grid item sm={4} xs={12} md={3}>
                                <Grid sx={{background:"#fff"}} p={2}>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* } */}
        </>
    )
}