import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import "./ListaCanchas.css";

import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { session } from '../../session/constants';
import axios from '../../utils/axios';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import cancha from '../../assets/images/canchas.png';
import cancha2 from '../../assets/images/cancha_prueba.png';

export const ListaCanchas = (props) => {
    var token = sessionStorage.getItem(session.SESSION_TOKEN);
    const [listCanchas,setListCanchas] = useState([])
    let history = useHistory();
    const data_props = props.location.state.from;
    console.log("LA FUCKIN LISTA DE INVITADIS: ",props); 

    console.log('TODO LO QUE VIENE(ID TYPE FIELD): ', props.location.state.idTypeField);

    const changeViewBack=(view)=>{
        // switch (view) {
        //     case 'horario':
        //         console.log('holi')
        //         history.push('/seleccionar-invitados-horario',{from:'horario'} )
        //         break;
        
        //     default:
                history.push('/seleccionar-invitados-horario', {from:'cancha', idTypeField: props.location.state.idTypeField});
        //         break;
        // }
    }

    // const mostrarCanchas = async () => {
    //     try {
    //         const response = await axios({
    //           method: "get",
    //           url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/list`,
    //           headers: {
    //             'Authorization': `Bearer ${token}`
    //           }              
    //         });
    //         // console.log(response);
    //         setListCanchas(response);
    //         // make axios post request
    //       } catch (error) {
    //         console.log(error);
    //       }
    // };
    const selectField=(selectField)=>{
        // console.log("Campo seleccionado",selectField);
        history.push(`/horarios/${selectField.id}`);
        history.push('/horarios', {
            a_iduser: props.location.state.a_iduser,
            a_idinvite: props.location.state.a_idinvite,
            idtype_reservation: props.location.state.idtype_reservation,
            from: 'canchas',
            externosBolean:props.location.state.externosBolean,
            idField: selectField.id,
            listaInvitados: props.location.state.listaInvitados,
            name_cancha: selectField.name,
            idTypeField: props.location.state.idTypeField
        }
        );
    }
    function mostrarCanchas() {
        //const token = 
        //localStorage.getItem("token_user");
        //console.log(token);
        //'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwMDAvdjEvYXV0aC9sb2dpbiIsImlhdCI6MTY2NTE3MTA0NCwiZXhwIjoxNjY1MTc0NjQ0LCJuYmYiOjE2NjUxNzEwNDQsImp0aSI6IjIxREtPWHkxREJ2Zjk4c0UiLCJzdWIiOiIxIiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.uWRjmBLfUhbaKMXSSCYe2uuSWB41heaTPyI7Oe7xgoA'
        return axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/listByUser?id_type_field=${props.location.state.idTypeField}`,
                {
                    headers:
                    {
                        // 'Access-Control-Allow-Origin': '*',
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + token,
                    }
                }
            )
            .then(pros => {
                const data = (pros.data).map((raw) => (
                    {
                        id: raw?.id,
                        name: raw?.name,
                        description: raw?.description,
                        image_1: raw?.image_1,
                        capacity: raw?.capacity
                    }
                ))
               
                setListCanchas(data);
            })
// _____________________________________________________
//             .then(pros => pros.json())
//             .then(async pros => {
//                 const data = (pros || []).map((raw) => ({
//                     id: raw?.id,
//                     name: raw?.name,
//                     description: raw?.description,
//                     image_1: raw?.image_1,
//                     capacity: raw?.capacity
//                 }))
//                 console.log(data);
//                 setListCanchas(data);
//             })
    }
    useEffect(() => {
        mostrarCanchas([...listCanchas]);
    }, [])

    return (
        <>
            <Grid container justifyContent="center" sx={{bgcolor:'#f4f4f4'}}>
                <Grid item xs={12} md={10} mt={2}>
                    <Grid container justifyContent={"center"} mt={1} pl={1}>
                        <Grid item xs={12} md={12}>
                            <Grid xs={1}>
                                {/* <Link to={'/deportes-canchas'} className='link__css'> */}
                                <Typography onClick={() => changeViewBack(data_props)} variant='h6' gutterBottom className='button_back'>
                                    <ArrowBackIosIcon className='icon_back' /> Volver
                                </Typography>
                            </Grid>
                            {/* </Link> */}

                            <Grid item xs={12} md={12}>
                                <Typography variant={"h5"} className="title__main">CANCHAS</Typography>
                                <Typography variant='body1' gutterBottom>Seleccione su cancha a reservar</Typography>
                            </Grid>

                    </Grid>
                    </Grid>
                    <Card>
                        <CardContent>
                        <Grid container mt={2}>
                        {listCanchas.map((item) => (
                            <Grid item xs={12} md={6} p={1}>
                                {/* <Link to={`/horarios-canchas`}> */}
                                    <Card onClick={()=>selectField(item)} sx={{ width: "100%", background: "#fff", borderRadius: "10px" }}>
                                        <CardActionArea className='contenedor'>
                                            <CardMedia
                                                component="img"
                                                height="180"
                                                image={cancha2}
                                            />
                                            <Grid container className='texto-encima'>
                                                <Grid item xs={10}>
                                                    <Typography gutterBottom variant="h6" component="div" ml={2} mt={1} className="texto-card">
                                                        Campo "{item.name}"
                                                    </Typography>
                                                    {/* <Typography gutterBottom variant="caption" component="div" ml={2} mt={0} className="texto-card2">
                                                        Descripción: {item.description}
                                                    </Typography>
                                                    <Typography gutterBottom variant="caption" component="div" ml={2} mt={0} className="texto-card2">
                                                        Capacidad: {item.capacity} personas
                                                    </Typography> */}
                                                </Grid>
                                                {/* <Grid item xs={2}>
                                                    <IconButton>
                                                        <FavoriteIcon sx={{ color: "#fff", marginLeft: "50px" }} />
                                                    </IconButton>
                                                </Grid> */}
                                            </Grid>
                                        </CardActionArea>
                                    </Card>
                                {/* </Link> */}
                            </Grid>
                        ))}
                    </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
const itemData = [
    {
        img: 'https://cdn.pixabay.com/photo/2015/10/19/16/47/ash-996321_960_720.jpg',
        title: 'Cancha 1',
    },
    {
        img: 'https://cdn.pixabay.com/photo/2015/10/19/16/47/ash-996321_960_720.jpg',
        title: 'Cancha 2',
    },
    {
        img: 'https://cdn.pixabay.com/photo/2015/10/19/16/47/ash-996321_960_720.jpg',
        title: 'Cancha 3',
    },
    {
        img: 'https://cdn.pixabay.com/photo/2015/10/19/16/47/ash-996321_960_720.jpg',
        title: 'Cancha 4',

    },
    {
        img: 'https://cdn.pixabay.com/photo/2015/10/19/16/47/ash-996321_960_720.jpg',
        title: 'Cancha 5',
    },
   
];

