import React, { useState } from "react"
import { Button, Grid, TextField, TableHead, Typography, TextFieldaphy } from "@mui/material"
import "./MyProfile.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import excel from '../../assets/icons/excel-icon.png';
import profile from '../../assets/images/penguis.png';
import agregar from '../../assets/icons/agregar.png';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import KeyIcon from '@mui/icons-material/Key';
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 350,
    },
}));

const dataUsuario = {
    nombre: "GARCÍA SOTO NACHO VASQUEZ", email: 'garciaSotoNacho@gmail.com', telefono: '28393002', rut: "1-10928226", comuna: "Ciudad de Zaragoza"
};
export const MyProfile = () => {
    const [data, setData] = useState(dataUsuario);
    const [isDisable, setIsDisabled] = useState(true);


    const [perfilUsuario, setPerfilUsuario] = useState({
        nombre: 'GARCÍA SOTO NACHO VASQUEZ',
        email: 'garciaSotoNacho@gmail.com',
        telefono: '28393002',
        rut: '1-10928226',
        comuna: 'Ciudad de Zaragoza'
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setPerfilUsuario((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleActualizar = () => {
        setIsDisabled(!isDisable);
    }
    return (
        <>
            <Grid container className="containerInvite">
                <Grid container justifyContent={"center"} mt={2}>
                    <Grid item xs={12} md={10}>
                        <Grid container xs={12} mb={2}>
                            <Link to={'/modulos'} className='link__css'>
                                <Typography variant='h6' gutterBottom className='link__css-back'>
                                    <ArrowBackIosIcon className='icon_back' /> Ir al menú principal
                                </Typography>
                            </Link>
                            <Grid item xs={12} container spacing={1} justifyContent='space-between'>
                                <Grid item xs={12} mb={1}>
                                    <Typography variant={"h5"} className="title__main">MI PERFIL</Typography>
                                </Grid>
                                <Grid item container xs={12} md={3} mt={1}>
                                    <Grid item xs={12} mt={1}>
                                        <div className="contenedor">
                                            <img alt='mi perfil' src={profile}></img>
                                        </div>

                                    </Grid>
                                </Grid>

                                <Grid container item xs={8} md={8} spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography
                                            gutterBottom
                                            variant="h4"
                                            className='title__user'
                                        >
                                            <b>Perfil de Usuario </b>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography className='data__user' gutterBottom variant="body1" component="div">
                                            Apellidos y Nombres
                                        </Typography>
                                        {/* <Typography variant="subtitle1" >
                                            García Soto Nacho Vasquez
                                        </Typography> */}
                                        <TextField
                                            // margin="normal"

                                            required
                                            fullWidth
                                            id="nombre"
                                            disabled={isDisable ? true : false}
                                            size='small'
                                            value={perfilUsuario ? perfilUsuario.nombre : ''}
                                            name="nombre"
                                            onChange={handleChange}
                                            // autoComplete="nombre"
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography className='data__user' gutterBottom variant="body1" component="div">
                                            Email
                                        </Typography>
                                        {/* <Typography variant="subtitle1" >
                                            garciaSotoNacho@gmail.com
                                        </Typography> */}
                                        <TextField
                                            // margin="normal"
                                            // sx={{ mb: 2 }}
                                            required
                                            fullWidth
                                            id="nombre"
                                            disabled={isDisable ? true : false}
                                            size='small'
                                            value={perfilUsuario ? perfilUsuario.email : ''}
                                            name="email"
                                            onChange={handleChange}
                                            // autoComplete="nombre"
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography className='data__user' gutterBottom variant="body1" component="div">
                                            Teléfono
                                        </Typography>
                                        {/* <Typography variant="subtitle1" >
                                        28393002
                                        </Typography> */}
                                        <TextField
                                            // margin="normal"
                                            // sx={{ mb: 2 }}
                                            required
                                            fullWidth
                                            disabled={isDisable ? true : false}
                                            id="nombre"
                                            size='small'
                                            value={perfilUsuario ? perfilUsuario.telefono : ''}
                                            name="telefono"
                                            onChange={handleChange}
                                        // autoComplete="nombre"

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography className='data__user' gutterBottom variant="body1" component="div">
                                            RUT
                                        </Typography>
                                        {/* <Typography variant="subtitle1" >
                                            1092827262
                                        </Typography> */}
                                        <TextField
                                            // margin="normal"
                                            // sx={{ mb: 2 }}
                                            required
                                            disabled={isDisable ? true : false}
                                            fullWidth
                                            id="nombre"
                                            size='small'
                                            value={perfilUsuario ? perfilUsuario.rut : ''}
                                            name="rut"
                                            onChange={handleChange}
                                        // autoComplete="nombre"

                                        />
                                    </Grid>
                                    <Grid item mb={2} xs={12} md={6}>
                                        <Typography className='data__user' gutterBottom variant="body1" component="div">
                                            Comuna
                                        </Typography>
                                        {/* <Typography variant="subtitle1" >
                                            Zaragoza
                                        </Typography> */}
                                        <TextField
                                            disabled={isDisable ? true : false}
                                            required
                                            fullWidth
                                            id="nombre"
                                            size='small'
                                            value={perfilUsuario ? perfilUsuario.comuna : ''}
                                            name="comuna"
                                            onChange={handleChange}

                                        />

                                    </Grid>
                                    <Grid container item mb={2} xs={12}>
                                        {isDisable ?
                                            <Button className='button__editar' onClick={handleActualizar}>Actualizar</Button>
                                            :
                                            <Grid xs={6} item container justifyContent={'space-between'}>
                                                <Button className='button__guardar' onClick={handleActualizar}>Guardar</Button>
                                                <Button className='button__actualizar' onClick={handleActualizar}>Cancelar</Button>
                                            </Grid>


                                        }

                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>

            </Grid>



        </>
    )
}