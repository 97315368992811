import React, { useEffect, useState } from "react"
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import "./Sports.css"
import tenis from '../../assets/icons/pelota.png';
import baseball from '../../assets/icons/baseball.png';
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from "react-router-dom";
// import axios from "axios";
import axios from "../../utils/axios";

export const DeportesHorario = (props) => {
    const history = useHistory();
    const data_props = props.location.state.from;
    var Token = localStorage.getItem('token_user')

    const [ListTypeField,setListTypeField] = useState([])

    const changeView = (view, idTipoCancha) =>{
        switch (view) {
            case 'horario':
                history.push('/seleccionar-invitados-horario',{from:'horario', idTypeField:idTipoCancha} )
                break;
        
            default:
                history.push('/seleccionar-invitados-horario', {from:'cancha', idTypeField:idTipoCancha});
                break;
        }
    }

    const getListTypeField = async () =>
    {
        await axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/type`,
                {
                    headers: {
                        Authorization: `Bearer ${Token}`,
                    }
                },
            )
            .then((response) => {
                setListTypeField(response.data.data)
            })
    //     const res = await axios({
    //         method: "get",
    //         url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/type`,
    //         headers: {
    //             Authorization: `Bearer ${Token}`,
    //         }
    //     })
    //     console.log(res)
    //    setListTypeField(res.data.data)
    }

    useEffect(()=>{
        getListTypeField();
    },[])


    return (
        <div>
            <Grid container justifyContent={"center"} mt={3} pl={1}>
                <Grid item xs={12} md={10}>
                    <Grid xs={12}>
                        <Link to={'/modulos'} className='link__css'>
                            <Typography variant='h6' gutterBottom className='link__css-back'>
                                <ArrowBackIosIcon className='icon_back' /> Volver
                            </Typography>
                        </Link>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Typography variant={"h5"} className="title__main">DEPORTES</Typography>
                            </Grid>
                            {/* <Grid item xs={4} md={2}>
                                <Button fullWidth className="btn-agregar" onClick={() => abrirModalInsertar()} startIcon={<AddCircleOutlineIcon />}>AGREGAR</Button>
                            </Grid> */}
                        </Grid>
                        <Typography variant='body1' gutterBottom>¿A qué deportess quieres jugar?</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
            >

                    <Grid container justifyContent="center" alignItems={"center"} >
                        <Grid item xs={12} md={10}>
                            <Grid container>
                                {
                                    ListTypeField.map((value)=>{
                                        return (
                                            <Grid sm={6} xs={12} md={3}
                                                onClick={() => { changeView(data_props, value.id) }}
                                            >
                                                <Card className="cardDeporte">
                                                    <Grid container justifyContent={"center"}>
                                                        <CardMedia
                                                            style={{ marginTop: "5px" }}
                                                            component="img"
                                                            height="130"
                                                            image={value.id == 1? tenis : value.id== 2? baseball: null}
                                                        />
                                                        <CardContent>
                                                            <Typography variant={"h6"} textAlign={"center"} className="txt_cardsDeporte">
                                                                {value.name}
                                                            </Typography>
                                                        </CardContent>

                                                    </Grid>

                                                </Card>

                                            </Grid>

                                        )
                                    })
                                }
                                {/* <Grid sm={6} xs={12} md={3} 
                               onClick={()=>{changeView(data_props, 1)}}
                                 >
                                       <Card className="cardDeporte">
                                            <Grid container justifyContent={"center"}>
                                                <CardMedia
                                                    style={{ marginTop: "5px" }}
                                                    component="img"
                                                    height="130"
                                                    image={tenis}
                                                />
                                                <CardContent>
                                                    <Typography variant={"h6"} textAlign={"center"} className="txt_cardsDeporte">
                                                        TENIS
                                                    </Typography>
                                                </CardContent>

                                            </Grid>

                                        </Card>
                                   
                                </Grid>
                                <Grid sm={6} xs={12} md={3} 
                                onClick={()=>{changeView(data_props, 2)}}
                                >
                                        <Card className="cardDeporte">
                                            <Grid container justifyContent={"center"}>
                                                <CardMedia
                                                    style={{ marginTop: "5px" }}
                                                    component="img"
                                                    height="130"
                                                    image={baseball}
                                                />
                                                <CardContent>
                                                    <Typography variant={"h6"} textAlign={"center"} className="txt_cardsDeporte">
                                                        PADEL
                                                    </Typography>
                                                </CardContent>
                                            </Grid>

                                        </Card>
                                  
                                </Grid> */}
                            </Grid>

                        </Grid>
                    </Grid>
            </Grid>
        </div>
    )
}