import React from "react"
import { Button, Card, CardContent, CardMedia, Divider, Grid, IconButton, Typography } from "@mui/material"
import "./ModuleAdmin.css"
import socios from '../../assets/icons/socios.png';
import familia from '../../assets/icons/familia.png';
import cronograma from '../../assets/icons/cronogramass.png';
import profesor from '../../assets/icons/profesores.png';
import membresia from '../../assets/icons/membresias.png';
import horaconfiguracion from '../../assets/icons/cronogramahora.png';
import { Link } from "react-router-dom";
import pelota from '../../assets/icons/pelotados.png';
// import 'ModuleAdmin.css';
import redtenis from '../../assets/icons/red.png';

export const ModuleAdmin = (props) => {
    const tipoUsuario = sessionStorage.getItem("tipoUsuario");
    return (
        <>
            {/* {tipoUsuario == "ADMINISTRADOR" && */}
                <Grid container justifyContent={"center"} className="backModules" sx={{bgcolor:'#f4f4f4'}}>
                    <Grid item xs={12} md={12} mt={0}>
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12} md={8} >

                                <Grid container className="containerModulos" p={3}>

                                {/* <Grid item xs={12} md={4}>
                                    <Link to={'/lista-canchas'} style={{ textDecoration: 'none' }}>
                                        <div class="card-container">
                                            <div class="pricing-card card-03">
                                                <div class="pricing">
                                                    <div class="price">
                                                        <img width={60} style={{ marginTop: "35px" }} src={redtenis} />
                                                    </div>
                                                    <Grid mt={3}>
                                                        <span class="type" style={{ color: "#469489" }}>CANCHA</span>
                                                    </Grid>

                                                    <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider>
                                                </div>
                                                <div class="card-body">
                                                    <div class="top-shape"></div>
                                                    <div class="card-content">
                                                        <button class="buy-btn">SIGUIENTE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Grid> */}

                                    <Grid item sm={4} xs={12} md={4}>
                                        <Link to={'/lista-socios'} style={{ textDecoration: 'none' }}>
                                            <div class="card-container">
                                                <div class="pricing-card card-03">
                                                    <div class="pricing">
                                                        <div class="price">
                                                            <img width={120} style={{ marginTop: "20px" }} src={socios} />
                                                        </div>
                                                        <Grid mt={1}>
                                                            <span class="type" style={{ color: "#BB1F1F" }}>SOCIOS</span>
                                                        </Grid>

                                                        {/* <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider> */}
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="top-shape"></div>
                                                        <div class="card-content">
                                                            <button class="buy-btn">SIGUIENTE</button>
                                                        </div>
                                                    </div>
                                                    {/* <div class="ribbon"></div> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </Grid>

                                    <Grid item sm={4} xs={12} md={4}>
                                        <Link to={'/lista-familia'} style={{ textDecoration: 'none' }}>
                                            <div class="card-container">
                                                <div class="pricing-card card-03">
                                                    <div class="pricing">
                                                        <div class="price">
                                                            <img width={120} style={{ marginTop: "20px" }} src={familia} />
                                                        </div>
                                                        <Grid mt={1}>
                                                            <span class="type" style={{ color: "#BB1F1F" }}>FAMILIARES</span>
                                                        </Grid>

                                                        {/* <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider> */}
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="top-shape"></div>
                                                        <div class="card-content">
                                                            <button class="buy-btn">SIGUIENTE</button>
                                                        </div>
                                                    </div>
                                                    {/* <div class="ribbon"></div> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </Grid>

                                    <Grid item sm={4} xs={12} md={4}>
                                        <Link to={'/lista-profesores'} style={{ textDecoration: 'none' }}>
                                            <div class="card-container">
                                                <div class="pricing-card card-03">
                                                    <div class="pricing">
                                                        <div class="price">
                                                            <img width={120} style={{ marginTop: "20px" }} src={profesor} />
                                                        </div>
                                                        <Grid mt={1}>
                                                            <span class="type" style={{ color: "#BB1F1F" }}>PROFESORES</span>
                                                        </Grid>

                                                        {/* <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider> */}
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="top-shape"></div>
                                                        <div class="card-content">
                                                            <button class="buy-btn">SIGUIENTE</button>
                                                        </div>
                                                    </div>
                                                    {/* <div class="ribbon"></div> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </Grid>
                                    <Grid item sm={4} xs={12} md={4}>
                                        <Link to={'/lista-externos'} style={{ textDecoration: 'none' }}>
                                            <div class="card-container">
                                                <div class="pricing-card card-03">
                                                    <div class="pricing">
                                                        <div class="price">
                                                            <img width={120} style={{ marginTop: "20px" }} src={profesor} />
                                                        </div>
                                                        <Grid mt={1}>
                                                            <span class="type" style={{ color: "#BB1F1F" }}>EXTERNOS</span>
                                                        </Grid>

                                                        {/* <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider> */}
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="top-shape"></div>
                                                        <div class="card-content">
                                                            <button class="buy-btn">SIGUIENTE</button>
                                                        </div>
                                                    </div>
                                                    {/* <div class="ribbon"></div> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </Grid>

                                    {/* <Grid item sm={4} xs={12} md={4}>
                                        <Link to={'/lista-alumnos'} style={{ textDecoration: 'none' }}>
                                            <div class="card-container">
                                                <div class="pricing-card card-03">
                                                    <div class="pricing">
                                                        <div class="price">
                                                            <img width={120} style={{ marginTop: "20px" }} src={membresia} />
                                                        </div>
                                                        <Grid mt={1}>
                                                            <span class="type" style={{ color: "#BB1F1F" }}>MEMBRESIAS</span>
                                                        </Grid>

                                                        <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="top-shape"></div>
                                                        <div class="card-content">
                                                            <button class="buy-btn">SIGUIENTE</button>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </Link>
                                    </Grid> */}
                                    <Grid item sm={4} xs={12} md={4}>
                                        <Link to={'/configuracion-horarios'} style={{ textDecoration: 'none' }}>
                                            <div class="card-container">
                                                <div class="pricing-card card-03">
                                                    <div class="pricing">
                                                        <div class="price">
                                                            <img width={130} style={{ marginTop: "10px" }} src={horaconfiguracion} />
                                                        </div>
                                                        <Grid mt={1}>
                                                            <span class="type" style={{ color: "#BB1F1F" }}>CONFIG. HORARIO</span>
                                                        </Grid>

                                                        {/* <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider> */}
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="top-shape"></div>
                                                        <div class="card-content">
                                                            <button class="buy-btn" >SIGUIENTE</button>
                                                        </div>
                                                    </div>
                                                    {/* <div class="ribbon"></div> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </Grid>
                                    <Grid item sm={4} xs={12} md={4}>
                                        <Link to={'/asignacion-horarios'} style={{ textDecoration: 'none' }}>
                                            <div class="card-container">
                                                <div class="pricing-card card-02">
                                                    <div class="pricing">
                                                        <div class="price">
                                                            <img width={120} style={{ marginTop: "20px" }} src={cronograma} />
                                                        </div>
                                                        <Grid mt={1}>
                                                            <span class="type" style={{ color: "#BB1F1F" }}>CRONOGRAMA</span>
                                                        </Grid>

                                                        {/* <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider> */}
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="top-shape"></div>
                                                        <div class="card-content">
                                                            <button class="buy-btn">SIGUIENTE</button>
                                                        </div>
                                                    </div>
                                                    {/* <div class="ribbon"></div> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </Grid>

                                    <Grid item sm={4} xs={12} md={4}>
                                        <Link to={'/transfer'} style={{ textDecoration: 'none' }}>
                                            <div class="card-container">
                                                <div class="pricing-card card-02">
                                                    <div class="pricing">
                                                        <div class="price">
                                                            <img width={120} style={{ marginTop: "20px" }} src={cronograma} />
                                                        </div>
                                                        <Grid mt={1}>
                                                            <span class="type" style={{ color: "#BB1F1F" }}>Reservas</span>
                                                        </Grid>

                                                        {/* <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider> */}
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="top-shape"></div>
                                                        <div class="card-content">
                                                            <button class="buy-btn">SIGUIENTE</button>
                                                        </div>
                                                    </div>
                                                    {/* <div class="ribbon"></div> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </Grid>

                                    <Grid item sm={4} xs={12} md={4}>
                                        <Link to={'/field'} style={{ textDecoration: 'none' }}>
                                            <div class="card-container">
                                                <div class="pricing-card card-02">
                                                    <div class="pricing">
                                                        <div class="price">
                                                            <img width={120} style={{ marginTop: "20px" }} src={redtenis} />
                                                        </div>
                                                        <Grid mt={1}>
                                                            <span class="type" style={{ color: "#BB1F1F" }}>Canchas</span>
                                                        </Grid>

                                                        {/* <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider> */}
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="top-shape"></div>
                                                        <div class="card-content">
                                                            <button class="buy-btn">SIGUIENTE</button>
                                                        </div>
                                                    </div>
                                                    {/* <div class="ribbon"></div> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            {/* }*/}
        </>
    )
}