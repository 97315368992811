import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Alert, Grid, Snackbar, TextField } from '@mui/material';
import logo from './../../assets/images/logo2_old.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import "./headermain.css";
import { Soap } from '@mui/icons-material';
import { Link, useHistory } from "react-router-dom";
import pelota from '../../assets/icons/pelotados.png';
import { session } from '../../session/constants';
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import axios from 'axios';
import pidecancha from '../../assets/images/logo3_old.png'

const settings = ['Mi Perfil', 'Configuración', 'Cerrar Sesión'];
const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      // border: "2px solid #000",
      borderRadius: "15px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 3),
      width: 450,
      margin: 5,
    },
  }));
  
const HeaderMain = (props) => {
    const tipoUsuario = localStorage.getItem("tipoUsuario");
    // const [ruta, setRuta]=React.useState(props.);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    
    const [openModal, setOpenModal] = React.useState(false);
    const [token, setToken] = React.useState(
        sessionStorage.getItem("Token")
      );
    
      const [snackBarConfig, setSnackBarConfig] = React.useState({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
     })
    
  const [externSelected, setExternSelected] = React.useState({
    oldPassword: '',
    newPassword: ''
  })

    let history = useHistory();
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        // history.push("/mi-perfil");
    };

    const handleCloseSession = () => {
        sessionStorage.setItem(session.SESSION_STATE, session.SESSION_OFF);
        sessionStorage.removeItem(session.SESSION_ID_USER);
        sessionStorage.removeItem(session.SESSION_TYPE_USER);
        sessionStorage.removeItem(session.SESSION_TOKEN);
        history.push("/login");
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setExternSelected((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }

      const handleSubmit = async (e) => {

        e.preventDefault();
        try {
          const response = await axios({
            method: "post",
            url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/update-password/3 `,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
                oldPassword: externSelected.oldPassword,
                newPassword: externSelected.newPassword
              }
          });

          if(response.data.data.original.message){
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'success',
                message: "Cambio de contraseña exitoso",
             }));
          }else{
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'error',
                message: "No se pudo cambiar su contraseña, credenciales inválidas",
             }));
          }
         

         setOpenModal(false)
         
        } catch (error) {
          console.log(error)
        }
      };
    const classes = useStyles();
    return (
        <AppBar position="static" className="headerstyle">
            <Container maxWidth="xl" sx={{bgcolor:'#191D3D'}}>
                <Toolbar disableGutters>


                    <Grid container alignItems={'center'}>
                        <Grid item xs={10} justifyContent={'center'} textAlign='center'>
                        <img src={pidecancha} width={300}></img>
                            {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                <Grid item justifyContent={'center'} >
                                <img src={pidecancha} style={{width:'200px'}}></img>
                                    <Typography textAlign={"center"} mb={0} variant="h4" className="titulo-principal"  >
                                        El Match Perfectoss
                                    </Typography>

                                    <Typography textAlign={"center"} mb={0} variant="subtitle1" className="titulo-secundario"  >
                                        <img src={pelota} width={15} mr={5}></img>  Un gran deporte para disfrutarlo en familia
                                    </Typography>
                                </Grid>
                            </Box> */}
                        </Grid>
                        <Grid item xs={1}>

                            <Grid container justifyContent={"end"}>
                                <Box sx={{ flexGrow: 0 }}>
                                    <Tooltip title="Mi perfil">
                                        <IconButton color='inherit' size='large' onClick={handleOpenUserMenu} >
                                            <AccountCircle fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {/* {settings.map((setting) => ( */}
                                        {/* {tipoUsuario == "administrador" ?
                                            ""
                                            :
                                            <MenuItem onClick={handleCloseUserMenu}>
                                                <Typography textAlign="center">Mi Perfil</Typography>
                                            </MenuItem>
                                        } */}

                                        <MenuItem onClick={handleCloseUserMenu}>
                                            <Typography textAlign="center" onClick={()=>{setOpenModal(true)}}>Cambiar contraseña</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={handleCloseSession}>
                                            <Typography textAlign="center">Cerrar Sesión</Typography>
                                        </MenuItem>
                                        {/* ))} */}
                                    </Menu>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>




                    <div>
                    <Snackbar
                        open={snackBarConfig.open}
                        autoHideDuration={snackBarConfig.autoHideDuration}
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                     >
                        <Alert
                           onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                           severity={snackBarConfig.severity}
                           variant="filled"
                        >
                           {snackBarConfig.message}
                        </Alert>
                     </Snackbar>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={()=>{setOpenModal(false)}}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 500,
        // }}

      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Typography
              textAlign="center"
              variant="h6"
              id="transition-modal-title"
              sx={{ color: "#000", fontWeight: "bold" }}
            >
              Cambiar Contraseña
            </Typography>
            <Grid container spacing={1} mt={2} justifyContent="center">

              <Grid item xs={12}>
                <TextField
                  type="text"
                  size="small"
                  name="oldPassword"
                  placeholder="Contraseña Actual*"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField

                  type="text"
                  size="small"
                  fullWidth
                  name="newPassword"
                  placeholder="Nueva Contraseña*"
                  // value={invitadoSeleccionado ? invitadoSeleccionado.apellidos : ''}
                  onChange={handleChange}
                />
              </Grid>


              <Grid container spacing={1} mt={2}>
                <Grid item xs={6}>
                  <Button fullWidth style={{ backgroundColor: '#BB1F1F', color:'#fff'}} onClick={() => setOpenModal(false)}>Cancelar</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={handleSubmit} fullWidth style={{ backgroundColor: '#191D3D', color:'#fff'}} type="submit">Cambiar Contraseña</Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>

                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default HeaderMain;
