import _ from 'lodash';
import config from '../../src/config/Config';
import { session } from '../session/constants';


// export const saveUser = (value) => {
//   if (window && window.localStorage) {
//     return window.localStorage.saveObject("user", value);
//   }

//   return null;
// }

// export const getSessionInfo = () => {
//   return localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : null;
// }

// export const getIdCompanySession = () => {
//   const sesion = getSessionInfo();
//   return sesion && sesion.user && sesion.user.idCompany || "";
// }

// export const getUserInitSession = () => {
//   const {user} = getSessionInfo();
//   const initial = user.firstName.charAt(0) + user.lastName.charAt(0);
//   return initial || "";
// }

export const defaultHeaders = () => { 
  const session = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : {};
  const token = session.token || "";
  const user = session.user || {};
  const idCompany = user.idCompany || null;

  return {
      headers: {
          ...config.headers, 
        //   Authorization: `token ${token}`
      },
      params: {
        // idCompany: idCompany
      }
  }
};

export const getSessionInfo = () => {
  return sessionStorage.getItem(session.SESSION_TOKEN) ? sessionStorage.getItem(session.SESSION_TOKEN) : null;
}

// export const getCustomUrl = (apiPrefix, url) => {
//   return apiPrefix + url;
// }

// export const customRolesName = [
//   { name: "Admin", value: "Administrador General" },
//   { name: "AdminEmpresa", value: "Administrador de Empresa"},
//   { name: "UserEmpresa", value: "Usuario"},
//   { name: "Guest", value: "Invitado" }
// ]

// export const getCustomRoleName = (oldRolName) => {
//   const customRol = customRolesName.find(rol => rol.name == oldRolName);
//   return  customRol ? customRol.value : oldRolName;
// }