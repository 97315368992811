import { createSlice } from '@reduxjs/toolkit'
// import { RootState } from '../store'

const initialState =
{
  status: 'fetching',
  reservationHorary: [],
  dayStatus: 'fetched',
  errorMessage: undefined
}

const reservation = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    onFetchReservationHorary (state, { payload }) {
      state.status        = 'fetched'
      state.reservationHorary   = payload
      state.errorMessage = undefined
    }
}
})

export const selectReservationState = (state) => state.reservation

export default reservation.reducer

export const {
  onFetchReservationHorary,
} = reservation.actions
