import moment from "moment";

export const formatHorary = (data) => {
  const arrayFilter = [];
  data.length > 0 && data.map((item) => {
    if (item.class_name && item.class_name == 'event-available') {
      let newObj = {
        ...item,
        title: ''
      }
      arrayFilter.push(newObj)
    } else {
      let newObj = {
        ...item,
        title: `${item?.hours[0]?.name_field} / ${item.name_user} `,
        className: item?.hours[0]?.className,
        class_name: item?.hours[0]?.class_name,
        date_booking: item?.date,
        start_hour: moment(item?.start).format("HH:mm:ss"),
        end_hour: moment(item?.end).format("HH:mm:ss")
      }
      arrayFilter.push(newObj)
    }
  })
  return arrayFilter
}

export const formatHoraryAgruped= (array) => {
  const horariosAgrupados = [];

  for (let i = 0; i < array.length; i++) {
    const currentInterval = array[i];
    const nextInterval = array[i + 1];

    const obj = {
      id: `${i + 1}`,
      idinitial: currentInterval?.id,
      start: currentInterval.start,
      end: currentInterval.end,
      idfield: currentInterval?.idfield,
      field: currentInterval?.field,
    };

    if (nextInterval?.idfield === currentInterval?.idfield && nextInterval?.start === currentInterval?.end) {
      const horaFinSiguiente = nextInterval.end;
      obj.end = horaFinSiguiente;
      obj.idfinal = nextInterval?.id;
      i++; // Avanzar un elemento extra, ya que este intervalo ya se agrupó con el siguiente
    }

    horariosAgrupados.push(obj);
  }

  return horariosAgrupados;
};

export const formatHoraryAgrupedExact = (array) => {
  const horariosAgrupados = [];

  for (let i = 0; i < array.length; i++) {
    const currentInterval = array[i];
    const nextInterval = array[i + 1];
    console.log('currentInterval', currentInterval)

    const obj = {
      id: `${i + 1}`,
      idinitial: currentInterval?.id,
      start: currentInterval.start,
      end: currentInterval.end,
      idfield: currentInterval?.idfield,
      field: currentInterval?.field,
    };

    // Function to check if two times have the same hour part (e.g., 9:00 === 10:00).
    const haveSameHour = (time1, time2) => {
      const hour1 = Number(time1.split(':')[0]);
      const hour2 = Number(time2.split(':')[0]);
      return hour1 === hour2;
    };

    if (
      nextInterval?.idfield === currentInterval?.idfield &&
      haveSameHour(currentInterval.start, currentInterval.end) &&
      haveSameHour(currentInterval.end, nextInterval?.start)
    ) {
      const horaFinSiguiente = nextInterval.end;
      obj.end = horaFinSiguiente;
      obj.idfinal = nextInterval?.id;
      i++; // Avanzar un elemento extra, ya que este intervalo ya se agrupó con el siguiente
    }

    horariosAgrupados.push(obj);
  }

  return horariosAgrupados;
};

// export const formatHoraryAgruped= (array) => {
//   const horariosAgrupados = [];
//   for (let i = 0; i < array.length; i++) {
//       const obj = {
//           id: `${i+1}`,
//           idinitial: array[i]?.id,
//           start: array[i].start,
//           end: array[i].end,
//           idfield: array[i]?.idfield,
//           field: array[i]?.field,
//       }
//       // Si el siguiente intervalo no existe o no es contiguo a este, mostrar el actual suelto
//       if (array[i]?.idfield === array[i + 1]?.idfield) {
//           if (i === array.length - 1 || array[i].end !== array[i + 1].start) {
//               // console.log(obj)
//               horariosAgrupados.push(obj);
//           } else {
//               const horaFinSiguiente = array[i + 1].end;
//               const intervaloAgrupado = `${array[i].start} - ${horaFinSiguiente}`;
//               const obj = {
//                 id: `${i+1}`,
//                   idinitial: array[i]?.id,
//                   idfinal: array[i + 1]?.id,
//                   idfield: array[i]?.idfield,
//                   field: array[i]?.field,
//                   start: array[i]?.start,
//                   end: horaFinSiguiente
//               }
//               // console.log('OBJTC',obj)
//               horariosAgrupados.push(obj);
//               i++; // Avanzar un elemento extra, ya que este intervalo ya se agrupó con el siguiente
//           }
//       } else {
//           const obj = {
//             id: `${i+1}`,
//               idinitial: array[i]?.id,
//               start: array[i].start,
//               end: array[i].end,
//               idfield: array[i]?.idfield,
//               field: array[i]?.field,
//           }
//           const obj2 = {
//             id: `${i+2}`,
//               idinitial: array[i + 1]?.id,
//               start: array[i + 1]?.start,
//               end: array[i + 1]?.end,
//               idfield: array[i + 1]?.idfield,
//               field: array[i +1]?.field,
//           }
//           horariosAgrupados.push(obj);
//           if ( obj2?.end !== array[i + 2]?.start) {
//               // console.log(obj)
//               horariosAgrupados.push(obj2);
//           } else {
//               const horaFinSiguiente = array[i + 2]?.end;
//               // const intervaloAgrupado = `${array[i].start} - ${horaFinSiguiente}`;
//               const obj = {
//                   id: `${i+2}`,
//                   idinitial: array[i+1]?.id,
//                   idfinal: array[i + 2]?.id,
//                   idfield: array[i+ 1]?.idfield,
//                   field: array[i + 1]?.field,
//                   start: array[i+1]?.start,
//                   end: horaFinSiguiente
//               }
//               obj.idinitial && horariosAgrupados.push(obj);
//               i++; // Avanzar un elemento extra, ya que este intervalo ya se agrupó con el siguiente
//           }
          
         
//           // horariosAgrupados.push(obj2);
//           // i++;
//       }
//   }
//   return horariosAgrupados;
// };