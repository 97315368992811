import {
    Alert,
    Button, Fade, FormControl, Grid, IconButton, InputAdornment, InputBase, InputLabel, MenuItem, Modal,
    Paper, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableFooter,
    TableHead, TablePagination, TableRow, TextField, Tooltip, Typography
} from "@mui/material"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../../utils/axios";
import { Link } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import { useHistory } from "react-router-dom";
import { useHoraryStore } from "../../../hooks/useHoraryStore";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import AssessmentIcon from '@mui/icons-material/Assessment';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 400,
    },
}));

export const GenerateRestriccion = () => {

    const [value, setValue] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [configs, setConfig] = useState([]);
    const [token, setToken] = useState(
        sessionStorage.getItem("Token")
    );
    let history = useHistory();
    const [dia, setDia] = useState([]);
    const [cancha, setCancha] = useState([]);
    const { status, selectField, setSelectedReport } = useHoraryStore()
    const [modalInsertar, setModalInsertar] = useState(false);
    const classes = useStyles();
    const [messageSuccess, setMessageSuccess] = useState({
        message: "",
        saverity: "success"
    });
    const [modalEliminar2, setModalEliminar2] = useState({
        status: false,
        id: null
    })
    const [openSuccess, setOpenSuccess] = useState(false);
    const [seletedHorario, setSelectedHorario] = useState({
        start: "",
        end: "",
        duration: 90,
        idday: null,
        idfield: '',
        idCompany: 1
    })
    const [error, setError] = useState(null);
    const getListaDia = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/day", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setDia(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getConfig()
        getListaDia([...dia]);
    }, [])

    const getConfig = async () => {
        axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/config/${selectField?.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setConfig(response.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const GenerateRestriccion = () => {
        setModalInsertar(true);
    }

    const ReportRestriccion = (data) => {
        history.push("/report-config-horary")
        setSelectedReport(data)
    }
    function handleChangeHours(e) {

        const changedFormValues = {
            ...seletedHorario,
            [e.target.name]: e.target.value,
        }
        console.log('changedFormValues', changedFormValues)
        changedFormValues.idday !== null && setError("");
        // changedFormValues.idfield.trim() != "" && setError("");
        changedFormValues.start.trim() != "" && setError("");
        changedFormValues.end.trim() != "" && setError("");
        setSelectedHorario(changedFormValues);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('seletedHorario', seletedHorario)

        if (!seletedHorario.start.trim()) {
            return setError("start");
        }
        if (!seletedHorario?.end.trim()) {
            return setError("end");
        }
        if (!seletedHorario.duration_horary>0) {
            return setError("duration_horary");
        }
        if (!seletedHorario.idday === null) {
            return setError("idday");
        }
        const payload = {
            start: seletedHorario?.start,
            end: seletedHorario?.end,
            duration_horary: 90,
            idfield: selectField?.id,
            idday: seletedHorario?.idday,
        }
        console.log('payload', payload)
        try {
            let errorMessage = null
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/config`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                )
                .then((response) => {
                    if (response.data.data.message) {
                        errorMessage = response.data.data.message
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
            // limpiaDatos();
            setMessageSuccess({
                message: errorMessage != null ? errorMessage : "Configuración generada con éxito",
                saverity: errorMessage != null ? 'error' : "success"
            })
            setOpenSuccess(true);
            setModalInsertar(false);
            setSelectedHorario({})
        } catch (error) {
            console.log('error')
        }
        getConfig();
    };
    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };

    const deleteConfig = async () => {
        await axios
            .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/config/${modalEliminar2?.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })

        setMessageSuccess(prev => ({ ...prev, message: "Registro eliminado correctamente!" }))
        setModalEliminar2(prev => ({ ...prev, status: false }))
        getConfig()
    }

    const prevDelete = (row) => {
        console.log('ROEEEE', row)
        setModalEliminar2(prev => ({ status: true, id: row?.id }))
    }

    return (
        <Grid sx={{ bgcolor: '#f4f4f4' }}>
            <Grid container className="containerInvite">
                <Grid container justifyContent={"center"} mt={2}>
                    <Grid item xs={12} md={10}>
                        <Grid xs={12} mb={2}>
                            <Link to={'/field'} className='link__css'>
                                <Typography variant='h6' gutterBottom className='link__css-back'>
                                    <ArrowBackIosIcon className='icon_back' /> Volver
                                </Typography>
                            </Link>
                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <Typography variant={"h5"} className="title__main">RESTRICCION DE HORAS {selectField?.name.toUpperCase()}</Typography>
                                    {/* <Typography variant={"subtitle2"} className="title__main">{}</Typography> */}
                                </Grid>
                                <Grid item md={6}>
                                    <Grid display="flex" justifyContent={"flex-end"}>
                                        {/* <IconButton sx={{ marginLeft: '5px' }}
                                            onClick={() => console.log()} >
                                            Agregar <AddCircleOutlineIcon/>
                                        </IconButton> */}
                                        <Button variant='contained' onClick={() => GenerateRestriccion()} >
                                            Nueva configuración
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <TableContainer component={Paper} >
                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                    <TableHead >
                                        <TableRow style={{ background: "#191D3D" }}>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Inicio</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Fin</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Duración para cada reserva</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Dia</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(
                                            rowsPerPage > 0
                                                ?
                                                configs
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                :
                                                configs
                                        )
                                            .map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {row?.start}
                                                    </TableCell>
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {row?.end}
                                                    </TableCell>
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {row?.duration_horary} minutos
                                                    </TableCell>
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {row?.name_day}
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                            {/* <Grid item>
                                                                <Tooltip title="Editar Restriccion de horarios 90' | 60'">
                                                                    <IconButton onClick={() => console.log('edit')}>
                                                                        <BorderColorIcon style={{ color: "#469489 " }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid> */}
                                                            <Grid item>
                                                                <Tooltip title="Eliminar Restriccion de horarios 90' | 60'">
                                                                    <IconButton onClick={() => prevDelete(row)}>
                                                                        <DeleteIcon style={{ color: "red " }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title="Reporte Restriccion de horarios 90' | 60'">
                                                                    <IconButton onClick={() => ReportRestriccion(row)}>
                                                                        <AssessmentIcon style={{ color: "#469489 " }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEliminar2.status}
                onClose={() => { setModalEliminar2(prev => ({ ...prev, status: false })) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalEliminar2.status}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >   Estás Seguro que deseas eliminar el registro
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => { setModalEliminar2(prev => ({ ...prev, status: false })) }}>NO</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => deleteConfig()}>Si</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalInsertar}
                onClose={() => { setModalInsertar(false) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalInsertar}>
                    <div className={classes.paper} >
                        <form onSubmit={handleSubmit}>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                AGREGAR NUEVA CONFIGURACION PARA {selectField?.name.toUpperCase()}
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">
                                <Grid item xs={12} md={12}>
                                    <Typography gutterBottom variant="subtitle2">Dia</Typography>

                                    <FormControl fullWidth>
                                        <Select
                                            error={error === "idday" ? true : false}
                                            helperText={error === "idday" ? "El dia es un campo requerido" : ""}
                                            id="demo-simple-select"
                                            placeholder="Seleccione el dia"
                                            onChange={handleChangeHours}
                                            value={seletedHorario.idday}
                                            size="small"
                                            name="idday"
                                        >
                                            {dia.map((item) => (
                                                <MenuItem
                                                    sx={{
                                                        display: "block !important",
                                                        padding: "5px 10px !important",
                                                    }}
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography gutterBottom variant="subtitle2"> Inicio</Typography>
                                    <TextField
                                        error={error === "start" ? true : false}
                                        helperText={error === "start" ? "La hora de inicio es un campo requerido" : ""}
                                        fullWidth
                                        type="time"
                                        size="small"
                                        name="start"
                                        value={seletedHorario.start}
                                        onChange={handleChangeHours}
                                        variant="outlined" />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography gutterBottom variant="subtitle2"> Fin</Typography>
                                    <TextField
                                        error={error === "end" ? true : false}
                                        helperText={error === "end" ? "La hora de fin es un campo requerido" : ""}
                                        fullWidth
                                        type="time"
                                        size="small"
                                        variant="outlined"
                                        name="end"
                                        value={seletedHorario.end}
                                        onChange={handleChangeHours}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography gutterBottom variant="subtitle2">Duración horario</Typography>
                                    <TextField
                                        // error={error === "duration" ? true : false}
                                        // helperText={error === "duration" ? "La duración alquiler es un campo requerido" : ""}
                                        // type={"number"}
                                        fullWidth
                                        size="small"
                                        id="outlined-basic"
                                        variant="outlined"
                                        disabled
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">minutos</InputAdornment>,
                                        }}
                                        name="duration"
                                        value={seletedHorario.duration}
                                    // onChange={handleChangeHours}
                                    />
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalInsertar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" type="submit">Agregar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleCloseSuccess}>
                    <Alert onClose={handleCloseSuccess} severity={messageSuccess.saverity} sx={{ width: '100%' }}>
                        {messageSuccess.message}
                    </Alert>
                </Snackbar>
            </Stack>
        </Grid>
    )
}