import React from "react";
import "./AssignmentHour.css"
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, Typography, IconButton, Box, TableHead, InputLabel, MenuItem, FormControl, Select, TextField, Button } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from "react";
import axios from "../../utils/axios"

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 350,
    },
}));


export const AssignmentHour = () => {
    const [day, setDay] = React.useState('');
    const [turn, setTurn] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [varDays, setVarDays] = React.useState('');
    const [modalAsignarColor, setModalAsignarColor] = React.useState(false);

    const [horarioCancha1, setHorarioCancha1] = React.useState([]);
    const [horarioCancha2, setHorarioCancha2] = React.useState([]);
    const [horarioCancha3, setHorarioCancha3] = React.useState([]);
    const [dia, setDia] = React.useState([]);
    const [paisState, setPaisState] = React.useState("");
    const [diaSeleccionado, setDiaSeleccionado] = React.useState({
        id: '',
        name: "",
        idcompany: 1,
        status: "",
    });
    const [competitor, setCompetitor] = React.useState([])
    const [competitorSelected, setCompetitorSelected] = React.useState([{
        id: '',
        color: '',
        name: ''
    }])
    const [snackBarConfig, setSnackBarConfig] = React.useState({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })

    const [horarioSelected, setHorarioSeleted] = React.useState([
        {
            id: "",
            start: "",
            end: "",
            duration: '',
            maintenance: '',
            idday: '',
            idfield: '',
            idcompetitor: "",
            idCompany: 1
        }
    ])
    const [token, setToken] = React.useState(
        sessionStorage.getItem("Token")
    );

    const editarColor = async () => {
        if (!competitorSelected?.id) {
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'error',
                message: "Error en la operación",
            }));
        }
        console.log('horario id', horarioSelected.id, 'idCompetitor', competitorSelected.id);
        const formFile = new FormData();
        try {
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/update-competitor-massive`,
                    {
                        idsHorary: horarioSelected?.ids,
                        idCompetitor: competitorSelected?.id
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                )
                .catch((error) => {
                    console.log(error)
                })
            getDia1([...horarioCancha1]);
            getDia2([...horarioCancha2]);
            getDia3([...horarioCancha3]);
            setModalAsignarColor(false)

        } catch (error) {
            console.log('error')
        }
    }

    const handleChangeP = (event) => {
        setPaisState(event.target.value);
    };

    const getListaCompetitor = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/competitor", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setCompetitor(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getListaDia = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/day", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setDia(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getDia1 = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/list-byDay/1", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setHorarioCancha1(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getDia2 = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/list-byDay/2", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setHorarioCancha2(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const getDia3 = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/list-byDay/3", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setHorarioCancha3(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const seleccionarHorario = (elemento) => {
        console.log('elemento', elemento)
        setHorarioSeleted(elemento)
        setModalAsignarColor(true)
    }
    const seleccionarCompetitor = (elemento) => {
        setCompetitorSelected(elemento)
        console.log('elementoCompetitor', elemento)
    }

    useEffect(() => {
        getDia1([...horarioCancha1]);
        getDia2([...horarioCancha2]);
        getDia3([...horarioCancha3]);
        getListaDia([...dia]);
        getListaCompetitor([...competitor])
    }, [])

    const renderList = horarioCancha1.map((item) => {
        const {
            id,
            field,
            hours,
        } = item;
        return (

            <Grid display="flex">
                <Grid mt={1} mr={1}>
                    <TableContainer key={id} component={Paper} >
                        <Typography textAlign={"center"} sx={{ fontWeight: "bolder" }} variant="subtitle1">{field}</Typography>
                        <Table >
                            <TableBody>
                                {(hours
                                ).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell  >
                                            {row.start}
                                        </TableCell>
                                        <TableCell width={100}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} >
                                                    <Button variant="outlined" style={{ background: row.color, padding: "10px" }} onClick={() => { seleccionarHorario(row) }} fullWidth>{item.color}</Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell >
                                            {row.end}
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                            <TableFooter>

                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

        )
    })

    const renderList2 = horarioCancha2.map((item) => {
        const {
            id,
            field,
            hours,
        } = item;
        return (

            <Grid display="flex">
                <Grid mt={1} mr={1}>
                    <TableContainer key={id} component={Paper} >
                        <Typography textAlign={"center"} sx={{ fontWeight: "bolder" }} variant="subtitle1">{field}</Typography>
                        <Table >
                            <TableBody>
                                {(hours
                                ).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell  >
                                            {row.start}
                                        </TableCell>
                                        <TableCell width={100}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} >
                                                    <Button variant="outlined" style={{ background: row.color, padding: "10px" }} onClick={() => { seleccionarHorario(row) }} fullWidth>{item.color}</Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell >
                                            {row.end}
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                            <TableFooter>

                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

        )
    })


    const renderList3 = horarioCancha3.map((item) => {
        const {
            id,
            field,
            hours,
        } = item;
        return (

            <Grid display="flex">
                <Grid mt={1} mr={1}>
                    <TableContainer key={id} component={Paper} >
                        <Typography textAlign={"center"} sx={{ fontWeight: "bolder" }} variant="subtitle1">{field}</Typography>
                        <Table >
                            <TableBody>
                                {(hours
                                ).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell  >
                                            {row.start}
                                        </TableCell>
                                        <TableCell width={100}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} >
                                                    <Button variant="outlined" style={{ background: row.color, padding: "10px" }} onClick={() => { seleccionarHorario(row) }} fullWidth>{item.color}</Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell >
                                            {row.end}
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                            <TableFooter>

                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

        )
    })

    const classes = useStyles();

    return (
        <Grid sx={{ bgcolor: '#f4f4f4' }}>
            <Grid container className="containerInvite">
                <Grid container justifyContent={"center"} mt={2}>
                    <Grid item xs={12} md={11}>
                        <Grid xs={12} mb={2}>
                            <Link to={'/modulos-administrador'} className='link__css'>
                                <Typography variant='h6' gutterBottom className='link__css-back'>
                                    <ArrowBackIosIcon className='icon_back' /> Volver
                                </Typography>
                            </Link>
                            <Grid item xs={12} md={12}>
                                <Typography variant={"h5"} className="title__main">ASIGNACION DE HORARIOS</Typography>
                            </Grid>

                        </Grid>
                        <Grid container spacing={1} p={2} mt={2} style={{ background: "rgb(255,255,255,0.7)", borderRadius: "20px" }} className="containerModulos">
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <FormControl fullWidth>
                                                <InputLabel size="small" id="demo-simple-select-label">
                                                    Seleccione el día
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    placeholder="Seleccione el día"
                                                    label="Seleccione el día"
                                                    onChange={handleChangeP}
                                                    size="small"
                                                >
                                                    {dia.map((item) => (
                                                        <MenuItem
                                                            sx={{
                                                                display: "block !important",
                                                                padding: "5px 10px !important",
                                                            }}
                                                            key={item.id}
                                                            value={item.name}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid mt={2} container justifyContent={"center"} >
                                {paisState == "Lunes a Jueves" ? renderList : paisState == "Viernes" ? renderList2 : paisState == "Sabado, domingo y festivo" ? renderList3 : renderList}
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalAsignarColor}
                onClose={() => setModalAsignarColor(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalAsignarColor}>
                    <div className={classes.paper} >
                        <form >
                            <Grid container>
                                <Grid item xs={11}>
                                    <Typography
                                        variant="subtitle1"
                                        id="transition-modal-title"
                                        sx={{ color: "#000", fontWeight: "bold" }}
                                    >
                                        ASIGNAR TIPO DE USUARIO
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <CloseIcon onClick={() => setModalAsignarColor(false)} />
                                </Grid>
                            </Grid>
                            <Grid spacing={1} container justifyContent={"center"} mt={1} mb={1}>
                                <Grid item xs={8}>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="cancha"
                                        // onChange={handleChange}
                                        >
                                            {competitor.map(item => (
                                                <FormControlLabel value={item.id} control={<Radio sx={{ color: item.color }} onClick={() => { seleccionarCompetitor(item) }} />} label={<Grid container>
                                                    <Typography gutterBottom variant="subtitle1">{item.name}</Typography>
                                                </Grid>} />
                                            ))}

                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>

                                    <Grid item xs={12}>
                                        <Button fullWidth className="card_reservation--add" onClick={() => editarColor()}>DEFINIR</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </Grid>
    )

}