import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Snackbar, Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./Register.css"
import EventIcon from '@mui/icons-material/Event';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import PinDropIcon from '@mui/icons-material/PinDrop';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import axios from "axios";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from "react-router-dom";
const theme = createTheme({

});

const initialFormValues = {
  name: '',
  surname: '',
  rut: '',
  birthday: '',
  email: '',
}
export const Register = () => {
  const [error, setError] = React.useState(null);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    severity: 'success',
    message: 'Error',
    autoHideDuration: 5000,
  })
  let history = useHistory();
  const changeViewBack = () => {

    history.push('/modulos', { from: 'horario' })
  }

  function ValidateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  }
  const limpiaDatos = () => {
    setFormValues(initialFormValues);
  };

  //   const changedFormValues = {
  //     ...formValues,
  //     name: '',
  //     surname: '',
  //     rut: '',
  //     birthday: '',
  //     email: ''
  //   }
  //   // changedFormValues.name.trim() !== "" && setError("");
  //   // changedFormValues.surname.trim() !== "" && setError("");
  //   // changedFormValues.rut.trim() !== "" && setError("");
  //   // changedFormValues.birthday.trim() != "" && setError("");
  //   // changedFormValues.email.trim() !== "" && setError("");
  //   setFormValues(changedFormValues);
  // };
  const validaterut = async (rut) => {
    // store the states in the form data
    // e.preventDefault();
    try {
      // make axios post request
      const response = await axios
        .get(`https://api.libreapi.cl/rut/validate?rut=${rut}`)
        .catch((err) => {
          console.log("Err: ", err);
        });
      if (response.data.data.valid == true) {
        return true;
      }

    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formValues.name.length < 1 || formValues.name.length > 50) {
      return setError("name");
    }
    if (formValues.surname.length < 1 || formValues.surname.length > 50) {
      return setError("surname");
    }
    if (!(/^(\d{1,2}(?:[\.]?\d{3}){2}-[\dkK])$/).test(formValues.rut)) {
      return setError("rut");
    }
    const respuesta_rut = await validaterut(formValues.rut);
    console.log('esperando rut: ', respuesta_rut);
    if (!respuesta_rut) {
      return setError("rut");
    }
    if (!formValues.birthday.trim()) {
      return setError("birthday");
    }
    if (ValidateEmail(formValues.email) === false) {
      return setError("email");
    }
    console.log('Si valida');
    const response = await axios({
      method: "post",
      url: "https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/register",
      // headers: {
      //     Authorization: `Bearer ${token}`,
      // },
      data: {
        name: formValues.name,
        surname: formValues.surname,
        rut: formValues.rut,
        birthday: formValues.birthday,
        email: formValues.email,
        idTypeUSer: 7,
        idCompany: 1,
        rutcompany: '33333333-3',
      }

    });
    // console.log(response.data.data.original.user);
    console.log("soy otro tipo de response: ",response.data);
    if (response.data.data?.original?.user) {
     
      setSnackBarConfig(prev => ({
        ...prev,
        open: true,
        severity: 'error',
        message: `${response.data?.data?.original.user}`,
      }))
    
      // history.push("/");
    }else {
      
        setSnackBarConfig(prev => ({
          ...prev,
          open: true,
          severity: 'success',
          message: 'Registro exitoso'
        }))
    limpiaDatos()
    }

  };


  const handleInputChange = (e) => {
    setError('');
    if (e.target.name == 'rut') {
      var foo = e.target.value
      if (foo.length > 0 && foo.length < 11) {
        setFormValues(prev => ({ ...prev, rut: foo, textError: '' }))
      } else if (foo.length == 0) {
        setFormValues(prev => ({ ...prev, rut: "", textError: '' }))
      }

    } else {
      const changedFormValues = {
        ...formValues,
        [e.target.name]: e.target.value
      }
      setFormValues(changedFormValues);
    }

    // changedFormValues.name.trim() !== "" && setError("");
    // changedFormValues.surname.trim() !== "" && setError("");
    // changedFormValues.rut.trim() !== "" && setError("");
    // changedFormValues.birthday.trim() != "" && setError("");
    // changedFormValues.email.trim() !== "" && setError("");

  }


  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }} justifyContent="center" className='bkRegister'>
        <Snackbar
          open={snackBarConfig.open}
          autoHideDuration={snackBarConfig.autoHideDuration}
          onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            severity={snackBarConfig.severity}
            variant="filled"
          >
            {snackBarConfig.message}
          </Alert>
        </Snackbar>

        <Grid item mt={2} mb={2} xs={12} sm={12} md={5} component={Paper} sx={{ borderRadius: "15px" }}>
          <Box
            sx={{
              mx: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >

            <Typography p={1} component="h1" variant="h5" fontWeight={"bolder"}>
              REGISTRARME COMO EXTERNO
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} mt={3}>
              <TextField
                // margin="normal"
                value={formValues?.name}
                sx={{ mb: 2 }}
                required
                fullWidth
                id="nombre"
                size='small'
                label="Nombre"
                name="name"
                onChange={handleInputChange}
                error={error === "name" ? true : false}
                helperText={error === "name" ? "Los nombres son requerido (ingrese entre 1 y 50 caracteres)" : ""}
                // autoComplete="nombre"
                autoFocus
              />
              <TextField
                // margin="normal"
                value={formValues?.surname}
                sx={{ mb: 2 }}
                required
                fullWidth
                size='small'
                id="apellido"
                label="Apellido"
                name="surname"
                onChange={handleInputChange}
                error={error === "surname" ? true : false}
                helperText={error === "surname" ? "Los apellidos son requerido (ingrese entre 1 y 50 caracteres)" : ""}
              // autoComplete="nombre"

              />
              <Grid container direction={'row'} alignItems='center' >
                <Grid item xs={12}>
                  <TextField
                    // margin="normal"
                    value={formValues?.rut}
                    sx={{ mb: 2 }}
                    required
                    fullWidth
                    id="rut"
                    size='small'
                    label="Rut"
                    name="rut"
                    autoComplete="rut"
                    onChange={handleInputChange}
                    error={error === "rut" ? true : false}
                    helperText={error === "rut" ? "Ingrese un RUT válido" : ""}

                  />
                </Grid>
              
               
                <Grid item xs={12} ml={{xs:0, sm:0, md:0, lg:1}} sm={12} md={12} lg={4}>
                  <span>Fecha Nacimiento</span>
                  <TextField
                    // margin="normal"
                    value={formValues?.birthday}
                    sx={{ mb: 2 }}
                    required
                    fullWidth
                    name="birthday"
                    size='small'
                    // label="Fecha Nacimiento"
                    type="date"
                    id="birthday"
                    error={error === "birthday" ? true : false}
                    helperText={error === "birthday" ? "La fecha de nacimiento es un campo requerido" : ""}
                    onChange={handleInputChange}
                    autoComplete="current-password"
                  />

                </Grid>
              </Grid>


              <TextField
                value={formValues?.email}
                // margin="normal"
                sx={{ mb: 2 }}
                required
                fullWidth
                name="email"
                size='small'
                label="Email"
                id="email"
                onChange={handleInputChange}
                error={error === "email" ? true : false}
                helperText={error === "email" ? "Ingrese un email válido" : ""}
                autoComplete="current-password"
              />

              {/* <FormControlLabel
                // value="top"
                control={<Checkbox />}
                label="Acepto los términos y condiciones."
                labelPlacement="end"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className='btn-login'
                sx={{ mt: 3, mb: 2 }}
              >
                INSCRIBIR
              </Button>
              <Typography onClick={() => changeViewBack()} variant='h6' gutterBottom className='link__css-back'>
                <ArrowBackIosIcon className='icon_back' /> Volver
              </Typography>
              <Grid xs={12} container>
                {/* <Grid item textAlign={'center'} xs>
                Si tienes problemas registrándote, contáctanos a {' '} 
                  <Link to={'#'} style={{ textDecoration: 'none' }} >
                   contactoaleatorio@easycancha.com
                  </Link>
                </Grid> */}

              </Grid>
            </Box>
          </Box>
        </Grid>

      </Grid>
    </ThemeProvider>
  )
}
