import { Grid, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import "./Schedule.css";


export const TablaHorasCanchas = ({data, handleChangeSelect, selectedIds }) => {

  const totalCanchas = data.length
  const [maxHours, setMaxHours] = useState(0)

  useEffect(() => {
    let maxHours = 0;
    let headWithMaxHours = '';
    for (const item of data) {
      if (item.hours.length > maxHours) {
        maxHours = item.hours.length;
        headWithMaxHours = item.hours.length.toString();
      }
    }
    setMaxHours(parseInt(headWithMaxHours))
  }, [])
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#191D3D',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));



  return (
    <div >
      <Table>
        <TableHead>
          <TableRow>
            {data.map(item => (
              <StyledTableCell key={item.idfield} align='center'>{item.field}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Array.from({ length: maxHours}).map((_item, index) => {
              return (<TableRow>
                {
                  Array.from({ length: totalCanchas }).map((cancha, indexCancha) => {
                    const startHour = data[indexCancha]?.hours[index]?.start || ''
                    const endHour = data[indexCancha]?.hours[index]?.end || ''
                   if(startHour && endHour)
                   {
                    return (
                      <TableCell >
                        <Grid item container
                          onClick={() => handleChangeSelect(data[indexCancha]?.hours[index])}
                          className={selectedIds.includes(data[indexCancha]?.hours[index]?.id) ? "horarioOcupado" : "horaiosPair"}
                        >
                          <Grid xs={12} display="flex" justifyContent={"space-between"} >
                            <Grid>
                              <Typography textAlign={"center"} color="#9b9b9b" gutterBottom variant="subtitle2">INICIO</Typography>
                              <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">{startHour}</Typography>
                            </Grid>
                            <Grid mt={2}>
                              <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">-</Typography>
                            </Grid>
                            <Grid>
                              <Typography textAlign={"center"} color="#9b9b9b" gutterBottom variant="subtitle2">FIN</Typography>
                              <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">{endHour}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        </TableCell>
                    )
                   
                   }
                   return (
                    <TableCell >
                       
                        </TableCell>
                   )
                  })
                }
              </TableRow>)
            })
          }
        </TableBody>
      </Table>
    </div>
  );
}