import React from "react"
import { Button, Card, CardContent, CardMedia, Divider, Fade, Grid, IconButton, Modal, TextField, Typography } from "@mui/material"
import "./ModuleSeleccion.css"
import deporte from '../../assets/images/deporte.png';
import calendario from '../../assets/icons/calendario.png';
import favorito from '../../assets/images/favorito.png';
import reservas from '../../assets/images/reservas.png';
import listareserva from '../../assets/icons/listareserva.png';
import redtenis from '../../assets/icons/red.png';
import invitados from '../../assets/images/invitados.png';
import listainvitado from '../../assets/icons/listainvitado1.png';
import canchas from '../../assets/images/cancha.png';
import cuentas from '../../assets/images/cuenta.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from "react-router-dom";
import pelota from '../../assets/icons/pelotados.png';
import 'animate.css';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useAuthStore } from "../../hooks/useAuthStore";


const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 450,
        margin: 5,
    },
}));


export const ModuleSeleccion = (props) => {

    const classes = useStyles();
    const history = useHistory();
    const {  user  } = useAuthStore()

    // const tipoUsuario= localStorage.getItem("tipoUsuario");
    const [openModal, setOpenModal] = React.useState(false);
    const [externSelected, setExternSelected] = React.useState({
        password: ''
    })
    const [snackBarConfig, setSnackBarConfig] = React.useState({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })
    var Token = localStorage.getItem('token_user')
    var DataUser = JSON.parse(localStorage.getItem('data_user'))
    var passwordEncrypt = localStorage.getItem('passwordEncrypt');
    var type_login = localStorage.getItem('type_login');
    const changeView = (view) => {
        switch (view) {
            case 'horario':
                history.push('/seleccionar-invitados-horario', { from: 'horario', idTypeField:1 })
                break;

            default:
                history.push('/seleccionar-invitados-horario', { from: 'cancha' ,idTypeField:1 });
                break;
        }
    }


    const handleChange = e => {
        const { name, value } = e.target;
        setExternSelected((prevState) => ({
            ...prevState,
            [name]: value
        }));
        //    console.log( btoa(externSelected.newPassword))
    }


    const handleSubmit = async (e) => {

        e.preventDefault();
        try {
            const response = await axios({
                method: "post",
                url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/update-password/${DataUser.id_user} `,
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                data: {
                    passwordEncrypted: passwordEncrypt,
                    newPassword: externSelected.password
                }
            });

            if (response.data.data.original.message) {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'success',
                    message: "Cambio de contraseña exitoso",
                }));
            } else {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'error',
                    message: "No se pudo cambiar su contraseña, credenciales inválidas",
                }));
            }


            setOpenModal(false)
            localStorage.setItem('type_login', '')
            localStorage.setItem('data_user', JSON.stringify({...DataUser, status_user: '1'}));

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (DataUser?.status_user == '0') {
            setOpenModal(true)
        }

    }, [])

    return (
        <>
            {/* {(tipoUsuario=="socio" || tipoUsuario=="familiar"||tipoUsuario=="alumno"||tipoUsuario=="profesor" ||tipoUsuario=="externo")&&   */}
            <Grid container justifyContent={"center"} className="backModules" p={3} sx={{bgcolor:'#f4f4f4'}}>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openModal}
                    // onClose={() => { setOpenModal(false) }}
                    closeAfterTransition
                // BackdropComponent={Backdrop}
                // BackdropProps={{
                //   timeout: 500,
                // }}

                >
                    <Fade in={openModal}>
                        <div className={classes.paper}>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                Crear su contraseña
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">

                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        size="small"
                                        name="password"
                                        placeholder="Contraseña*"
                                        fullWidth
                                        value={externSelected.password}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        {/* <Button fullWidth className="btn-cancelar" onClick={() => setOpenModal(false)}>Cancelar</Button> */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button onClick={handleSubmit} fullWidth className="btn-aceptar" type="submit">Guardar Contraseña</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Fade>
                </Modal>
                <Grid item xs={12} md={12} mt={0}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} md={8} mt={1}>

                            <Grid container mt={8} spacing={1} className="containerModulos">

                                <Grid item sm={4} xs={12} onClick={() => { changeView('horario') }}>
                                    {/* <Link to={'/deportes-horario'} style={{ textDecoration: 'none' }}> */}
                                    <div class="card-container">
                                        <div class="pricing-card card-03">
                                            <div class="pricing">
                                                <div class="price">
                                                    <img width={60} style={{ marginTop: "35px" }} src={calendario} />
                                                </div>
                                                <Grid mt={3}>
                                                    <span class="type" style={{ color: "#BB1F1F" }}>Reservar por </span>
                                                    <br />
                                                    <span class="type" style={{ color: "#BB1F1F" }}>HORARIO</span>
                                                </Grid>

                                                {/* <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider> */}
                                            </div>
                                            <div class="card-body">
                                                <div class="top-shape"></div>
                                                <div class="card-content">
                                                    <ul>
                                                        <Typography style={{ color: "#b3b3b3" }}>Reserve su cancha</Typography>
                                                        <Typography style={{ color: "#b3b3b3" }}>escogiendo un horario.</Typography>
                                                    </ul>
                                                    <button class="buy-btn">SIGUIENTE</button>
                                                </div>
                                            </div>
                                            {/* <div class="ribbon"></div> */}
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </Grid>

                                <Grid item sm={4} xs={12} onClick={() => { changeView('cancha') }}>
                                    {/* <Link to={'/deportes-cancha'} style={{ textDecoration: 'none' }}> */}
                                    <div class="card-container">
                                        <div class="pricing-card card-03">
                                            <div class="pricing">
                                                <div class="price">
                                                    <img width={60} style={{ marginTop: "35px" }} src={redtenis} />
                                                </div>
                                                <Grid mt={3}>
                                                    <span class="type" style={{ color: "#BB1F1F" }}>Reservar por </span>
                                                    <br />
                                                    <span class="type" style={{ color: "#BB1F1F" }}>CANCHA</span>
                                                </Grid>

                                                {/* <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider> */}
                                            </div>
                                            <div class="card-body">
                                                <div class="top-shape"></div>
                                                <div class="card-content">
                                                    <ul>
                                                        <Typography style={{ color: "#b3b3b3" }}>Reserve su cancha</Typography>
                                                        <Typography style={{ color: "#b3b3b3" }}>escogiendo una cancha.</Typography>
                                                    </ul>
                                                    <button class="buy-btn">SIGUIENTE</button>
                                                </div>
                                            </div>
                                            {/* <div class="ribbon"></div> */}
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </Grid>

                                <Grid item sm={4} xs={12} >
                                    <Link to={'/mis-reservas'} style={{ textDecoration: 'none' }}>
                                        <div class="card-container">
                                            <div class="pricing-card card-02">
                                                <div class="pricing">
                                                    <div class="price">
                                                        <img width={60} style={{ marginTop: "35px" }} src={listareserva} />
                                                    </div>
                                                    <Grid mt={3}>
                                                        <span class="type" style={{ color: "#BB1F1F" }}>MIS </span>
                                                        <br />
                                                        <span class="type" style={{ color: "#BB1F1F" }}> RESERVAS </span>
                                                    </Grid>

                                                    {/* <Divider style={{ fontWeight: "bolder", color: "rgb(255, 191, 0)" }}>___________________</Divider> */}
                                                </div>
                                                <div class="card-body">
                                                    <div class="top-shape"></div>
                                                    <div class="card-content">
                                                        <ul>
                                                            <Typography style={{ color: "#b3b3b3" }}>Visualize todas</Typography>
                                                            <Typography style={{ color: "#b3b3b3" }}>sus reservas generadas.</Typography>
                                                        </ul>
                                                        <button class="buy-btn">SIGUIENTE</button>
                                                    </div>
                                                </div>
                                                {/* <div class="ribbon"></div> */}
                                            </div>
                                        </div>
                                    </Link>
                                </Grid>

                                {/* <Grid item sm={4} xs={12} md={3}>
                                    <div class="card-container">
                                        <div class="pricing-card card-03">
                                            <div class="pricing">
                                                <div class="price">
                                                    <img width={60} style={{ marginTop: "35px" }} src={listainvitado} />
                                                </div>
                                                <Grid mt={3}>
                                                    <span class="type" style={{ color: "#BB1F1F" }}>LISTAS DE </span>
                                                    <br />
                                                    <span class="type" style={{ color: "#BB1F1F" }}> INVITACIONES </span>
                                                </Grid>

                                            </div>
                                            <div class="card-body">
                                                <div class="top-shape"></div>
                                                <div class="card-content">
                                                    <ul>
                                                        <Typography style={{ color: "#b3b3b3" }}>Visualize sus </Typography>
                                                        <Typography style={{ color: "#b3b3b3" }}>invitaciones disponibles.</Typography>
                                                    </ul>
                                                    <button class="buy-btn">SIGUIENTE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid> */}

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* } */}
        </>
    )
}