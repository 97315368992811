import { useEffect, useState } from "react";
import { Button, FormControl, Grid, TextField, Autocomplete, CircularProgress } from "@mui/material"
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
// import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import axios from "../../utils/axios";
import { ModalInfo } from "./ModalInfo";
import CircleIcon from '@mui/icons-material/Circle';
import SearchIcon from '@mui/icons-material/Search'
import './style.css'
import { formatHorary } from "../../toolbox/helpers/fullcalendar.helper";
import { useReserveStore } from "../../hooks/useReserveStore";

export const CustomFullCalendar = () => {

    var Token = localStorage.getItem('token_user');
    const data_user = localStorage.getItem('data_user');
    const userObject = JSON.parse(data_user);
    const idcompany = userObject.id_company;

    const [loading, setLoading] = useState(null);
    const [loadingField, setLoadingField] = useState(false);
    const [loadingCompetitor, setLoadingCompetitor] = useState(false);
    
    const [bookings, setBookings] = useState([]);
    const [showModalReserve, setShowModalReserve] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState('');
    const [fields, setFields] = useState([]);
    const [selectedField, setSelectedField] = useState('');
    const [competitor, setCompetitor] = useState([]);
    const [competitorSelected, setCompetitorSelected] = useState('');
    const [dateParams, setDateParams] = useState(moment().format('YYYY-MM-DD'));

    const current_date = moment().format('YYYY-MM-DD');
    const today = new Date().toISOString().split('T')[0];
    const {
        reservationHorary,
        getReservationHorary,
      } = useReserveStore()

    useEffect(() => {
        getListaCompetitor();
        getField();
    }, [])

    // useEffect(() => {
    //     const calendarFormated = formatHorary(reservationHorary)
    //     console.log('calendarFormated', calendarFormated)
    //     setCalendarHorary(calendarFormated)
    //   }, [reservationHorary])

    useEffect(() => {
        getReservationHorary([])
        if(dateParams && selectedField && competitorSelected) reservationsActive(dateParams, competitorSelected.id, selectedField.id)
    }, [selectedField, competitorSelected, dateParams])

    // useEffect(() => {
    //     if(dateParams && selectedField && competitorSelected) reservationsActive(dateParams, competitorSelected.id, selectedField.id)
    // }, [dateParams])

    const getListaCompetitor = async (e) => {
        setLoadingCompetitor(true);
        await axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/competitor", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            })
            .then((res) => {
                const dataapi = res.data.data || []
                setCompetitor(dataapi)
                setCompetitorSelected(dataapi[2])
                setLoadingCompetitor(false)
            })
            .catch((error) => {
                setLoadingCompetitor(false)
                console.error(error);
            });
    };

    const getField = async (e) => {
        setLoadingField(true);
        await axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/list", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            })
            .then((res) => {
                const dataapi = res.data.data || []
                setFields(dataapi)
                setSelectedField(dataapi[0])
                setLoadingField(false);
            })
            .catch((error) => {
                console.error(error);
                setLoadingField(false);
            });
    };

    const reservationsActive = async (date, idCompetitor, idfield) => {
        // getReservationHorary(idCompetitor, date, idfield)
        await axios
        .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/report-calendar?orderByDate=DESC&status=[0,1]&date=${date}&idCompetitor=${idCompetitor?idCompetitor:''}&idfield=${idfield?idfield:''}`, {
            headers: {
                Authorization: `Bearer ${Token}`,
            },
        })
        .then((res) => {
            if (res?.data?.data?.original?.error) {
                getReservationHorary([])
                setBookings([]);
                // setLoading(false);
            } else {
                const data = res?.data?.data || [];
                const arrayFilter = formatHorary(data)
                getReservationHorary(arrayFilter)
                setBookings(arrayFilter);
                // setLoading(false);
            }
        })
        .catch((error) => {
            // setLoading(false);
            console.error(error);
        });
    }

    const handleEventClick = (eventInfo) => {
        const { event } = eventInfo;
        if(event?.extendedProps?.class_name == 'event-reservation'){
            setShowModalReserve(true);
            setSelectedReservation(event.extendedProps);
        }
    };

    const getCurrentMonth = (arg) => {
      
        if (arg.view.type === "timeGridWeek") {
            console.log('arg', arg)
            // getReservationHorary([])
        console.log('arg.view.', arg.view)
        const startDate = arg.start
        console.log(moment(startDate).format('YYYY-MM-DD'));
        const dateFormat = moment(startDate).format('YYYY-MM-DD')
        setDateParams(dateFormat)
        }
        // if (arg.view.type === 'dayGridDay') {
        //     setDateParams(dateFormat);
        //     return
        // }
    } 
    console.log('reservationHorary', reservationHorary)
    const events = [
        {
          id: 1,
          title: 'Reserva 1',
          start: '2023-07-25T10:00:00',
          end: '2023-07-25T12:00:00',
          className: 'event-reservation',
        },
        {
            id: 1,
            title: 'Reserva 1',
            start: '2023-07-25T13:00:00',
            end: '2023-07-25T14:30:00',
            className: 'event-available',
          },
        {
          id: 4,
          title: 'Horario Disponible 2',
          start: '2023-07-29T13:00:00',
          end: '2023-07-29T15:00:00',
          className: 'event-available',
        },
      ];

      const eventContent = (eventInfo) => {
        return (
          <div
            className={`custom-event ${eventInfo.event.extendedProps.className}`}
            style={{
            backgroundColor: 'inherit',
            color: '#ffffff',
            textAlign:'center',
            fontWeight:'bolder',
            }}
          >
           <p  style={{ fontSize:'14px'}}> {eventInfo.timeText}</p>
            <p  style={{ fontSize:'13px'}}> {eventInfo.event.title}</p>
          </div>
        );
      };
    

    return (
        <>
        <Grid sx={{ width:'100%', marginTop:'10px'}}>

            <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center" sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', marginBottom:'10px', backgroundColor:'#f2f2f2'}}>
                <Autocomplete
                    id="combo-box-department"
                    size="small"
                    loading={loadingField}
                    options={competitor || []}
                    getOptionLabel={(field) => field.name}
                    renderInput={(params) => <TextField {...params} label="seleccione un tipo de usuario" size="small"/>}
                    sx={{width:'300px', padding: '8px'}}
                    value={competitorSelected && competitorSelected.id ? competitorSelected : null}
                    onChange={(e, newValue) => {
                        setCompetitorSelected(newValue);
                    }}
                />
                <Autocomplete
                    id="combo-box-department"
                    size="small"
                    loading={loadingField}
                    options={fields || []}
                    getOptionLabel={(field) => field.name}
                    renderInput={(params) => <TextField {...params} label="seleccione una cancha" size="small"/>}
                    sx={{width:'300px', padding: '8px'}}
                    value={selectedField && selectedField.id ? selectedField : null}
                    onChange={(e, newValue) => {
                        setSelectedField(newValue);
                        
                    }}
                />
                {/* <Grid item display='flex'>
                    <Button 
                        startIcon={<SearchIcon/>} 
                        onClick={showCalendar} 
                        size="small" 
                        variant="contained" 
                        sx={{ backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '8px', fontSize:"12px", "&:hover": {backgroundColor: "#212D39" } }}>
                            Buscar
                    </Button>
                </Grid> */}
                <Grid item display='flex' paddingLeft='10px'>
                    <Button 
                        startIcon={<CircleIcon sx={{color: "#af3a3a"}}/>}
                        size="small" 
                        variant="contained" 
                        sx={{ backgroundColor: '#f2f2f2', color: 'black', textTransform: 'none', padding: '8px', fontSize:"12px", cursor:'none', "&:hover": {backgroundColor: "#f2f2f2" } }}>
                            Espacio ocupado
                    </Button>
                    <Button 
                        startIcon={<CircleIcon sx={{color: "#39b139"}}/>}
                        size="small" 
                        variant="contained" 
                        sx={{ backgroundColor: '#f2f2f2', color: 'black', textTransform: 'none', padding: '8px', fontSize:"12px", marginLeft:'5px', cursor:'none', "&:hover": {backgroundColor: "#f2f2f2" } }}>
                            Espacio disponible
                    </Button>
                </Grid>
            </Grid>
            {
                !loading && ( 
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin]}
                        headerToolbar={{
                            left: "prev,today,next",
                            center: "title",
                            // right: "dayGridMonth,timeGridWeek,timeGridDay"
                            right: "timeGridWeek,timeGridDay"
                        }}
                        buttonText={{
                            today: "hoy",
                            day: "día",
                            week: "semana",
                            // month: "mes",
                            list: "lista"
                        }}
                        dayHeaderFormat={{
                            weekday: 'long',
                            day: 'numeric'
                        }}

                        datesSet={(arg) => getCurrentMonth(arg)} 
                        
                        initialView="timeGridWeek"
                        initialDate={new Date()}
                        allDaySlot={false}
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        events={reservationHorary}
                        eventContent={eventContent}
                        slotEventOverlap={false}
                        slotMinTime="09:00:00"
                        slotMaxTime="23:00:00" 
                        // timeFormat= 'HH:mm'
                        locale={'es'}
                        firstDay={1} // Establece el día de inicio de la semana como lunes (0: domingo, 1: lunes, 2: martes, ..., 6: sábado)
                      
                        eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                          }}
                        height={'500px'}
                        // validRange={{
                        //     start: today
                        // }}

                        eventClick={handleEventClick}
                        className="custom-calendar" 
                    />
                )
            }
        </Grid>
            
        {
            loading && (
                <Grid sx={{textAlign:'center'}}>
                    <CircularProgress size={28} color="inherit" /> 
                </Grid>
            )
        }

        {
            showModalReserve && (
                <ModalInfo
                    open={showModalReserve}
                    closeModal={()=>{
                        setShowModalReserve(false);
                    }}
                    rowSelected={selectedReservation}
                />
            )
        }
        </>
    )
}

