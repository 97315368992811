import React, { useEffect, useState } from "react"
import { Button, Grid, TextField, TableHead, Typography, TextFieldaphy, Tooltip } from "@mui/material"
import "./ListOuter.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import MenuItem from "@mui/material/MenuItem";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FormControl from "@mui/material/FormControl";
import excel from '../../../assets/icons/excel-icon.png';
import agregar from '../../../assets/icons/agregar.png';
import KeyIcon from '@mui/icons-material/Key';
import axios from "../../../utils/axios";
import Autocomplete from '@mui/material/Autocomplete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Snackbar from '@mui/material/Snackbar';

import MuiAlert from '@mui/material/Alert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import BlockIcon from '@mui/icons-material/Block';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 350,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };





    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const dataInvitados = [
    { id: 1, nombre: "Jordan", apellidos: 'Toledo', rut: "1-222222", correo: "jordan@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 1" },
    { id: 2, nombre: "Jaime", apellidos: 'Lopez', rut: "3-222222", correo: "jaime@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 2" },
    { id: 3, nombre: "Jhon", apellidos: 'Perez', rut: "4-222222", correo: "jhon@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 3" },
    { id: 4, nombre: "Carlos", apellidos: 'Quizpe', rut: "5-222222", correo: "carlos@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 4" },
];


export const ListOuter = () => {
    const [value, setValue] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [modalEnviar, setModalEnviar] = useState(false);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalRestore, setModalRestore] = useState(false);
    const [data, setData] = useState(dataInvitados);
    const [search, setSearch] = useState("");
    const [archivo, setArchivo] = useState("");
    const [family, setFamily] = useState([])
    const [error, setError] = React.useState(null);
    const [ourtersActive, setOurtersActive] = useState([])
    const [ourtersInactive, setOurtersInactive] = useState([])
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [messageSuccess, setMessageSuccess] = React.useState("");
    const [template, setTemplate] = useState([]);
    const [color, setColor]=useState("#191D3D")
    const [showModalExcel, setModalExcel] = React.useState(false);
    const [districts, setDistricts] = useState([]);
    const [districtSelected, setDistrictSelected] = useState('');
    const [modalEliminar2, setModalEliminar2] = useState(false)
    var user = JSON.parse(localStorage.getItem('data_user'))
    const [saveFile, setSaveFile] = useState({
        name: '',
        path: '',
        preview: null,
        data: null
    });
    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };
    const [ourterSelected, setOurterSelected] = useState({
        id: '',
        name: '',
        surname: '',
        rut: '',
        birthday: '',
        email: '',
        idTypeUser: 7,
        // idCompany: 1,
        rutcompany: '',
        status : ''
    })
    const handleChange = (obj, value) => {
        setValue(value)
    }

    const [openError, setOpenError] = React.useState(false);
    const [token, setToken] = useState(
        sessionStorage.getItem("Token")
    );
    const [snackBarConfig, setSnackBarConfig] = React.useState({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })
    const getOurtersActive = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/externs?status=1", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setOurtersActive(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getOutersActiveSearch = async (term) => {
        axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/externs?status=1&term=${term}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setOurtersActive(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const getOurtersInactive = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/externs?status=2", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setOurtersInactive(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const getOurtersInactiveSearch = async (term) => {
        axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/externs?status=2&term=${term}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setOurtersInactive(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const seleccionarOurter = (elemento, caso) => {
        setOurterSelected(elemento);
        switch (caso) {
            case 'Editar':
                setModalEditar(true)
                break;
            case 'Eliminar':
                setModalEliminar(true)
                break;
            case 'Restore':
                setModalRestore(true)
                break;
            default:
                setModalEnviar(true);
                break;
        }
        // (caso === 'Editar') ? setModalEditar(true) : setModalEliminar(true)
    }

    const handleChangeFamily = e => {
        setError('');
        if(e.target.name == 'rut'){
          var foo = e.target.value
          if (foo.length > 0 && foo.length < 11) {
             setOurterSelected(prev => ({ ...prev, rut: foo, textError: '' }))
          } else if (foo.length == 0) {
              setOurterSelected(prev => ({ ...prev, rut: "", textError: '' }))
          }
    
        }else{
        const changedFormValues = {
            ...ourterSelected,
            [e.target.name]: e.target.value,
        };
        changedFormValues.name.trim() !== "" && setError("");
        changedFormValues.surname.trim() !== "" && setError("");
        changedFormValues.rut.trim() !== "" && setError("");
        changedFormValues.birthday.trim() != "" && setError("");
        changedFormValues.email.trim() !== "" && setError("");
        setOurterSelected(changedFormValues);
    }
    }
    // const handleChangeEditFamily = e => {
    //     // setOurterSelected((userValues) => ({
    //     //     ...userValues,
    //     //     [e.target.name]: e.target.value,
    //     // }))
    //     const changedFormValues = {
    //         ...ourterSelected,
    //         [e.target.name]: e.target.value,
    //     };
    //     changedFormValues.name.trim() !== "" && setError("");
    //     changedFormValues.surname.trim() !== "" && setError("");
    //     changedFormValues.rut.trim() !== "" && setError("");
    //     changedFormValues.birthday !== null && setError("");
    //     changedFormValues.email.trim() !== "" && setError("");
    //     setOurterSelected(changedFormValues);
    // }

    const editar = async (id) => {
        if (!ourterSelected.name.trim()) {
            return setError("name");
        }
        if (!ourterSelected.surname.trim()) {
            return setError("surname");
        }
        if (!ourterSelected.rut.trim()) {
            return setError("rut");
        }
        if (!ourterSelected.birthday) {
            return setError("birthday");
        }
        if (!ourterSelected.email.trim()) {
            return setError("email");
        }
        if (ourterSelected.email.trim() !== "undefined") {
            let posicionArroba = ourterSelected.email.trim().lastIndexOf('@');
            let posicionPunto = ourterSelected.email.trim().lastIndexOf('.');

            if (!(posicionArroba < posicionPunto && posicionArroba > 0 && ourterSelected.email.trim().indexOf('@@') == -1 && posicionPunto > 2 && (ourterSelected.email.trim().length - posicionPunto) > 2)) {
                return setError("emailInvalido");
            }
        }
        setModalEditar(false);
        const formFile = new FormData();
        formFile.append('name', ourterSelected.name)
        formFile.append('surname', ourterSelected.surname)
        formFile.append('rut', ourterSelected.rut)
        formFile.append('birthday', ourterSelected.birthday)
        formFile.append('email', ourterSelected.email)
        formFile.append('idcompany', user?.id_company)
        formFile.append('idTypeUSer', 7)

        try {
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/update/${id}`,
                    formFile,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                )
                .then((response) => {
                    setOurtersActive(...response.data)
                    setOurtersInactive(...response.data)
                    getOurtersActive();
                    getOurtersInactive();
                })
                .catch((error) => {
                    console.log(error)
                })
            limpiaDatos();
            setMessageSuccess("Registro editado satisfactoriamente!")
            setOpenSuccess(true);
            setModalEditar(false);
        } catch (error) {
            console.log('error')
        }
        getOurtersInactive();
        getOurtersActive();
    }

    const enviar = async () => {
        try {
            const response = await axios
                .post(
                    `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/auth/recover-password/${ourterSelected.rut}`
                )
                .catch((err) => {
                    console.log("Err: ", err);
                });

            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'success',
                message: "Se le envio un correo con las credenciales ",
            }));
        } catch (error) {
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'error',
                message: "Ocurrio un problema, intentelo mas tarde ",
            }));
        }
        setModalEnviar(false);
    }
    const restore = async (id) => {
        await axios
            .patch(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/restore/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                },
            ).then((response) => {
                // setData(...response.data)
                console.log("respuesta", response);
            })
        setMessageSuccess("Registro restaurado correctmante!")
        setOpenSuccess(true)
        setModalRestore(false);
        getOurtersActive();
        getOurtersInactive();

    }
    const eliminar = async (id) => {
        await axios
            .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
        setMessageSuccess("Registro eliminado satisfactoriamente!")
        setOpenSuccess(true)
        setModalEliminar(false);
        getOurtersInactive();
        getOurtersActive();
        setModalEliminar(false);
    };

    const abrirModalInsertar = () => {
        setModalInsertar(true);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        // setModalInsertar(false);
        if (!ourterSelected.name.trim()) {
            return setError("name");
        }
        if (!ourterSelected.surname.trim()) {
            return setError("surname");
        }
        if (!ourterSelected.rut.trim()) {
            return setError("rut");
        }
        if (!ourterSelected.birthday) {
            return setError("birthday");
        }
        if (!ourterSelected.email.trim()) {
            return setError("email");
        }
        if (ourterSelected.email.trim() !== "undefined") {
            let posicionArroba = ourterSelected.email.trim().lastIndexOf('@');
            let posicionPunto = ourterSelected.email.trim().lastIndexOf('.');

            if (!(posicionArroba < posicionPunto && posicionArroba > 0 && ourterSelected.email.trim().indexOf('@@') == -1 && posicionPunto > 2 && (ourterSelected.email.trim().length - posicionPunto) > 2)) {
                return setError("emailInvalido");
            }
        }
        const formFile = new FormData();
        formFile.append('name', ourterSelected.name)
        formFile.append('surname', ourterSelected.surname)
        formFile.append('rut', ourterSelected.rut)
        formFile.append('birthday', ourterSelected.birthday)
        formFile.append('email', ourterSelected.email)
        formFile.append('idcompany', user?.id_company)
        formFile.append('idTypeUSer', 7)

        try {
            let errorMessage = null
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/register`,
                    formFile,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                )
                .then((response) => {
                    if (response.data.data.message) {
                        errorMessage = response.data.data.message
                    }
                    setOurtersActive(...response.data)
                    setOurtersInactive(...response.data)
                    })
                .catch((error) => {
                    console.log(error)
                })
            limpiaDatos();
            setMessageSuccess(errorMessage != null ? errorMessage : "Correo enviado para el autologin!")
            setOpenSuccess(true);
            setModalInsertar(false);
        } catch (error) {
            console.log('error')
        }

        getOurtersInactive();
        getOurtersActive();
    };

    const limpiaDatos = () => {
        setOurterSelected((userValues) => ({
            ...userValues,
            name: '',
            surname: '',
            rut: '',
            birthday: '',
            email: '',
            idTypeUser: 7,
            status: ''
            // idCompany: 1,
            // rutcompany: '2222222-2'
        }));
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const classes = useStyles();
    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };
    const subirArchivo = (e) => {
        const { name, value } = e.target;
        setArchivo((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }
    const changefile = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        let src, preview, type = file.type;
        setSaveFile(prev => ({ ...prev, data: file, path: src, preview: preview }))
        setColor("#4c9141")
    }

    const getDownloadTemplateExcel = async () => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/export-excel?parent=1", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setTemplate(res.data)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleCloseExcel = ()=>{
        setModalExcel(false);
        setColor("#191D3D");
        limpiaDatos();
    }

    const GenerateUserByExcel = async () => {
        const formFile = new FormData();
        formFile.append('file', saveFile.data || null);
        formFile.append('idTypeUSer', 4);
        formFile.append('idSocio', ourterSelected.idSocio || null);

        try {
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/load-excel`,
                    formFile,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                ).then((res) => {
                    limpiaDatos();
                    setMessageSuccess("Usuarios nuevos generados!")
                    setOpenSuccess(true);
                    setModalExcel(false)
                })
                .catch((error) => {
                    console.log(error)
                    setOpenError(true)
                })
        } catch (error) {
            console.log('error')
        }

    }
    useEffect(() => {
        setData([...data])
        getOurtersInactive([...ourtersInactive]);
        getOurtersActive([...ourtersActive]);
        // getDownloadTemplateExcel([...template])
    }, [archivo])

    const handleSearch = (event) => {
        try{   const name = event.target.name;
           const valueInput = event.target.value;
           switch (name) {
              case 'search':
                 if(valueInput.length>3)
                 {
                   value == 0? getOutersActiveSearch(valueInput) : getOurtersInactiveSearch(valueInput)
                    setPage(0)
                 }
                 if(valueInput.length==0)
                 {
                    value == 0?  getOurtersActive(): getOurtersInactive();
                 }
                 setSearch(valueInput);
  
                 break;
              default:
                 break;
           }
        }catch(e){
           console.log(e)
        }
        };

    const handleOpenModalDelete = (rowSelected) => {
        setOurterSelected(rowSelected)
        setModalEliminar2(true)
    }

    const handleEliminar = async () => {
        const id = ourterSelected.id
        await axios
        .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/deleteUser/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        setMessageSuccess("Registro eliminado correctamente!")
        setOpenSuccess(true)
        setModalEliminar2(false);
        getOurtersActive();
        getOurtersInactive();
    }

    const handleDeleteBD = async () => {
        const id = ourterSelected.id
        await axios
        .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/deleteBD/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        setMessageSuccess("Registro eliminado correctamente!")
        setOpenSuccess(true)
        setModalEliminar2(false);
        getOurtersActive();
        getOurtersInactive();
    }

    return (
        <Grid sx={{bgcolor:'#f4f4f4'}}>
            <Grid container className="containerInvite">
                <Grid container justifyContent={"center"} mt={2}>
                    <Grid item xs={12} md={10}>
                        <Grid xs={12} mb={2}>
                            <Link to={'/modulos-administrador'} className='link__css'>
                                <Typography variant='h6' gutterBottom className='link__css-back'>
                                    <ArrowBackIosIcon className='icon_back' /> Volver
                                </Typography>
                            </Link>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant={"h5"} className="title__main">EXTERNOS</Typography>
                                </Grid>
                                <Grid item xs={8} md={6} mt={1}>
                                    <Paper
                                        component="form"
                                        sx={{ p: '0px 2px', display: 'flex', alignItems: 'center' }}
                                    >

                                        <InputBase
                                            value={search}
                                            name='search'
                                            onChange={handleSearch}
                                            on
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Buscador"
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>


                                    </Paper>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    {/* <Button fullWidth className="btn-agregar" ></Button> */}
                                    <Grid display="flex" justifyContent={"flex-end"}>
                                        {/* <IconButton sx={{ marginLeft: '5px' }}
                                            onClick={() => setModalExcel(true)}
                                        >
                                            <img className="btn-agregar-excel" src={excel}></img>
                                        </IconButton> */}
                                        <IconButton sx={{ marginLeft: '5px' }}
                                            onClick={() => abrirModalInsertar()} >
                                            <img className="btn-agregar-excel" src={agregar}></img>

                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {value == 0 ? <Typography variant='body1' gutterBottom>Tienes {ourtersActive.length} externos activos.</Typography>
                                : <Typography variant='body1' gutterBottom>Tienes {ourtersInactive.length} externos inactivos.</Typography>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={10} mt={3}>
                        <Box sx={{ width: '100%', bgcolor: '#fff' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs centered value={value} onChange={handleChange} indicatorColor="primary"
                                    textColor="primary" aria-label="basic tabs example">
                                    <Tab label="EXTERNOS ACTIVOS " textColor="#f00" {...a11yProps(0)} />
                                    <Tab label="EXTERNOS INACTIVOS " textColor="#f00" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} >
                                    <TabPanel value={value} index={0}>
                                        <Grid item xs={12} mt={3}>
                                            <TableContainer component={Paper} >
                                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                                    <TableHead >
                                                        <TableRow style={{ background: "#191D3D" }}>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Nombres y Apellidos</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Rut</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Fecha de nacimiento</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Correo</TableCell>
                                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(rowsPerPage > 0
                                                            ? ourtersActive.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : ourtersActive
                                                        ).map((row) => (
                                                                <TableRow key={row.id}>

                                                                    <TableCell className="cell">
                                                                        {row.name + " " + row.surname}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.rut}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.birthday}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.email}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid container spacing={1}>
                                                                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                                                                                <Tooltip title="Enviar llave privada">
                                                                                    <IconButton onClick={() => seleccionarOurter(row, 'EnviarLlave')}>
                                                                                        <KeyIcon style={{ color: "#e4980d " }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Editar">
                                                                                    <IconButton onClick={() => seleccionarOurter(row, 'Editar')}>
                                                                                        <EditIcon style={{ color: "#469489 " }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Deshabilitar">
                                                                                    <IconButton onClick={() => seleccionarOurter(row, 'Eliminar')}>
                                                                                        <BlockIcon style={{ color: "#b10202" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Eliminar">
                                                                                    <IconButton onClick={() => handleOpenModalDelete(row)}>
                                                                                        <DeleteIcon style={{ color: "#b10202" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}

                                                        {emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={6} />
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25]}
                                                                colSpan={5}
                                                                count={ourtersActive.length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                                ActionsComponent={TablePaginationActions}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Grid item xs={12} mt={3}>
                                            <TableContainer component={Paper} >
                                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                                    <TableHead >
                                                        <TableRow style={{ background: "#191D3D" }}>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Nombres y Apellidos</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Rut</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Fecha de nacimiento</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Correo</TableCell>
                                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(rowsPerPage > 0
                                                            ? ourtersInactive.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : ourtersInactive
                                                        ).map((row) => (
                                                                <TableRow key={row.id}>
                                                                    <TableCell className="cell">
                                                                        {row.name + " " + row.surname}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.rut}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.birthday}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.email}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid container spacing={1}>
                                                                            {/* <Grid item xs={4}>
                                                                                <IconButton onClick={() => seleccionarOurter(row, 'EnviarLlave')}>
                                                                                    <KeyIcon style={{ color: "#e4980d " }} />
                                                                                </IconButton>
                                                                            </Grid> */}
                                                                            <Grid item xs={12} diplay="flex" justifyContent="center">
                                                                                <Tooltip title="Editar">
                                                                                    <IconButton onClick={() => seleccionarOurter(row, 'Editar')}>
                                                                                        <EditIcon style={{ color: "#469489 " }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Habilitar">
                                                                                    <IconButton onClick={() => seleccionarOurter(row, 'Restore')}>
                                                                                        <RestartAltIcon style={{ color: "#056600" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Eliminar">
                                                                                    <IconButton onClick={ () => handleOpenModalDelete(row) }>
                                                                                        <DeleteIcon style={{ color: "#b10202" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}

                                                       
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                                colSpan={5}
                                                                count={ourtersInactive.length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                                ActionsComponent={TablePaginationActions}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </TabPanel>

                                </Grid>
                            </Grid>


                        </Box>

                    </Grid>
                </Grid>

            </Grid>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleCloseSuccess}>
                    <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
                        {messageSuccess}
                    </Alert>
                </Snackbar>
            </Stack>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                        Seleccionar el Socio y/o subir un archivo
                    </Alert>
                </Snackbar>
            </Stack>

            {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showModalExcel}
                onClose={handleCloseExcel}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showModalExcel}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                            </Typography>
                            <Grid container  justifyContent="center">
                                <Grid container  mt={2}>
                                <FormControl fullWidth>
                                        <InputLabel size="small" id="demo-simple-select-label">
                                            Seleccione el socio
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Seleccione el socio"
                                            size="small"
                                            name="idSocio"
                                            onChange={handleChangeFamily}
                                        >
                                            {socio.map((item) => (
                                                <MenuItem
                                                    sx={{
                                                        display: "block !important",
                                                        padding: "5px 10px !important",
                                                    }}
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name + " " + item.surname}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Grid item xs={6} mt={2}>
                                        <Button href={template.link} target="_blank" variand="contained" fullWidth
                                            sx={{ background: "#191D3D", color: "#fff", padding: "40px 20px", borderRadius: '20px', mt: '4px' }} className="btn-Hover">
                                            DESCARGAR PLANTILLA
                                            <FileDownloadIcon /> </Button>
                                    </Grid>
                                    <Grid item xs={6} mt={2}>
                                        <Grid item xs={12} sx={{ position: 'relative' }}>
                                            <div className="wrapper">
                                                <div className="file-upload"
                                                style={{background:color}}
                                                >
                                                    <FileUploadIcon />
                                                    <span style={{ fontSize: '15px' }}>CARGAR</span>
                                                    <input type="file"
                                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                        onChange={changefile}
                                                    />
                                                </div>
                                            </div>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button fullWidth 
                                        className="btn-aceptar"
                                        onClick={() => { GenerateUserByExcel() }}>
                                        Generar Nuevos Usuarios
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal> */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEditar}
                onClose={() =>{ setModalEditar(false); limpiaDatos();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEditar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                EDITAR EXTERNO
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">

                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        size="small"
                                        name="name"
                                        placeholder="Nombres*"
                                        fullWidth
                                        value={ourterSelected.name}
                                        onChange={handleChangeFamily}
                                        error={error === "name" ? true : false}
                                        helperText={error === "name" ? "Los nombres son un campo requerido" : ""}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField

                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="surname"
                                        placeholder="Apellidos"
                                        value={ourterSelected.surname}
                                        onChange={handleChangeFamily}
                                        error={error === "surname" ? true : false}
                                        helperText={error === "surname" ? "Los apellidos son un campo requerido" : ""}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField

                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="rut"
                                        placeholder="RUT*"
                                        value={ourterSelected.rut}
                                        onChange={handleChangeFamily}
                                        error={error === "rut" ? true : false}
                                        helperText={error === "rut" ? "El RUT es un campo requerido" : ""}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="date"
                                        fullWidth
                                        name="birthday"
                                        placeholder="Fecha de nacimiento"
                                        value={ourterSelected.birthday}
                                        onChange={handleChangeFamily}
                                        error={error === "birthday" ? true : false}
                                        helperText={error === "birthday" ? "Seleccione la fecha de nacimiento" : ""}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="text"
                                        fullWidth
                                        name="email"
                                        placeholder="Correo*"
                                        value={ourterSelected.email}
                                        onChange={handleChangeFamily}
                                        error={error === "email" ? true : false}
                                        helperText={error === "email" ? "El email es un campo requerido" : ""}
                                    />
                                </Grid>

                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEditar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => editar(ourterSelected.id)}>Actualizar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEliminar}
                onClose={() =>{ setModalEliminar(false);  limpiaDatos();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEliminar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                Estás Seguro que deseas deshabilitar a  {ourterSelected && (ourterSelected.name + " " + ourterSelected.surname)}
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEliminar(false)}>NO</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => eliminar(ourterSelected.id)}>Si</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEnviar}
                onClose={() => {setModalEnviar(false);  limpiaDatos();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEnviar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                ¿Enviar llave privada al familiar {ourterSelected && ourterSelected.name}?
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEnviar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => enviar()}>Enviar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEliminar2}
                onClose={() => {setModalEliminar2(false); limpiaDatos()}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalEliminar2}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >   Estás Seguro que deseas eliminar a {ourterSelected && (ourterSelected.name + " " + ourterSelected.surname)}
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEliminar2(false)}>NO</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => ourterSelected.status == '0'? handleDeleteBD() : handleEliminar()}>Si</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalRestore}
                onClose={() => {setModalRestore(false);  limpiaDatos();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalRestore}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                ¿Restaurar al familiar {ourterSelected.name}  {ourterSelected.surname}?
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalRestore(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => restore(ourterSelected.id)}>Restaurar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalInsertar}
                onClose={() => {setModalInsertar(false); limpiaDatos();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalInsertar}>
                    <div className={classes.paper} >
                        <form onSubmit={handleSubmit}>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                AGREGAR EXTERNO
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        size="small"
                                        name="name"
                                        // value={familyNew.name}
                                        error={error === "name" ? true : false}
                                        helperText={error === "name" ? "El nombre es un campo requerido" : ""}
                                        placeholder="Nombres"
                                        fullWidth
                                        onChange={handleChangeFamily}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField

                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="surname"
                                        // value={familyNew.surname}
                                        error={error === "surname" ? true : false}
                                        helperText={error === "surname" ? "El apellido es un campo requerido" : ""}
                                        placeholder="Apellidos"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.apellidos : ''}
                                        onChange={handleChangeFamily}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField

                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="rut"
                                        placeholder="RUT"
                                        value={ourterSelected.rut}
                                        error={error === "rut" ? true : false}
                                        helperText={error === "rut" ? "El RUT es un campo requerido" : ""}
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.rut : ''}
                                        onChange={handleChangeFamily}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="date"
                                        fullWidth
                                        name="birthday"
                                        placeholder="Fecha de nacimiento"
                                        // value={familyNew.birthday}
                                        error={error === "birthday" ? true : false}
                                        helperText={error === "birthday" ? "Seleccione la fecha de nacimiento" : ""}
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.direccion : ''}
                                        onChange={handleChangeFamily}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="email"
                                        placeholder="Email"
                                        // value={familyNew.email}
                                        error={error === "email" ? true : false}
                                        helperText={error === "email" ? "El email es un campo requerido" : ""}
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.rut : ''}
                                        onChange={handleChangeFamily}
                                    />
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalInsertar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" type="submit">Agregar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </Grid>
    )
}