import * as React from 'react';
import { IconButton, Grid, Typography, Modal, Paper, Table, TableBody, TableContainer, TableHead, TableRow, FormControl, InputLabel, MenuItem, Box, CardHeader } from "@mui/material"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import "./Schedule.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import CommitIcon from '@mui/icons-material/Commit';
import { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import axios from '../../utils/axios';
// import axios from 'axios';
import { session } from '../../session/constants';
import { useHistory } from 'react-router-dom';
import moment from "moment/moment";
import { PrevReservation } from "../Reservation/PrevReservation";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TablaHorasCanchas } from './TablaHorasCanchas';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 450,
        margin: 5,
    },
}));

export const Horario = (props) => {
    const booleanExternos = props.location.state.externosBolean;
    const [openError, setOpenError] = useState({
        bool: false,
        message: ''
    });
    const [snackBarConfig, setSnackBarConfig] = useState({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })

    const classes = useStyles();

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(prev => ({ ...prev, bool: false }));
    };


    const changeIdDay = (name_day) => {
        if (name_day == 'lunes' || name_day == 'martes' || name_day == 'miércoles' || name_day == 'jueves') {
            return 1;
        }

        if (name_day == 'viernes') {
            return 2;
        }

        if (name_day == 'sábado' || name_day == 'domingo') {
            return 3;
        }
    }

    const changeMonthName = (num_month) => {
        switch (num_month) {
            case '01':
                return 'Enero';
            case '02':
                return 'Febrero';
            case '03':
                return 'Marzo';
            case '04':
                return 'Abril';
            case '05':
                return 'Mayo';
            case '06':
                return 'Junio';
            case '07':
                return 'Julio';
            case '08':
                return 'Agosto';
            case '09':
                return 'Setiembre';
            case '10':
                return 'Octubre';
            case '11':
                return 'Noviembre';
            case '12':
                return 'Diciembre';
        }
    }
    const InitialDays = [
        // {
        //     'day': moment().subtract(3, 'd'),
        //     'name_date': moment.weekdays(moment().subtract(3, 'd').day()),
        //     'num_date': moment().subtract(3, 'd').format('DD')
        // },
        // {
        //     'day': moment().subtract(2, 'd'),
        //     'name_date': moment.weekdays(moment().subtract(2, 'd').day()),
        //     'num_date': moment().subtract(2, 'd').format('DD')
        // },
        // {
        //     'day': moment().subtract(1, 'd'),
        //     'name_date': moment.weekdays(moment().subtract(1, 'd').day()),
        //     'num_date': moment().subtract(1, 'd').format('DD')
        // },
        {
            'day': moment(),
            'name_date': moment.weekdays(moment().day()),
            'num_date': moment().format('DD')
        },
        {
            'day': moment().add(1, 'd'),
            'name_date': moment.weekdays(moment().add(1, 'd').day()),
            'num_date': moment().add(1, 'd').format('DD')
        },
        {
            'day': moment().add(2, 'd'),
            'name_date': moment.weekdays(moment().add(2, 'd').day()),
            'num_date': moment().add(2, 'd').format('DD')
        },
        {
            'day': moment().add(3, 'd'),
            'name_date': moment.weekdays(moment().add(3, 'd').day()),
            'num_date': moment().add(3, 'd').format('DD')
        },
        {
            'day': moment().add(4, 'd'),
            'name_date': moment.weekdays(moment().add(4, 'd').day()),
            'num_date': moment().add(4, 'd').format('DD')
        },
        {
            'day': moment().add(5, 'd'),
            'name_date': moment.weekdays(moment().add(5, 'd').day()),
            'num_date': moment().add(5, 'd').format('DD')
        },
        {
            'day': moment().add(6, 'd'),
            'name_date': moment.weekdays(moment().add(6, 'd').day()),
            'num_date': moment().add(6, 'd').format('DD')
        },
    ]
    const history = useHistory();
    const data_props = props.location.state.from
    const [ArrayDays, setArrayDays] = useState(InitialDays);
    const [isDay, setIsDay] = useState();
    const [isHorario, setIsHorario] = useState(false);
    const [dataAvailableTime, setDataAvailableTime] = useState([]);
    const [dataField, setdataField] = useState();
    const [horarioSelect, setHorarioSelect] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [amount, setAmount] = useState(0);
    const [arrayField, setArrayField] = useState([])
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedIdsHorary, setSelectedIdsHorary] = useState([]);
    const [data, setData] = useState({});
    const [idDay, setIdDay] = useState('');

    const handleDay = (day) => {
        setSelectedIds([])
        setSelectedIdsHorary([])
        availableTime(day);
        setIsDay(day);
    }

    const [fieldFilter, setFieldFilter] = useState(0);

    const handleChange = (event) => {
        setFieldFilter(event.target.value);
    };

    var Token = localStorage.getItem('token_user')
    var data_user = JSON.parse(localStorage.getItem("data_user"))
    let tipoUsuario = data_user.type_user
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      // Función para detectar el tamaño de la pantalla y actualizar el estado
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Ajusta aquí el ancho que consideres como "móvil"
      };
  
      // Agregar el evento de escucha al tamaño de la ventana
      window.addEventListener('resize', handleResize);
  
      // Ejecutar la función al montar el componente para inicializar el estado
      handleResize();
  
      // Eliminar el evento de escucha al desmontar el componente
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
        availableTime(isDay)
    }, [fieldFilter])

    function handleDayCanchas(value) {
        setSelectedIds([])
        setSelectedIdsHorary([])
        let idday = {};
        let dayFormat = '';
        ArrayDays.map((item) => {
            // console.log(item.day.format('YYYY-MM-DD'))
            if (item.day.format('YYYY-MM-DD') == value) {
                idday = changeIdDay(item.name_date)
                dayFormat = item.day.format('YYYY-MM-DD')

            }
        })
        var initialCompetidor = 0;

        switch (tipoUsuario) {
            case 'SOCIO':
                initialCompetidor = 1;
                break;
            case 'FAMILIAR':
                initialCompetidor = 2;
                break;
            case 'EXTERNO':
                initialCompetidor = 3;
                break;
            case 'PROFESOR':
                initialCompetidor = 4;
                break;
        }
        if (initialCompetidor == 1 && booleanExternos) {
            initialCompetidor = 1
        } else if (initialCompetidor == 1 && booleanExternos == false) {
            initialCompetidor = 2
        }
        setIdDay(idday)
        
        return axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/list-availables-byField?date=${dayFormat}&idday=${idday}&idCompetitor=${initialCompetidor}&idField=${props.location.state.idField}`,
                {
                    headers: {
                        // 'Access-Control-Allow-Origin': '*',
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + Token,
                    }
                })
            .then(pros => {
                // console.log("soy pros data: ",pros.data.data)
                let arrayDtaCancha = [];
                arrayDtaCancha.push(pros.data.data)
                ArrayHoraryCancha(arrayDtaCancha)
            })
        // fetch(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/list-availables-byField?date=${dayFormat}&idday=${idday}&idCompetitor=1&idField=${props.location.state.idField}`, {
        //     method: 'GET',
        //     // data: {
        //     //     "idField": props.location.state.idField,
        //     //     "idday": 
        //     // },
        //     headers: {
        //         // 'Access-Control-Allow-Origin': '*',
        //         // 'Content-Type': 'application/x-www-form-urlencoded',
        //         'Authorization': 'Bearer ' + Token,
        //     }
        // })
        //     .then(pros => pros.json())
        //     .then(
        //         async pros => {
        //             // const data = (pros.data || []).map((raw) => ({
        //             //     id: raw?.id,
        //             //     start: raw?.start,
        //             //     end: raw?.end,
        //             //     duration: raw?.duration,                        
        //             // }))
        //             console.log(pros.data)
        //             let arrayDtaCancha = [];
        //             arrayDtaCancha.push(pros.data)
        //             ArrayHoraryCancha(arrayDtaCancha)
        //             //setDataAvailableTime(props.data)
        //             //setListHorarios(data);
        //             // console.log("Los horarios traidos son: ", pros.data);
        //         }

        //     )
    }

    const availableTime = async (value) => {
        if (!value) {
            return
        }
        let idday = {};
        let dayFormat = '';
        ArrayDays.map((item) => {
            if (item.day.format('YYYY-MM-DD') == value) {
                idday = changeIdDay(item.name_date)
                dayFormat = item.day.format('YYYY-MM-DD')

            }
        })
        var initialCompetidor = 0;
        switch (tipoUsuario) {
            case 'SOCIO':
                initialCompetidor = 1;
                break;
            case 'FAMILIAR':
                initialCompetidor = 2;
                break;
            case 'EXTERNO':
                initialCompetidor = 3;
                break;
            case 'PROFESOR':
                initialCompetidor = 4;
                break;
        }
        if (initialCompetidor == 1 && booleanExternos) {
            initialCompetidor = 1
        } else if (initialCompetidor == 1 && booleanExternos == false) {
            initialCompetidor = 2
        }
        setIdDay(idday)
        await axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/horary/list-byDayEspecific?date=${dayFormat}&idday=${idday}&idCompetitor=${initialCompetidor}&idTypeField=${props.location.state.idTypeField}&idfield=${fieldFilter ? fieldFilter : ''}`,
                {
                    headers: {
                        Authorization: `Bearer ${Token}`,
                    },
                })
            .then((res) => {
                ArrayHorary(res)

            })
            .catch((error) => {
                console.error(error);
            });
    };

    const ArrayHoraryCancha = (row) => {
        const newArray = [];
        row.map((value) => {
            value.hours.map((item) => {
                newArray.push({
                    ...item,
                    idfield: value?.idfield ? value?.idfield: props.location.state.idField,
                    field: value.name,
                    img: value.image_1
                })
            })
        })
        console.log('newArray', newArray)
        setDataAvailableTime(newArray)
    }

    const ArrayHorary = (row) => {
        setArrayField(row.data?.data);
        const newArray = [];
        row.data.data.map((value) => {
            value.hours.map((item) => {
                newArray.push({
                    ...item,
                    idfield: value?.idfield,
                    field: value?.field,
                    img: value?.image_1
                })
            })
        })
        setData(row.data?.data)
        setDataAvailableTime(newArray)

    }

    const Reservation = async (data) => {
        const res = await
            axios.post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation`,
                data,
                // method: "post",
                // url: `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation`,
                {
                    headers: {
                        Authorization: `Bearer ${Token}`,
                    },
                })
        return res;
    }

    const AmountToPay = async (data) => {
        var response;
        await axios
            .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/viewAmountToPay`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${Token}`,
                    }
                },
            )
            .then((res) => {
                res?.data?.data?.bool === false ?
                    setOpenError(prev => ({ ...prev, bool: true, message: res?.data?.data?.message }))
                    : setOpenModal(true);
                response = res
            })
            .catch((error) => {
                console.log(error)
            })
        return response;
    }

    useEffect(() => {
        if (data_props == 'horarios') {
            handleDay(moment().format('YYYY-MM-DD'));
            // availableTime(moment().format('YYYY-MM-DD'));
        } else {
            handleDayCanchas(moment().format('YYYY-MM-DD'));
            setIsDay(moment().format('YYYY-MM-DD'));
        }

    }, [])

    const listaInvitados = props.location.state.listaInvitados;

    const handleReservation = (data) => {
        const state = {
            'date': isDay,
            'idtype_reservation': props.location.state.idtype_reservation,
            // "idhorary": data.id_horary,
            "idhorary": data.id_horary,
            "a_iduser": props.location.state.a_iduser,
            "a_idinvite": props.location.state.a_idinvite,
            "listaInvitados": props.location.state.listaInvitados
        }
        // Reservation(state);
        // history.push('/registro-exitoso', state);
    }



    const changeView = (amount_resp, state) => {
        setOpenModal(false)
        if (amount_resp == 0) {
            history.push('/registro-exitoso', state);
        } else {
            history.push('/pagos', state);
        }
    }
    const changeViewBack = (view) => {

        switch (view) {
            case 'canchas':
                history.push('/lista-canchas', { from: 'canchas', idField: props.location.state.idField, idTypeField: props.location.state.idTypeField, idtype_reservation: props.location.state.idtype_reservation, a_idinvite: props.location.state.a_idinvite, a_iduser: props.location.state.a_iduser, listaInvitados: props.location.state.listaInvitados })
                break;
            default:
                history.push('/seleccionar-invitados-horario', { from: 'horario', idTypeField: props.location.state.idTypeField, idtype_reservation: props.location.state.idtype_reservation, a_idinvite: props.location.state.a_idinvite, a_iduser: props.location.state.a_iduser })
                break;
        }
    }

    var año = 2022;
    var mes = 8;

    var diasMes = new Date(año, mes, 0).getDate();
    var diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

    for (var dia = 1; dia <= diasMes; dia++) {
        // Ojo, hay que restarle 1 para obtener el mes correcto
        var indice = new Date(año, mes - 1, dia).getDay();
        //console.log(`El día número ${dia} del mes ${mes} del año ${año} es ${diasSemana[indice]}`);
    }

    const handleChangeSelect = async (horary) => {
        console.log('horary',horary)
        setSelectedIds([]);
        setSelectedIdsHorary([])
       
        let itemsSelected = [];
        const idselected = horary?.id
        console.log('idselected', idselected)
        const index = itemsSelected.indexOf(idselected);
        console.log('index', index)
        if (index === -1) {
            itemsSelected.push(idselected);
        } else {
            itemsSelected[index] = idselected;
        }
        console.log('itemsSelected', itemsSelected)
        setSelectedIds(itemsSelected)
        setSelectedIdsHorary(horary?.ids)
        handleHorario(itemsSelected, horary?.ids)
    };

    const handleHorario = async (ids_selected, idsHorary) => {
        const array_Horary = dataAvailableTime.filter((item) => ids_selected.includes(item.id));
        // setHorarioSelect(row);
        console.log('array_Horary', array_Horary)

        if (!props.location.state.idField) {
            const idfieldToCompare = array_Horary[0]?.idfield;
            // Verificar si todos los objetos tienen el mismo valor para el campo de idfield
            const allObjectsHaveSameIdfield = array_Horary.every((obj) => obj?.idfield === idfieldToCompare);
            if (!allObjectsHaveSameIdfield) {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'warning',
                    message: "Los horarios no son de la misma cancha",
                }));
                return
            }
        }
        const state = {
            'idtype_reservation': props.location.state.idtype_reservation,
            "users": props.location.state.a_iduser,
            "invites": props.location.state.a_idinvite,
            "date": isDay,
            "idhorary": idsHorary,
            // "idhorary": row.id,
            "idTypeField": props.location.state.idTypeField,
            "listaInvitados": props.location.state.listaInvitados
        }
        console.log('state', state)

        const resp = await AmountToPay(state);
        setAmount(resp.data);
    }


    const onClose = () => {
        setSelectedIds([])
        setOpenModal(false)
    }

    const tableStyle = {
        width: '100%', // Ancho deseado para la tabla
        margin: 'auto', // Centrar la tabla horizontalmente
        marginTop: '20px', // Espaciado superior
    };

    return (
        <Box  sx={{pt : isMobile ? 1: 5, pl : isMobile ? 1: 10, pr : isMobile ? 1: 10, bgcolor:'#f4f4f4'}}>
             <Grid mb={2} >
                    {/* <Link to={'/seleccionar-invitados-horario'} className='link__css'> */}
                    <Typography sx={{ cursor: 'pointer' }} onClick={() => changeViewBack(data_props)} variant='h6' gutterBottom className='link__css-back'>
                        <ArrowBackIosIcon className='icon_back' /> Volver
                    </Typography>
                    {/* </Link> */}
                </Grid>

            <Grid  mt={3} xs={12}>
                {/* <Grid container item xs={7} mb={1} justifyContent={"center"}>
                    <Grid item justifyContent='center'>
                        <Typography ml={3} mb={1} variant="h6" color="#191D3D">{changeMonthName(moment(isDay).format('MM'))} - {moment(isDay).format('YYYY')}</Typography>
                    </Grid>
                </Grid> */}
                <Card>
                    <CardHeader
                        // avatar={
                        //     <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        //         R
                        //     </Avatar>
                        // }
                        // action={
                        //     <IconButton aria-label="settings">
                        //         <MoreVertIcon />
                        //     </IconButton>
                        // }
                        title="HORARIOS DISPONIBLES"
                        subheader={`${changeMonthName(moment(isDay).format('MM'))} - ${moment(isDay).format('YYYY')}`}
                    />
                        <CardContent>
                <Grid container justifyContent={"center"} item xs={12} md={12}>
                            {ArrayDays.map((value) => {
                                return (
                                    <Grid container item xs={3} md={1} justifyContent={"center"} alignItems="center">
                                        <Grid item onClick={() => {
                                            data_props == 'horarios' ? handleDay(moment(value.day).format('YYYY-MM-DD')) :
                                                handleDayCanchas(moment(value.day).format('YYYY-MM-DD'));
                                            setIsDay(moment(value.day).format('YYYY-MM-DD'));
                                        }} className={isDay == moment(value.day).format('YYYY-MM-DD') ? 'day-active' : 'day'}>
                                            <Typography textAlign={"center"} gutterBottom variant="h6">{value.num_date}</Typography>
                                            <Typography textAlign={"center"} gutterBottom variant="subtitle1">{value.name_date}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                            }
                     
                    {/* <Grid item xs={1} mt={4}>
                        <IconButton className="ico--fondo" onClick={() => { NextFech() }}>
                            <ChevronRightIcon className="ico-day" />
                        </IconButton>
                    </Grid> */}
                    {/* <Grid container justifyContent={"right"}>
                                    <IconButton className="ico--fondo">
                                        <ChevronRightIcon className="ico-day" />
                                    </IconButton>
                                </Grid> */}
                    {/* </Grid> */}
                    {/* <Grid item xs={1} mt={4}>
                                <Grid container justifyContent={"right"}>
                                    <IconButton className="ico--fondo">
                                        <ChevronRightIcon className="ico-day" />
                                    </IconButton>
                                </Grid>
                            </Grid> */}
                    {/* </Grid> */}

                    {/* </Grid> */}

                </Grid>
                </CardContent>
                    </Card>

            </Grid>

            <Card>
            <CardContent>
            <Grid >
                <Grid item xs={12} md={8}>
                    <Grid item container xs={12} flexDirection={'row'} justifyContent={'space-evenly'} className="border-container">
                 
                    </Grid>
                   
                    <Grid container>
                        {
                            isMobile ? 
                               <>
                                    {arrayField?.length > 0 &&
                                        <Grid item  >
                                            <Typography gutterBottom color="#000" mt={1}>Filtro por:</Typography>
                                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-standard-label">Cancha</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={fieldFilter}
                                                    onChange={handleChange}
                                                    label="cancha"
                                                >
                                                    <MenuItem value={0}>
                                                        Todas
                                                    </MenuItem>
                                                    {
                                                        arrayField.map((field) =>
                                                            <MenuItem value={field?.idfield}>{field?.field}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>

                                        </Grid>}
                                {
                                    dataAvailableTime.map((row, i) => {
                                        return (
                                            <Grid item direction={'row'} xs={12} md={4}>
                                                <Grid item container
                                                    onClick={() => handleChangeSelect(row)}
                                                    className={selectedIds.includes(row.id) ? "horarioOcupado" : "horaiosPair"}
                                                >
                                                    <Grid xs={12}>
                                                        <Typography textAlign={"center"} color="#9b9b9b" gutterBottom variant="subtitle2">{row.field}</Typography>
                                                    </Grid>
                                                    <Grid xs={12} display="flex" justifyContent={"space-between"} >

                                                        <Grid>
                                                            <Typography textAlign={"center"} color="#9b9b9b" gutterBottom variant="subtitle2">INICIO</Typography>
                                                            <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">{row.start}</Typography>
                                                        </Grid>
                                                        <Grid mt={2}>
                                                            <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">-</Typography>
                                                        </Grid>
                                                        <Grid>
                                                            <Typography textAlign={"center"} color="#9b9b9b" gutterBottom variant="subtitle2">FIN</Typography>
                                                            <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">{row.end}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                               </>
                            :
                            <>
                            {
                                data_props == 'horarios' ?(
                                    <TableContainer component={Paper} style={tableStyle}>
                                    {
                                        data?.length>0 && (
                                          <TablaHorasCanchas data={data} selectedIds={selectedIds} handleChangeSelect={handleChangeSelect}/>
                                        )
                                    }
                                </TableContainer>
                                ):(
                                    <>
                                    {
                                        dataAvailableTime.map((row, i) => {
                                            return (
                                                <Grid item direction={'row'} xs={12} md={4}>
                                                    <Grid item container
                                                        onClick={() => handleChangeSelect(row)}
                                                        className={selectedIds.includes(row.id) ? "horarioOcupado" : "horaiosPair"}
                                                    >
                                                        {/* <Grid xs={12}>
                                                            <Typography textAlign={"center"} color="#9b9b9b" gutterBottom variant="subtitle2">{row.field}</Typography>
                                                        </Grid> */}
                                                        <Grid xs={12} display="flex" justifyContent={"space-between"} >
    
                                                            <Grid>
                                                                <Typography textAlign={"center"} color="#9b9b9b" gutterBottom variant="subtitle2">INICIO</Typography>
                                                                <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">{row.start}</Typography>
                                                            </Grid>
                                                            <Grid mt={2}>
                                                                <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">-</Typography>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography textAlign={"center"} color="#9b9b9b" gutterBottom variant="subtitle2">FIN</Typography>
                                                                <Typography textAlign={"center"} color="#469489" gutterBottom variant="h6">{row.end}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                   </>
                                )
                            }
                            </>
                            
                            
                        }
                     
                       
                        {/* <div>
                            <h1>Horas en Canchas</h1>
                            <TablaHorasCanchas data={data} />
                        </div> */}
                    </Grid>
                </Grid>
                {isHorario ?
                    <Grid container mt={3} justifyContent={"center"} xs={12}>
                        <Grid iten container justifyContent={"center"} mt={1} pl={1} md={8}>
                            <Grid item xs={12} md={12}>
                                <Typography variant={"h5"} className="title__main">Canchas disponibles para el 0{isDay} de Setiembre ( {horarioSelect.start} - {horarioSelect.end} )</Typography>
                            </Grid>
                        </Grid>
                        <Grid container mt={2} justifyContent={"center"} md={8}>
                            {dataField.map((item) => (
                                <Grid item xs={12} md={6} p={1}>
                                    {/* <Link to={`/pagos`}> */}
                                    <Card sx={{ width: "100%", background: "#fff", borderRadius: "10px" }}
                                        onClick={() => {
                                            handleReservation(item)
                                        }}>
                                        <CardActionArea className='contenedor'>
                                            <CardMedia
                                                component="img"
                                                height="180"
                                                image={item.img}
                                            />
                                            <Grid container className='texto-encima'>
                                                <Grid item xs={10}>
                                                    <Typography gutterBottom variant="h6" component="div" ml={2} mt={1} className="texto-card">
                                                        {item.title}
                                                    </Typography>
                                                    <Typography gutterBottom variant="caption" component="div" ml={2} mt={0} className="texto-card2">
                                                        {item.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton>
                                                        <FavoriteIcon sx={{ color: "#fff" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </CardActionArea>
                                    </Card>
                                    {/* </Link> */}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    :
                    ""
                }
            </Grid>
            </CardContent>
            </Card>
            <Snackbar open={openError?.bool} autoHideDuration={4500} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    {openError.message}
                    {/* Uno de tus invitados ya ha reservado en este turno. ¡Intenta reservar en otro turno! */}
                </Alert>
            </Snackbar>
            {
                openModal &&
                <Modal
                    open={openModal}
                    onClose={() => { onClose() }}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}

                >
                    <div className={classes.paper}>
                        {<PrevReservation
                            idsHorary={selectedIds}
                            idsReserveHorary={selectedIdsHorary}
                            horario={dataAvailableTime}
                            day={moment(isDay).format('YYYY-MM-DD')}
                            amount={amount.data}
                            idDay={idDay}
                            handleReservation={Reservation}
                            changeView={changeView}
                            setOpen={setOpenModal}
                            data={props.location.state}
                            listaInvitados={listaInvitados ? listaInvitados : []}
                        />}
                    </div>

                </Modal>
            }
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

