import React, { useEffect, useCallback, useMemo } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Grid, TextField, Typography } from "@mui/material";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import "../../Pages/Reservation/Reservation.css"
import { Divider } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {CardActionArea } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AgregarInvitados from "../../Components/Modal/AgregarInvitados";
import ReactCardFlip from 'react-card-flip';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import axios from "axios";
import moment from 'moment'
import 'moment/locale/es';
moment.locale('es');

const recently = [
  { nombre: "Andy Jhovany", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan Jhonas", apellido: "Cornejo Chinga", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy Jhovany", apellido: "Espinoza Joaquin", rut: "2-22222", correo: "andy@gmal.com" },
]

const parners = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
]

const invities = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
  { nombre: "Jordan", apellido: "Cornejo", rut: "2-33333", correo: "andy@gmal.com" },
]

const families = [
  { nombre: "Andy", apellido: "Espinoza", rut: "2-22222", correo: "andy@gmal.com" },
]
export const ReservationPending = (props) => {

  const [openModal, setOpenModal] = React.useState(false);
  const [isFlipped, setIsFlipped] = React.useState(false)
  const [openInvitados, setOpenInvitados] = React.useState(false)
  const [parner, setParner] = React.useState(false)
  const [family, setFamily] = React.useState(false)
  const [invitados, setInvitados] = React.useState(false)
  const [resenties, setRecenties] = React.useState(true)
  const [dataReservation, setDataReservation] = React.useState([])

// const myReservationsPending = async (user) => {
//     var today = new Date();
//     var day = today.getDate();
//     var month = today.getMonth() + 1;
//     var year = today.getFullYear();
//     const date =  `${year}-${month}-${day}`;
//     await axios
//         .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/report?pending=0&user=${user}&date=${date}&orderBy=DESC`, {
//             headers: {
//                 Authorization: `Bearer ${Token}`,
//             },
//         })
//         .then((res) => {
//             console.log(res);
//             getReservations(res.data.data)

//         })
//         .catch((error) => {
//             console.error(error);
//         });
// };

  return (
    <Grid>
      
    </Grid>
  )
}