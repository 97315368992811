import { createSlice } from '@reduxjs/toolkit'
// import { RootState } from '../store'

const initialState =
{
  status: 'fetching',
  selectField: {},
  selectReport:{},
  dayStatus: 'fetched',
  errorMessage: undefined
}

const config = createSlice({
  name: 'config',
  initialState,
  reducers: {
    onSelectField (state, { payload }) {
      state.status        = 'fetched'
      state.selectField   = payload
      state.errorMessage = undefined
    },
    onSelectReport (state, { payload }) {
      state.status        = 'fetched'
      state.selectReport   = payload
      state.errorMessage = undefined
    }

}
})

export const selectConfigHoraryState = (state) => state.config

export default config.reducer

export const {
    onSelectField,
    onSelectReport
} = config.actions
