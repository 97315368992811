import React, { useState } from "react"
import { Button, Grid, TextField, TableHead, Typography, TextFieldaphy, Tooltip, ButtonGroup, Autocomplete } from "@mui/material"
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from "react";
import axios from "../../utils/axios";
import RuleIcon from '@mui/icons-material/Rule';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ModalImage from "react-modal-image";
import boleta from '../../assets/images/boleta_imagen.png'
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import { ParticipantsModal } from "./ParticipantsModal";
import AppsIcon from '@mui/icons-material/Apps'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { CustomFullCalendar } from "../../Components/CustomFullCalendar/CustomFullCalendar";
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { h } from "preact";
import moment from "moment/moment";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 350,
    },
}));


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};



export const TransferPending = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [data, setData] = useState([]);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalAmount, setModalAmount] = useState(false);
    const [dataAmount, setDataAmount] = useState({
        id_reservation: null,
        old_amount: 0,
        new_amount: 0
    });
    const [dataPrint, setdataPrint] = useState({
        id: '',
        idreservation: ''
    });
    const [search, setSearch] = useState("");
    const [loadingCompetitor, setLoadingCompetitor] = useState(false);
    const [competitorSelected, setCompetitorSelected] = useState('');
    const [competitor, setCompetitor] = useState([]);
    var Token = localStorage.getItem('token_user')
    const [value, setValue] = React.useState(0);
    const [dataReservationActive, setDataReservationsActive] = React.useState([]);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [messageSuccess, setMessageSuccess] = React.useState("");

    // FILTROS
    const [valueStart, setValueStart] = React.useState('');
    const [valueEnd, setValueEnd] = React.useState('');
    const [changeType, setChangeType] = React.useState('');

    //full calendar / table
    const [typeList, setTypeList] = React.useState('table');

    const handleChangeStart = (e) => {
        setValueStart(e.target.value);

    };
    const handleChangeEnd = (e) => {
        setValueEnd(e.target.value);
    };
    const filterAplication = () => {
        myReservationsActive(valueStart, valueEnd, changeType);
    }
    const handleChangeType = (event) => {
        setChangeType(event.target.value);
    };

    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const seleccionaralumno = (elemento, caso) => {
        switch (caso) {
            case 'Editar':
                setdataPrint(elemento)
                setModalEditar(true);
                break;
            case 'amount':
                // console.log(elemento)
                setDataAmount(prev => ({ ...prev, old_amount: elemento.total, id_reservation: elemento?.idreservation }))
                setModalAmount(true)
                break;
            case 'amount2':
                const { id, total } = elemento?.data;
                setDataAmount(prev => ({ ...prev, old_amount: total, id_reservation: id }))
                setModalAmount(true)
                break;
            default:
                break;
        }
    }

    const updateAmount = async () => {
        await axios
            .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/amount`,
                dataAmount,
                {
                    headers: {
                        Authorization: `Bearer ${Token}`,
                    }
                },
            ).then((response) => {
                setMessageSuccess("Monto editado satisfactoriamente!")
                setOpenSuccess(true);
                setModalAmount(false);
            })
        myReservationsPendings();
        myReservationsActive(moment().format('YYYY-MM-DD'));
    }

    const editar = async (id) => {
        const formFile = new FormData();
        try {
            await axios
                .patch(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/transfer/accept/${id}`,
                    formFile,
                    {
                        headers: {
                            Authorization: `Bearer ${Token}`,
                        }
                    },
                )
                .then((response) => {
                    setData(...response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
            setMessageSuccess("Transferencia validada satisfactoriamente!")
            setOpenSuccess(true);
            setModalEditar(false);
        } catch (error) {
            console.log('error')
        }
        myReservationsPendings();
        myReservationsActive(moment().format('YYYY-MM-DD'));
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const classes = useStyles();

    const myReservationsPendings = async () => {
        var today = new Date();
        var day = today.getDate();
        var month = today.getMonth() + 1;
        var year = today.getFullYear();
        const date = `${year}-${month}-${day}`;
        await axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/transfer/listAll`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                params: {
                    orderBy: 'DESC'
                }
            })
            .then((res) => {
                if (res?.data?.data?.original?.error) {
                    setData([])
                } else {
                    setData(res.data.data)
                }

            })
            .catch((error) => {
                console.error(error);
            });
    };

    const myReservationsPendingsBusqueda = async (term) => {
        var today = new Date();
        var day = today.getDate();
        var month = today.getMonth() + 1;
        var year = today.getFullYear();
        const date = `${year}-${month}-${day}`;
        await axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/transfer/listAll?term=${term}`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            })
            .then((res) => {
                // console.log(res)
                if (res?.data?.data?.original?.error) {
                    setData([])
                } else {
                    setData(res.data.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const myReservationsActive = async (date, dateEnd, type_user) => {
        await axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/report?orderByDate=DESC&status=[0,1]&date=${date ? date : ''}&dateEnd=${dateEnd ? dateEnd : ''}&type_user=${type_user ? type_user : ''}`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            })
            .then((res) => {
                if (res?.data?.data?.original?.error) {
                    setDataReservationsActive([])
                } else {
                    setDataReservationsActive(res.data.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    // const myReservationsActive = async () => {
    //     await axios
    //     .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/report?orderBy=DESC&pending=2`, {
    //         headers: {
    //             Authorization: `Bearer ${Token}`,
    //         },
    //     })
    //     .then((res) => {
    //         console.log("Somos los anulados: ",res.data.data)
    //         if (res?.data?.data?.original?.error) {
    //             console.log('hola')
    //             setDataReservationsActive([])
    //         } else {
    //             console.log(res.data)
    //             setDataReservationsActive(res.data.data)
    //         }
    //         console.log(res)
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //     });
    // }

    const handleBuscador = (event) => {
        try {
            const name = event.target.name;
            const value_term = event.target.value;
            switch (name) {
                case 'search':
                    if (value_term.length > 0) {
                        // if (value == 0) {
                            // myReservationsPendingsBusqueda(value_term)
                        // } else {

                            myReservationsActiveBusqueda(value_term)
                        // }

                    } else {
                        // if (value == 0) {
                        //     myReservationsPendings()
                        // } else {

                            myReservationsActive(moment().format('YYYY-MM-DD'))
                        // }
                    }

                    setSearch(value_term);

                    break;
                default:
                    break;
            }
        } catch (e) {
            console.log(e)
        }
    }

    const myReservationsActiveBusqueda = async (term) => {
        await axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/report?orderByDate=DESC&pending=${1}&term=${term}`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            })
            .then((res) => {
                // console.log(res)
                if (res?.data?.data?.original?.error) {
                    // console.log('hola')
                    setDataReservationsActive([])
                } else {
                    // console.log(res.data)
                    setDataReservationsActive(res.data.data)
                }
                // console.log(res)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        getListaCompetitor()
        // myReservationsPendings()
        myReservationsActive(moment().format('YYYY-MM-DD'))
    }, [value])

    // useEffect(() => {
    //     if(competitor)  myReservationsActive(moment().format('YYYY-MM-DD'))
    // }, [competitor])

    const confirmAssistance = async (idreservation) => {
        const formFile = new FormData();
        await axios
            .patch(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/assistance/${idreservation}`,
                formFile,
                {
                    headers: {
                        Authorization: `Bearer ${Token}`,
                    }
                })
            .then((response) => {
                setData(...response.data)
            })
            .catch((error) => {
                console.log(error)
            })
        myReservationsPendings()
        myReservationsActive()
    }

    const getListaCompetitor = async (e) => {
        setLoadingCompetitor(true);
        await axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/competitor", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            })
            .then((res) => {
                const dataapi = res.data.data || []
                setCompetitor(dataapi)
                // setCompetitorSelected(dataapi[2])
                setLoadingCompetitor(false)
            })
            .catch((error) => {
                setLoadingCompetitor(false)
                console.error(error);
            });
    };


    const [modalDeleteReservation, setModalDeleteReservation] = useState(false)
    const [modalParticipants, setModalParticipants] = useState(false)
    const [rowSelected, setRowSelected] = useState(null)


    const openModalParticipants = (row) => {
        setRowSelected(row)
        setModalParticipants(true)
    }

    var today = new Date()

    const deleteReservation = (row) => {
        setRowSelected(row)
        setModalDeleteReservation(true)
    }

    const handleDeleteReservation = async (id) => {
        setModalDeleteReservation(false)
        await axios
            .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/reservation/${id}`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                }
            })
        myReservationsPendings()
        myReservationsActive()
    }

    return (
        <Grid sx={{bgcolor:'#f4f4f4'}}>
            <Grid container className="containerInvite">
                <Grid container justifyContent={"center"} mt={2}>
                    {
                        typeList == 'table' && (
                            <Grid item xs={12} md={10}>
                                <Link to={'/modulos-administrador'} className='link__css'>
                                    <Typography variant='h6' gutterBottom className='link__css-back'>
                                        <ArrowBackIosIcon className='icon_back' /> Volver
                                    </Typography>
                                </Link>
                            </Grid>
                        )
                    }
                    <Grid item container display='flex' justifyContent='space-between' alignItems='center'
                        spacing={1} xs={12} md={typeList == 'table' ? 10 : 12} sx={{ marginBottom: '8px' }}>
                        <Grid item xs={5} md={3}>
                            <Typography variant={"h5"} className="title__main">
                                {typeList == 'table' ? 'RESERVAS' : 'RESERVAS ACTIVAS'}
                            </Typography>
                        </Grid>
                        <Grid item xs={7} md={6}>
                            {
                                typeList == 'table' && (
                                    <Paper
                                        component="form"
                                        sx={{ p: '0px 1px', display: 'flex', alignItems: 'center' }}
                                    >
                                        <InputBase
                                            name='search'
                                            value={search}
                                            onChange={handleBuscador}
                                            on
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Buscador"
                                        />
                                        <IconButton type="button" sx={{ p: '5px' }} aria-label="search" onClick={() => handleBuscador()}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Paper>
                                )
                            }
                        </Grid>

                        <Grid item container xs={12} md={12} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'end', flexWrap: 'wrap' }}>
                            <ButtonGroup variant="contained" aria-label="button-group-custom-table" style={{ padding: "0px 5px", color: "#808080" }}>
                                <IconButton size="small" color="inherit" aria-label="edit" onClick={() => { setTypeList('table') }}>
                                    <ListAltIcon fontSize='small' />
                                </IconButton>
                                <IconButton size="small" color="inherit" aria-label="edit" onClick={() => { setTypeList('other') }}>
                                    <AppsIcon fontSize='small' />
                                </IconButton>
                            </ButtonGroup>
                        </Grid>
                    </Grid>

                    {
                        typeList == 'table' && (
                            <Grid item xs={12} md={10} className='table' mb={2}>
                                <Box sx={{ width: '100%', bgcolor: '#fff' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs centered value={value} onChange={handleChange} indicatorColor="primary"
                                            textColor="primary" aria-label="basic tabs example">
                                            {/* <Tab label="TRANSFERENCIAS PENDIENTES - EXTERNOS " textColor="#f00" {...a11yProps(0)} /> */}
                                            <Tab label="RESERVAS ACTIVAS" textColor="#0f0"{...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <Grid container justifyContent={"center"}>
                                        <Grid item xs={12} >
                                            {/* <TabPanel value={value} index={0}>
                                                <Grid item xs={12} mt={3}>
                                                    <TableContainer component={Paper} >
                                                        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                                            <TableHead >
                                                                <TableRow style={{ background: "#191D3D" }}>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Fecha reserva</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Reservado por</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Titular</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Total Pagado</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Tipo Reserva</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Imagen Transferencia</TableCell>
                                                                    <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {(rowsPerPage > 0 && data.length > 0
                                                                    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                    : data
                                                                    // .filter(item => item.name_user.toLowerCase().includes(search.toLowerCase()) || item.titular.toLowerCase().includes(search.toLowerCase()))
                                                                )
                                                                    .map((row, i) => (
                                                                        <TableRow key={i}>
                                                                            <TableCell component="th" scope="row" className="cell1">
                                                                                {row.fecha_reserva}
                                                                            </TableCell>
                                                                            <TableCell className="cell">
                                                                                {row.usuario}
                                                                            </TableCell>
                                                                            <TableCell className="cell">
                                                                                {row.titular}
                                                                            </TableCell>
                                                                            <TableCell className="cell">
                                                                                {row.total}
                                                                            </TableCell>
                                                                            <TableCell className="cell">
                                                                                {row.tipo_reserva}
                                                                            </TableCell>
                                                                            <TableCell className="cell" >
                                                                                <div style={{ cursor: 'pointer' }}>
                                                                                    {
                                                                                        row.image ?
                                                                                            <>
                                                                                                <ModalImage
                                                                                                    small={boleta}
                                                                                                    medium={row.image && `https://www.oldmackayans.pidecancha.com/api_oldmackayans/${row.image}`}
                                                                                                    alt={<a style={{ textDecoration: 'none', color: '#fff', display: 'flex' }} href={`https://www.oldmackayans.pidecancha.com/api_oldmackayans/${row.image}`} target="_blank">Ver imagen en pestaña nueva <VisibilityIcon sx={{ marginLeft: '5px' }} /></a>}
                                                                                                />

                                                                                                <Typography sx={{ fontWeight: '500' }}>VER IMAGEN</Typography>
                                                                                            </>
                                                                                            :
                                                                                            <Typography sx={{ fontWeight: '300' }}>imagen no disponible</Typography>
                                                                                    }
                                                                                </div>




                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={4}>
                                                                                        <Tooltip title="Ver imagen">
                                                                                            <IconButton onClick={() => seleccionaralumno(row, 'Editar')}>
                                                                                                <RuleIcon style={{ color: "#469489 " }} />
                                                                                            </IconButton>
                                                                                        </Tooltip>

                                                                                    </Grid>
                                                                                    {
                                                                                        row.total && row.total > 0 && (
                                                                                            <Grid item xs={4}>
                                                                                                <Tooltip title="Validar pago">
                                                                                                    <IconButton onClick={() => seleccionaralumno(row, 'amount')}>
                                                                                                        <PriceCheckIcon style={{ color: "#53a653 " }} />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </Grid>
                                                                                        )
                                                                                    }
                                                                                </Grid>
                                                                            </TableCell>

                                                                        </TableRow>

                                                                    ))}

                                                                {emptyRows > 0 && (
                                                                    <TableRow style={{ height: 53 * emptyRows }}>
                                                                        <TableCell colSpan={12} />
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                            <TableFooter>
                                                                <TableRow>
                                                                    <TablePagination
                                                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                                        colSpan={12}
                                                                        count={data.filter(item => item.usuario.toLowerCase().includes(search.toLowerCase())).length}
                                                                        rowsPerPage={rowsPerPage}
                                                                        page={page}
                                                                        SelectProps={{
                                                                            TextFieldProps: {
                                                                                'aria-label': 'rows per page',
                                                                            },
                                                                            native: true,
                                                                        }}
                                                                        onPageChange={handleChangePage}
                                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                                        ActionsComponent={TablePaginationActions}
                                                                    />
                                                                </TableRow>
                                                            </TableFooter>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </TabPanel> */}
                                            {/* <TabPanel value={value} index={1}> */}
                                                <Grid container item xs={12} mt={1}>
                                                    <Grid item container mb={2} xs={12} display={'flex'} spacing={2} alignItems='center'>
                                                        <Grid mt={2} item xs={12} md={2}>
                                                            <Typography>Filtrar documentos </Typography>
                                                        </Grid>
                                                        <Grid mt={2} item xs={12} md={2} lg={2}>
                                                            <TextField
                                                            fullWidth
                                                                id="date"
                                                                label="Desde"
                                                                type="date"
                                                                size="small"
                                                                onChange={(e) => handleChangeStart(e)}
                                                                value={valueStart}
                                                                // sx={{ marginLeft: '5px' }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                                ActionsComponent={TablePaginationActions}
                                                            />
                                                        </Grid>
                                                        <Grid mt={2} item xs={12} md={2} lg={2}>
                                                            <TextField
                                                            fullWidth
                                                                id="dateEnd"
                                                                size="small"
                                                                label="Hasta"
                                                                type="date"
                                                                onChange={(e) => handleChangeEnd(e)}
                                                                value={valueEnd}
                                                                // sx={{ marginLeft: '5px' }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={2} lg={2}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">
                                                                    Tipo de usuario
                                                                </InputLabel>
                                                                <Select
                                                                    fullWidth
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    // placeholder="Tipo de usuario"
                                                                    label="Tipo de usuario"
                                                                    onChange={handleChangeType}
                                                                    size="small"
                                                                >
                                                                    {type_user_id.map((item) => (
                                                                        <MenuItem
                                                                            // sx={{
                                                                            //     display: "block !important",
                                                                            //     padding: "5px 10px !important",
                                                                            // }}
                                                                            key={item.id}
                                                                            value={item.id}
                                                                        >
                                                                            {item.type}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {/* <Autocomplete
                                                                    id="combo-box-department"
                                                                    size="small"
                                                                    loading={loadingCompetitor}
                                                                    options={competitor || []}
                                                                    getOptionLabel={(field) => field.name}
                                                                    renderInput={(params) => <TextField {...params} label="seleccione un tipo de usuario" size="small" />}
                                                                    sx={{ width: '300px', padding: '8px' }}
                                                                    value={competitorSelected && competitorSelected.id ? competitorSelected : null}
                                                                    onChange={(e, newValue) => {
                                                                        setCompetitorSelected(newValue);
                                                                    }}
                                                                /> */}
                                                            </FormControl>
                                                            {/* </Box> */}
                                                        </Grid>
                                                        <Grid item mt={4} xs={12} md={2} lg={2}>
                                                            <Button fullWidth onClick={() => filterAplication()}  className="btn-cancelar">Aplicar filtros</Button>
                                                        </Grid>

                                                    </Grid>
                                                    <TableContainer component={Paper} >
                                                        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                                            <TableHead >
                                                                <TableRow style={{ background: "#191D3D" }}>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Fecha de reserva</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Reservado por</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Tipo Usuario</TableCell>
                                                                    {/* <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Deporte</TableCell> */}
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Cancha</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Inicio</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Fin</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Total Pagado</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Tipo Reserva</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Imagen Transferencia</TableCell>
                                                                    <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Asistencia</TableCell>
                                                                    <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {(rowsPerPage > 0 && dataReservationActive.length > 0
                                                                    ? dataReservationActive.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                    : dataReservationActive
                                                                    // filter(item => item.data.name_user.toLowerCase().includes(search.toLowerCase()) || item.data.surname_user.toLowerCase().includes(search.toLowerCase()))
                                                                )
                                                                    .map((row, i) => {
                                                                        // console.log('row', row)
                                                                        // console.log('today', today)
                                                                        const [fechaMinima, fechaMaxima] = encontrarFechas(row?.hours);
                                                                        const horaMin = fechaMinima.getHours().toString().padStart(2, '0');
                                                                        const minutosMin = fechaMinima.getMinutes().toString().padStart(2, '0');
                                                                        const segundosMin = fechaMinima.getSeconds().toString().padStart(2, '0');
                                                                    
                                                                        const horaMinima = `${horaMin}:${minutosMin}:${segundosMin}`;
                                                                        
                                                                        const horaMax = fechaMaxima.getHours().toString().padStart(2, '0');
                                                                        const minutosMax = fechaMaxima.getMinutes().toString().padStart(2, '0');
                                                                        const segundosMax = fechaMaxima.getSeconds().toString().padStart(2, '0'); // Rellenar con cero a la izquierda si el día es de un solo dígito
                                                                        const horaMaxima = `${horaMax}:${minutosMax}:${segundosMax}`;


                                                                        const split = row.data.date.split('-')
                                                                        const splitHour = horaMaxima.split(':')
                                                                        const date = new Date(parseInt(split[0]), parseInt(split[1])-1 , parseInt(split[2]), parseInt(splitHour[0]), parseInt(splitHour[1]), parseInt(splitHour[2]))
                                                                        const value2 = date >= today ? true : false


                                                                        return (
                                                                            <TableRow key={i}>
                                                                                <TableCell component="th" scope="row" className="cell1">
                                                                                    {row.data.date}
                                                                                </TableCell>
                                                                                <TableCell className="cell">
                                                                                    {row.data.name_user + ', ' + row.data.surname_user}
                                                                                </TableCell>
                                                                                <TableCell className="cell">
                                                                                    {row.data.name_type_user}
                                                                                </TableCell>
                                                                                {/* <TableCell className="cell">
                                                                                {row.data.name}
                                                                            </TableCell> */}
                                                                                <TableCell className="cell">
                                                                                {row?.hours[0]?.name_field}
                                                                            </TableCell>
                                                                                <TableCell className="cell">
                                                                                {horaMinima.slice(0, -3)}
                                                                            </TableCell>
                                                                            <TableCell className="cell">
                                                                                {horaMaxima.slice(0, -3)}
                                                                            </TableCell>
                                                                                <TableCell className="cell">
                                                                                    {row.data.total}
                                                                                </TableCell>
                                                                                <TableCell className="cell">
                                                                                    {row.data.type_reservation}
                                                                                </TableCell>
                                                                                <TableCell className="cell" style={{ textAlign: "center" }}>
                                                                                    {/* {row.image ?
                                                                                    <a href={`https://www.oldmackayans.pidecancha.com/api_oldmackayans/${row.data.image}`} target="_blank">
                                                                                        <img src={`https://www.oldmackayans.pidecancha.com/api_oldmackayans/${row.data.image}`} width='150px'></img>
                                                                                    </a> : ''} */}
                                                                                    <div style={{ cursor: 'pointer' }}>
                                                                                        {
                                                                                            row.data.image ?
                                                                                                <>
                                                                                                    <ModalImage
                                                                                                        small={boleta}
                                                                                                        // hideDownload
                                                                                                        // hideZoom
                                                                                                        medium={row.data.image && `https://www.oldmackayans.pidecancha.com/api_oldmackayans/${row.data.image}`}
                                                                                                        alt={<a style={{ textDecoration: 'none', color: '#fff', display: 'flex' }} href={`https://www.oldmackayans.pidecancha.com/api_oldmackayans/${row.data.image}`} target="_blank">Ver imagen en pestaña nueva <VisibilityIcon sx={{ marginLeft: '5px' }} /></a>}
                                                                                                    />

                                                                                                    <Typography sx={{ fontWeight: '500' }}>VER IMAGEN</Typography>
                                                                                                </>
                                                                                                :
                                                                                                <Typography sx={{ fontWeight: '300' }}>imagen no disponible</Typography>
                                                                                        }

                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                                                    {row.data.assistance === '1' ? 'Asistió' : 'No asistió'}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {row?.data?.total > 0 &&
                                                                                        <Grid container spacing={1}>
                                                                                            <Grid item xs={4}>
                                                                                                <Tooltip title="Confirmar pago">
                                                                                                    <IconButton onClick={() => seleccionaralumno(row, 'amount2')}>
                                                                                                        <PriceCheckIcon style={{ color: "#469489 " }} />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    }
                                                                                    {
                                                                                        row.data.assistance === '0' && (
                                                                                            <Grid container spacing={1}>
                                                                                                <Grid item xs={4}>
                                                                                                    <Tooltip title="Confirmar asistencia">
                                                                                                        <IconButton onClick={() => confirmAssistance(row.data.id)}>
                                                                                                            <DomainVerificationIcon style={{ color: "#469489 " }} />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        value2 === true && (
                                                                                            <Grid container spacing={1}>
                                                                                                <Grid item xs={4}>
                                                                                                    <Tooltip title="Anular reserva">
                                                                                                        <IconButton onClick={() => deleteReservation(row.data)}>
                                                                                                            <EventBusyIcon style={{ color: "#469489 " }} />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        )
                                                                                    }
                                                                                    <Grid container spacing={1}>
                                                                                        <Grid item xs={4}>
                                                                                            <Tooltip title="Ver participantes">
                                                                                                <IconButton onClick={() => openModalParticipants(row.data)}>
                                                                                                    <VisibilityIcon style={{ color: "#469489 " }} />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}

                                                                {emptyRows > 0 && (
                                                                    <TableRow style={{ height: 53 * emptyRows }}>
                                                                        <TableCell colSpan={12} />
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                            <TableFooter>
                                                                <TableRow>
                                                                    <TablePagination
                                                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                                        colSpan={12}
                                                                        count={dataReservationActive.filter(item => item.data.name_user.toLowerCase().includes(search.toLowerCase())).length}
                                                                        rowsPerPage={rowsPerPage}
                                                                        page={page}
                                                                        SelectProps={{
                                                                            TextFieldProps: {
                                                                                'aria-label': 'rows per page',
                                                                            },
                                                                            native: true,
                                                                        }}
                                                                        onPageChange={handleChangePage}
                                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                                        ActionsComponent={TablePaginationActions}
                                                                    />
                                                                </TableRow>
                                                            </TableFooter>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            {/* </TabPanel> */}

                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                    <TableCell colSpan={12} />
                                                </TableRow>
                                            )}
                                        </Grid>
                                        {/* <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                    colSpan={12}
                                                    count={dataReservationActive.filter(item => item.data.name_user.toLowerCase().includes(search.toLowerCase())).length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        TextFieldProps: {
                                                            'aria-label': 'rows per page',
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter> */}
                                    </Grid>
                                </Box>
                            </Grid>

                        )
                    }
                    {
                        typeList == 'other' && (
                            <>
                                <CustomFullCalendar />
                            </>
                        )
                    }


                </Grid>
            </Grid>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEditar}
                onClose={() => setModalEditar(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEditar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                View
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">
                                {
                                    dataPrint.image ?
                                        <Grid justifyContent="center">
                                            <a href={`https://www.oldmackayans.pidecancha.com/api_oldmackayans/${dataPrint.image}`} target="_blank">
                                                <img src={`https://www.oldmackayans.pidecancha.com/api_oldmackayans/${dataPrint.image}`} width={150}></img>
                                            </a>
                                        </Grid> :
                                        <p>imagen no disponible</p>
                                }


                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEditar(false)}>Omitir</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => editar(dataPrint.id)}>Validar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalDeleteReservation}
                onClose={() => { setModalDeleteReservation(false); setRowSelected(null) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalDeleteReservation}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                ¿Está seguro de anular la reserva realizada por {rowSelected?.name_user || ''}  {rowSelected?.surname_user || ''}?
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalDeleteReservation(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => handleDeleteReservation(rowSelected.id)}>Confirmar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalAmount}
                onClose={() => setModalAmount(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalAmount}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                Editar Monto
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <TextField
                                        id="date"
                                        label="Monto Actual"
                                        fullWidth
                                        type="number"
                                        size="small"
                                        // onChange={(e) => handleChangeStart(e)}
                                        disabled
                                        value={dataAmount?.old_amount}
                                        sx={{ marginBottom: '10px' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                  
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                        id="date"
                                        label="Nuevo Monto"
                                        fullWidth
                                        type="number"
                                        size="small"
                                        onChange={(e) => { setDataAmount(prev => ({ ...prev, new_amount: e.target.value })) }}
                                        value={dataAmount?.new_amount}
                                        sx={{ marginTop: '10px' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container spacing={1} mt={2}>

                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalAmount(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => updateAmount()}>Guardar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
                    <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
                        {messageSuccess}
                    </Alert>
                </Snackbar>
            </Stack>
            <ParticipantsModal
                open={modalParticipants}
                rowSelected={rowSelected}
                onClose={() => setModalParticipants(false)}
            />
        </Grid>
    )
}
const type_user_id = [
    {
        id: 3,
        type: 'SOCIO'
    },
    {
        id: 5,
        type: 'PROFESOR'
    },
    {
        id: 7,
        type: 'EXTERNO'
    }
]


function encontrarFechas(reservas) {
    // console.log(reservas)
    if (!reservas || reservas.length === 0) {
        return [null, null];
    }

    let fechaMinima = convertirAFechaMilisegundos(reservas[0].start);
    let fechaMaxima = convertirAFechaMilisegundos(reservas[0].end);

    reservas.forEach((reserva) => {
        if (validarFechas(reserva)) {
            const fechaInicio = convertirAFechaMilisegundos(reserva.start);
            const fechaFin = convertirAFechaMilisegundos(reserva.end);

            if (fechaInicio < fechaMinima) {
                fechaMinima = fechaInicio;
            }

            if (fechaFin > fechaMaxima) {
                fechaMaxima = fechaFin;
            }
        }
    });

    return [new Date(fechaMinima), new Date(fechaMaxima)];
}

function validarFechas(fechas) {
    const fechaInicio = convertirAFechaMilisegundos(fechas.start);
    const fechaFin = convertirAFechaMilisegundos(fechas.end);

    if (isNaN(fechaInicio) || isNaN(fechaFin)) {
        return false; // Si alguna fecha no es válida, la validación falla
    }

    if (fechaInicio >= fechaFin) {
        return false; // Si la fecha de inicio no es anterior a la fecha de fin, la validación falla
    }

    return true; // Si todas las validaciones pasan, las fechas son válidas
}

function convertirAFechaMilisegundos(fechaString) {
    const [hora, minuto, segundo] = fechaString.split(":");
    const fecha = new Date();
    fecha.setHours(parseInt(hora, 10));
    fecha.setMinutes(parseInt(minuto, 10));
    fecha.setSeconds(parseInt(segundo, 10));
    return fecha.getTime();
}