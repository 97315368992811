import React, { useEffect, useState } from "react"
import { Button, Grid, TextField, TableHead, Typography, TextFieldaphy, Tooltip } from "@mui/material"
import "./ListPartner.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import excel from '../../../assets/icons/excel-icon.png';
import agregar from '../../../assets/icons/agregar.png';
import KeyIcon from '@mui/icons-material/Key';
import axios from "../../../utils/axios";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import BlockIcon from '@mui/icons-material/Block';
import { useAuthStore } from "../../../hooks/useAuthStore";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 350,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const dataInvitados = [
    { id: 1, nombre: "Jordan", apellidos: 'Toledo', rut: "1-222222", correo: "jordan@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 1" },
    { id: 2, nombre: "Jaime", apellidos: 'Lopez', rut: "3-222222", correo: "jaime@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 2" },
    { id: 3, nombre: "Jhon", apellidos: 'Perez', rut: "4-222222", correo: "jhon@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 3" },
    { id: 4, nombre: "Carlos", apellidos: 'Quizpe', rut: "5-222222", correo: "carlos@gmail.com", direccion: "Av 28 Julio", comuna: "Comuna 4" },
];


export const ListPartner = () => {
    const [value, setValue] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [showModalExcel, setModalExcel] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [modalRestore, setModalRestore] = useState(false);
    const [modalEnviar, setModalEnviar] = useState(false);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEliminar2, setModalEliminar2] = useState(false)
    const [data, setData] = useState(dataInvitados);
    const [search, setSearch] = useState("");
    const [archivo, setArchivo] = useState("");
    const [parnersActive, setParnersActive] = useState([]);
    const [parnersInactive, setParnersInactive] = useState([]);
    const [template, setTemplate] = useState([]);
    const [error, setError] = React.useState(null);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [messageSuccess, setMessageSuccess] = React.useState("");
    const [color, setColor]=useState("#191D3D")
    var user = JSON.parse(localStorage.getItem('data_user'))
    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };

    const [parnerSelected, setParnersSelected] = useState({
        id: '',
        name: '',
        surname: '',
        rut: '',
        birthday: '',
        email: '',
        idTypeUser: 3,
        // idCompany: 2,
        // rutcompany: '2222222-2'
    })
    const [parnerNew, setParnerNew] = useState({
        id: '',
        name: '',
        surname: '',
        rut: '',
        birthday: '',
        email: '',
        idTypeUser: 3,
    })
    // const { id, name, surname, rut, birthday, email, idTypeUser } = parnerNew;

    const [token, setToken] = useState(
        sessionStorage.getItem("Token")
    );
    const [snackBarConfig, setSnackBarConfig] = React.useState({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })
    const [invitadoSeleccionado, setInvitadoSeleccionado] = useState({
        id: '',
        nombre: '',
        apellidos: '',
        rut: '',
        correo: '',
        direccion: '',
        comuna: ''
    });

    const [saveFile, setSaveFile] = useState({
        name: '',
        path: '',
        preview: null,
        data: null
    });

    const [errorMessages, setErrorMessages] = React.useState({});
    const [openError, setOpenError] = React.useState(false);
    // const [openSuccess, setOpenSuccess] = React.useState(false);

    const handleChange = (obj, value) => {
        setValue(value)
    }
    const handleCloseExcel = ()=>{
        setModalExcel(false);
        setColor("#191D3D");
        limpiaDatos();
    }
    const getParnersActive = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/partners?status=1", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setParnersActive(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const getParnersActiveSearch = async (term) => {
        axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/partners?status=1&term=${term}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setParnersActive(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const getParnersInactive = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/partners?status=2", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setParnersInactive(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const getParnersInactiveSearch = async (term) => {
        axios
            .get(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/partners?status=2&term=${term}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setParnersInactive(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const seleccionarInvitado = (elemento, caso) => {
        setParnersSelected(elemento);
        switch (caso) {
            case 'Editar':
                setModalEditar(true)
                break;
            case 'Eliminar':
                setModalEliminar(true)
                break;
            case 'Restore':
                setModalRestore(true)
                break;
            default:
                setModalEnviar(true);
                break;
        }
        // (caso === 'Editar') ? setModalEditar(true) : setModalEliminar(true)
    }

    const handleChangeParner = e => {
        // setParnerNew((userValues) => ({
        //     ...userValues,
        //     [e.target.name]: e.target.value,
        // }))
        console.log(e)
        setError('');
        if(e.target.name == 'rut'){
         // setVerify(false)
        
        //   var Fn = {
        //      // Valida el rut con su cadena completa "XXXXXXXX-X"
        //      validaRut: function (rutCompleto) {
        //         if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
        //            return false;
        //         var tmp = rutCompleto.split('-');
        //         var digv = tmp[1];
        //         var rut = tmp[0];
        //         if (digv == 'K') digv = 'k';
        //         return (Fn.dv(rut) == digv);
        //      },
        //      dv: function (T) {
        //         var M = 0, S = 1;
        //         for (; T; T = Math.floor(T / 10))
        //            S = (S + T % 10 * (9 - M++ % 6)) % 11;
        //         return S ? S - 1 : 'k';
        //      }
        //   }
          var foo = e.target.value
        //   .split("-").join("")
          if (foo.length > 0 && foo.length < 11) {
            //  foo = foo.match(new RegExp('.{1,8}', 'g')).join("-");
             setParnersSelected(prev => ({ ...prev, rut: foo, textError: '' }))
          } else if (foo.length == 0) {
             setParnersSelected(prev => ({ ...prev, rut: "", textError: '' }))
          }
    
        }else{
       
        const changedFormValues = {
            ...parnerSelected,
            [e.target.name]: e.target.value,
        };
        changedFormValues.name.trim() !== "" && setError("");
        changedFormValues.surname.trim() !== "" && setError("");
        changedFormValues.rut.trim() !== "" && setError("");
        changedFormValues.birthday.trim() != "" && setError("");
        changedFormValues.email.trim() !== "" && setError("");
        setParnersSelected(changedFormValues);
    }
    }
    // const handleChangeEditParner = e => {
    //     // setParnerNew((userValues) => ({
    //     //     ...userValues,
    //     //     [e.target.name]: e.target.value,
    //     // }))
    //     const changedFormValues = {
    //         ...parnerSelected,
    //         [e.target.name]: e.target.value,
    //     };
    //     changedFormValues.name.trim() !== "" && setError("");
    //     changedFormValues.surname.trim() !== "" && setError("");
    //     changedFormValues.rut.trim() !== "" && setError("");
    //     changedFormValues.birthday.trim() !== "" && setError("");
    //     changedFormValues.email.trim() !== "" && setError("");
    //     setParnersSelected(changedFormValues);
    // }

    const editar = async (id) => {

        if (!parnerSelected.name.trim()) {
            return setError("name");
        }
        if (!parnerSelected.surname.trim()) {
            return setError("surname");
        }
        if (!parnerSelected.rut.trim()) {
            return setError("rut");
        }
        if (!parnerSelected.birthday.trim()) {
            return setError("birthday");
        }
        if (!parnerSelected.email.trim()) {
            return setError("email");
        }
        if (parnerSelected.email.trim() !== "undefined") {
            let posicionArroba = parnerSelected.email.trim().lastIndexOf('@');
            let posicionPunto = parnerSelected.email.trim().lastIndexOf('.');

            if (!(posicionArroba < posicionPunto && posicionArroba > 0 && parnerSelected.email.trim().indexOf('@@') == -1 && posicionPunto > 2 && (parnerSelected.email.trim().length - posicionPunto) > 2)) {
                return setError("emailInvalido");
            }
        }
        // setModalEditar(false);
        const formFile = new FormData();
        formFile.append('name', parnerSelected.name)
        formFile.append('surname', parnerSelected.surname)
        formFile.append('rut', parnerSelected.rut)
        formFile.append('birthday', parnerSelected.birthday)
        formFile.append('email', parnerSelected.email)
        formFile.append('idTypeUSer', 3)
        formFile.append('idcompany', user?.id_company)
        // formFile.append('rutcompany', '2222222-2')
        try {
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/update/${id}`,
                    formFile,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                )
                .then((response) => {
                    setParnersActive(...response.data)
                    setParnersInactive(...response.data)
                    console.log("respuesta", response);
                })
                .catch((error) => {
                    console.log(error)
                })
            limpiaDatos();
            getParnersActive();
            getParnersInactive();
            setMessageSuccess("Registro editado correctamente!")
            setOpenSuccess(true);
            setModalEditar(false);
        } catch (error) {
            console.log('error')
        }
        getParnersActive();
        getParnersInactive();
    }

    const enviar = async () => {

        try {
            const response = await axios
                .post(
                    `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/auth/recover-password/${parnerSelected.rut}`
                )
                .catch((err) => {
                    console.log("Err: ", err);
                });

            console.log(response)
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'success',
                message: "Se le envio un correo con las credenciales ",
            }));
        } catch (error) {
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'error',
                message: "Ocurrio un problema, intentelo mas tarde ",
            }));
        }

        setModalEnviar(false);
    }
    const restore = async (id) => {
        await axios
            .patch(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/restore/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                },
            ).then((response) => {
                // setData(...response.data)
                console.log("respuesta", response);
            })
        setMessageSuccess("Registro restaurado correctmante!")
        setOpenSuccess(true)
        setModalRestore(false);
        getParnersActive();
        getParnersInactive();

    }

    const eliminar = async (id) => {
        await axios
            .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
        setMessageSuccess("Registro deshabilitado correctamente!")
        setOpenSuccess(true)
        setModalEliminar(false);
        getParnersActive();
        getParnersInactive();

        console.log(id)
    };

    const abrirModalInsertar = () => {
        setInvitadoSeleccionado(null);
        setModalInsertar(true);
    }

    const handleSubmit = async (e) => {


        e.preventDefault();
        // setModalInsertar(false);
        if (!parnerSelected.name.trim()) {
            return setError("name");
        }
        if (!parnerSelected.surname.trim()) {
            return setError("surname");
        }
        if (!parnerSelected.rut.trim()) {
            return setError("rut");
        }
        if (!parnerSelected.birthday.trim()) {
            return setError("birthday");
        }
        if (!parnerSelected.email.trim()) {
            return setError("email");
        }
        if (parnerSelected.email.trim() !== "undefined") {
            let posicionArroba = parnerSelected.email.trim().lastIndexOf('@');
            let posicionPunto = parnerSelected.email.trim().lastIndexOf('.');

            if (!(posicionArroba < posicionPunto && posicionArroba > 0 && parnerSelected.email.trim().indexOf('@@') == -1 && posicionPunto > 2 && (parnerSelected.email.trim().length - posicionPunto) > 2)) {
                return setError("emailInvalido");
            }
        }
        const formFile = new FormData();
        formFile.append('name', parnerSelected.name)
        formFile.append('surname', parnerSelected.surname)
        formFile.append('rut', parnerSelected.rut)
        formFile.append('birthday', parnerSelected.birthday)
        formFile.append('email', parnerSelected.email)
        formFile.append('idcompany', user?.id_company)
        formFile.append('idTypeUSer', 3)
        
        try {
            let errorMessage = null
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/register`,
                    formFile,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                )
                .then((response) => {
                    if (response.data.data.message) {
                        errorMessage = response.data.data.message
                    }
                    setParnersActive(...response.data)
                    setParnersInactive(...response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
            limpiaDatos();
            setMessageSuccess(errorMessage != null ? errorMessage : "Correo enviado para el autologin!")
            setOpenSuccess(true);
            setModalInsertar(false);
            setColor("#191D3D");
        } catch (error) {
            console.log('error')
        }
        getParnersActive();
        getParnersInactive();
    };

    const limpiaDatos = () => {
        setParnersSelected((userValues) => ({
            ...userValues,
            name: '',
            surname: '',
            rut: '',
            birthday: '',
            email: '',
            idTypeUser: 3,
            // idCompany: 2,
            rutcompany: ''
        }));
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - parnersActive.length) : 0;

    const handleChangePage = (event, newPage) => {
        console.log(newPage)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const classes = useStyles();

    const subirArchivo = (e) => {
        const { name, value } = e.target;
        setArchivo((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const getDownloadTemplateExcel = async () => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/export-excel", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setTemplate(res.data)
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const handleSearch = (event) => {
        try{   const name = event.target.name;
           const valueInput = event.target.value;
           switch (name) {
              case 'search':
                 if(valueInput.length>3)
                 {
                   value == 0? getParnersActiveSearch(valueInput) : getParnersInactiveSearch(valueInput)
                    setPage(0)
                 }
                 if(valueInput.length==0)
                 {
                    value == 0?  getParnersActive(): getParnersInactive();
                 }
                 setSearch(valueInput);
  
                 break;
              default:
                 break;
           }
        }catch(e){
           console.log(e)
        }
        };

        
    useEffect(() => {
        setData([...data])
        getParnersActive([...parnersActive]);
        getParnersInactive([...parnersInactive]);
        // if (archivo != '') {
        getDownloadTemplateExcel([...template])
        // }

    }, [archivo])
    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };

    const changefile = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        let src, preview, type = file.type;
        setSaveFile(prev => ({ ...prev, data: file, path: src, preview: preview }))
        setColor("#4c9141")
        console.log(file);
    }
    const GenerateUserByExcel = async () => {
        const formFile = new FormData();
        formFile.append('file', saveFile.data || null);
        formFile.append('idTypeUSer', 3);

        try {
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/load-excel`,
                    formFile,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                ).then((res) => {
                    limpiaDatos();
                    setMessageSuccess("Usuarios nuevos generados!")
                    setOpenSuccess(true);
                    setModalExcel(false)
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log('error')
        }

    }

    const handleOpenModalDelete = (rowSelected) => {
        setParnersSelected(rowSelected)
        setModalEliminar2(true)
        console.log('parnerSelected', parnerSelected)
    }

    const handleEliminar = async () => {
        const id = parnerSelected.id
        await axios
        .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/deleteUser/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        setMessageSuccess("Registro eliminado correctamente!")
        setOpenSuccess(true)
        setModalEliminar2(false);
        getParnersActive();
        getParnersInactive();
    }

    const handleDeleteBD = async () => {
        const id = parnerSelected.id
        await axios
        .delete(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/deleteBD/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        setMessageSuccess("Registro eliminado correctamente!")
        setOpenSuccess(true)
        setModalEliminar2(false);
        getParnersActive();
        getParnersInactive();
    }

    return (
        <Grid sx={{bgcolor:'#f4f4f4'}}>
            <Grid container className="containerInvite" >
                <Grid container justifyContent={"center"} mt={2}>
                    <Grid item xs={12} md={10}>
                        <Grid xs={12} mb={2}>
                            <Link to={'/modulos-administrador'} className='link__css'>
                                <Typography variant='h6' gutterBottom className='link__css-back'>
                                    <ArrowBackIosIcon className='icon_back' /> Volver
                                </Typography>
                            </Link>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant={"h5"} className="title__main">SOCIOS</Typography>
                                </Grid>
                                <Grid item xs={8} md={6} mt={1}>
                                    <Paper
                                        component="form"
                                        sx={{ p: '0px 2px', display: 'flex', alignItems: 'center' }}
                                    >

                                        <InputBase
                                            value={search}
                                            onChange={handleSearch}
                                            on
                                            name={'search'}
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Buscador"
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>


                                    </Paper>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    {/* <Button fullWidth className="btn-agregar" ></Button> */}
                                    <Grid display="flex" justifyContent={"flex-end"}>
                                        <IconButton sx={{ marginLeft: '5px' }}
                                            onClick={() => setModalExcel(true)}
                                        >
                                            <img className="btn-agregar-excel" src={excel}></img>
                                        </IconButton>
                                        <IconButton sx={{ marginLeft: '5px' }}
                                            onClick={() => abrirModalInsertar()} >
                                            <img className="btn-agregar-excel" src={agregar}></img>

                                        </IconButton>
                                    </Grid>
                                </Grid>

                            </Grid>
                            {value == 0 ? <Typography variant='body1' gutterBottom>Tienes {parnersActive.length} socios activos.</Typography>
                                : <Typography variant='body1' gutterBottom>Tienes {parnersInactive.length} socios inactivos.</Typography>
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} mt={3}>

                        <Box sx={{ width: '100%', bgcolor: '#fff' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs centered value={value} onChange={handleChange} indicatorColor="primary"
                                    textColor="primary" aria-label="basic tabs example">
                                    <Tab label="SOCIOS ACTIVOS " textColor="#f00" {...a11yProps(0)} />
                                    <Tab label="SOCIOS INACTIVOS " textColor="#f00" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} >
                                    <TabPanel value={value} index={0}>
                                        <Grid item xs={12} mt={3}>
                                            <TableContainer component={Paper} >
                                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                                    <TableHead >
                                                        <TableRow style={{ background: "#191D3D" }}>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Nombres y Apellidos</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Rut</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Fecha de nacimiento</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Correo</TableCell>
                                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(
                                                            rowsPerPage > 0
                                                            ? 
                                                            parnersActive
                                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : 
                                                            parnersActive
                                                        )
                                                        // .filter(item => item.name.toLowerCase().includes(search.toLowerCase()) || item.rut.toLowerCase().includes(search.toLowerCase()))
                                                            .map((row) => (
                                                                <TableRow key={row.id}>
                                                                    <TableCell className="cell">
                                                                        {row.name + " " + row.surname}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.rut}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.birthday}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.email}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid container spacing={1}>
                                                                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                                <Tooltip title="Enviar llave">
                                                                                    <IconButton onClick={() => seleccionarInvitado(row, 'EnviarLlave')}>
                                                                                        <KeyIcon style={{ color: "#e4980d " }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Editar">
                                                                                    <IconButton onClick={() => seleccionarInvitado(row, 'Editar')}>
                                                                                        <EditIcon style={{ color: "#469489 " }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Deshabilitar">
                                                                                    <IconButton onClick={() => seleccionarInvitado(row, 'Eliminar')}>
                                                                                        <BlockIcon style={{ color: "#b10202" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Eliminar">
                                                                                    <IconButton onClick={() => handleOpenModalDelete(row)}>
                                                                                        <DeleteIcon style={{ color: "#b10202" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}

                                                        {/* {emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={6} />
                                                            </TableRow>
                                                        )} */}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25]}
                                                                colSpan={5}
                                                                count={parnersActive.length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                // SelectProps={{
                                                                //     TextFieldProps: {
                                                                //         'aria-label': 'rows per page',
                                                                //     },
                                                                //     native: true,
                                                                // }}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                                ActionsComponent={TablePaginationActions}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Grid item xs={12} mt={3}>
                                            <TableContainer component={Paper} >
                                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                                    <TableHead >
                                                        <TableRow style={{ background: "#191D3D" }}>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Nombres y Apellidos</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Rut</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Fecha de nacimiento</TableCell>
                                                            <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Correo</TableCell>
                                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(rowsPerPage > 0
                                                            ? parnersInactive.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : parnersInactive
                                                        )
                                                        // .filter(item => item.name.toLowerCase().includes(search.toLowerCase()) || item.rut.toLowerCase().includes(search.toLowerCase()))
                                                            .map((row) => (
                                                                <TableRow key={row.id}>

                                                                    <TableCell className="cell">
                                                                        {row.name + " " + row.surname}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.rut}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.birthday}
                                                                    </TableCell>
                                                                    <TableCell className="cell">
                                                                        {row.email}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid container spacing={1}>

                                                                            <Grid item xs={12} display="flex" justifyContent="center">
                                                                                <IconButton onClick={() => seleccionarInvitado(row, 'Editar')}>
                                                                                    <EditIcon style={{ color: "#469489 " }} />
                                                                                </IconButton>
                                                                                <IconButton onClick={() => seleccionarInvitado(row, 'Restore')}>
                                                                                    <RestartAltIcon style={{ color: "#056600" }} />
                                                                                </IconButton>
                                                                                <Tooltip title="Eliminar">
                                                                                    <IconButton onClick={ () => handleOpenModalDelete(row) }>
                                                                                        <DeleteIcon style={{ color: "#b10202" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}

                                                        {/* {emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={6} />
                                                            </TableRow>
                                                        )} */}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                                colSpan={5}
                                                                count={parnersInactive.length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                SelectProps={{
                                                                    TextFieldProps: {
                                                                        'aria-label': 'rows per page',
                                                                    },
                                                                    native: true,
                                                                }}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                                ActionsComponent={TablePaginationActions}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

            </Grid>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEditar}
                onClose={() =>{ setModalEditar(false); limpiaDatos()}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEditar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                EDITAR SOCIOS
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">

                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        size="small"
                                        name="name"
                                        placeholder="Nombres*"
                                        fullWidth
                                        value={parnerSelected.name}
                                        error={error === "name" ? true : false}
                                        helperText={error === "name" ? "Los nombres son un campo requerido" : ""}
                                        onChange={handleChangeParner}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField

                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="surname"
                                        placeholder="Apellidos*"
                                        value={parnerSelected.surname}
                                        onChange={handleChangeParner}
                                        error={error === "surname" ? true : false}
                                        helperText={error === "surname" ? "Los apellidos son un campo requerido" : ""}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField

                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="rut"
                                        placeholder="RUT*"
                                        value={parnerSelected.rut}
                                        onChange={handleChangeParner}
                                        error={error === "rut" ? true : false}
                                        helperText={error === "rut" ? "El RUT es un campo requerido" : ""}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="date"
                                        fullWidth
                                        name="birthday"
                                        placeholder="Fecha de nacimiento"
                                        value={parnerSelected.birthday}
                                        onChange={handleChangeParner}
                                        error={error === "birthday" ? true : false}
                                        helperText={error === "birthday" ? "La fecha de nacimiento es un campo requerido" : ""}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="text"
                                        fullWidth
                                        name="email"
                                        placeholder="Correo*"
                                        value={parnerSelected.email}
                                        onChange={handleChangeParner}
                                        error={error === "email" ? true : false}
                                        helperText={error === "email" ? "El email es un campo requerido" : ""}
                                    />
                                </Grid>

                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEditar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => editar(parnerSelected.id)}>Actualizar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEliminar2}
                onClose={() => {setModalEliminar2(false); limpiaDatos()}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalEliminar2}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >   Estás Seguro que deseas eliminar el socio {parnerSelected && (parnerSelected.name + " " + parnerSelected.surname)}
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEliminar2(false)}>NO</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => parnerSelected.status == '0' ? handleDeleteBD() : handleEliminar()}>Si</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEliminar}
                onClose={() => {setModalEliminar(false); limpiaDatos()}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEliminar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >   Estás Seguro que deseas deshabilitar el socio {parnerSelected && (parnerSelected.name + " " + parnerSelected.surname)}
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEliminar(false)}>NO</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => eliminar(parnerSelected.id)}>Si</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showModalExcel}
                onClose={handleCloseExcel}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showModalExcel}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button href={template.link} target="_blank" variand="contained" fullWidth
                                            sx={{ background: "#191D3D", color: "#fff", padding: "40px 20px", borderRadius: '20px', mt: '4px' }} className="btn-Hover">
                                            DESCARGAR PLANTILLA
                                            <FileDownloadIcon /> </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid item xs={12} sx={{ position: 'relative' }}>
                                            <div className="wrapper">
                                                <div className="file-upload"
                                                    style={{ background: color }}                                                                                                >
                                                    <FileUploadIcon />
                                                    <span style={{ fontSize: '15px' }}>CARGAR</span>
                                                    <input type="file"
                                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                        onChange={changefile}
                                                    />
                                                </div>
                                            </div>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button fullWidth className="btn-aceptar"
                                        onClick={() => { GenerateUserByExcel() }}>
                                        Generar Nuevos Usuarios
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEnviar}
                onClose={() => {setModalEnviar(false); limpiaDatos();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEnviar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                ¿Enviar llave privada al socio {parnerSelected.name}  {parnerSelected.surname}?
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEnviar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => enviar()}>Enviar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalRestore}
                onClose={() => {setModalRestore(false); limpiaDatos()}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalRestore}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                ¿Restaurar al socio {parnerSelected.name}  {parnerSelected.surname}?
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalRestore(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => restore(parnerSelected.id)}>Restaurar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalInsertar}
                onClose={() => {setModalInsertar(false); limpiaDatos()}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalInsertar}>
                    <div className={classes.paper} >
                        <form onSubmit={handleSubmit}>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                AGREGAR SOCIOS
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">

                                <Grid item xs={12}>
                                    <TextField
                                        id="name"
                                        type="text"
                                        size="small"
                                        name="name"
                                        error={error === "name" ? true : false}
                                        helperText={error === "name" ? "Los nombres son un campo requerido" : ""}
                                        value={parnerSelected.name}
                                        placeholder="Nombres"
                                        fullWidth
                                        onChange={handleChangeParner}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="surname"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="surname"
                                        value={parnerSelected.surname}
                                        error={error === "surname" ? true : false}
                                        helperText={error === "surname" ? "Los apellidos son un campo requerido" : ""}
                                        placeholder="Apellidos"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.apellidos : ''}
                                        onChange={handleChangeParner}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        id="rut"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="rut"
                                        value={parnerSelected.rut}
                                        error={error === "rut" ? true : false}
                                        helperText={error === "rut" ? "El RUT es un campo requerido" : ""}
                                        placeholder="RUT"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.rut : ''}
                                        onChange={handleChangeParner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="birthday"
                                        size="small"
                                        type="date"
                                        fullWidth
                                        name="birthday"
                                        error={error === "birthday" ? true : false}
                                        helperText={error === "birthday" ? "La fecha de nacimiento es un campo requerido" : ""}
                                        placeholder="Fecha de nacimiento"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.direccion : ''}
                                        onChange={handleChangeParner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id='email'
                                        size="small"
                                        type="text"
                                        fullWidth
                                        name="email"
                                        // value={parnerSelected.email}
                                        error={error === "email" ? true : false}
                                        helperText={error === "email" ? "El correo es un campo requerido" : ""}
                                        placeholder="Correo"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.correo : ''}
                                        onChange={handleChangeParner}
                                    />
                                </Grid>

                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalInsertar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" type="submit">Agregar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
                    <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
                        {messageSuccess}
                    </Alert>
                </Snackbar>
            </Stack>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    Seleccionar el Socio y/o subir un archivo
                    </Alert>
                </Snackbar>
            </Stack>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
        </Grid>
    )
}
