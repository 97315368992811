import { createSlice } from '@reduxjs/toolkit'

const initialState =
{
  status: 'not-authenticated',
  user: {},
  openSuccess: false,
  openError: false,
  errorMessage: undefined
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
  
    onLogin (state, {payload}) {
      state.status        = 'authenticated'
      state.user          = payload
      state.errorMessage  = undefined
    },
    onOpenSuccess(state, {payload}) {
      state.openSuccess   = payload
    },
    onOpenError(state, {payload}) {
      state.openError   = payload
    }

  }
})

export default authSlice.reducer

export const {
  onLogin,
  onOpenSuccess,
  onOpenError
} = authSlice.actions
