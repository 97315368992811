import React, { useState } from "react"
import { Button, Grid, TextField, TableHead, Typography, TextFieldaphy } from "@mui/material"
import "./ListInvites.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 350,
    },
}));


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const dataInvitados = [
    { id: 1, nombre: "Jordan", apellidos: 'Toledo', rut: "2-222222", correo: "jordan@gmail.com", direccion:"Av 28 Julio", comuna:"Comuna 1" },
    { id: 2, nombre: "Jaime", apellidos: 'Lopez', rut: "2-222222", correo: "jaime@gmail.com", direccion:"Av 28 Julio", comuna:"Comuna 2" },
    { id: 3, nombre: "Jhon", apellidos: 'Perez', rut: "2-222222", correo: "jhon@gmail.com", direccion:"Av 28 Julio", comuna:"Comuna 3" },
    { id: 4, nombre: "Carlos", apellidos: 'Quizpe', rut: "2-222222", correo: "carlos@gmail.com", direccion:"Av 28 Julio", comuna:"Comuna 4" },
];


export const ListInvites = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [data, setData] = useState(dataInvitados);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [modalInsertar, setModalInsertar] = useState(false);

    const [invitadoSeleccionado, setInvitadoSeleccionado] = useState({
        id: '',
        nombre: '',
        apellidos: '',
        rut: '',
        correo: '',
        direccion:'',
        comuna:''
    });

    const seleccionarInvitado = (elemento, caso) => {
        setInvitadoSeleccionado(elemento);
        (caso === 'Editar') ? setModalEditar(true) : setModalEliminar(true)
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setInvitadoSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const editar = () => {
        var dataNueva = data;
        dataNueva.map(invitado => {
            if (invitado.id === invitadoSeleccionado.id) {
                invitado.apellidos = invitadoSeleccionado.apellidos;
                invitado.nombre = invitadoSeleccionado.nombre;
                invitado.rut=invitadoSeleccionado.rut;
                invitado.correo=invitadoSeleccionado.correo;
                invitado.direccion=invitadoSeleccionado.direccion;
                invitado.comuna=invitadoSeleccionado.comuna
            }
        });
        setData(dataNueva);
        setModalEditar(false);
    }

    const eliminar = () => {
        setData(data.filter(invitado => invitado.id !== invitadoSeleccionado.id));
        setModalEliminar(false);
    }

    const abrirModalInsertar = () => {
        setInvitadoSeleccionado(null);
        setModalInsertar(true);
    }

    const insertar = () => {
        var valorInsertar = invitadoSeleccionado;
        valorInsertar.id = data[data.length - 1].id + 1;
        var dataNueva = data;
        dataNueva.push(valorInsertar);
        setData(dataNueva);
        setModalInsertar(false);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const classes = useStyles();
    return (
        <>
            <Grid container className="containerInvite">
                <Grid container justifyContent={"center"} mt={2}>
                    <Grid item xs={12} md={10}>
                        <Grid xs={12} mb={2}>
                            <Link to={'/modulos'} className='link__css'>
                                <Typography variant='h6' gutterBottom className='link__css-back'>
                                    <ArrowBackIosIcon className='icon_back' /> Volver
                                </Typography>
                            </Link>
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Typography variant={"h5"} className="title__main">MIS INVITADOS</Typography>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                    <Button fullWidth className="btn-agregar" onClick={() => abrirModalInsertar()} endIcon={<AddCircleOutlineIcon />}>AGREGAR</Button>
                                </Grid>
                            </Grid>


                            <Typography variant='body1' gutterBottom>Tienes {dataInvitados.length} invitados.</Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} mt={3}>
                        <TableContainer component={Paper} >
                            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                <TableHead >
                                    <TableRow style={{ background: "#191D3D" }}>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Id</TableCell>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Nombres y Apellidos</TableCell>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Rut</TableCell>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Correo</TableCell>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Direccion</TableCell>
                                        <TableCell style={{ textAlign: "start", padding: "12px", color: "#fff" }}>Comuna</TableCell>
                                        <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : data
                                    ).map((row) => (
                                        <TableRow key={row.nombre}>
                                            <TableCell component="th" scope="row" className="cell1">
                                                {row.id}
                                            </TableCell>
                                            <TableCell className="cell">
                                                {row.nombre + " " + row.apellidos}
                                            </TableCell>
                                            <TableCell className="cell">
                                                {row.rut}
                                            </TableCell>
                                            <TableCell className="cell">
                                                {row.correo}
                                            </TableCell>
                                            <TableCell className="cell">
                                                {row.direccion}
                                            </TableCell>
                                            <TableCell className="cell">
                                                {row.comuna}
                                            </TableCell>
                                            <TableCell>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <IconButton onClick={() => seleccionarInvitado(row, 'Editar')}>
                                                            <EditIcon style={{ color: "#e4980d " }} />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <IconButton onClick={() => seleccionarInvitado(row, 'Eliminar')}>
                                                            <DeleteIcon style={{ color: "#b10202" }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={3}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                TextFieldProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

            </Grid>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEditar}
                onClose={() => setModalEditar(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEditar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                EDITAR INVITADOS
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        readOnly
                                        type="text"
                                        name="id"
                                        fullWidth
                                        value={invitadoSeleccionado && invitadoSeleccionado.id}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        name="nombre"
                                        fullWidth
                                        value={invitadoSeleccionado && invitadoSeleccionado.nombre}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        fullWidth
                                        name="apellidos"
                                        value={invitadoSeleccionado && invitadoSeleccionado.apellidos}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        fullWidth
                                        name="rut"
                                        value={invitadoSeleccionado && invitadoSeleccionado.rut}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        fullWidth
                                        name="rut"
                                        value={invitadoSeleccionado && invitadoSeleccionado.correo}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        fullWidth
                                        name="direccion"
                                        value={invitadoSeleccionado && invitadoSeleccionado.direccion}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"

                                        type="text"
                                        fullWidth
                                        name="comuna"
                                        value={invitadoSeleccionado && invitadoSeleccionado.comuna}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEditar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => editar()}>Actualizar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalEliminar}
                onClose={() => setModalEliminar(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalEliminar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                Estás Seguro que deseas eliminar el invitado {invitadoSeleccionado && invitadoSeleccionado.nombre}
                            </Typography>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalEliminar(false)}>NO</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => eliminar()}>Si</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalInsertar}
                onClose={() => modalInsertar(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalInsertar}>
                    <div className={classes.paper} >
                        <form>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                AGREGAR INVITADOS
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="text"
                                        name="id"
                                        fullWidth
                                        value={data[data.length - 1].id + 1}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        size="small"
                                        name="nombre"
                                        placeholder="Nombre*"
                                        fullWidth
                                        value={invitadoSeleccionado ? invitadoSeleccionado.nombre : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField

                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="apellidos"
                                        placeholder="Apellidos*"
                                        value={invitadoSeleccionado ? invitadoSeleccionado.apellidos : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField

                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="rut"
                                        placeholder="RUT*"
                                        value={invitadoSeleccionado ? invitadoSeleccionado.rut : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="text"
                                        fullWidth
                                        name="correo"
                                        placeholder="Correo*"
                                        value={invitadoSeleccionado ? invitadoSeleccionado.correo : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="text"
                                        fullWidth
                                        name="direccion"
                                        placeholder="Direccion*"
                                        value={invitadoSeleccionado ? invitadoSeleccionado.direccion : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        type="text"
                                        fullWidth
                                        name="comuna"
                                        placeholder="Comuna*"
                                        value={invitadoSeleccionado ? invitadoSeleccionado.comuna : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalInsertar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" onClick={() => insertar()}>Agregar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>


            {/* <Modal isOpen={modalEditar}>
                <Grid>
                <div>
                    <h3>Editar País</h3>
                </div>
                </Grid>
                <Grid>
                <div className="form-group">
                    <label>ID</label>
                    <TextField
                    className="form-control"
                    readOnly
                    type="text"
                    name="id"
                    value={invitadoSeleccionado && invitadoSeleccionado.id}
                    />
                    <br />

                    <label>País</label>
                    <TextField
                    className="form-control"
                    type="text"
                    name="nombre"
                    value={invitadoSeleccionado && invitadoSeleccionado.nombre}
                    onChange={handleChange}
                    />
                    <br />

                    <label>apellidos</label>
                    <TextField
                    className="form-control"
                    type="text"
                    name="apellidos"
                    value={invitadoSeleccionado && invitadoSeleccionado.apellidos}
                    onChange={handleChange}
                    />
                    <br />
                </div>
                </Grid>
                <Grid>
                <button className="btn btn-primary" onClick={()=>editar()}>
                    Actualizar
                </button>
                <button
                    className="btn btn-danger"
                    onClick={()=>setModalEditar(false)}
                >
                    Cancelar
                </button>
                </Grid>
            </Modal> */}

            {/* <Modal isOpen={modalEliminar}>
        <Grid>
          Estás Seguro que deseas eliminar el país {invitadoSeleccionado && invitadoSeleccionado.nombre}
        </Grid>
        <Grid>
          <button className="btn btn-danger" onClick={()=>eliminar()}>
            Sí
          </button>
          <button
            className="btn btn-secondary"
            onClick={()=>setModalEliminar(false)}
          >
            No
          </button>
        </Grid>
      </Modal> */}


            {/* <Modal isOpen={modalInsertar}>
        <Grid>
          <div>
            <h3>Insertar País</h3>
          </div>
        </Grid>
        <Grid>
          <div className="form-group">
            <label>ID</label>
            <TextField
              className="form-control"
              readOnly
              type="text"
              name="id"
              value={data[data.length-1].id+1}
            />
            <br />

            <label>País</label>
            <TextField
              className="form-control"
              type="text"
              name="nombre"
              value={invitadoSeleccionado ? invitadoSeleccionado.nombre: ''}
              onChange={handleChange}
            />
            <br />

            <label>apellidos</label>
            <TextField
              className="form-control"
              type="text"
              name="apellidos"
              value={invitadoSeleccionado ? invitadoSeleccionado.apellidos: ''}
              onChange={handleChange}
            />
            <br />
          </div>
        </Grid>
        <Grid>
          <button className="btn btn-primary"
          onClick={()=>insertar()}>
            Insertar
          </button>
          <button
            className="btn btn-danger"
            onClick={()=>setModalInsertar(false)}
          >
            Cancelar
          </button>
        </Grid>
      </Modal> */}

        </>
    )
}