import { api } from './configs/axiosConfigs'

export const ProfileAPI = {
  get: async (token) => {
    console.log(token)
    api.defaults.headers.common['Authorization'] = `${token}`
    const response = await api.get(
      '/auth/me'
    ).then(response => response.data.data)
      .catch((error) => {
        console.error(error)
      })
    return response
  }
}