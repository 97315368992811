import {
    Alert,
    Box,
    Button, Fade, FormControl, Grid, IconButton, InputAdornment, InputBase, InputLabel, MenuItem, Modal,
    Paper, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableFooter,
    TableHead, TablePagination, TableRow, TextField, Tooltip, Typography
} from "@mui/material"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import SearchIcon from '@mui/icons-material/Search'
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../../utils/axios";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Link } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import { useHistory } from "react-router-dom";
import { useHoraryStore } from "../../../hooks/useHoraryStore";
import agregar from '../../../assets/icons/red.png';
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@mui/material/styles';
import Backdrop from "@material-ui/core/Backdrop";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1, 1, 1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: 350,
    },
}));


export const FieldList = () => {
    const classes = useStyles();
    const [search, setSearch] = useState("");
    const [value, setValue] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [fields, setFields] = useState([]);
    const [token, setToken] = useState(
        sessionStorage.getItem("Token")
    );
    const {status, setSelectedField } = useHoraryStore()
    const [ typeField, setTypeField ] = useState([])
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    let history = useHistory();
    const [error, setError] = useState(null);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState("");
    const [parnersActive, setParnersActive] = useState([]);
    const [parnersInactive, setParnersInactive] = useState([]);
    var user = JSON.parse(localStorage.getItem('data_user'))
    const [fieldSelected, setfieldSelected] = useState({
        id: '',
        name: '',
        description: '',
        capacity: '',
        idType_field: '',
    })

    useEffect(()=>{
        getFields()
        getListaDia();
    },[])

    // useEffect(()=>{
    //     getListaDia();
    // },[modalInsertar])

    const handleSearch = (event) => {
        try{   const name = event.target.name;
           const valueInput = event.target.value;
           switch (name) {
              case 'search':
                 if(valueInput.length>3)
                 {
                //    value == 0? getParnersActiveSearch(valueInput) : getParnersInactiveSearch(valueInput)
                    setPage(0)
                 }
                 if(valueInput.length==0)
                 {
                    // value == 0?  getParnersActive(): getParnersInactive();
                 }
                 setSearch(valueInput);
  
                 break;
              default:
                 break;
           }
        }catch(e){
           console.log(e)
        }
    }

    const abrirModalInsertar = () => {
        setModalInsertar(true);
    }

    const getFields = async () => {
        axios
        .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/list", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            setFields(response.data.data)
        })
        .catch((error) => {
            console.error(error);
        });
    };

    const showQR = (qr) => {
        const win = window.open('https://www.oldmackayans.pidecancha.com/api_oldmackayans/' + qr, '_blank')
        win.focus()
    }

    const generateQR = async (id) => {
        const formFile = new FormData()
        await axios
        .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/generateQR/${id}`,
            formFile,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            },
        )
        .then((response) => {
            
        })
        .catch((error) => {
            console.log(error)
        })
        getFields()
    }

    const getListaDia = async (e) => {
        axios
            .get("https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/type", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log('type_field', res.data.data)
                setTypeField(res.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const GenerateRestriccion = (data) =>{
        history.push("/config-render-horary")
        setSelectedField(data)
    }

    const limpiaDatos = () => {
        setfieldSelected((userValues) => ({
            ...userValues,
            name: '',
            description: '',
            capacity: '',
            idType_field: '',
        }));
    };

    const handleChangeField = e => {
        setError('');
        
        const changedFormValues = {
            ...fieldSelected,
            [e.target.name]: e.target.value,
        };
        changedFormValues.name.trim() !== "" && setError("");
        changedFormValues.description.trim() !== "" && setError("");
        changedFormValues.idType_field !== null && setError("");
        setfieldSelected(changedFormValues);
    }
    const handleSubmit = async (e) => {

        e.preventDefault();
        // setModalInsertar(false);
        if (!fieldSelected.name.trim()) {
            return setError("name");
        }
        if (!fieldSelected.description.trim()) {
            return setError("description");
        }
        if (!fieldSelected.idType_field === null) {
            return setError("idType_field");
        }
      
        const formFile = new FormData();
        formFile.append('name', fieldSelected.name)
        formFile.append('description', fieldSelected.description)
        formFile.append('capacity', fieldSelected.capacity)
        formFile.append('idType_field', fieldSelected.idType_field)
        formFile.append('idCompany', user?.id_company)
        // formFile.append('idTypeUSer', 7)
        
        try {
            let errorMessage = null
            await axios
                .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/field/register`,
                    formFile,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                )
                .then((response) => {
                    if (response.data.data.message) {
                        errorMessage = response.data.data.message
                    }
                    // setFields(...response.data)
                    })
                .catch((error) => {
                    console.log(error)
                })
            limpiaDatos();
            setMessageSuccess(errorMessage != null ? errorMessage : "Cancha creada correctamente")
            setOpenSuccess(true);
            setModalInsertar(false);
        } catch (error) {
            console.log('error')
        }
        getFields();
    };


    // const editar = async (id) => {
    //     if (!fieldSelected.name.trim()) {
    //         return setError("name");
    //     }
    //     if (!fieldSelected.surname.trim()) {
    //         return setError("surname");
    //     }
    //     if (!fieldSelected.rut.trim()) {
    //         return setError("rut");
    //     }
    //     if (!fieldSelected.birthday) {
    //         return setError("birthday");
    //     }
    //     if (!fieldSelected.email.trim()) {
    //         return setError("email");
    //     }
    //     if (fieldSelected.email.trim() !== "undefined") {
    //         let posicionArroba = fieldSelected.email.trim().lastIndexOf('@');
    //         let posicionPunto = fieldSelected.email.trim().lastIndexOf('.');

    //         if (!(posicionArroba < posicionPunto && posicionArroba > 0 && fieldSelected.email.trim().indexOf('@@') == -1 && posicionPunto > 2 && (fieldSelected.email.trim().length - posicionPunto) > 2)) {
    //             return setError("emailInvalido");
    //         }
    //     }
    //     setModalEditar(false);
    //     const formFile = new FormData();
    //     formFile.append('name', fieldSelected.name)
    //     formFile.append('surname', fieldSelected.surname)
    //     formFile.append('rut', fieldSelected.rut)
    //     formFile.append('birthday', fieldSelected.birthday)
    //     formFile.append('email', fieldSelected.email)
    //     formFile.append('idcompany', user?.id_company)
    //     formFile.append('idTypeUSer', 7)

    //     try {
    //         await axios
    //             .post(`https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/user/update/${id}`,
    //                 formFile,
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${token}`,
    //                     }
    //                 },
    //             )
    //             .then((response) => {
    //                 setOurtersActive(...response.data)
    //                 setOurtersInactive(...response.data)
    //                 getOurtersActive();
    //                 getOurtersInactive();
    //             })
    //             .catch((error) => {
    //                 console.log(error)
    //             })
    //         limpiaDatos();
    //         setMessageSuccess("Registro editado satisfactoriamente!")
    //         setOpenSuccess(true);
    //         setModalEditar(false);
    //     } catch (error) {
    //         console.log('error')
    //     }
    //     getOurtersInactive();
    //     getOurtersActive();
    // }

    // const enviar = async () => {
    //     try {
    //         const response = await axios
    //             .post(
    //                 `https://www.oldmackayans.pidecancha.com/api_oldmackayans/v1/auth/recover-password/${fieldSelected.rut}`
    //             )
    //             .catch((err) => {
    //                 console.log("Err: ", err);
    //             });

    //         setSnackBarConfig(prev => ({
    //             ...prev,
    //             open: true,
    //             severity: 'success',
    //             message: "Se le envio un correo con las credenciales ",
    //         }));
    //     } catch (error) {
    //         setSnackBarConfig(prev => ({
    //             ...prev,
    //             open: true,
    //             severity: 'error',
    //             message: "Ocurrio un problema, intentelo mas tarde ",
    //         }));
    //     }
    //     setModalEnviar(false);
    // }
    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
    
        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };
    
        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };
    
        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };
    
        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
    
    
        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    const handleChangePage = (event, newPage) => {
        console.log(newPage)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
    <Grid sx={{bgcolor:'#f4f4f4'}}>
        <Grid container className="containerInvite">
            <Grid container justifyContent={"center"} mt={2}>
            <Grid item xs={12} md={10}>
                        <Grid xs={12} mb={2}>
                            <Link to={'/modulos-administrador'} className='link__css'>
                                <Typography variant='h6' gutterBottom className='link__css-back'>
                                    <ArrowBackIosIcon className='icon_back' /> Volver
                                </Typography>
                            </Link>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant={"h5"} className="title__main">CANCHAS</Typography>
                                </Grid>
                                {/* <Grid item xs={8} md={6} mt={1}>
                                    <Paper
                                        component="form"
                                        sx={{ p: '0px 2px', display: 'flex', alignItems: 'center' }}
                                    >

                                        <InputBase
                                            value={search}
                                            onChange={handleSearch}
                                            on
                                            name={'search'}
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Buscador"
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>


                                    </Paper>
                                </Grid> */}
                                <Grid item xs={12} md={9}>
                                    {/* <Button fullWidth className="btn-agregar" ></Button> */}
                                    <Grid display="flex" justifyContent={"flex-end"}>
                                        {/* <IconButton sx={{ marginLeft: '5px' }}
                                            onClick={() => setModalExcel(true)}
                                        >
                                            <img className="btn-agregar-excel" src={excel}></img>
                                        </IconButton> */}
                                      <Tooltip title="Agregar cancha">
                                      <IconButton sx={{ marginLeft: '5px' }}
                                            onClick={() => abrirModalInsertar()}
                                             >
                                            <img className="btn-agregar-excel" src={agregar}></img>
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                </Grid>

                            </Grid>
                            {/* {value == 0 ? <Typography variant='body1' gutterBottom>Tienes {parnersActive.length} socios activos.</Typography>
                                : <Typography variant='body1' gutterBottom>Tienes {parnersInactive.length} socios inactivos.</Typography>
                            } */}
                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <TableContainer component={Paper} >
                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                    <TableHead >
                                        <TableRow style={{ background: "#191D3D" }}>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Nombre</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: "12px", color: "#fff" }}>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(
                                            rowsPerPage > 0
                                            ? 
                                            fields
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            :
                                            fields
                                        )
                                        .map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell className="cell" sx={{ textAlign: 'center' }}>
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                   
                                                        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                           <Grid item>
                                                           <Tooltip title="Generar Restriccion de horarios 90' | 60'">
                                                        <IconButton onClick={() => GenerateRestriccion(row)}>
                                                            <SettingsIcon style={{ color: "#469489 " }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                           </Grid>
                                                            {
                                                            row.qr == null
                                                            ?
                                                            <Grid item>
                                                                <Tooltip title="Generar QR">
                                                                    <IconButton onClick={() => generateQR(row.id)}>
                                                                        <FileUploadIcon style={{ color: "#469489 " }} />
                                                                    </IconButton>
                                                                </Tooltip>                                                                
                                                            </Grid>
                                                            :
                                                            <Grid item>
                                                                <Tooltip title="Ver QR">
                                                                    <IconButton onClick={() => showQR(row.qr)}>
                                                                        <QrCode2Icon style={{ color: "#469489 " }} />
                                                                    </IconButton>
                                                                </Tooltip>                                                          
                                                            </Grid>
                                                            }
                                                        </Grid>
                                                        {/* <Grid container spacing={1}>
                                                            <Grid item xs={4}>
                                                                <IconButton onClick={() => seleccionarInvitado(row, 'EnviarLlave')}>
                                                                    <KeyIcon style={{ color: "#e4980d " }} />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <IconButton onClick={() => seleccionarInvitado(row, 'Editar')}>
                                                                    <EditIcon style={{ color: "#469489 " }} />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <IconButton onClick={() => seleccionarInvitado(row, 'Eliminar')}>
                                                                    <DeleteIcon style={{ color: "#b10202" }} />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid> */}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, 50]}
                                                colSpan={25}
                                                count={parnersActive.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                // SelectProps={{
                                                //     TextFieldProps: {
                                                //         'aria-label': 'rows per page',
                                                //     },
                                                //     native: true,
                                                // }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
            </Grid>
        </Grid>

        <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalInsertar}
                // onClose={() => {setModalInsertar(false); limpiaDatos();}}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            //   style={{  }}
            >
                <Fade in={modalInsertar}>
                    <div className={classes.paper} >
                        <form onSubmit={handleSubmit}>
                            <Typography
                                textAlign="center"
                                variant="h6"
                                id="transition-modal-title"
                                sx={{ color: "#000", fontWeight: "bold" }}
                            >
                                AGREGAR CANCHA
                            </Typography>
                            <Grid container spacing={1} mt={2} justifyContent="center">
                                <Grid item xs={12}>
                                <Typography gutterBottom variant="subtitle2">Nombre*</Typography>
                                    <TextField
                                        type="text"
                                        size="small"
                                        name="name"
                                        // value={familyNew.name}
                                        error={error === "name" ? true : false}
                                        helperText={error === "name" ? "El nombre es un campo requerido" : ""}
                                        // placeholder="Nombre"
                                        fullWidth
                                        onChange={handleChangeField}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                <Typography gutterBottom variant="subtitle2">Descripción*</Typography>
                                    <TextField
                                        type="text"
                                        size="small"
                                        fullWidth
                                        name="description"
                                        // value={familyNew.description}
                                        error={error === "description" ? true : false}
                                        helperText={error === "description" ? "La Descripción es un campo requerido" : ""}
                                        // placeholder="Descripción"
                                        // value={invitadoSeleccionado ? invitadoSeleccionado.apellidos : ''}
                                        onChange={handleChangeField}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography gutterBottom variant="subtitle2">Capacidad*</Typography>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id="outlined-basic"
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">personas</InputAdornment>,
                                        }}
                                        name="capacity"
                                        onChange={handleChangeField}
                                        // value={seletedHorario.duration}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <Typography gutterBottom variant="subtitle2">Tipo de cancha*</Typography>

                                    <FormControl fullWidth>
                                        <Select
                                            error={error === "idType_field" ? true : false}
                                            helperText={error === "idType_field" ? "El idType_field es un campo requerido" : ""}
                                            id="demo-simple-select"
                                            placeholder="Seleccione el tipo de cancha"
                                            onChange={handleChangeField}
                                            value={fieldSelected?.idType_field}
                                            size="small"
                                            name="idType_field"
                                        >
                                            {typeField?.length>0 && typeField.map((item) => (
                                                <MenuItem
                                                    sx={{
                                                        display: "block !important",
                                                        padding: "5px 10px !important",
                                                    }}
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-cancelar" onClick={() => setModalInsertar(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth className="btn-aceptar" type="submit">Agregar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
    </Grid>)
}