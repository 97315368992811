import { onFetchReservationHorary, selectReservationState } from '../redux/slices/reservation'
import { useSelector, useDispatch } from '../redux/store'

export const useReserveStore = () => {
  const dispatch = useDispatch()
  const { status, reservationHorary} = useSelector(selectReservationState)

  const getReservationHorary = async (data) => {
  
    try {
    //   const response = await ReserveAPI.get(idCompetitor, date, idfield)
    //  if(!response){
    //   throw new Error();
    //  }
      dispatch(onFetchReservationHorary(data))
    } catch (error) {
      console.error(error)
    }
  }


  
  return {
    //States
    status, 
    reservationHorary,
    getReservationHorary,
 
  }
}
