import React from 'react';
import { Switch, Route, Redirect, Router, hashHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history'
// import Atributte from '../Pages/Maintainer/Atributte/Atributte';
// import Line from '../Pages/Maintainer/Line/Line';
// import Family from '../Pages/Maintainer/Family/Family';
import { Login } from '../Pages/Login/Login';
import { Main } from '../Pages/Main/Main';
import { Detail } from '../Pages/Detail/Detail';
import { Register } from '../Pages/Register/Register';
import PrivateRoute from './PrivateRoute';
import { ModuleSeleccion } from '../Pages/ModuleMain/ModuleSeleccion';
import { Deportes, DeportesHorario } from '../Pages/Sports/SportsSchedule';
import { Filter } from '../Pages/Filter/Filter';
import { Homes } from '../Pages/Homes/Homes';
import { ListInvites } from '../Pages/ListInvites/ListInvites';
import { Reservation } from '../Pages/Reservation/Reservation';
import { Horario } from '../Pages/Schedule/Schedule';
import { ListaCanchas } from '../Pages/ListCanchas/ListaCanchas';
import { DeportesCancha } from '../Pages/Sports/SportsField';
import MainScreen from '../Pages/Payment';
import { RegisterPayment } from '../Pages/RegisterPayment/RegisterPayment';
import { ListaCanchasHorario } from '../Pages/ListCanchas/ListaCanchasHorario';
import { HorarioCanchas } from '../Pages/Schedule/ScheduleCanchas';
import { Invities } from '../Pages/Invities/Invities';
import { SelectGuestsHorario } from '../Pages/SelectGuests/SelectGuestsHorario';
import { ListProfesores } from '../Pages/Maintainer/ListTeacher/ListTeacher';
import { ListAlumnos } from '../Pages/Maintainer/ListStudent/ListStudent';
import { SelectGuestsCancha } from '../Pages/SelectGuests/SelectGuestsCancha';
import { ListSchools } from '../Pages/Maintainer/ListSchool/ListSchools';
import { ListPartner } from '../Pages/Maintainer/ListPartner/ListPartner';
import { ListFamily } from '../Pages/Maintainer/ListFamily/ListFamily';
import { ScheduleConfiguration } from '../Pages/Maintainer/ScheduleConfigurations/ScheduleConfiguration';
import { ModuleAdmin } from '../Pages/ModuleAdmin/ModuleAdmin';
import { AssignmentHour } from '../Pages/AssignmentHour/AssignmentHour';
import { MyProfile } from '../Pages/MyProfile/MyProfile';
import { Field } from '../Pages/Maintainer/Field/Field';
import {ReservationPending} from '../Pages/Reservation/ReservationPending';
import {TransferPending} from '../Pages/Transfer/TransferPending';
import { session } from '../session/constants';
import { Qr } from '../Pages/Qr/Qr';
import { ReservationInformation } from '../Pages/ReservationInformation/ReservationInformation';
import * as qs from 'qs';
import { ListOuter } from '../Pages/Maintainer/ListOuter/ListOuter';
import { FieldList } from '../Pages/ModuleAdmin/Field/FieldList';
import { ReservationAssistance } from '../Pages/ReservationInformation/ReservationAssistance';
import { GenerateRestriccion } from '../Pages/ModuleAdmin/Field/GenerateRestriccion';
import { ReportRestriccion } from '../Pages/ModuleAdmin/Field/ReportRestriccion';

const newHistory = createBrowserHistory();
const Routes = (props) => {
  const sessionGet = sessionStorage.getItem(session.SESSION_STATE);
  const sessionOn = sessionStorage.getItem(session.SESSION_ON);
  const tipoUsuario = sessionStorage.getItem(session.SESSION_TYPE_USER);
  return (
    <Router history={newHistory}>

      {/* <MainHeader /> */}
      {/* {sessionGet == session.SESSION_ON ? ( */}
      <Switch style={{ height: "100% !important" }}>
        <Route exact path="/" {...props} component={Login} />
        <Route exact path="/login" {...props} component={Login} />
        <Route exact path="/registro" {...props} component={Register} />  
        <Route exact path="/qr" {...props} component={Qr} />
        <Route exact path="/invitation" {...props} component={ReservationInformation} />
        <Route exact path="/field-assistance" {...props} component={ReservationAssistance} />
        <Route exact path="/registro" {...props} component={Register} />        
      </Switch>


      {(tipoUsuario == "ADMINISTRADOR") ? (
        <Switch style={{ height: "100% !important" }}>
          <PrivateRoute exact path="/modulos-administrador" {...props} component={ModuleAdmin} />
          <PrivateRoute exact path="/lista-escuelas" {...props} component={ListSchools} />
          <PrivateRoute exact path="/lista-profesores" {...props} component={ListProfesores} />
          <PrivateRoute exact path="/lista-alumnos" {...props} component={ListAlumnos} />
          <PrivateRoute exact path="/lista-socios" {...props} component={ListPartner} />
          <PrivateRoute exact path="/lista-familia" {...props} component={ListFamily} />
          <PrivateRoute exact path="/lista-externos" {...props} component={ListOuter} />
          <PrivateRoute exact path="/configuracion-horarios" {...props} component={ScheduleConfiguration} />
          <PrivateRoute exact path="/asignacion-horarios" {...props} component={AssignmentHour} />
          <PrivateRoute exact path="/mi-perfil" {...props} component={MyProfile} />
          <PrivateRoute exact path="/lista-canchas" {...props} component={Field} />
          <PrivateRoute exact path="/transfer" {...props} component={TransferPending} />
          <PrivateRoute exact path="/field" {...props} component={FieldList} />
          <PrivateRoute exact path="/config-render-horary" {...props} component={GenerateRestriccion} />
          <PrivateRoute exact path="/report-config-horary" {...props} component={ReportRestriccion} />
        </Switch>
      ) : (
        <Switch style={{ height: "100% !important" }}>
          <PrivateRoute exact path="/modulos" {...props} component={ModuleSeleccion} />
          <PrivateRoute exact path="/mis-reservas" {...props} component={Reservation} />
          <PrivateRoute exact path="/lista-invitados" {...props} component={ListInvites} />
          <PrivateRoute exact path="/horarios" {...props} component={Horario} />
          <PrivateRoute exact path="/lista-canchas" {...props} component={ListaCanchas} />
          <PrivateRoute exact path="/pagos" {...props} component={MainScreen} />
          <PrivateRoute exact path="/registro-exitoso" {...props} component={RegisterPayment} />
          <PrivateRoute exact path="/reservation-pending" {...props} component={ReservationPending} />
          <PrivateRoute exact path="/lista-canchas-horario" {...props} component={ListaCanchas} />
          <PrivateRoute exact path="/horarios-canchas" {...props} component={HorarioCanchas} />
          <PrivateRoute exact path="/mis-invitados" {...props} component={Invities} />
          <PrivateRoute exact path="/seleccionar-invitados-cancha" {...props} component={SelectGuestsCancha} />
          <PrivateRoute exact path="/seleccionar-invitados-horario" {...props} component={SelectGuestsHorario} />
          <PrivateRoute exact path="/deportes-cancha" {...props} component={DeportesCancha} />
          <PrivateRoute exact path="/deportes-horario" {...props} component={DeportesHorario} />
          <PrivateRoute exact path="/favoritos" {...props} component={Main} />
          <PrivateRoute exact path="/mi-perfil" {...props} component={MyProfile} />
        </Switch>
      )}


      {/* {tipoUsuario == "administrador" && <Switch style={{ height: "100% !important" }}>
        <PrivateRoute exact path="/lista-escuelas" {...props} component={ListSchools} />
        <PrivateRoute exact path="/lista-profesores" {...props} component={ListProfesores} />
        <PrivateRoute exact path="/lista-alumnos" {...props} component={ListAlumnos} />
        <PrivateRoute exact path="/lista-socios" {...props} component={ListPartner} />
        <PrivateRoute exact path="/lista-familia" {...props} component={ListFamily} />
        <PrivateRoute exact path="/configuracion-horarios" {...props} component={ScheduleConfiguration} />
        <PrivateRoute exact path="/asignacion-horarios" {...props} component={AssignmentHour} />
        <PrivateRoute exact path="/mi-perfil" {...props} component={MyProfile} />
        <PrivateRoute exact path="/lista-canchas" {...props} component={Field} />
      </Switch>} */}
    </Router>
  )
};

export default Routes;
