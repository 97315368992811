import { combineReducers } from 'redux'
import reservationReducer from './slices/reservation'
import configReducer from './slices/configHorary'
import authReducer from './slices/authStore'

const reducer = combineReducers({
reservation: reservationReducer,
config: configReducer,
auth: authReducer,
})

export default reducer
